import React from "react";
import { AppBar, MenuItemLink, SimpleForm, useAuthProvider, useRedirect } from "react-admin";
import { Box, Typography, withStyles } from "@material-ui/core";
import Logo from "../vermaco_logo_white.png";
import { Button } from "react-admin";
import CryptoJS from "crypto-js";
import { Form } from 'react-final-form';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Link from "../utils/components/Link";
import NotificationsIcon from '@material-ui/icons/Notifications';
import { UserMenu } from "./UserMenu";
import ReferenceInput from "../utils/components/ReferenceInput";
import AutocompleteInput from "../utils/components/AutocompleteInput";
import GlobalContractSearch from "./components/GlobalContractSearch";

const styles = {
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
};


const env = process.env.REACT_APP_ENV;

const CustomAppBar = withStyles( styles )( ( { classes, ...props } ) => {
  const authProvider = useAuthProvider();
  const dateObject = new Date();
  const day = ("0" + dateObject.getDate()).slice(-2);
  const month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
  const md5String = `vm-${day}${month}${dateObject.getFullYear()}-vm`;
  const activationCode = CryptoJS.MD5( md5String ).toString().substr(0,4);
  const redirect = useRedirect();

  return (
    <AppBar color="secondary" style={{ backgroundColor: env === "production" ? 'secondary' : '#B72323' }} userMenu={<UserMenu /> } {...props}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />

      {env === "production" ? (
        <img src={Logo} style={{ width: "80px" }} alt="Vermaco Logo"/>
      ) : 'TESTSYSTEM'}

      <span className={classes.spacer}/>

      <GlobalContractSearch />

      <Box pr="12px">Freischaltcode: <strong>{activationCode}</strong></Box>

      <Button
        title={"Theme wechseln"}
        onClick={() => {
          localStorage.setItem(
            "darkModeEnabled",
            ( localStorage.getItem( "darkModeEnabled" ) === "1" ) ? "0" : "1"
          );

          location.reload()
        }}
        style={{ color: "white"}}
        children={<Brightness4Icon/>}
      />
      <Button style={{ color: "white"}} onClick={
        () => {
          redirect( "list", "/notifications" )
        }
      }>
        <NotificationsIcon style={{maxWidth: "auto"}}/>
      </Button>
    </AppBar>
  );
});

export default CustomAppBar;
