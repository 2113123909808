import React from "react";
import { useEffect } from "react";
import {
  ExportButton,
  FilterButton,
  SortButton,
  CreateButton,
  TopToolbar,
  useListContext,
  sanitizeListRestProps,
  Button,
} from "react-admin";

import RotateLeftIcon from "@material-ui/icons/RotateLeft";

const DefaultListActions = (props) => {
  const {
    className,
    maxResults,
    hsShowFilter,
    hsShowExport,
    showSortButton,
    sortButtonFields,
    allowCreate,
    setFilters,
    hsfilterDefaultValues,
    ...rest
  } = props;
  const { total } = useListContext();

  return (
    <TopToolbar
      className={className}
      {...sanitizeListRestProps(rest)}
      style={{ alignItems: "center" }}
    >
      {allowCreate && <CreateButton label={"neu"} />}
      {showSortButton && (
          <SortButton
            label={"sortieren nach"}
            fields={sortButtonFields}
          />
      )}
      {hsShowFilter && <FilterButton label={"Filter"} />}
      {hsShowFilter && (
        <Button
          title="Filter zurücksetzen"
          onClick={() => setFilters(hsfilterDefaultValues, [])}
          children={<RotateLeftIcon />}
        />
      )}
      {hsShowExport && (
        <ExportButton
          label={"exportieren"}
          disabled={total === 0}
          maxResults={maxResults}
        />
      )}

      {props.children}
    </TopToolbar>
  );
};

export default DefaultListActions;
