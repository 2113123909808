import { MenuItemLink, UserMenu as ReactUserMenu } from "react-admin";
import React, { forwardRef } from "react";
import SettingsIcon from "@material-ui/icons/Settings";

const ConfigurationMenu = forwardRef(({ onClick }, ref) => (
  <MenuItemLink
    ref={ref}
    to="/configuration"
    primaryText="Einstellungen"
    leftIcon={<SettingsIcon />}
    onClick={onClick} // close the menu on click
  />
));

export const UserMenu = props => (
  <ReactUserMenu {...props}>
    <ConfigurationMenu />
  </ReactUserMenu>
);
