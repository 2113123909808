import React from "react";
import { TextInput, useDelete, useRedirect } from "react-admin";
import { Box, Button, List, ListItem, Toolbar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/RemoveCircleOutline";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { makeStyles } from "@material-ui/core/styles";
import { InputContainer, ParagraphBox } from "../../FormElements";
import Typography from "@material-ui/core/Typography";

const ContactFormIterator = (props) => {
  const total = props.fields?.value?.length ?? 0;
  const { inputClassName = null } = props;
  const [ deleteOne ] = useDelete()


  const addButtonClickHandler = () => {
    props.fields.push({
                        name: '',
                        phone: '',
                        mail: '',
                      });
  }

  const classes = useStyles();

  return (
    <List>
      { props.fields.map( ( item, index ) => {
          return <ListItem key={ index } className={ `${classes.listItem} ${classes.listItemShadow}` }>
            <ParagraphBox style={ { width: '100%' } }>
              <InputContainer left>
                <TextInput helperText={ false } className={ inputClassName } label={ "Name" } source={ `${ item }.name` } fullWidth />
              </InputContainer>
              <InputContainer center>
                <TextInput helperText={ false } className={ inputClassName } label={ "E-Mail" } source={ `${ item }.mail` } fullWidth />
              </InputContainer>
              <InputContainer right>
                <TextInput helperText={ false } className={ inputClassName } label={ "Telefon" } source={ `${ item }.phone` } fullWidth />
              </InputContainer>
            </ParagraphBox>

            <Box display={ 'flex' } flexDirection={ "column" } justifyContent={ "center" } ml={ "0.5rem" }>
              <Button
                color={ 'primary' }
                title="Position entfernen"
                style={ { minWidth: '32px' } }
                onClick={ () => {
                  props.fields.remove( index )
                  if( props.fields?.value[ index ]['@id'] ) {
                    deleteOne(
                      'contacts',
                      props.fields?.value[ index ][ '@id' ],
                      props.fields?.value[ index ],
                      {
                        onSuccess: () => {
                          notify( `Eintrag erfolgreich entfernt`, { type: 'success' } );
                          setLoading( false )
                        },
                        onFailure: ( error ) => {
                          setLoading( false )
                          notify( error.message || `Fehler beim entfernen`, { type: 'warning', multiLine: true }
                          )
                        }
                      } )
                  }
                }}>
                <CloseIcon />
              </Button>
            </Box>
          </ListItem>
      } ) }

      <ListItem key={ 'actionButtons' } className={ classes.listItem }>
        <Button
          color={ 'primary' }
          label="ra.action.add"
          onClick={ addButtonClickHandler }
        >
          <AddIcon />&nbsp;Ansprechpartner Hinzufügen
        </Button>
      </ListItem>
    </List>
  )
}

const useStyles = makeStyles( ( theme ) => ( {
  listItem: {
    padding: '0 5px 0 5px',
    margin: '0 -5px',
    width: 'calc( 100% + 10px )',
    display: 'flex',
    backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.default,
    // borderBottom: "2px solid rgba(128,128,128, 0.75)",
    // '&:nth-child(odd)': {
    //   // boxShadow: "rgb(0,0,0,0.26) 0px 2px 20px 0px",
    //   backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.odd,
    // },
  },

  listItemShadow: {
    '&:hover': {
      backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.hover,
    },
  },

  listItemControl: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center"
  },

  currentPos: {
    display: "flex",
    alignItems: "center",
  },
  textInput: {
    margin: "8px 3px 4px 3px",
    float: 'left',
  }
} ) )

export default ContactFormIterator;
