import React from "react";
import { linkToRecord, TextField, useListContext } from "react-admin";
import { Box, withStyles } from "@material-ui/core";
import { applicationFilter, filterDefaultValues } from "./components/Filter";
import HotGroupedList from "../utils/components/HotGroupedList";
import { useStyles } from "../contracts/ContractList";
import { useStyles as useTableRowStyles } from "../contracts/components/Timeline/ContractTableItem";
import { groupByEntity } from "./components/groupByContract";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import DefaultPagination from "../utils/DefaultPagination";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import DateField from "../utils/components/DateField";
import { ApplicationButton } from "./components/ApplicationButton";
import { defaultButtonStyle } from "../utils/components/styles/ButtonStyles";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import moment from "moment";
import DeleteIcon from '@material-ui/icons/Delete';
import EntityLink from "../utils/components/EntityLink";
import IconLink from "@material-ui/icons/InsertLink";
import Link from "../utils/components/Link";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Grid from "@material-ui/core/Grid";

const styles = {
  rowCell: {
    verticalAlign: "middle",
  },
};

const HotCenteredList = withStyles( styles )( HotGroupedList )

const AdditionalDistanceField = ( { additionalData } ) => {
  const additionalDistance = additionalData?.additionalDistance ?? null
  const distanceExceeded = additionalData?.allowedAdditionalDistanceExceeded ?? null

  const checkboxStyles = { fontSize: '1.25rem', color: distanceExceeded ? "grey" : "rgb(40, 167, 69)" }

  return <Box>
    {additionalDistance && <Box display={"flex"}>
      Berechnete Mehrkilometer über Wohnort: {additionalDistance} km<br/>
    </Box>}

    {distanceExceeded !== null && <Box display={"flex"} alignItems={"center"}>
      Wird die erlaubten Mehrkilometer nicht überschreiten: <CheckBoxIcon style={checkboxStyles}/>
    </Box>}
  </Box>
}

const FlexTimesField = ( { additionalData, contract } ) => {
  const hasAddtionalData = typeof additionalData === "object";

  let pickupTimeString
  let deliveryTimeString

  if( hasAddtionalData && 'pickupTimeFrom' in additionalData && 'pickupTimeTo' in additionalData ) {
    let pickupDateString = moment( additionalData.pickupDate ).format( 'DD.MM.YYYY' );
    pickupTimeString = `${pickupDateString} ${additionalData.pickupTimeFrom}-${additionalData.pickupTimeTo}`;
  } else {
    pickupTimeString = `${contract.pickupDate} ${contract.pickupTimeFrom}-${contract.pickupTimeTo}`
  }

  if( hasAddtionalData && 'deliveryTimeFrom' in additionalData && 'deliveryTimeTo' in additionalData ) {
    let deliveryDateString = moment( additionalData.deliveryDate ).format( 'DD.MM.YYYY' );
    deliveryTimeString = `${deliveryDateString} ${additionalData.deliveryTimeFrom}-${additionalData.deliveryTimeTo}`;
  } else {
    deliveryTimeString = `${contract.deliveryDate} ${contract.deliveryTimeFrom}-${contract.deliveryTimeTo}`

  }

  return <Box>
    <Grid container>
      <Grid item xs={6}>
        Ab: {contract.pickupLocation.ort}
      </Grid>
      <Grid item xs={6}>
        {pickupTimeString}
      </Grid>
    </Grid>
    <Grid container>
      <Grid item xs={6}>
        Zu: {contract.deliveryLocation.ort}
      </Grid>
      <Grid item xs={6}>
        {deliveryTimeString}
      </Grid>
    </Grid>
  </Box>
}

const BundleApplication = ( { group } ) => {
  const classes = useStyles();
  const rowClasses = useTableRowStyles();

  const bundle = group.entity;
  const contracts = bundle.contractBundles.map( item => item.contract );

  let contractInfos = <span>
    Fahrtpaket {bundle.bundle} aus {contracts.map( function( item, count ) {
    return item.auftragsnummer+( count < contracts.length-1 ? ', ' : '' );
  } )} ({group.applications.length} Bewerbung/en)
  </span>

  let rows = [
    <TableRow>
      <TableCell component="th" colSpan={20} className={classes.dayRow}>
        <Typography className={classes.day} variant="subtitle2" gutterBottom style={{ display: "inline-block", marginRight: "5px" }}>
          {contractInfos}
        </Typography>
      </TableCell>
    </TableRow>
  ]

  for( const application of group.applications ) {
    const row = <TableRow className={rowClasses.root}>

      <TableCell style={{ verticalAlign: "middle" }}>
        <DateField record={application} source={"createdAt"} label={"Beworben am"} displayTime={true}/>
      </TableCell>

      <TableCell style={{ verticalAlign: "middle" }}>
        <TextField record={application} source={"translatedStatus"} label={"Status"}/>
      </TableCell>

      <TableCell style={{ verticalAlign: "middle" }}>
        <TextField record={application} source={"driver.name"} label={"Fahrer"}/>
      </TableCell>

      <TableCell>
        {contracts?.map( function( contract ) {
          const dataEntry = application.additionalData[ contract._id ]

          return <Grid container className={classes.gridContainer}>
            <Grid item xs={2} style={{ verticalAlign: "center", textAlign: "left" }}>
              <EntityLink record={contract} style={{ justifyContent: "flex-start" }}>{contract.auftragsnummer}</EntityLink>
            </Grid>
            <Grid item xs={6}>
              <FlexTimesField additionalData={dataEntry} contract={contract}/>
            </Grid>
            <Grid item xs={4}>
              <AdditionalDistanceField additionalData={dataEntry}/>
            </Grid>
          </Grid>
        } )}
      </TableCell>

      <TableCell style={{ verticalAlign: "middle" }}>
        <Box display={"flex"}>
          <ApplicationButton record={application} text={"Annehmen"} style={defaultButtonStyle} value={"accepted"} showBundleDialog={application.contract?.isPartOfBundle}/>
          <ApplicationButton record={application} text={"Ablehnen"} style={defaultButtonStyle} value={"declined"} icon={<RemoveCircleIcon style={{ color: "#F44336" }}/>}/>
          <ApplicationButton record={application} text={"Löschen ohne Fahrerbenachrichtigung"} style={defaultButtonStyle} value={"deleted"} icon={<DeleteIcon/>}/>
        </Box>
      </TableCell>
    </TableRow>

    rows.push( row )
  }

  return rows
}

const ContractApplication = ( { group } ) => {
  const classes = useStyles();
  const rowClasses = useTableRowStyles();

  const contract = group.entity;
  const additionalRide = group.additionalRide;

  const BundleLink = ( { contract } ) => {
    let links = []
    for( const bundle of contract.bundles ) {
      links.push( <Link to={() => linkToRecord( '/bundles', bundle[ '@id' ], 'edit' )}>
        <strong style={{ color: "red", marginRight: "5px" }}> AUS PAKET</strong>
        <IconLink display={"inline-block"} style={{ marginBottom: "-6px" }}/>
        {bundle?.bundle}
      </Link> )
    }

    return links
  }

  const PickupLocation = ( { contract } ) => (
    <strong>{contract.pickupLocation.ort}</strong>
  );

  const DeliveryLocation = ( { contract } ) => (
    <strong>{contract.deliveryLocation.ort}</strong>
  );

  const ContractInfos = ( { contract } ) => {
    let item = [
      <span>
        {contract.pickupDate} {contract.pickupTimeFrom}-{contract.pickupTimeTo} {contract?.isPartOfBundle && <BundleLink contract={contract}/>} | <PickupLocation contract={contract}/> -&gt;{contract.deliveryDate} {contract.deliveryTimeFrom}-{contract.deliveryTimeTo} | <DeliveryLocation contract={contract}/>
      </span>
    ]

    if( additionalRide ) {
      item.push(
        <span> -&gt; {additionalRide.deliveryDate} {additionalRide.deliveryTimeFrom}-{additionalRide.deliveryTimeTo} | <strong>{additionalRide.deliveryLocation.ort}</strong></span>
      )
    }

    return item
  }


  let rows = [
    <TableRow>
      <TableCell component="th" colSpan={20} className={classes.dayRow}>
        <Typography className={classes.day} variant="subtitle2" gutterBottom style={{ display: "inline-block", marginRight: "5px" }}>
          Auftrag {contract.auftragsnummer} | {contract.licensePlate} ({group.applications.length} Bewerbung/en)
        </Typography>

        <ContractInfos contract={contract}/>
      </TableCell>
    </TableRow>
  ]

  for( const application of group.applications ) {

    const row = <TableRow className={rowClasses.root}>

      <TableCell style={{ verticalAlign: "middle" }}>
        <DateField record={application} source={"createdAt"} label={"Beworben am"} displayTime={true}/>
      </TableCell>

      <TableCell style={{ verticalAlign: "middle" }}>
        <TextField record={application} source={"translatedStatus"} label={"Status"}/>
      </TableCell>

      <TableCell style={{ verticalAlign: "middle" }}>
        <TextField record={application} source={"driver.name"} label={"Fahrer"}/>
      </TableCell>

      <TableCell>

        <Grid container className={classes.gridContainer}>
          <Grid item xs={2}>
            <EntityLink record={contract}>{contract.auftragsnummer}</EntityLink>
            {/*{application.additionalRide && <>*/}
            {/*  <br />*/}
            {/*  <EntityLink record={ application.additionalRide }>{ application.additionalRide.auftragsnummer }</EntityLink>*/}
            {/*</>}*/}
          </Grid>
          <Grid item xs={6}>
            <FlexTimesField additionalData={application.additionalData} contract={contract}/>
          </Grid>
          <Grid item xs={4}>
            <AdditionalDistanceField additionalData={application.additionalData}/>
          </Grid>
        </Grid>

        {application.additionalRide && <Grid container className={classes.gridContainer}>
          <Grid item xs={2}>
            <EntityLink record={application.additionalRide}>{application.additionalRide.auftragsnummer}</EntityLink>
          </Grid>
          <Grid item xs={6}>
            <FlexTimesField additionalData={application.additionalData} contract={application.additionalRide}/>
          </Grid>
          <Grid item xs={4}>
          </Grid>
        </Grid>}
      </TableCell>

      <TableCell style={{ verticalAlign: "middle" }}>
        <Box display={"flex"}>
          <ApplicationButton record={application} text={"Annehmen"} style={defaultButtonStyle} value={"accepted"} showBundleDialog={application.contract?.isPartOfBundle}/>
          <ApplicationButton record={application} text={"Ablehnen"} style={defaultButtonStyle} value={"declined"} icon={<RemoveCircleIcon style={{ color: "#F44336" }}/>}/>
          <ApplicationButton record={application} text={"Löschen ohne Fahrerbenachrichtigung"} style={defaultButtonStyle} value={"deleted"} icon={<DeleteIcon/>}/>
        </Box>
      </TableCell>
    </TableRow>

    rows.push( row )
  }

  return rows
}

const ApplicationListView = () => {
  const { ids, data } = useListContext();
  const classes = useStyles();

  if( !ids ) {
    return null;
  }
  const records = ids.map( ( id ) => data[ id ] );
  const { applicationGroups, applicationsByEntity } = groupByEntity( records );

  const listItems = applicationGroups.map( ( id ) => {
    const group = applicationsByEntity[ id ];
    if( group.type === 'contract' ) {
      return <ContractApplication group={group}/>
    }
    if( group.type === 'bundle' ) {
      return <BundleApplication group={group}/>
    }

  } )

  return (
    <Box>
      <DefaultPagination/>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeadCell}>
                Beworben am
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                Status
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                Fahrer
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {listItems}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

const ApplicationsList = ( props ) => (
  <HotCenteredList
    sort={"contract.datum"}
    sortOrder={"ASC"}
    allowCreate={false}
    hasEdit={false}
    {...props}
    filters={applicationFilter}
    filterDefaultValues={filterDefaultValues}>
    <ApplicationListView {...props} />
  </HotCenteredList>
);

export default ApplicationsList;
