import React, { useState } from "react";

import { BulkExportButton, Button, useListContext, useRefresh } from "react-admin";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

import { contractsFilters, contractsFiltersDefaultValues } from "./components/ContractsFilter";
import groupByDay from "./components/Timeline/groupByDay";
import HotGroupedList from "../utils/components/HotGroupedList";
import ContractTableItem from "./components/Timeline/ContractTableItem";
import DefaultPagination from "../utils/DefaultPagination";
import { Box } from "@material-ui/core";
import { MultipleDriverField } from "./components/MultipleDriverField";
import { BulkToggleDisplayInOfferList } from "./components/ListButtons/ToggleDisplayInOfferListButton";
import { CreateBulkBundleButton } from "./components/ListButtons/CreateBundleButton";
import DefaultListActions from "../utils/DefaultListActions";
import Link from "../utils/components/Link";
import { BulkConfirmButton } from "./components/ListButtons/ConfirmButton";


export const useStyles = makeStyles( theme => ( {
  root: {
    width: "100%",
    margin: "1rem auto",
  },
  tableContainer: {
    overflowX: "initial",
    overflowY: "scroll",
    maxHeight: "calc(100vh - 250px)",
    boxShadow: "0px 0px 1px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
  },
  day: {
    marginTop: "10px",
    fontWeight: "bold",
  },
  dayRow: {
    backgroundColor: theme.overrides.dayRow.backgroundColor,
    position: 'sticky',
    top: '-1px',
    zIndex: '1',
  },
  tableHeadCell: {
    padding: "24px",
  },
  responsiveBox: {
    padding: "0 0.25rem 0.5rem",
    fontSize: "0.875rem",
    maxWidth: "1280px",
    [theme.breakpoints.down('lg')]: {
      maxWidth: '860px',  // Set the max-width for small screens
    },
  },
  gridContainer: {
    borderBottom: '1px solid rgb(224,224,224)',
    alignItems: 'center',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
} ) )

export const getDayString = ( date ) =>
  new Date( date ).toLocaleDateString( "de-DE", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  } );

const ContractListView = () => {
  const [selectedContracts, setSelectedContracts] = useState( new Set() );
  const { ids, data } = useListContext();
  const classes = useStyles();
  const refresh = useRefresh()

  if( !ids ) {
    return null;
  }

  const records = ids.map( ( id ) => data[ id ] );
  const { days, eventsByDay } = groupByDay( records );

  if( days.length === 0 ) {
    return <></>;
  }

  const listItems = days.map( ( day ) => {
    const contractTableDayGroup = [<TableRow key={day.toString()}>
                                     <TableCell component="th" colSpan={20} className={classes.dayRow}>
                                       <Typography
                                         className={classes.day}
                                         variant="subtitle2"
                                         gutterBottom
                                       >
                                         {getDayString( day )} ({eventsByDay[ day ].length} Fahrt/en)
                                       </Typography>
                                     </TableCell>
                                   </TableRow>]
    let itemsByDay = []
    const contractTableItems = eventsByDay[ day ].map( ( record ) => {
      let contract = record
      if( record.anschlussvon && data[ record.anschlussvon ] ) {
        contract = data[ record.anschlussvon ]
      }
      if( itemsByDay.includes( contract['@id'] ) ) {
        return null
      }
      itemsByDay.push( contract['@id'] )
      return  <ContractTableItem record={contract} key={contract['@id']} selectedContracts={selectedContracts} setSelectedContracts={setSelectedContracts}/>
    } )

    return contractTableDayGroup.concat( contractTableItems )
  } )

  return (
    <Box>
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <Box className={classes.responsiveBox}>
        {selectedContracts.size > 0 && <>
          <MultipleDriverField contracts={selectedContracts} setSelectedContracts={setSelectedContracts} style={{ marginTop: "5px" }}/>&nbsp;
          <CreateBulkBundleButton contracts={selectedContracts} setSelectedContracts={setSelectedContracts}/>&nbsp;
          <BulkConfirmButton contracts={selectedContracts} setSelectedContracts={setSelectedContracts} />&nbsp;
          <BulkToggleDisplayInOfferList contracts={selectedContracts} setSelectedContracts={setSelectedContracts}/>&nbsp;
          <Button onClick={() => setSelectedContracts(new Set())} label="Auswahl aufheben" variant="outlined" style={{ marginTop: "5px" }}/>
        </>}
        </Box>

        <DefaultPagination/>
      </Box>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeadCell} colSpan={3}>
                Nr
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                Fahrer / Kunde
              </TableCell>
              <TableCell className={classes.tableHeadCell} colSpan={3}>
                Tour
              </TableCell>
              <TableCell className={classes.tableHeadCell}>Retoure</TableCell>
              <TableCell className={classes.tableHeadCell} colSpan={20}>
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listItems}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const ContractList = (props) => {
  return (
    <HotGroupedList
      filter={{ anschlussvon: '' }}
      filters={contractsFilters}
      filterDefaultValues={contractsFiltersDefaultValues}
      allowCreate={true}
      sort="datum"
      bulkActionButtons={<BulkExportButton />}
      listActions={<DefaultListActions
        hsfilterDefaultValues={contractsFiltersDefaultValues}
        hsShowFilter={true}
        allowCreate={true}
      >
        <Link to="/contracts/create-multiple">Massenanlage</Link>
      </DefaultListActions>}
      {...props}
    >
      <ContractListView {...props} key={"contractlistview"}/>
    </HotGroupedList>
  );
};


export { ContractList };
