
import { Loading as DefaultLoading } from "ra-ui-materialui";
import { withStyles } from "@material-ui/core";

const styles = {
  container: {
    position: "absolute",
    zIndex: 100000,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: "rgba(255, 255, 255, 0.8)",
  },
  message: {
    position: "fixed",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  }
}

const Loading = withStyles(styles)(DefaultLoading);

export default ( { primaryText = "custom.page.loading", secondaryText = "custom.message.loading" } ) => {
  return (
    <Loading
      loadingPrimary={primaryText}
      loadingSecondary={secondaryText}
    />
  );
}
