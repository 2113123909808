import { FieldGuesser } from "@api-platform/admin";
import React from "react";
import { BooleanField } from "react-admin";
import HotList from "../utils/components/HotList";

const ServiceList = (props) => (
  <HotList {...props} sort={"sortierungsnummer"} filterDefaultValues={{ boolchar_cleared: false }}>
      <FieldGuesser source={"name"}/>
      <FieldGuesser source={"beschreibung"}/>
      <BooleanField source={"kostenlos"}/>
      <FieldGuesser source={"stundenpreis"}/>
      <FieldGuesser source={"anschriftnotwendig"}/>
      <FieldGuesser source={"sortierungsnummer"}/>
  </HotList>
);

export default ServiceList;
