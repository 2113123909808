import { AutocompleteInput } from "react-admin";


export const LocationDropdown = props => {
  return <AutocompleteInput
    choices={[
      { id: 'Frontpartie', name: 'Frontpartie' },
      { id: 'Linke Seite', name: 'Linke Seite' },
      { id: 'Heckpartie', name: 'Heckpartie' },
      { id: 'Dach', name: 'Dach' },
      { id: 'Innenraum', name: 'Innenraum' },
      { id: 'Rechte Seite', name: 'Rechte Seite' },
      { id: 'Kofferraum', name: 'Kofferraum' },
    ]}
    {...props}>

  </AutocompleteInput>
}
