import { useRefresh, Button } from "react-admin";
import ReplayIcon from "@material-ui/icons/Replay";
import * as React from "react";

const RefreshButton = ( props ) => {
  const refresh = useRefresh();

  return (
    <Button
      label={"Neu laden"}
      onClick={() => refresh()}
      children={<ReplayIcon/>}
      style={{ margin: '0 1rem' }}
      {...props}
    />
  );
};

export default RefreshButton;
