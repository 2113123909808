import { SearchInput, BooleanInput } from "react-admin";
import "moment/locale/de";
import React from "react";
import { CustomerReferenceInput } from "../../customers/components/CustomerReferenceInput";
import { DriverReferenceInput } from "../../drivers/components/DriverReferenceInput";

export const userFilter = [
  <SearchInput placeholder="Nutzername" source="_name" alwaysOn />,
  <CustomerReferenceInput source="customer_and_subs" label={"Kundenkonto"} alwaysOn />,
  <DriverReferenceInput label="Fahrer" source="logistics_and_subs" alwaysOn />,
  <BooleanInput
    label="Nur aktive anzeigen"
    source="active"
    alwaysOn
  />,
];

export const userFiltersDefaultValues = {
  active: 1
  // "abgeschlossen": "0"
};
