import PricingList from "./PricingList";
import { EntityCreate, EntityEdit } from "./PricingEdit";

const Pricing = {
  list: PricingList,
  edit: EntityEdit,
  create: EntityCreate,
  name: "pricings"
}

export default Pricing;
