import { ServiceReferenceInput } from "../../services/components/ServiceReferenceInput";
import * as React from "react";
import { ArrayInput, FormDataConsumer, SelectInput } from "react-admin";
import CloseIcon from "@material-ui/icons/RemoveCircleOutline";
import { Spacer } from "../../utils/components/FormElements";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import Grid from "@material-ui/core/Grid";
import { TextInput } from "../../utils/components";
import { makeStyles } from "@material-ui/core/styles";
import { AddressReferenceInput } from "../../address/components/AddressReferenceInput";

const useStyles = makeStyles( ( theme ) => ( {
  listRow: {
    alignItems: 'center',
    backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.default,
    // borderBottom: "2px solid rgba(128,128,128, 0.75)",
    '&:nth-child(odd)': {
      // boxShadow: "rgb(0,0,0,0.26) 0px 2px 20px 0px",
      backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.odd,
    },
    '&:hover': {
      backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.hover,
    },
  },
  listHead: {
    padding: '0 5px 5px 5px',
    fontSize: '0.9rem',
    fontWeight: 'bold',
    borderBottom: '1px solid #fefefe'

  },
  listItem: {
    padding: '0px 5px'
  },
  listItemRight: {
    padding: '0px 5px',
    textAlign: 'right'
  },

  textInput: {
    margin: "8px 3px 4px 3px",
    float: 'left',
  }
} ) )


const ConditionsFormIterator = ( props ) => {
  const classes = useStyles();

  const addButtonClickHandler = () => {
    const newItem = {
      field: 'pickupLocation',
      condition: 'eq',
      value: ''
    }

    props.fields.push( newItem )
  }


  return (
    <Grid container alignItems="center">
      <Grid item xs={8}>
        {props.fields.map( ( item, index ) => {
          return <Grid container key={index} alignItems="center">
            <Grid item xs={12} md={4} className={classes.listItem}>
              <SelectInput
                choices={[
                  { id: 'pickupLocation', name: 'Abholadresse' },
                  { id: 'deliveryLocation', name: 'Zustelladresse' },
                ]}
                source={`${item}.field`}
                label={"Feld"}
                helperText={false}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={3} className={classes.listItem}>
              <SelectInput
                choices={[
                  { id: 'eq', name: 'ist gleich' },
                  { id: 'neq', name: 'ist nicht' },
                ]}
                source={`${item}.condition`}
                label={"Bedingung"}
                helperText={false}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4} className={classes.listItem}>
              <AddressReferenceInput source={`${item}.value`} label={"Wert"} />
            </Grid>

            <Grid item className={classes.listItem}>
              <Button
                color={'primary'}
                title="Position entfernen"
                style={{ minWidth: '32px' }}
                onClick={() => props.fields.remove( index )}>
                <CloseIcon/>
              </Button>
            </Grid>
          </Grid>
        } )}
      </Grid>
      <Grid item>
        <Button
          color={'primary'}
          onClick={addButtonClickHandler}
          label="ra.action.add">
          <AddIcon/> Bedingung Hinzufügen
        </Button>
      </Grid>
    </Grid>
  )
}

const DefaultServiceFormIterator = ( props ) => {
  const classes = useStyles();

  const addButtonClickHandler = ( selectedService ) => {
    props.fields.push( {
                         service: {
                           '@id': selectedService
                         },
                       } )
  }

  return (
    <>
      {props.fields.map( ( item, index ) => {

        return <Grid container alignItems="center" className={classes.listRow}>
          <Grid item xs={12} md={2} style={{ marginRight: "0.25rem" }}>
            <ServiceReferenceInput source={`${item}.service`} helperText={false}/>
          </Grid>

          <Grid item xs={12} md={8}>
            <ArrayInput source={`${item}.conditions`} label={""}>
              <ConditionsFormIterator/>
            </ArrayInput>
          </Grid>

          <Grid item>
            <Button
              color={'primary'}
              title="Position entfernen"
              style={{ minWidth: '32px' }}
              onClick={() => {
                props.fields.remove( index );
              }}>
              <CloseIcon/> Zusatzleistung entfernen
            </Button>
          </Grid>
        </Grid>
      } )}

      <Spacer/>
      <Grid container alignItems="center">
        <Grid item xs={12} md={3}>
          <ServiceReferenceInput source={"selectedService"} helperText={false}/>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormDataConsumer>
            {( { formData, ...rest } ) => {
              return <Button
                color={'primary'}
                label="Hinzufügen"
                onClick={() => addButtonClickHandler( formData.selectedService )}
                disabled={formData.selectedService === undefined || formData.selectedService === ''}
              >
                <AddIcon/> Hinzufügen
              </Button>
            }}
          </FormDataConsumer>
        </Grid>
      </Grid>
    </>
  )
}

export default DefaultServiceFormIterator
