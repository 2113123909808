import { Box, Button, Chip, makeStyles, Tooltip } from '@material-ui/core';
import { FunctionField, List, ListContextProvider, ResourceContextProvider, TextField, TopToolbar, useListController, } from 'react-admin';

import PriceTextField from '../../utils/components/PriceTextField';
import DefaultPagination from '../../utils/DefaultPagination';
import { GmapsLink } from '../../utils/GmapsLink';
import RoomIcon from '@material-ui/icons/Room';
import IconArrowReturnRight from '../../utils/components/icons/IconArrowReturnRight';

import { creditFilters, creditFiltersDefaultValues, CreditTags, BulkCreateInvoiceButton, BulkCreateInvoiceByFiltersButton, ContractCreditDatagrid } from './components';
import IconChargingStation from "../../utils/components/icons/IconChargingStation";
import React from "react";
import ContentCreate from '@material-ui/icons/Create';
import EntityLink from "../../utils/components/EntityLink";
import SettingsIcon from "@material-ui/icons/Settings";
import { ExportPDFButton } from "../components/ListButtons";
import { IconEditRetoureProtocol } from "../../utils/components/icons/IconEditRetoureProtocol";
import { IconEditRetoure } from "../../utils/components/icons/IconEditRetoure";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import DefaultListActions from "../../utils/DefaultListActions";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import LockIcon from "@material-ui/icons/Lock";
import { GenericContractButton } from "../components/ListButtons/GenericContractButton";
import { ContractDatesInfo } from "../components/ContractDatesInfo";


const formatEur = ( number ) => new Intl.NumberFormat( 'de-DE', {
  style: 'currency',
  currency: 'EUR'
} ).format( number );

const useStyles = makeStyles( ( theme ) => ( {
  googleButton: {
    marginTop: theme.spacing( 0.65 ),
    padding: '0.15rem 0.5rem',
    fontSize: '11px'
  },
  googleButtonIcon: {
    marginRight: theme.spacing( -0.8 ),
    width: '0.7rem',
    height: '0.7rem',
  }
}));

const ProtocolBulkActionButtons = (props) => (
  <>
    <BulkCreateInvoiceButton {...props} />
  </>
);

const GoogleDifferenceField = ({ record }) => {
  const classes = useStyles();

  const {
    totalSollOrVwSollKm,
    totalGooglesollkm,
    pickupLocation,
    deliveryLocation,
    totalDifferenceBetweenSollAndGoogleKm,
    differenceBetweenSollAndGooglePriceGroups
  } = record;

  const showPriceDiff = differenceBetweenSollAndGooglePriceGroups < 0 ||differenceBetweenSollAndGooglePriceGroups > 0;

  // Get Google Maps link if both pickup and delivery location are set
  let googleMapsLink;
  const directions_start = `${pickupLocation.strasse},${pickupLocation.ort}`;
  const directions_end = `${deliveryLocation.strasse},${deliveryLocation.ort}`;
  googleMapsLink = GmapsLink( directions_start, directions_end );

  const differenceBetweenPriceGroupsLabel = ( number ) => {
    if( number > 0 ) {
      return `+ ${formatEur( number )}`;
    } else {
      return `${formatEur( number )}`;
    }
  }

  return (
    (totalSollOrVwSollKm && totalGooglesollkm) &&
      <>
        <Box>
          <Box display="flex" gridGap="3px">
            <TextField source="totalSollOrVwSollKm"/>
            <span> zu </span>
            <TextField source="totalGooglesollkm"/> km.
          </Box>
          <Chip
            size="small"
            label={totalDifferenceBetweenSollAndGoogleKm+' km'}
            style={{
              backgroundColor: totalDifferenceBetweenSollAndGoogleKm < 0 ? '#F44336' : '#4CAF50',
              color: 'white'
            }}
          />
          {showPriceDiff && (
            <Chip
              size="small"
              label={differenceBetweenPriceGroupsLabel( differenceBetweenSollAndGooglePriceGroups )}
              style={{
                backgroundColor: differenceBetweenSollAndGooglePriceGroups < 0 ? '#F44336' : '#4CAF50',
                color: 'white',
                marginLeft: '3px'
              }}
            />
          )}
          {googleMapsLink && (
            <>
              <br />
              <Button
                className={classes.googleButton}
                startIcon={<RoomIcon className={classes.googleButtonIcon} />}
                variant="outlined"
                color="primary"
                size="small"
                target="_blank"
                href={googleMapsLink}
              >
                Maps
              </Button>
            </>
          )}
        </Box>
      </>
  );
};

const KmDifferenceField = ( { record } ) => {
  const {
    totalSollkm,
    totalIstkm,
    totalDifferenceBetweenSollAndIstKm,
  } = record;

  return (
    totalSollkm &&
    totalIstkm ? (
      <>
        <Box>
          <Box display="flex" gridGap="3px">
            <TextField source="totalSollkm"/>
            <span> zu </span>
            <TextField source="totalIstkm"/> km.
          </Box>
          <Chip
            size="small"
            label={totalDifferenceBetweenSollAndIstKm+' km'}
            style={{
              backgroundColor: totalDifferenceBetweenSollAndIstKm < 0 ? '#F44336' : '#4CAF50',
              color: 'white'
            }}
          />
        </Box>
      </>
    ) : null
  );
};

const BillingActions = ( props ) => (
  <TopToolbar>
    <BulkCreateInvoiceByFiltersButton {...props} />
    <Button
      title="Filter zurücksetzen"
      onClick={() => setFilters(hsfilterDefaultValues, [])}
      children={<RotateLeftIcon />}
    />
  </TopToolbar>
);


const ContractCreditList = ( props ) => {
  const perPage = parseInt( process.env.REACT_APP_LIST_ITEMS )

  const listControllerProps = {
    basePath: '/credit_contracts',
    hasCreate: false,
    resource: 'contracts',
    perPage: perPage,
    exporter: false,
    syncWithLocation: true,
    disableSyncWithLocation: false,
    filter: {
      custom__abgeschlossen: true,
      custom__hide_anschlussvon: true,
      provider__disable_bundles: true
    },
    sort: {
      field: 'datum',
      order: 'ASC'
    },
  };

  const controllerProps = useListController(listControllerProps);
  return (
    <ResourceContextProvider value="contracts">
      <ListContextProvider value={controllerProps}>
        <List
          filters={creditFilters}
          filterDefaultValues={creditFiltersDefaultValues}
          sort={{ field: 'datum', order: 'ASC' }}
          bulkActionButtons={<ProtocolBulkActionButtons/>}
          perPage={perPage}
          pagination={<DefaultPagination perPageArray={[10, 25, 50, 100, 200, 500, 1000]}/>}
          title={"Gutschrift erstellen"}
          actions={<DefaultListActions
            hsfilterDefaultValues={creditFiltersDefaultValues}
            allowCreate={false}
            hsShowFilter={true}
          />}
          syncWithLocation
          basePath="/credit_contracts"
          {...controllerProps}
        >
          <ContractCreditDatagrid>
            <TextField source="index" label={"#"} sortable={false}/>

            <FunctionField
              label="Auftragsnr."
              render={( record ) => (
                <Box display={"flex"} alignItems={"start"} flexDirection={"column"}>
                  <Box>
                    <EntityLink record={record.protocol} style={{ minWidth: 'auto'}}>
                      <Box display={"flex"} alignItems={"center"}>

                        <TextField source="auftragsnummer"/>
                        {record.verzoegerteAbgabe && (
                          <IconChargingStation style={{ width: "12px", marginLeft: "5px" }}/>
                        )}
                      </Box>
                    </EntityLink>
                  </Box>

                  <FunctionField
                    style={{ display: "flex", justifyContent: "center" }}
                    render={( record ) => <CreditTags record={record}/>}
                  />
                </Box>
              )}
            />

            <FunctionField
              label={"Abholer"}
              render={( record ) => (
                <>{record?.driver?.kuerzel}{record?.driver?.fahrervon ? ` | ${record?.driver?.fahrervon?.kuerzel}` : ''}</>
              )}
            />

            <TextField label="Kennzeichen" source="licensePlate"/>
            <FunctionField
              label="Typ"
              render={( record ) => {
                if( record.abcfahrt ) {
                  return 'ABC';
                }
                if( record.abafahrt ) {
                  return 'ABA';
                }
                return 'AB'
              }}
                      />
            <FunctionField
                label="Datum"
                render={( record ) => (
                    <ContractDatesInfo record={record}/>
                )}
            />
            <>
              <TextField source="pickupLocation.plz"/>{' '}
              <TextField source="pickupLocation.ort"/>
              <FunctionField
                label="KM 1"
                render={( record ) => (
                    <Tooltip title={'A -> B: '+ record.sollkm + ' km (Ist: ' + record.istkm + 'km)'} placement="top">
                    <Box display="flex" alignItems="center">
                        <IconArrowReturnRight style={{ width: '14px', marginRight: "2px" }}/>
                        <span style={{ whiteSpace: 'nowrap' }} >
                                <TextField source="deliveryLocation.plz"/>{' '}
                                <TextField source="deliveryLocation.ort"/>{' '}
                        </span>
                    </Box>
                    </Tooltip>
                )}
                />

              <FunctionField
                label="Auftragstyp"
                render={( record ) => ( ( record.abcfahrt || record.abafahrt ) && record.anschlussAuftrag &&
                    <Tooltip title={(record.abafahrt ? 'B -> A: ' : 'B -> C: ') +record.anschlussAuftrag.sollkm + 'km (Ist: '+ record.anschlussAuftrag.istkm + 'km)'} placement="top" arrow="true">
                        <Box display="flex" alignItems="center">
                            <IconArrowReturnRight style={{ width: '14px', marginRight: "2px" }}/>
                            <span style={{ whiteSpace: 'nowrap' }}>
                            <TextField record={record.anschlussAuftrag} source="deliveryLocation.plz"/>{' '}
                            <TextField record={record.anschlussAuftrag} source="deliveryLocation.ort"/>
                            </span>
                        </Box>
                    </Tooltip>
                )}/>

            </>
            <FunctionField
              label="Soll zu Google km."
              render={( record ) => <GoogleDifferenceField record={record}/>}
            />
            <FunctionField
              label="Soll zu Ist km."
              render={( record ) => <KmDifferenceField record={record}/>}
            />

            <PriceTextField label="Wert" source="gutschriftpreis" />

            <PriceTextField label="Taxi" source="taxiTotal"/>
            <PriceTextField label="Waschen" source="creditListCostWashTotal"/>
            <PriceTextField label="Wartezeit" source="wartezeitPreisTotal"/>
            <PriceTextField label="Tanken" source="costFuelNetTotal"/>
            <PriceTextField label="Waschkosten" source="costWashBillNetTotal"/>
            <PriceTextField label="Bahn" source="costTrainNetTotal"/>
            <PriceTextField label="Öl" source="costOilNetTotal" />
            <PriceTextField label="andere" source="costOtherNetCreditTotal"/>
            <FunctionField render={( record ) => {
                return <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
                  <EntityLink record={record} icon={<ContentCreate/>} style={{ minWidth: 'auto' }}/>
                  <EntityLink record={record?.anschlussAuftrag} icon={<IconEditRetoure/>} title={"Admin Retoure"} style={{ minWidth: 'auto' }}/>
                  <EntityLink record={record.protocol} icon={<SettingsIcon/>} style={{ minWidth: 'auto' }}/>
                  <EntityLink record={record?.anschlussAuftrag?.protocol} icon={<IconEditRetoureProtocol/>} title={"Admin Retoure"} style={{ minWidth: 'auto' }}/>
                  <ExportPDFButton record={record} style={{ minWidth: 'auto' }}/>
                  <GenericContractButton
                    text={`Auftrag für Gutschrift ${record.sperrenfuergutschrift === '1' ? 'frei geben' : 'sperren'}`}
                    record={record}
                    data={{ sperrenfuergutschrift: record.sperrenfuergutschrift === '1' ? '0' : '1' }}
                    style={{ minWidth: 'auto' }}
                    icon={record.sperrenfuergutschrift === '1' ? <LockOpenIcon/> : <LockIcon/>}
                  />
                </Box>
              }}/>

          </ContractCreditDatagrid>
        </List>
      </ListContextProvider>
    </ResourceContextProvider>
  );
};

export { ContractCreditList };
