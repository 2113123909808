import * as React from "react";
import { Create, Edit, FormTab, TabbedForm, TextInput } from "react-admin";
import RichTextInput from 'ra-input-rich-text';

import { Box } from '@material-ui/core';

import DefaultEditActions from "../utils/DefaultEditActions";

const EntityForm = props => (
  <TabbedForm {...props}>
    <FormTab label={"Vorlage bearbeiten"}>
      <Box display="flex" style={{ width: '100%' }}>
        <Box flex={1}>

          <TextInput source={"name"} fullWidth/>
          <TextInput source={"betreff"} fullWidth/>
        </Box>
        <Box flex={1}>

        </Box>
      </Box>

      <Box display="flex" style={{ width: '100%' }}>
        <Box flex={1}>
          <TextInput source={"absenderemail"} fullWidth/>
          <TextInput source={"absendername"} fullWidth/>
        </Box>
        <Box flex={1}>

        </Box>
      </Box>

      <Box mt={"0.5em"} style={{ width: '100%' }}>
        <RichTextInput source={"text"} fullWidth/>
      </Box>
    </FormTab>

    <FormTab label={"Update Text"}>
      <Box style={{ width: '100%' }}>
        <TextInput source={"betreffupdate"} fullWidth/>
        <RichTextInput source={"textupdate"} fullWidth/>
      </Box>
    </FormTab>

  </TabbedForm>
);

const EntityTitle = ( { record } ) => {
  return <span>Zusatzleistung {record ? `"${record.name}"` : ''}</span>;
}

const EntityEdit = props => (
  <Edit actions={<DefaultEditActions {...props} />} title={<EntityTitle/>} {...props}>
    <EntityForm/>
  </Edit>
);

const EntityCreate = props => (
  <Create actions={<DefaultEditActions {...props} />} {...props} title={"Neuen Eintrag anlegen"}>
    <EntityForm style={{ width: '100%' }}/>
  </Create>
);


export { EntityEdit, EntityCreate };
