import { Button, useNotify, useRefresh } from 'react-admin';
import MailIcon from '@material-ui/icons/Mail';
import { green } from '@material-ui/core/colors';
import { format } from 'date-fns';

import { PatchRequest } from '../../../utils/request/PatchRequest';
import GlobalLoading from "../../../utils/components/GlobalLoading";
import { useState } from "react";

export const SendDriverMailButton = ( { IsFahrerMailGesendet, id, disabled, style = {}, displayButtonText = false } ) => {
    const [loading, setLoading] = useState( false );
    const notify = useNotify();
    const refresh = useRefresh();

    const handleClick = async() => {
        setLoading( true );
        const now = format( new Date(), 'yyyy-M-d HH:mm:ss' );
        const PATCH_REQUEST_URL = `${process.env.REACT_APP_API}/api/contracts/${id}`;

        const res = await PatchRequest( PATCH_REQUEST_URL, {
            driverChangedWithMail: true,
            forceMailSend: true
        } );

        if( res && res.status === 200 ) {
            notify( 'E-Mail an Fahrer gesendet', 'success' );
            setLoading( false );
            refresh();
        } else {
            notify( 'Fehler beim E-Mail versenden', 'error' );
            setLoading( false );
        }
    };

    return (
        <>
          {loading &&<GlobalLoading/>}
          <Button
            title={
                IsFahrerMailGesendet
                ? 'E-Mail bereits an Fahrer versendet.'
                : 'E-Mail an Fahrer versenden'
            }
            onClick={handleClick}
            children={<>
                <MailIcon style={{ color: IsFahrerMailGesendet ? green[ 500 ] : '' }} />
                {displayButtonText && (IsFahrerMailGesendet
                    ? 'E-Mail bereits an Fahrer versendet.'
                    : 'E-Mail an Fahrer versenden')}
            </>
            }
            disabled={disabled}
            style={style}
          />
        </>
    );
};
