import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { ArrayInput, Create, Edit, FormDataConsumer, FormWithRedirect, SaveButton, useNotify } from "react-admin";
import DefaultEditActions from "../utils/DefaultEditActions";
import TimeRangeInput from "../utils/components/TimeRangeInput";
import { CategoryReferenceInput } from "../category/components/CategoryReferenceInput";
import { TextInput as CustomTextInput } from "../utils/components";
import { Box, Card, CardContent, CardHeader, Toolbar } from "@material-ui/core";
import RefreshButton from "../utils/components/RefreshButton";
import { InputContainer, ParagraphBox } from "../utils/components/FormElements";
import ContactFormIterator from "../utils/components/InputComponents/ArrayInput/ContactFormIterator";
import { UMailFormIterator } from "./components/UMailFormIterator";
import useGridContainerStyles from "../utils/components/GridContainerStyles";
import CountrySelectInput from "../utils/components/CountrySelectInput";
import RichTextInput from "ra-input-rich-text";
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps"
import Button from "@material-ui/core/Button";
import { apiEndpoint, PostRequest } from "../utils/request/PostRequest";
import { useForm, useFormState } from "react-final-form";
import NumberInput from "../utils/components/NumberInput";

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY


const TextInput = ( props ) => {
  const { source, type, ...rest } = props;
  return <InputContainer {...rest}>
    <CustomTextInput fullWidth source={source} type={type}/>
  </InputContainer>
}

const UpdateLocationButton = ( { record } ) => {
  const { change } = useForm();
  const notify = useNotify();

  return <FormDataConsumer>
    {( { formData, ...rest } ) => {

      return <Button
        variant="contained"
        color="primary"
        onClick={async() => {
          // Get the position by sending a request to a specific route
          await PostRequest( `${apiEndpoint}/api/direction`, {
            location: `${formData.street} ${formData.hausnr}, ${formData.plz} ${formData.city}`,
          } ).then( ( response ) => {
            change( "lat", response.lat )
            change( "lng", response.lng )
            notify( "Koordinaten wurden erfolgreich ermittelt", { type: "success" } )
          } ).catch( ( error ) => {
            notify( "Koordinaten konnten nicht ermittelt werden", { type: "error" } )
          } )
        }}>
        Koordinaten ermitteln
      </Button>
    }}
  </FormDataConsumer>
}

const GoogleMaps = ( { record } ) => {
  const { values } = useFormState( { subscription: { values: true } } );
  const [coordinates, setCoordinates] = useState( null );

  useEffect( () => {
    setCoordinates( {
                      lat: parseFloat( values.lat ),
                      lng: parseFloat( values.lng )
                    }
    )
  }, [values.lat, values.lng] )

  if( record.coordinates && !coordinates ) {
    setCoordinates( {
                      ...record.coordinates
                    } )
  }

  const MyMapComponent = useMemo(
    () =>
      withScriptjs(
        withGoogleMap( ( props ) => (
          <GoogleMap
            defaultZoom={8}
            defaultCenter={{ lat: coordinates.lat, lng: coordinates.lng }}
          >
            {props.isMarkerShown && (
              <Marker position={{ lat: coordinates?.lat, lng: coordinates?.lng }}/>
            )}
          </GoogleMap>
        ) )
      ),
    [coordinates?.lat, coordinates?.lng]
  );

  return <MyMapComponent
    isMarkerShown={true}
    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
    loadingElement={<div style={{ height: `100%` }}/>}
    containerElement={<div style={{ height: `400px` }}/>}
    mapElement={<div style={{ height: `100%` }}/>}
  />
}

const EntityForm = ( props ) => {
  const classes = useGridContainerStyles()
  return <FormWithRedirect
    {...props}
    render={( formProps ) => {
      return <form autoComplete="off" style={{ marginBottom: "4rem" }}>
        <Box className={classes.formContainer}>
          <Card className={classes.card}>
            <CardHeader title={"Adress-Daten"}/>
            <CardContent>
              <ParagraphBox>
                <TextInput source={"firma"} left/>
                <TextInput source={"vorname"} center/>
                <TextInput source={"nachname"} right/>
              </ParagraphBox>

              <ParagraphBox>
                <TextInput source={"street"} label={"Strasse"} left/>
                <TextInput source={"houseNum"} label={"Hausnummer"} center/>

                <TextInput source={"zip"} label={"Postleitzahl"} center/>
                <TextInput source={"city"} label={"Ort"} center/>
                <InputContainer {...props} right>
                  <CountrySelectInput source={"country"} />
                </InputContainer>
              </ParagraphBox>

              <ParagraphBox>
                <TextInput source={"tel"} left/>
                <TextInput source={"email"} right/>
              </ParagraphBox>

              <ParagraphBox>
                <InputContainer left>
                  <TimeRangeInput helperText={false} source={"startzeit"} fullWidth/>
                </InputContainer>

                <InputContainer center>
                  <TimeRangeInput helperText={false} source={"endzeit"} fullWidth/>
                </InputContainer>

                <InputContainer right>
                  <CategoryReferenceInput source={"kategorie"}/>
                </InputContainer>
              </ParagraphBox>

              <ParagraphBox>
                <TextInput source={"suchname"} left/>
                <TextInput source={"name"} center/>
                <TextInput source={"beschreibung"} right/>
              </ParagraphBox>
            </CardContent>
          </Card>

          <Card className={classes.card}>
            <CardHeader title={"Kontakte"}/>
            <CardContent>
              <ArrayInput source="contacts" label={""}>
                <ContactFormIterator/>
              </ArrayInput>
            </CardContent>
          </Card>


          <Card>
          <ParagraphBox flexWrap={"wrap"}>
            <FormTabContainerBox flex={1} minWidth={"550px"}>
              <ParagraphHeadline>Geo-Daten</ParagraphHeadline>
              <ParagraphBox alignItems={"center"}>
                <TextInput source={"lat"} label={"Latitude"} type={"number"} left/>
                <TextInput source={"lng"} label={"Longitude"} type={"number"} center/>
                <UpdateLocationButton record={props.record}/>
              </ParagraphBox>

              <Spacer />

              <ParagraphHeadline>Beschreibung</ParagraphHeadline>
              <RichTextInput source={"description"} label={""} minRows={10} multiline fullWidth/>
            </FormTabContainerBox>

            <FormTabContainerBox flex={1} minWidth={"550px"}>
              <ParagraphHeadline>&nbsp;</ParagraphHeadline>

              <GoogleMaps record={props.record}/>
            </FormTabContainerBox>
          </ParagraphBox>
        </Card>

        <Spacer space="1rem"/>

        <Card className={classes.card}>
            <CardHeader title={"Automatischer Mailversand"}/>
            <CardContent className={classes.cardContent}>
              <ArrayInput source="addressMails" label={""}>
                <UMailFormIterator/>
              </ArrayInput>
            </CardContent>
          </Card>

          <Toolbar
            style={{
              position: "fixed",
              zIndex: 100000,
              bottom: 10,
              maxWidth: "100%",
              backgroundColor: "rgba(255,255,255, 0.8)",
              borderRadius: "5px",
            }}
          >
            {props.isCreate ? <Box width="100%">
                              <SaveButton
                                saving={props.saving}
                                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                                label={"Adresse anlegen"}
                                style={{ marginRight: "0.5rem" }}
                              />
                            </Box> :
             <Box width="100%">
               <SaveButton
                 saving={props.saving}
                 handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                 transform={data => {
                   return { ...data, strasse: data.street, ort: data.city }
                 }}
                 label={"Speichern und schließen"}
                 style={{ marginRight: "0.5rem" }}
               />
               <SaveButton
                 saving={props.saving}
                 redirect={false}
                 label={"Speichern"}
                 transform={data => {
                   return { ...data, strasse: data.street, ort: data.city }
                 }}
                 handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                 style={{ marginRight: "0.5rem" }}
               />

               <RefreshButton/>
             </Box>}
          </Toolbar>
        </Box>
      </form>
    }}/>
};

const EntityTitle = ( { record } ) => {
  return <span>Adresse {record ? `"${record.firma}" [id:${record.originId}]` : ""}</span>;
};

const EntityEdit = ( props ) => (
  <Edit
    actions={<DefaultEditActions allowNew={true} {...props} />}
    title={<EntityTitle/>}
    component={"div"}
    {...props}
  >
    <EntityForm/>
  </Edit>
);

const EntityCreate = ( props ) => (
  <Create
    actions={<DefaultEditActions allowNew={true} {...props} />}
    {...props}
    title={"Neuen Eintrag anlegen"}
  >
    <EntityForm style={{ width: "100%" }} isCreate={true}/>
  </Create>
);

export { EntityEdit, EntityCreate };
