import { ArrayInput, FormDataConsumer, FormTab, TextField, useNotify } from "react-admin";
import * as React from "react";
import StyledFormIterator from "../../../utils/components/CustomIterator";
import Button from "@material-ui/core/Button";
import { GetRequest } from "../../../utils/request/GetRequest";
import { useRecordContext } from "ra-core";
import { useState } from "react";
import GlobalLoading from "../../../utils/components/GlobalLoading";


export default ( props ) => {
  const [loading, setLoading] = useState( false );
  const record = useRecordContext();
  const notify = useNotify();

  return <FormTab label={"gespeicherte Bilder"} {...props}>
    {loading && <GlobalLoading />}
    <Button
      variant={"contained"}
      color={"primary"}
      onClick={async () => {
        setLoading( true );
        const url = `/protocols/${record.orderNumber}/images`;

        await GetRequest(
          url, false
        ).then( ( result ) => {
          setLoading( false );
          window.open( result.url, '_blank' );
          notify( `Download erfolgreich gestartet`, { type: "info" } );
        } ).catch( ( error) => {
          setLoading( false );

          if (error.response) {
            notify( `Fehler: ${error.response.data.error}`, { type: "warning" } );
          } else {
            notify( `Fehler: ${error.message}`, { type: "warning" } );
          }
        } )
      }}>
      Alle Standard-Bilder herunter laden
    </Button>

    <ArrayInput source="awsFiles" label={"AWS Dateien"} fullWidth>

      <StyledFormIterator disableReordering disableAdd disableRemove>
        <FormDataConsumer>
          {( { scopedFormData, getSource } ) => {
            return <>
              <TextField source={getSource( "label" )} fullWidth label={"Titel"}/>

              <div>
                <img
                  style={{
                    margin: "0.5rem",
                    maxHeight: "10rem",
                    cursor: "pointer"
                  }}
                  loading={"lazy"}
                  src={scopedFormData.image}
                  title={`${scopedFormData.label} - ${scopedFormData.image}`}
                  alt={scopedFormData.label}
                  onClick={e => props.onImageClick( e, true )}
                />
              </div>
            </>
          }}
        </FormDataConsumer>
      </StyledFormIterator>
    </ArrayInput>
  </FormTab>
}
