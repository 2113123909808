import { AutocompleteInput, ReferenceInput as DefaultReferenceInput } from "react-admin";
import React from "react";
import PropTypes from "prop-types";

const ReferenceInput = ( {
                           optionText = obj => defaultOptionText( obj ),
                           perPage = 200,
                           allowEmpty = true,
                           resettable = true,
                           fullWidth = true,
                           label = "Reference Input",
                           format,
                           sort,
                           source,
                           filter,
                           reference,
                           ...rest
                         } ) => {

  return <DefaultReferenceInput

    filter={{ ...filter }}
    perPage={perPage}
    allowEmpty={allowEmpty}
    resettable={resettable}
    fullWidth={fullWidth}
    label={label}
    source={source}
    sort={sort}
    reference={reference}
    format={format}
    filterToQuery={( text ) => text ? { _name: text } : null}
    {...rest}
  >
    {rest.children ??
     <AutocompleteInput
       optionText={optionText}
       style={{ minWidth: "200px" }}
       helperText={false}
       options={{ InputProps: { autoComplete: 'reference-autocomplete'} }}
       />
    }
  </DefaultReferenceInput>;
}


ReferenceInput.defaultProps = {
  ...DefaultReferenceInput.defaultProps,
  perPage: 200,
  allowEmpty: true,
  resettable: true,
  fullWidth: true,
  filter: { aktiv: "1", boolchar_cleared: false, active: 1 },
  format: ( v ) => ( v !== null && typeof v === "object" && v.hasOwnProperty( "@id" ) ? v[ "@id" ] : v ),
  optionText: ( obj ) => defaultOptionText( obj ),
};

const defaultOptionText = ( obj ) => {
  if( !obj || obj.hasOwnProperty( "id" ) && !obj.id ) {
    return 'Bitte wählen...'
  }
  return obj.firma
}

export default ReferenceInput;
