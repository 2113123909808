import React from "react";
import { BooleanField, FunctionField, TextField, EditButton } from "react-admin";
import HotList from "../utils/components/HotList";
import { invoiceListFilters } from "./components/InvoiceListFilter";
import DateField from "../utils/components/DateField";
import { Box } from "@material-ui/core";
import { generateInvoicePDF } from "../utils/request/PostRequest";
import CurrencyFormatter from "../utils/components/styles/CurrencyFormatter";
import * as ButtonStyles from "../utils/components/styles/ButtonStyles";
import { InvoiceDocumentButtonGroup } from "./components/InvoiceDocumentButtonGroup";
import { SendMailButton } from "./components/SendMailButton";

const HsInvoiceList = props => (
  <HotList {...props}
           sort="createdAt"
           sortOrder="DESC"
           filters={invoiceListFilters}
           hasEdit={false}
           allowCreate={true}>

    <DateField label={"Datum"}
               source={"createdAt"}
               showTime={true}
               options={{ year: "numeric", month: "2-digit", day: "2-digit", hour: '2-digit', minute: '2-digit' }}/>

    <DateField label={"Rechnungsdatum"}
               source={"invoiceDate"}
               showTime={false}
               options={{ year: "numeric", month: "2-digit", day: "2-digit"}}/>

    <DateField label={"Verschickt am"}
               source={"mailSend"}
               showTime={false}
               options={{ year: "numeric", month: "2-digit", day: "2-digit"}}/>

    <TextField source={"type"} label={"Art"}/>
    <TextField source={"orderNumber"} label={"Nr."}/>

    <TextField source={"billingRecipient"} label={"Empfänger"}/>
    <TextField source={"recipientMail"} label={"Empfänger"}/>

    <FunctionField
      label="Gesamtsumme"
      sortBy={"amountTotal"}
      render={( record ) => (
      CurrencyFormatter( record.amountTotal )
    )}/>

    <BooleanField source={"invoiceCompleted"} label={"Abgeschlossen"}/>

    <FunctionField
      render={( record ) => (
        <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
          <InvoiceDocumentButtonGroup showLabels={false} record={record} {...props} />

          <SendMailButton record={record} disabled={record.recipientMail === ''} {...props} />

          <EditButton
            title={"Bearbeiten"}
            label={"Bearbeiten"}
            {...props}
            record={record}
            target={"_blank"}
            style={ButtonStyles.noMargin}
          />
        </Box>

      )}/>
  </HotList>
);

const handleInvoiceClick = async( event, id ) => {
  try {
    const response = await generateInvoicePDF( id )
    if( response?.url ) {
      window.open( response.url, '_blank' )
    }
  } catch( e ) {
    console.log( e );
  }
}

export default HsInvoiceList;
