import { ArrayInput, FormTab } from "react-admin";
import MailsTabFormIterator from "./MailsTabFormIterator";
import * as React from "react";
import { ComplaintFormIterator } from "../ComplaintFormIterator";
import { useRecordContext } from "ra-core";
import { Box } from "@material-ui/core";

export default ( props ) => {
  const record = useRecordContext()

  return <FormTab label={"Protokollbeanstandungen"} {...props}>
    <Box style={{width: "100%"}} maxWidth={"1024px"}>
      <ArrayInput source={"complaints"} label={""} fullWidth>
      <ComplaintFormIterator recordId={record['@id']} />
    </ArrayInput>
    </Box>
  </FormTab>
}
