import { AutocompleteInput, ReferenceInput } from "react-admin";
import ReferenceArrayInput from "../../utils/components/ReferenceArrayInput";

export const CategoryReferenceInput = ( props ) => {

  return <ReferenceInput
    label={"Kategorie"}
    reference={"categories"}
    fullWidth
    resettable
    format={( v ) =>
      v !== null &&
      typeof v === "object" &&
      v.hasOwnProperty( "@id" )
      ? v[ "@id" ]
      : v
    }
    filterToQuery={( searchText ) => ( { _name: searchText } )}
    perPage={200}
    helperText={false}
    {...props}
  >
    <AutocompleteInput
      optionText={"kategorie"}
      source={"kategorie"}
    />
  </ReferenceInput>
}

export const CategoryReferenceArrayInput = ( props ) => {

    return <ReferenceArrayInput
      label={"Kategorien"}
      reference={"categories"}
      fullWidth
      resettable
      format={( v ) =>
        v !== null &&
        typeof v === "object" &&
        v.hasOwnProperty( "@id" )
        ? v[ "@id" ]
        : v
      }
      filterToQuery={( searchText ) => ( { _name: searchText } )}
      perPage={200}
      helperText={false}
      {...props}
    >
      <AutocompleteInput
        optionText={"kategorie"}
        source={"kategorie"}
      />
    </ReferenceArrayInput>
}
