import React from "react";
import { DateInput, SearchInput, SelectArrayInput, SelectInput } from 'react-admin';

const billingListFilters = [
  <SearchInput
    placeholder="Auftragsnummer"
    source="auftragsnummer"
    style={{ minWidth: "250px", marginTop: "0" }}
    alwaysOn
  />,
  <DateInput
    label="Rechnungsdatum von"
    source="datum[after]"
    style={{ minWidth: "200px" }}
    alwaysOn
  />,
  <DateInput
    label="Rechnungsdatum bis"
    source="datum[before]"
    style={{ minWidth: "200px" }}
    alwaysOn
  />,
  <SearchInput
    placeholder="Empfänger"
    source="_name"
    style={{ minWidth: "250px", marginTop: "0" }}
    alwaysOn
  />,
  <SelectInput
    source={"formulartyp"}
    label={"Formulartyp"}
    choices={[
      { id: 'Rechnung', name: 'Rechnung' },
      { id: 'Gutschrift', name: 'Gutschrift' },
      { id: 'Angebot', name: 'Angebot' },
    ]} fullWidth
    alwaysOn
  />

];

export { billingListFilters };
