import React, { useState } from "react";
import { FunctionField, TextField } from "react-admin";
import HotList from "../utils/components/HotList";
import { Box, ClickAwayListener, Tooltip } from "@material-ui/core";
import { RetryButton } from "./components/RetryButton";
import { noMargin } from "../utils/components/styles/ButtonStyles";
import InfoIcon from "@material-ui/icons/Info";
import DateField from "../utils/components/DateField";
import PreviewButton from "./components/PreviewButton";



export const MailLogList = ( props ) => (
  <HotList {...props}
           sort={"createdAt"}
           sortOrder={"DESC"}
           filter={{ isSend: false }}>
    <DateField source={"createdAt"} locales="de-DE" options={{ year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }} label={"Erzeugt am"} />
    <TextField source={"subject"} label={"Betreff"} />
    <TextField source={"recipients"} label={"Empfänger-Adresse"}/>
    <FunctionField render={( record ) => {
      return <TooltipField title={record.translatedStatus} text={record.error}/>
    }} label={"Status"}/>


    <FunctionField
      label="" render={( record ) =>
        <Box align="right" style={{ display: "flex", justifyContent: "flex-end", verticalAlign: "middle" }}>
          {record.status === 'error' && <RetryButton record={record} style={noMargin}/>}
          <PreviewButton record={record} />
        </Box>
      }
    />
  </HotList>
);

const TooltipField = ( { title, text } ) => {
  const [open, setOpen] = useState( false );
  const styles = {
    color: '#FFF',
    padding: '4px 8px',
    fontSize: '0.75rem',
    maxWidth: '300px',
    minWidth: '200px',
    wordWrap: 'break-word',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '500',
    lineHeight: '1.4em',
    borderRadius: '4px',
    backgroundColor: 'rgba(97, 97, 97, 0.9)',

    position: 'absolute',
    top: 28,
    right: 0,
    left: 0,
    zIndex: 1100,
    border: '1px solid',
    p: 1,
    opacity: open ? '1' : '0',
    transition: 'all 1s',

  };
  return <ClickAwayListener onClickAway={() => setOpen(false)}>
    <Box sx={{ position: 'relative' }} alignItems={"center"} display={"flex"}>
      {title} {text && <InfoIcon style={{fontSize: '1rem', marginLeft: "0.25rem"}} onClick={() => setOpen(true)}/>}

      {open ? (
        <Box sx={styles}>
          <>{text}</>
        </Box>
      ) : null}
    </Box>

    {/*<div>*/}
    {/*  <Tooltip*/}
    {/*    title={<LocationTooltipContent location={location}/>}*/}
    {/*    placement="top"*/}
    {/*    arrow*/}
    {/*    PopperProps={{*/}
    {/*      disablePortal: true,*/}
    {/*    }}*/}
    {/*    onClose={handleClick}*/}
    {/*    open={open}*/}
    {/*    disableFocusListener*/}
    {/*    disableHoverListener*/}
    {/*    disableTouchListener*/}
    {/*  >*/}
    {/*    <div onClick={handleTooltipOpen} style={{ cursor: "pointer" }}>*/}
    {/*      {children}*/}
    {/*    </div>*/}
    {/*  </Tooltip>*/}
    {/*</div>*/}
  </ClickAwayListener>
}
