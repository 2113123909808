import React, { useState } from "react";
import { useTheme } from '@material-ui/styles';

import { BooleanField, Button, Confirm, TextField, useNotify, useRedirect, useRefresh } from "react-admin";
import { Spacer } from "../../../utils/components/FormElements";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import { PostRequest } from "../../../utils/request/PostRequest";
import DateField from "../../../utils/components/DateField";

const MailsTabFormIterator = (props) => {
  const classes = useStyles();

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Datum</TableCell>
              <TableCell>Nutzer</TableCell>
              <TableCell>Typ</TableCell>
              <TableCell>Empfänger</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.fields.map( ( item, index ) => {
              return <TableRow>
                <TableCell>{index+1}</TableCell>
                <TableCell><DateField helperText={false} className={classes.textInput} options={ { year: "numeric", month: "2-digit", day: "2-digit", hour: '2-digit', minute: '2-digit' } } label={"Datum"} source={`${item}.date.date`} fullWidth /></TableCell>
                <TableCell><TextField helperText={false} className={classes.textInput} label={"#"} source={`${item}.user`} fullWidth /></TableCell>
                <TableCell><TextField helperText={false} className={classes.textInput} label={"#"} source={`${item}.type`} fullWidth /></TableCell>
                <TableCell><TextField helperText={false} className={classes.textInput} label={"#"} source={`${item}.recipient`} fullWidth /></TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>

      </TableContainer>
      <Spacer />
    </>
  )
}

const useStyles = makeStyles( ( theme ) => ( {
  listItem: {
    padding: '0 5px 0 5px',
    display: 'flex',
    backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.default,
    // borderBottom: "2px solid rgba(128,128,128, 0.75)",
    '&:nth-child(odd)': {
      // boxShadow: "rgb(0,0,0,0.26) 0px 2px 20px 0px",
      backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.odd,
    },
    '&:hover': {
      backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.hover,
    },
  },

  listItemControl: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center"
  },

  currentPos: {
    display: "flex",
    alignItems: "center",
  },
  textInput: {
    margin: "8px 3px 4px 3px",
    float: 'left',
  }
} ) )

MailsTabFormIterator.defaultProps = {
  fieldName: 'availableSnapshots'
}


export default MailsTabFormIterator;
