import { MenuItemLink, usePermissions } from "react-admin";
import ListIcon from "@material-ui/icons/List";
import * as React from "react";
import UserSettings from "../utils/components/UserSettings";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import StarBorderIcon from '@material-ui/icons/StarRate';
import classnames from "classnames";
import { Button } from "@material-ui/core";
import { apiEndpoint, PostRequest } from "../utils/request/PostRequest";

const useStyles = makeStyles(theme => ({
    menuItemRoot: {
        position: 'relative',
        '&:hover $starIcon': {
            opacity: 1
        },
    },
    starIcon: {
        position: 'absolute',
        padding: 0,
        right: 0,
        top: "50%",
        transform: 'translate(-50%, -55%)',
        opacity: 0,
        justifyContent: 'flex-end',
        minWidth: 'auto',
        transition: 'opacity 0.1s ease-in-out, color 0.1s',
        '&:hover': {
            color: 'gold',
        },
        '&.active': {
            color: 'gold',
        },
        '> span': {
            padding: 0
        }
    },
    active: {
        fontWeight: 'bold',
    },
    '&:hover $starIcon': {
        display: 'block',
    },
}));

const MenuItem = (props) => {
    const {requiredRoles = [], count = 0, to, ...rest} = props;
    const {permissions} = usePermissions()
    const location = useLocation();
    const classes = useStyles();

    const linkTarget = UserSettings()?.openLinksInNewTab ? '_blank' : '_self';

    let path = to
    let active = false;
    if (typeof to === 'object') {
        path = to.pathname
    }

    if (location.pathname === path || location.pathname.startsWith(path + '/')) {
        active = true
    }

    function checkPermission(requiredRoles: string[] = []) {
        if (requiredRoles.length === 0) {
            return true;
        }
        requiredRoles.push('vermaco_admin')
        let result = false;
        requiredRoles.forEach(role => {
            if (permissions && permissions.includes(role)) {
                result = true;
            }
        })

        return result;
    }

    let itemText = props.children ?? props.primaryText;
    if( !props.children && count > 0) {
        itemText += ' (' + count + ')'
    }

    const MenuItem = <MenuItemLink
        to={to}
        leftIcon={<ListIcon/>}
        target={linkTarget}
        className={active ? classes.active : ''}
        {...rest}
        primaryText={itemText}
    />

    const addToFavorites = () => {
        let favorites = JSON.parse(localStorage.getItem('favorites') || '[]');
        const currentMenuItem = { to: props.to, primaryText: props.children ?? props.primaryText };
        favorites.push(currentMenuItem);
        localStorage.setItem('favorites', JSON.stringify(favorites));
        PostRequest(
            `${apiEndpoint}/api/users/${localStorage.getItem( 'userId' )}/updateUserSetting`,
            {
                key: 'favorites',
                value: JSON.stringify(favorites)
            }
        ).then( () => {
            console.log( "success!" );
        })

        window.dispatchEvent(new Event('favoritesUpdated'));
    }

    const removeFromFavorites = () => {
        let favorites = JSON.parse(localStorage.getItem('favorites') || '[]');
        const currentMenuItem = { to: props.to, primaryText: props.children ?? props.primaryText };
        favorites = favorites.filter(item => {
            // check if to has a property pathname
            if (typeof item.to === 'object') {
                return item.to.pathname !== currentMenuItem.to?.pathname
            }
            return item.to !== currentMenuItem.to
        });
        localStorage.setItem('favorites', JSON.stringify(favorites));
        PostRequest(
            `${apiEndpoint}/api/users/${localStorage.getItem( 'userId' )}/updateUserSetting`,
            {
                key: 'favorites',
                value: JSON.stringify(favorites)
            }
        ).then( () => {
            console.log( "success!" );
        })
        window.dispatchEvent(new Event('favoritesUpdated'));
    }

    return checkPermission(requiredRoles) ?
        <div className={classnames(classes.menuItemRoot, active ? classes.active : '')}>
            {MenuItem}
            <FavoriteButton classes={classes.starIcon} menuItem={MenuItem} addToFavorites={addToFavorites} removeFromFavorites={removeFromFavorites}/>
        </div> : null
}

const FavoriteButton = ({classes, menuItem, addToFavorites, removeFromFavorites}) => {
    const [isFavorite, setIsFavorite] = React.useState(false);

    React.useEffect(() => {
        let favorites = JSON.parse(localStorage.getItem('favorites') || '[]');
        const currentMenuItem = { to: menuItem.props.to, primaryText: menuItem.props.primaryText };
        setIsFavorite(favorites.some(item => {
            // check if to has a property pathname
            if (typeof item.to === 'object') {
                return item.to.pathname === currentMenuItem.to?.pathname
            }
            return item.to === currentMenuItem.to
        } ));
    }, [menuItem]);

    const toggleFavorite = () => {
        if (isFavorite) {
            removeFromFavorites();
        } else {
            addToFavorites();
        }
        setIsFavorite(!isFavorite);
    }

    return <Button className={classes} onClick={toggleFavorite}>
        <StarBorderIcon style={{color: isFavorite ? 'gold' : ''}}/>
    </Button>
}

export default MenuItem;
