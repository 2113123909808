import BundleList from "./BundleList";
import { EntityEdit, EntityCreate } from "./BundleEdit";

const Bundles = {
  name: "bundles",
  options: {
    label: "Fahrtpakete"
  },
  list: BundleList,
  edit: EntityEdit,
  create: EntityCreate
}

export default Bundles;
