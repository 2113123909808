import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { Link } from "react-router-dom";
import UserSettings from "./UserSettings";


export default ( props ) => {
  const classes = useStyles();
  const { isExternal, to, icon, title, style, target, subStyles, onClick } = props;
  const t = UserSettings()?.openLinksInNewTab ? '_blank' : '_self';

  return isExternal ?

         <a href={to}
            className={classnames( classes.root )}
            title={title}
            style={style}
            target={target ?? '_blank'}
            onClick={onClick}>
           <span style={subStyles ?? {}} className={classnames( classes.label )}>
             {icon}
             {props.children && <span className={classnames( classes.link_text )}>
                {props.children}
              </span>}
           </span>
         </a> :
         <Link
           className={classnames( classes.root )}
           to={to}
           title={title}
           style={style ?? {}}
           target={target ?? t}
           onClick={onClick}>
    <span className={classnames( classes.label )}>
      {icon}
      {props.children && <span style={subStyles ?? {}} className={classnames( classes.link_text )}>
        {props.children}
      </span>}
    </span>
         </Link>

}


const useStyles = makeStyles( theme => {
                                return {
                                  root: {
                                    display: "inline-flex",
                                    alignItems: "center",
                                    padding: "4px 5px",
                                    boxSizing: "border-box",
                                    textDecoration: "none",
                                    minWidth: "64px",
                                    justifyContent: "center",
                                    '&:hover': {
                                      backgroundColor: theme.palette.action.hover,
                                    }
                                  },
                                  label: {
                                    '& svg': {
                                      fontSize: '20px'
                                    },
                                    display: "inherit",
                                    alignItems: "inherit",
                                    justifyContent: "inherit",
                                    color: theme.palette.primary.main,
                                  },
                                  link_text: {
                                    fontSize: '0.8125rem',
                                    textTransform: 'uppercase',
                                    paddingLeft: "0.5em"
                                  }
                                }
                              }
);
