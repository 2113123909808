import * as React from "react";
import { Create, Edit, FormWithRedirect, SimpleForm } from "react-admin";
import { InputGuesser } from "@api-platform/admin";
import DefaultEditActions from "../utils/DefaultEditActions";
import { BooleanInput, TextInput } from "../utils/components";
import { CustomerReferenceInput } from "../customers/components/CustomerReferenceInput";
import { FormTabContainerBox, InputContainer, ParagraphBox, ParagraphHeadline, Spacer } from "../utils/components/FormElements";
import { Box, Card } from "@material-ui/core";
import EntityToolbar from "../utils/components/Toolbar";
import CountrySelectInput from "../utils/components/CountrySelectInput";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../theme";

const useStyles = makeStyles(theme => ( {
  card: {
    width: "100%",
    maxWidth: "1024px",
    minWidth: "550px",
    margin: "1rem"
  },
  form: {
    width: "100%"
  },
  formContainer: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap"
  }
}))
const EntityForm = (props) => {

  const classes = useStyles();
  return <FormWithRedirect
    {...props}
    render={(formProps) => {
      return <form autoComplete="off">
        <Box className={classes.formContainer}>
          <Card className={classes.card}>
            <ParagraphBox>
              <FormTabContainerBox flex={1} minWidth={"550px"}>
                <ParagraphHeadline>Adressdaten</ParagraphHeadline>

                <ParagraphBox>
                  <InputContainer left>
                      <TextInput source={"searchLabel"} label={"Suchname"} fullWidth />
                  </InputContainer>
                  <InputContainer center>
                    <TextInput source={"firma"} fullWidth />
                  </InputContainer>
                  <InputContainer right>
                    <TextInput source={"firma2"} fullWidth label={"Firmenzusatz"} />
                  </InputContainer>
                </ParagraphBox>
                <ParagraphBox>
                  <InputContainer left>
                    <TextInput source={"anrede"} fullWidth />
                  </InputContainer>
                  <InputContainer center flex={2}>
                    <TextInput source={"vorname"} fullWidth />
                  </InputContainer>
                  <InputContainer center flex={2}>
                    <TextInput source={"nachname"} fullWidth />
                  </InputContainer>
                </ParagraphBox>
                <ParagraphBox>
                  <InputContainer left flex={3}>
                    <TextInput source={"strasse"} fullWidth />
                  </InputContainer>
                  <InputContainer right>
                    <TextInput source={"hausnr"} fullWidth />
                  </InputContainer>
                </ParagraphBox>
                <ParagraphBox>
                  <InputContainer left>
                    <TextInput source={"plz"} fullWidth />
                  </InputContainer>
                  <InputContainer center flex={2}>
                    <TextInput source={"ort"} fullWidth />
                  </InputContainer>
                  <InputContainer right flex={2}>
                    <CountrySelectInput source={"land"} />
                  </InputContainer>
                </ParagraphBox>
                <ParagraphBox>
                  <InputContainer>
                    <BooleanInput source={"active"} label={"Aktiv"} />
                  </InputContainer>
                </ParagraphBox>
              </FormTabContainerBox>

            </ParagraphBox>
          </Card>

          <Card className={classes.card}>
            <ParagraphBox>
              <FormTabContainerBox flex={1} minWidth={"550px"}>
                <ParagraphHeadline>Kontaktdaten</ParagraphHeadline>
                <ParagraphBox>

                  <InputContainer left>
                    <TextInput source={"email"} fullWidth />
                  </InputContainer>
                  <InputContainer center>
                    <TextInput source={"telefon"} fullWidth />
                  </InputContainer>
                  <InputContainer right>
                    <TextInput source={"fax"} fullWidth />
                  </InputContainer>
                </ParagraphBox>
                <ParagraphBox>
                  <InputContainer left>
                    <CustomerReferenceInput source={"adressbesitzer"} label={"Adressbesitzer"}/>
                  </InputContainer>

                  <InputContainer right>
                    <TextInput source={"abteilung"} fullWidth label={"Abteilung"}/>
                  </InputContainer>

                </ParagraphBox>

              </FormTabContainerBox>
            </ParagraphBox>

            <ParagraphBox>
              <FormTabContainerBox flex={1} minWidth={"550px"}>
                <ParagraphHeadline>Rechnungsdaten</ParagraphHeadline>
                <TextInput source={"rechnungsEmail"} label={"Rechnungs-Email"} fullWidth />
                <ParagraphBox>
                  <InputContainer left>
                    <TextInput source={"ustid"} fullWidth />
                  </InputContainer>
                  <InputContainer center>
                    <TextInput source={"steuernummer"} fullWidth />
                  </InputContainer>
                  <InputContainer right>
                    <TextInput source={"steuerId"} fullWidth label={"Steuer-ID"}/>
                  </InputContainer>
                </ParagraphBox>

              </FormTabContainerBox>
            </ParagraphBox>
          </Card>
        </Box>
        <EntityToolbar {...formProps} />
      </form>
    }}
  />
}

const EntityTitle = ({ record }) => {
  return <span>Rechnungsanschrift {record ? `"${record.firma}"` : ""}</span>;
};

const EntityEdit = (props) => (
  <Edit
    actions={<DefaultEditActions allowNew={true} {...props} />}
    title={<EntityTitle />}
    component={"div"}
    {...props}
  >
    <EntityForm />
  </Edit>
);

const EntityCreate = (props) => (
  <Create
    actions={<DefaultEditActions allowNew={true} {...props} />}
    title={"Neuen Eintrag anlegen"}
    component={"div"}
    {...props}
  >
    <EntityForm {...props} style={{ width: "100%" }} />
  </Create>
);

export { EntityEdit, EntityCreate };
