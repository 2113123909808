import { FieldGuesser } from "@api-platform/admin";
import React from "react";
import { BooleanField, TextField } from "react-admin"
import HotList from "../utils/components/HotList";
import { billingAddressFilter, billingAddressFiltersDefaultValues } from "./components/BillingAddressFilter";

const BillingAddressList = (props) => (
  <HotList {...props} filters={billingAddressFilter} filterDefaultValues={billingAddressFiltersDefaultValues} sort={"sortierungsnummer"} allowCreate={true} title={"Rechnungsadressen"}>
      <TextField source={"firma"}/>
      <TextField source={"name"} sortable={false}/>
      <TextField source={"plz"}/>
      <TextField source={"ort"}/>
      <TextField source={"email"}/>
      <TextField source={"telefon"}/>
      <BooleanField source={"active"} label={"Aktiv"}/>
  </HotList>
);

export default BillingAddressList;
