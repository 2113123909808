import React from "react";

import { Box, Tooltip, Checkbox } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import { TextField, EditButton } from "react-admin";
import { ContractTags } from "../../utils/components/ListTags";
import { RemoveDriverButton, } from "../components/ListButtons";
import { DriverField } from "../components/DriverField";
import PersonIcon from '@material-ui/icons/Person';
import ImageIcon from '@material-ui/icons/Image';
import IconChargingStation from "../../utils/components/icons/IconChargingStation";
import { InfoButton } from "../components/InfoField";
import UserSettings from "../../utils/components/UserSettings";
import LocationTooltip from "../../utils/components/LocationTooltip";
import { GmapsRouteButton } from "../../utils/components/Buttons";
import ContractStatusLabel from "../components/Timeline/ContractStatusLabel";
import EntityLink from "../../utils/components/EntityLink";
import { IconEditRetoureProtocol } from "../../utils/components/icons/IconEditRetoureProtocol";
import { IconEditRetoure } from "../../utils/components/icons/IconEditRetoure";
import { SortOrderButtons } from "../components/ListButtons/SortOrderButtons";
import { defaultButtonStyle } from "../../utils/components/styles/ButtonStyles";

export const useStyles = makeStyles( theme => ( {
  root: {
    verticalAlign: 'top',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  tableCell: {
    verticalAlign: "middle",
  },
  tableCellLeft: {
    verticalAlign: "middle",
    align: "left"
  }
} ), { name: 'ContractTableRow' } );

const StyledTableRow = ( props ) => {
  const classes = useStyles();
  return (
    <TableRow {...props} className={classes.root}>
      {props.children}
    </TableRow>
  );
};

const t = UserSettings()?.openLinksInNewTab ? '_blank' : '_self';

const ContractTableItem = ( { record, ...props } ) => {
  const classes = useStyles();
  if( !record.id ) {
    record.id =  record['@id']
  }
  const {selectedContracts, setSelectedContracts, ...rest} = props;

  let style = {}
  if( record.cancellationRequested ) {
    style = { backgroundColor: "rgba(255, 0, 0, 0.2)" }
  }

  return <TableRow className={classes.root} style={style} >
    <TableCell className={classes.tableCellLeft}>
      <Checkbox onChange={(e) => {
        const newSelectedContracts = new Set(selectedContracts);
        if (e.target.checked) {
          newSelectedContracts.add(record._id);
        } else {
          newSelectedContracts.delete(record._id);
        }
        setSelectedContracts(newSelectedContracts);
      } }
      checked={selectedContracts.has(record._id)}/>
    </TableCell>
    <TableCell className={classes.tableCellLeft}>
      <Box display="flex" alignItems="center" gridGap="3px">
        <strong>{record.auftragsnummer}</strong>

        {record.verzoegerteAbgabe && (
          <IconChargingStation style={{ width: "12px" }}/>
        )}
        {record.benutzer && (
          <Tooltip title={'AppUser '+record.benutzer.kuerzel + ' | '+record.benutzer.name} arrow interactive><PersonIcon style={{ width: "14px" }}/></Tooltip>
        )}
      </Box>
      {record.anschlussAuftrag?.auftragsnummer}
    </TableCell>

    <TableCell className={classes.tableCell}>
      <Box display="flex" flexWrap="wrap" justifyContent="center" alignItems={"center"} gridGap="3px">
        <ContractTags record={record}/>
        <InfoButton record={record}/>
        {!record.protocol?.allUploadsDone && <ImageIcon style={{display: "flex", color: "#DC3545"}} title={"Es wurden noch nicht alle Bilder hoch geladen"}/>}
        </Box>
      </TableCell>

    <TableCell className={classes.tableCell}>
      <div style={{ display: "flex", flexFlow: "row", alignItems: "center" }}>
        <DriverField record={record}/>&nbsp;<TextField record={record} source={"contractClientAddress.kuerzel"}/>
      </div>
    </TableCell>

    <TableCell>
      <LocationTooltip record={record} location={record.pickupLocation}>
        <span style={{ color: record.protocol?.status > 0 ? 'grey' : '' }}>
          {record.pickupDate} {record.pickupTimeFrom}-{record.pickupTimeTo} {record.pickupLocation?.ort ? `| ` : ''}<strong>{record.pickupLocation?.ort}</strong>
        </span>
      </LocationTooltip>
      <TextField record={record} source={"carDetails"}/>
    </TableCell>

    <TableCell>
      <GmapsRouteButton
        pickupLocation={record.pickupLocation}
        deliveryLocation={record.deliveryLocation}
        style={{
          display: "block",
          textTransform: "unset",
          color: "black"
        }}>
        <>
          {record.totalGooglesollkm} Km
          <br/>
          <TextField record={record} source={"totalGooglefahrtzeit"}/>
        </>
      </GmapsRouteButton>
    </TableCell>

    <TableCell>
      <LocationTooltip record={record} location={record.deliveryLocation}>
        <span style={{ color: record.protocol?.status > 2 ? 'grey' : '' }}>
          {record.deliveryDate} {record.deliveryTime}{record.deliveryLocation?.ort ? ` | ` : ''}<strong>{record.deliveryLocation?.ort}</strong>
        </span>
      </LocationTooltip>
    </TableCell>

    <TableCell>
      {record.anschlussAuftrag && <LocationTooltip record={record} location={record.anschlussAuftrag.deliveryLocation}>
          <span>
              {record.anschlussAuftrag.deliveryDate}{" "}
              {record.anschlussAuftrag.deliveryTimeFrom}-
              {record.anschlussAuftrag.deliveryTimeTo}
              {record.anschlussAuftrag.deliveryLocation?.ort ? " | " : ''}<strong>{record.anschlussAuftrag.deliveryLocation.ort}</strong>
              <br/>
              {record.anschlussAuftrag.carDetails}
          </span>
      </LocationTooltip>}
    </TableCell>

    <TableCell>
      <ContractStatusLabel record={record}/>
    </TableCell>

    <TableCell align="right">
      <Box display={"flex"} justifyContent={"flex-end"}>
        <RemoveDriverButton disabled={record.driver ? null : true} record={record} style={defaultButtonStyle} />
        <EntityLink record={record?.protocol} icon={<SettingsIcon/>} title={"Admin"} style={defaultButtonStyle} />
        <EntityLink record={record?.anschlussAuftrag?.protocol} icon={<IconEditRetoureProtocol/>} title={"Admin Retoure"} style={defaultButtonStyle}/>
        <EditButton title={"Bearbeiten"} label={""} record={record} target={t} style={defaultButtonStyle}/>
        <EntityLink record={record?.anschlussAuftrag} icon={<IconEditRetoure/>} style={defaultButtonStyle} />
        <SortOrderButtons record={record} />
      </Box>
    </TableCell>
  </TableRow>

};

export default ContractTableItem;
