import { SvgIcon } from "@material-ui/core";

export const IconEditRetoure = ( props ) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M0,0H24V24H0V0Z" style={{ fill: "none" }}/>
    <path d="M3,17.25v3.75h3.75l11.06-11.06-3.75-3.75L3,17.25ZM20.71,7.04c.39-.39,.39-1.02,0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41,0l-1.83,1.83,3.75,3.75,1.83-1.83Z"/>
    <path d="M18.81,18.78h-1.28v2.86h-1.61v-7.82h2.91c.92,0,1.64,.21,2.14,.62,.5,.41,.75,.99,.75,1.75,0,.53-.12,.98-.35,1.33-.23,.36-.58,.64-1.05,.85l1.69,3.2v.08h-1.73l-1.47-2.86Zm-1.28-1.3h1.3c.4,0,.72-.1,.94-.31,.22-.21,.33-.49,.33-.85s-.11-.66-.31-.87c-.21-.21-.53-.32-.96-.32h-1.29v2.35Z"/>
  </SvgIcon>
);

