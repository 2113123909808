import { useSimpleFormIteratorItem } from "ra-ui-materialui/esm/input/ArrayInput/useSimpleFormIteratorItem";
import { IconButtonWithTooltip } from "ra-ui-materialui/esm/button";
import ArrowUpwardIcon from "@material-ui/icons/ArrowLeft";
import ArrowDownwardIcon from "@material-ui/icons/ArrowRight";
import * as React from "react";

export const ReOrderButtons = ( { className } ) => {
  const { index, total, reOrder } = useSimpleFormIteratorItem();

  return (
    <div className={className}>
      <IconButtonWithTooltip
        label="ra.action.move_up"
        size="small"
        onClick={() => reOrder( index-1 )}
        disabled={index <= 0}
      >
        <ArrowUpwardIcon/>
      </IconButtonWithTooltip>
      <IconButtonWithTooltip
        label="ra.action.move_down"
        size="small"
        onClick={() => reOrder( index+1 )}
        disabled={total == null || index >= total-1}
      >
        <ArrowDownwardIcon/>
      </IconButtonWithTooltip>
    </div>
  );
};
