import { Button, FormDataConsumer, useDataProvider, useNotify, useRefresh } from "react-admin";

import SaveIcon from "@material-ui/icons/Save";
import { useRecordContext } from "ra-core";
import { PatchRequest } from "../../../utils/request/PatchRequest";
import React, { useState } from "react";
import GlobalLoading from "../../../utils/components/GlobalLoading";
import Toolbar from "../../../utils/components/Toolbar";

export const SaveButtonGroup = ( { record = useRecordContext() } ) => {
  const [saving, setSaving] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  if( !record ) {
    return null;
  }

  const sendMailText = record.send ? "Bereits verschickt" : "Status an VWFS melden"
  const importText = record.imported ? ( record.allContractsImported ? "Bereits importiert" : "Verbliebene Aufträge importieren" ) : "Aufträge erstellen"

  const save = async (data, successMessage= "Aufträge erfolgreich importiert") => {
    setSaving(true);

    const checkboxes = document.querySelectorAll('[name*=bundles]')
    for (const checkbox of checkboxes) {
      if (checkbox.checked) {
        await dataProvider.update(
          'bundles', {
            id: checkbox.dataset.id,
            data: { active: false }
        } ).then( ( response ) => {
          // success side effects go here
          notify( `Fahrtpaket erfolgreich aufgelöst`, { type: "success" } );
        } ).catch( ( error ) => {
          // failure side effects go here
          notify( `Fehler beim bearbeiten: ${error.message}`, { type: "warning" } );
        } )
      }
    }

    await dataProvider.update(
      'vw_imports', {
        id: record.id,
        data: data
      }).then( response => {
        notify( 'custom.message.success', { type: "success" });
        setSaving(false);
        refresh( true )
      } )
      .catch( err => {
        notify( `Fehler: ${err.message}`, { type: "warning" } );
        setSaving(false);
      } );
  }

  return (
    <>
      {saving &&<GlobalLoading/>}
      <Toolbar
        showSaveAndCloseButton={false}
        showSaveButton={false}
        showResetButton={false}
        key={"toolbar"}>
        <Button
          title={sendMailText}
          variant="contained"
          onClick={() => save({sendMail: true}, "Status erfolgreich an VWFS gemeldet")}
          disabled={record.send || saving}
          children={
            <>
              <SaveIcon/>
              {sendMailText}
            </>
          }
        /> <Button
          title={importText}
          variant="contained"
          onClick={() => save({importContracts: true, imported: true})}
          disabled={saving || (record.imported && record.allContractsImported)}
          children={
            <>
              <SaveIcon/>
              {importText}
            </>
          }
        />
      </Toolbar>

    </>
  );
};
