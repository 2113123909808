import NumberInput from "../../../utils/components/NumberInput";
import { ArrayInput, BooleanInput, FormDataConsumer, FormTab, SimpleFormIterator } from "react-admin";
import * as React from "react";


export default ( props ) => {
  return <FormTab label="Zubehör" {...props}>
    <NumberInput label={"Anzahl Tankkarten"} source={"fuelCards"} allowEmpty={true} resettable={true}/>
    <NumberInput label={"Anzahl Schlüssel"} source={"carKeys"} allowEmpty={true} resettable={true}/>
    <NumberInput label={"Anzahl Ladekabel"} source={"chargingCables"} allowEmpty={true} resettable={true}/>

    <ArrayInput source="accessories" label={"Ausstattung"} fullWidth>
      <SimpleFormIterator disableRemove={true} disableAdd={true}>
        <FormDataConsumer>
          {( { getSource, scopedFormData } ) => {
            return scopedFormData &&
                   <BooleanInput
                     source={getSource( "selected" )}
                     record={scopedFormData}
                     label={scopedFormData?.type?.name}
                   />
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  </FormTab>
}
