const GOOGLE_URL = "https://www.google.de/maps/dir/";

const encodeRFC5987ValueChars = (str) => {
  return (
    encodeURIComponent(str)
      // Beachte, dass obwohl RFC3986 "!" reserviert, es nicht kodiert
      // werden muss, weil RFC5987 es nicht reserviert.
      .replace(/['()]/g, escape) // i.e., %27 %28 %29
      .replace(/\*/g, "%2A")
      // Die folgenden Zeichen müssen nicht nach RFC5987 kodiert werden,
      // daher können wir bessere Lesbarkeit übers Netzwerk sicherstellen:
      // |`^
      .replace(/%(?:7C|60|5E)/g, unescape)
  );
};

const GmapsLink = (start, destination) => {
  return (
    GOOGLE_URL +
    encodeRFC5987ValueChars(start) +
    "/" +
    encodeRFC5987ValueChars(destination)
  );
};

export { GmapsLink };
