import React from "react";
import { TextField, ReferenceField, ShowButton, FunctionField } from 'react-admin';
import HotList from "../utils/components/HotList";
import { logFilter, logFilterDefaultValues } from "./components/LogFilter";

const MessageLabel = ( record ) => {
  const {level, message} = record
  return `[${level}] ${message}`;
}
const UserAgentLabel = ( record ) => {
  const agent = record.data?.request['user-agent']
  let label = ''
  if( agent?.includes('Postman') ) {
    label = 'Postman'
  } else if( agent?.includes( 'dart' ) ) {
    label = 'App'
  } else {
    label = 'API (probably)'
  }

  // if( agent.length > 32) {
  //   label += '...';
  // }
  return label;
}

const EntityList = ( props ) => (
  <HotList sort={"createdAt"}
           sortOrder={"DESC"}
           filters={logFilter}
           filterDefaultValues={logFilterDefaultValues}
           {...props}>
    <TextField source={"createdAtFormatted"} label={"Datum"} sortBy={"createdAt"}/>
    <TextField source={"type"} label={"Typ"}/>
    <FunctionField render={MessageLabel} label={"Nachricht"}/>
    <FunctionField render={UserAgentLabel} label={"Agent"}/>
    <FunctionField render={logEntry => `${logEntry.data.request?.uri?.substring(0,64)}${logEntry.data.request?.uri?.length > 64 ? '...' : ''}`} label={"URL"} />
    <TextField source={"data.request.method"} label={"Method"} />
    <ReferenceField source={"user"} reference={"users"}>
      <TextField source={"username"} label={"Nutzer"}/>
    </ReferenceField>
    <ShowButton {...props}/>
  </HotList>
);

export default EntityList;
