import { FunctionField, TextField } from "react-admin";
import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";


const ContractStatusLabel = ( props ) => {
  const { record } = props;

  if( !record || !record.protocol ) {
    return null;
  }

  const protocol = record.protocol
  const statusArray = protocol.statusArray

  const statusToDisplay = <Box key={"statusToDisplay"} style={{minWidth: "120px"}}>
    {statusArray.map( function( s ) {
      return <StatusField title={s.date} status={s.status} key={`${record.id}_${s.status}`}/>
    } )}
  </Box>

  const extendedStatusToDisplay = <Box key={"extendedStatusToDisplay"}>
    {protocol.anschlussProtokoll?.statusArray?.map( function( s ) {
      const status = s.status+4
      return <StatusField title={s.date} status={status} key={`${record.id}_${status}`}/>
    } )}
  </Box>

  return [statusToDisplay, extendedStatusToDisplay]
}

const StatusField = ( props ) => {
  const { title, status } = props
  const styles = {
    border: "1px solid black",
    padding: "0px 3px",
    margin: "1px 2px",
    borderRadius: "5px",
    display: "inline-block",
    marginBottom: "2px",
    backgroundColor: "#C82333",
    borderColor: "#BD2130",
    color: "white",
    fontWeight: "bold",
  }

  return <span
    title={title}
    style={styles}
  >
    U{status}
  </span>
}

export default ContractStatusLabel;
