import { InputContainer, ParagraphBox, ParagraphHeadline } from "../../../utils/components/FormElements";
import { StyledInput } from "../../../utils/components";
import { BooleanInput, FormDataConsumer, DateInput, NumberInput, SelectInput, TextInput } from "react-admin";
import CurrencyInput from "../../../utils/components/InputComponents/CurrencyInput";
import React from "react";
import InputAdornment from '@material-ui/core/InputAdornment';


export default ( { record, isCreate= false } ) => (
  <>
    <ParagraphHeadline>Grunddaten</ParagraphHeadline>

    <ParagraphBox>
      <InputContainer left>
        <ParagraphBox>
          <StyledInput left>
            <SelectInput
              source={"type"}
              label={"Formulartyp"}
              choices={[
                { id: 'Rechnung', name: 'Rechnung' },
                { id: 'Gutschrift', name: 'Gutschrift' },
              ]} fullWidth
              defaultValue={"Rechnung"}
              disabled={record.invoiceCompleted || !isCreate}
              helperText={false}
            />
          </StyledInput>


          <StyledInput source={"orderNumber"} label={"Nr"} resettable center helperText={isCreate ? false : "Zum generieren einer neuen Nummer das Feld leeren"}/>

          <FormDataConsumer>
            {({ formData, ...rest }) => (
                <StyledInput right style={{display: 'flex', alignItems: 'center', marginLeft: "0.5rem"}}>
                  <BooleanInput source="invoiceCompleted" label={`${formData.type} abgeschlossen`} helperText={false}/>
                </StyledInput>
            )}
          </FormDataConsumer>
        </ParagraphBox>

        <ParagraphBox>
          <StyledInput left>
            <CurrencyInput source="amountTotal" label="Gesamtsumme" readOnly />
          </StyledInput>
          <StyledInput center>
            <CurrencyInput source={"amountNet"} label="Nettosumme" readOnly />
          </StyledInput>
          <StyledInput right>
            <CurrencyInput source={"amountTax"} label="MwSt." readOnly />
          </StyledInput>
        </ParagraphBox>

        <FormDataConsumer>
          {({ formData }) => (
            formData.type === 'Rechnung' && <ParagraphBox style={{marginTop: "7px"}}>
              <InputContainer left>
                <DateInput source={"invoiceDate"} label={"Rechnungsdatum"} helperText={false} fullWidth/>
              </InputContainer>
              <StyledInput right>
                <NumberInput
                  source={"termOfPayment"}
                  label={"Zahlungsziel"}
                  min={0}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">(in Tagen)</InputAdornment>,
                  }}
                  helperText={false}
                  fullWidth
                />
              </StyledInput>

            </ParagraphBox>
          )}
        </FormDataConsumer>

        <ParagraphBox>
          <InputContainer left>
            <TextInput source={"additionalText"} label={"Zusatztext"} fullWidth multiline helperText={false}/>
          </InputContainer>
        </ParagraphBox>

      </InputContainer>

    </ParagraphBox>
  </>
)
