const pushItemToContracts = (contract, contractArray, item) => {
  if (!contractArray[contract]) {
    contractArray[contract] = [];
  }

  contractArray[contract] = contractArray[contract].concat(item);

  return contractArray;
};

const groupByEntity = (items) => {
  let applicationGroups = {};
  for( const item of items ) {
    const identifier = item.type === 'contract' ? item.contract.auftragsnummer : item.bundle.bundle;

    if (!applicationGroups[identifier]) {
      let pickupDate
      if( item.type === 'contract' ) {
        pickupDate = item.contract.pickupDateRaw
      }
      if( item.type === 'bundle' ) {
        pickupDate = item.bundle.contractBundles[0].contract?.pickupDateRaw
      }

      applicationGroups[identifier] = {
        applications: [],
        entity: item.entity,
        additionalRide: item.additionalRide,
        type: item.type,
        pickupDate: new Date( pickupDate ).valueOf()
      };
    }

    applicationGroups[identifier].applications.push( item);
    applicationGroups[identifier].count++;
  }

  const ids = Object.keys(applicationGroups)

  const sortByDate = (a,b) => {
    return applicationGroups[a].pickupDate - applicationGroups[b].pickupDate
  }

  return {
    applicationGroups: ids.sort(sortByDate),
    applicationsByEntity: applicationGroups,
  };
}

export { groupByEntity };
