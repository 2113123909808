import React, {Fragment} from 'react';
import {
  Datagrid,
  List,
  ListContextProvider,
  ResourceContextProvider,
  TextField,
  useListController,
  FunctionField, useNotify, Button
} from 'react-admin';

import { Box } from "@material-ui/core";
import AssignmentIcon from '@material-ui/icons/Assignment';


import DefaultPagination from '../../utils/DefaultPagination';
import DateField from "../../utils/components/DateField";
import { CancelContractButton } from "./components/CancelContractButton";
import { ConfirmContractButton } from "./components/ConfirmContractButton";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { athlonFilter, athlonFilterDefaultValues } from "./components/AhtlonFilter";

const useStyles = makeStyles({
                               actions: {
                                 display: 'flex',
                                 flexDirection: 'row',
                                 justifyContent: 'center'
                               }
                             });

export const AtrImportList = ( props ) => {
  return <AthlonImportList {...props} customer="277" />
}

export const AthlonImportList = ( props ) => {
  const customer = props.customer;
  const listControllerProps = {
    basePath: '/athlon_import',
    hasCreate: false,
    resource: 'import_contracts',
    perPage: 100,
    exporter: false,
    filter: {
      isDeclinedByVermaco: false,
      exists: {
        import: false
      },
      auftraggeber: customer
    }
  };

  const classes = useStyles();
  const notify = useNotify()

  const controllerProps = useListController( listControllerProps );
  return (
    <ResourceContextProvider value="hs_contracts">
      <ListContextProvider value={controllerProps}>
        <List
          filters={athlonFilter}
          filterDefaultValues={athlonFilterDefaultValues}
          perPage={100}
          pagination={<DefaultPagination/>}
          bulkActionButtons={false}
          sort={{ field: 'createdAt', order: 'ASC' }}
          syncWithLocation
          {...controllerProps}
        >
          <Datagrid>
            <DateField label="Eingangsdatum" source="createdAt"/>
            <Fragment label="Auftragsdatum" sortBy="datum">
              <DateField label="Abholdatum" source="datum" options={{ month: "2-digit", day: "2-digit" }}/> - <DateField label="Bringdatum" source="bringdatum"/>
            </Fragment>
            <TextField label="Auftraggeber" source="auftraggeberKuerzel"/>
            <TextField label="Leasingvertragsnummer" source="leasingVertragsnummer"/>
            <TextField label="Fahrzeugtyp" source="autotyp"/>
            <TextField label="Kennzeichen" source="kennzeichen"/>
            <TextField label="Abholort" source="abholort" sortable={false} />
            <TextField label="Zielort" source="bringort" sortable={false} />

            <FunctionField headerClassName={classes.actions} label="Aktionen" render={ (record) => (
              record.isImported ? <span id={`copy-${record.leasingVertragsnummer}`}>
                <Button
                  children={ <AssignmentIcon /> }
                  title="Auftragsdaten als Tabellenzeile kopieren"
                  onClick={ ( event ) => {
                                    document.querySelector(`#copy-${record.leasingVertragsnummer} textarea`).select();
                                    document.execCommand("copy");
                                  } } />
                                  <textarea style={{opacity: 0, width: 0, height: 0}}>{ record.copyText }</textarea>

              </span> :
              <Box className={classes.actions}>
                  <ConfirmContractButton record={record}/>
                  <CancelContractButton record={record}/>
              </Box>
            )} />
          </Datagrid>
        </List>
      </ListContextProvider>
    </ResourceContextProvider>
  );
};

AthlonImportList.propTypes = {
  customer: PropTypes.oneOf(['272', '277'])
}
AthlonImportList.defaultProps  = {
  customer: '272'
}
