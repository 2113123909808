import SimpleFormIterator from "../CustomIterator";

const SimpleFormIndexableIterator = (props) => {
  props.fields.map((damage, i) => {
    if (props.fields.value[i] !== undefined) {
      return props.fields.value[i]['sortOrder'] = i;
    }
  });
  return (
    <SimpleFormIterator {...props} />
  );
}


export default SimpleFormIndexableIterator;
