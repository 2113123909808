import * as React from "react";
import { Create, Edit, FormDataConsumer, SimpleForm } from "react-admin";
import DefaultEditActions from "../utils/DefaultEditActions";
import { TextInput } from "../utils/components";
import { CustomerReferenceInput } from "../customers/components/CustomerReferenceInput";
import { UserReferenceArrayInput } from "../user/components/UserReferenceInput";
import { BillingAddressReferenceInput } from "../billingAddress/components/BillingAddressReferenceInput";
import { AddressReferenceInput } from "../address/components/AddressReferenceInput";
import { getFilter } from "../contracts/ContractEdit";
import { Box } from "@material-ui/core";


const EntityForm = (props) => (
  <SimpleForm style={{ width: "100%" }} {...props}>
    <Box>
      <TextInput source={"name"} fullWidth/>
    </Box>
    <Box>
    <CustomerReferenceInput source={"customer"} label={"Kunde"} fullWidth />
    </Box>
    <Box>
    <FormDataConsumer>
      {( { formData } ) => (
        <BillingAddressReferenceInput
          source={"defaultBillingAddress"}
          label={"Rechnungsadresse"}
          filter={getFilter( formData.customer, 'adressbesitzer' )}
          fullWidth
        />
      )}
    </FormDataConsumer>
    </Box>

    <Box>
    <FormDataConsumer>
      {( { formData } ) => (
        <AddressReferenceInput
          source={"serviceAddress"}
          label={"Leistungsempf\xe4nger"}
          filter={getFilter( formData.customer, 'allowedCustomers' )}
          style={{marginRight: "1em"}}
          fullWidth
        />
      )}
    </FormDataConsumer>
    </Box>

    {!props.isCreate && (
      <Box>

      <UserReferenceArrayInput
        label={"Mitarbeiter"}
        source={"users"}
        fullWidth
        filter={{
          "customer_and_subs": props.record.customer
        }}/>
      </Box>

      )}
  </SimpleForm>
);

const EntityTitle = ({ record }) => {
  return <span>Filiale / Abteilung {record.name}</span>;
};

export const EntityEdit = (props) => (
  <Edit
    actions={<DefaultEditActions {...props} />}
    title={<EntityTitle />}
    {...props}
  >
    <EntityForm
      isCreate={false} />
  </Edit>
);

export const EntityCreate = (props) => (
  <Create
    actions={<DefaultEditActions {...props} />}
    title={"Neuen Eintrag anlegen"}
    {...props}
  >
    <EntityForm
      isCreate={true}
      {...props}
    />
  </Create>
);
