import React from "react";
import { ReferenceField, DateField, TextField } from 'react-admin';
import HotList from "../utils/components/HotList";
import { pricingFilter, pricingFiltersDefaultValues } from "./components/PricingFilter";


const PricingList = props => (
  <HotList sort='sortierung'
           filterDefaultValues={pricingFiltersDefaultValues}
           filters={pricingFilter}
           {...props}>
    <DateField locales="de-DE" options={{ year: 'numeric', month: '2-digit', day: '2-digit' }} source={"gueltigab"} label={"Gültig ab"}/>
    <TextField source={"km"}/>
    <ReferenceField label="Fima" source="kunde" reference="customers">
      <TextField source="firma"/>
    </ReferenceField>
    <TextField source={"preis"} label={"Preis"}/>
  </HotList>
);

export default PricingList;
