import AppEventList from "./AppEventList";
import { EntityCreate, EntityEdit } from "./AppEventEdit";

const Complaints = {
  list: AppEventList,
  edit: EntityEdit,
  create: EntityCreate,
  name: "app_events"
};

export default Complaints;
