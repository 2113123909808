// in ./CustomResetViewsButton.js
import * as React from "react";
import { Fragment, useState } from "react";
import {
  Button,
  Confirm,
  useUpdateMany,
  useUpdate,
  useRefresh,
  useNotify,
  useUnselectAll, linkToRecord,
} from "react-admin";
import SendIcon from "@material-ui/icons/Send";
import MailIcon from '@material-ui/icons/Mail';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import Link from "../../../utils/components/Link";
import { AlertTitle } from "@material-ui/lab";
import { IconButton } from "@material-ui/core";
import { CloseIcon } from "react-modal-image/lib/icons";

const PROTOCOLS_ENDPOINT = process.env.REACT_APP_API_ENTRYPOINT+"/protocols"
const ENDPOINT = PROTOCOLS_ENDPOINT+"/send_mails"

const BulkSendButton = ( { selectedIds } ) => {
  const [loading, setLoading] = useState( false );
  const [open, setOpen] = useState( false );
  const [error, setError] = React.useState( null );
  const [errorMessage, setErrorMessage] = React.useState( [] );
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const save = async() => {
    setLoading( true )
    setError( null );
    setErrorMessage( [] );

    await axios.post( ENDPOINT, { protocolIds: selectedIds }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem( "token" )}`,
      },
    } );
  }

  const handleClick = () => setOpen( true );
  const handleDialogClose = () => setOpen( false );

  const handleConfirm = () => {
    save().then( () => {
      unselectAll( "protocols" );
      refresh();
      notify( "Protokolle versendet" );
      setLoading( false )
    } ).catch( e => {
      unselectAll( "protocols" );
      if( typeof e.response?.data[ 'messages' ] !== "undefined" ) {
        const event = new MouseEvent( 'click', {
          view: window,
          bubbles: true,
          cancelable: true
        } );

        e.response?.data[ 'messages' ].forEach(
          error => {
            if( error.protocolId ) {
              const row = [...document.querySelectorAll( 'td > span' )]
                .filter( a => a.textContent.includes( error.protocolId ) )

              row[ 0 ].closest( 'tr' ).querySelector( 'input[type="checkbox"]' ).dispatchEvent( event )
            }
          }
        )
      }

      setError( "Fehler beim Versand" );
      setErrorMessage( e.response?.data[ 'messages' ] );
      setLoading( false )
    } );
    setOpen( false );
  };

  return (
    <Fragment>
      <Snackbar open={error}>
        <Alert severity="error" elevation={6} variant="filled" action={
          <IconButton
            style={{ alignSelf: "flex-start" }}
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setError( null );
            }}
          >
            <CloseIcon fontSize="inherit"/>
          </IconButton>
        }
               sx={{ mb: 2 }}>
          <AlertTitle>{error}</AlertTitle>
          {errorMessage.map( error => {
            return <p style={{ margin: "0" }}>
              {error.protocolIRI ? <ProtocolLink subStyles={{ color: "white", textTransform: "unset" }} id={error.protocolIRI}>
                                   <strong>{error.protocolId}:</strong> {error.message}
                                 </ProtocolLink> :
               error.message}
            </p>
          } )}
        </Alert>
      </Snackbar>

      <Button
        label="Protokolle versenden"
        onClick={handleClick}
        children={<SendIcon/>}
      />
      <Confirm
        isOpen={open}
        loading={loading}
        title="Protokollversand"
        content="Ausgewählte Protokolle versenden?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

const ProtocolLink = ( props ) => {
  const { id } = props
  const link = linkToRecord( `/protocols`, id, 'edit' );
  return <Link to={link} target={'_blank'} {...props}>
    {props.children}
  </Link>
}

const SendButton = ( props ) => {
  const { record, ...rest } = props;
  const [isOpened, setIsOpened] = useState( false );
  const refresh = useRefresh();
  const notify = useNotify();

  const [update, { loading }] = useUpdate( 'protocols',
                                           record[ '@id' ],
                                           {
                                             sendProtocolMail: true,
                                             confirmMailToBeSend: true
                                           },
                                           record,
                                           {
                                             onSuccess: () => {
                                               refresh();
                                               notify( 'Das Protokoll wurde verschickt' );
                                             },
                                             onFailure: ( error ) => {
                                               notify(
                                                 error.message || 'Fehler beim Verschicken des Protokolls',
                                                 { type: 'warning', multiLine: true }
                                               )
                                             }
                                           }
  );

  const handleConfirm = () => {
    update();
    setIsOpened( false );
  };

  return (
    <Fragment>
      <Button
        title={'Protokoll versenden'}
        onClick={() => setIsOpened( true )}
        children={<MailIcon/>}
        {...rest}
      />
      <Confirm
        isOpen={isOpened}
        loading={loading}
        title={"Protokollversand "+record.orderNumber}
        content={"Das Protokoll"+( record.mailSend ? " erneut" : "" )+" versenden?"}
        onConfirm={handleConfirm}
        onClose={() => setIsOpened( false )}
      />
    </Fragment>
  );
}

export { SendButton, BulkSendButton };
