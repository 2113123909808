import { cacheDataProviderProxy } from "react-admin";
import { Redirect, Route } from "react-router-dom";
import {
  fetchHydra as baseFetchHydra,
  hydraDataProvider as baseHydraDataProvider,
  useIntrospection,
} from "@api-platform/admin";
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";

const ENTRYPOINT = process.env.REACT_APP_API_ENTRYPOINT;

const fetchHydra = (url, options = {}) =>
  baseFetchHydra(url, {
    ...options,
    headers: getHeaders,
  });

const RedirectToLogin = () => {
  const introspect = useIntrospection();

  if (localStorage.getItem("token")) {
    introspect();
    return <></>;
  }
  return <Redirect to="/login" />;
};

const getHeaders = () =>
  localStorage.getItem("token")
    ? {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      }
    : {};

const apiDocumentationParser = async (entrypoint) => {
  try {
    const { api } = await parseHydraDocumentation(entrypoint, {
      headers: getHeaders,
    });
    return { api };
  } catch (result) {
    if (result.status === 401) {
      // Prevent infinite loop if the token is expired
      localStorage.removeItem("token");

      return {
        api: result.api,
        customRoutes: [<Route path="/" component={RedirectToLogin} />],
      };
    }

    throw result;
  }
};

const providerProps = {
  entrypoint: ENTRYPOINT,
  httpClient: fetchHydra,
  apiDocumentationParser: apiDocumentationParser,
  mercure: false,
  useEmbedded: true,
};

const baseDataProvider = baseHydraDataProvider(providerProps);
const dataProvider = {
  ...baseDataProvider,
  create: (resource, params) => {
    return baseDataProvider.create(resource, params);
  },
};

export default cacheDataProviderProxy(dataProvider, 30);
