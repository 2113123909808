import * as React from "react";
import { Create, Edit, SimpleForm, TextInput } from "react-admin";
import DefaultEditActions from "../utils/DefaultEditActions";

const EntityForm = props => (
  <SimpleForm {...props}>
    <TextInput locales="de-DE" options={{ year: 'numeric', month: '2-digit', day: '2-digit' }} source={"gueltigab"} label={"Gültig ab"}/>
    <TextInput source={"km"}/>
    <TextInput source={"kunde"} label={"Kunde"}/>
    <TextInput source={"preis"} label={"Preis"}/>
  </SimpleForm>
);

const EntityTitle = ( { record } ) => {
  return <span>Zubehör {record ? `"${record.name}"` : ''}</span>;
}

const EntityEdit = props => (
  <Edit actions={<DefaultEditActions {...props} />} title={<EntityTitle/>} {...props}>
    <EntityForm/>
  </Edit>
);

const EntityCreate = props => (
  <Create actions={<DefaultEditActions {...props} />} {...props} title={"Neuen Eintrag anlegen"}>
    <EntityForm {...props} style={{ width: '100%' }}/>
  </Create>
);


export { EntityEdit, EntityCreate };
