import { BooleanInput, SearchInput } from "react-admin";
import React from "react";
import { CustomerReferenceArrayInput } from "../../customers/components/CustomerReferenceInput";
import ReferenceArrayInput from "../../utils/components/ReferenceArrayInput";
import translateDriverLabel from "../../contracts/components/TranslateDriverLabel";
import moment from 'moment';
import "moment/locale/de";
import { DriverReferenceArrayInput } from "../../drivers/components/DriverReferenceInput";

moment.locale( "de" );

const addressFilter = [
  <SearchInput
    placeholder="Suche"
    source="_name"
    style={{ minWidth: "250px", marginTop: "0" }}
    alwaysOn
  />,
  <BooleanInput
    source="kategorie"
    label="Stellplatz"
    parse={v => v ? '1' : null}
    format={v => typeof v !== "undefined"}
    alwaysOn
  />,
];

const addressFiltersDefaultValues = {
  saved: 1
};

export {
  addressFilter,
  addressFiltersDefaultValues
}
