import ProtocolList from "./ProtocolList";
import ProtocolEdit from "./ProtocolEdit";

const Protocols = {
  list: ProtocolList,
  edit: ProtocolEdit,
  name: "protocols"
};

export default Protocols;
