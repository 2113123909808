import React from "react";
import { SelectArrayInput, AutocompleteInput, AutocompleteArrayInput } from "react-admin";
import ReferenceArrayInput from "../../utils/components/ReferenceArrayInput";
import { DriverReferenceArrayInput } from "../../drivers/components/DriverReferenceInput";

export const applicationFilter = [
  <SelectArrayInput label={"Status"} source={"status"} choices={[
    { name: 'Ausstehend', value: 'pending' },
    { name: 'Angenommen', value: 'accepted' },
    { name: 'Abgelehnt', value: 'declined' },
    { name: 'Gelöscht', value: 'deleted' },
    { name: 'Zurück gezogen', value: 'revoked' },
  ]} optionValue={"value"} optionText={"name"} alwaysOn style={{minWidth: "200px"}}/>,

  <ReferenceArrayInput
    alwaysOn
    source={"contract"}
    reference={"contracts"}
    allowEmpty={true}
    label={"Auftrag"}
    fullWidth
    resettable
    filterToQuery={( searchText ) => ( {
      auftragsnummer: searchText,
      abgeschlossen: 0,
      fahrerfehlt: 0,
      auftaegeExt: {
        sperrenFuerAngebotsliste: 0,
      }
    } )}
    perPage={50}
    helperText={false}
  >
    <AutocompleteArrayInput
      optionText="auftragsnummer"
      source={"anschlussAuftrag.auftragsnummer"}
    />
  </ReferenceArrayInput>,
  <DriverReferenceArrayInput source={"logistics_and_subs"} filter={{ groups_and_subs: ["3", "5", "6", "7", "8"] }} alwaysOn/>,

];

export const filterDefaultValues = {
  "status": ['pending'],
  // "abgeschlossen": "0"
};

