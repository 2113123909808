import * as React from "react";
import { Create, Edit, SimpleForm, BooleanInput } from "react-admin";
import DefaultEditActions from "../utils/DefaultEditActions";
import { DateInput } from "../utils/components/DateInput";
import { Box } from "@material-ui/core";
import {
  validateRequired
} from "../utils/validators/DefaultValidators";
import { TextInput } from "../utils/components";


const EntityForm = (props) => (
  <SimpleForm {...props}>
    <TextInput source={"recipient"} style={{minWidth: "350px"}} label={"Empfänger Mail"}/>
  </SimpleForm>
);

const EntityTitle = ({ record }) => {
  return <span>Vorversand für Auftrag {record.protocol.orderNumber}</span>;
};

export const EntityEdit = (props) => (
  <Edit
    actions={<DefaultEditActions {...props} />}
    title={<EntityTitle />}
    {...props}
  >
    <EntityForm
      isCreate={false} />
  </Edit>
);

export const EntityCreate = (props) => (
  <Create
    actions={<DefaultEditActions {...props} />}
    title={"Neuen Eintrag anlegen"}
    {...props}
  >
    <EntityForm
      isCreate={true}
      {...props}
    />
  </Create>
);
