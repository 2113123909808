import * as React from "react";
import { ArrayInput, Button, Confirm, Create, Edit, FormWithRedirect, SaveButton, useDataProvider, useNotify } from "react-admin";
import DefaultEditActions from "../utils/DefaultEditActions";
import { BooleanInput, TextInput } from "../utils/components";
import { InputContainer, ParagraphBox, ParagraphHeadline, Spacer } from "../utils/components/FormElements";
import { Box, Toolbar } from "@material-ui/core";
import PriceGroupFormIterator from "./components/PriceGroupFormIterator";
import RefreshButton from "../utils/components/RefreshButton";
import { CloneGroupButton } from "./components/CloneGroupButton";
import { useState } from "react";
import { PatchRequest } from "../utils/request/PatchRequest";

const SetDefaultPriceGroupButton = ( { record }) => {
  const [open, setOpen] = useState( false )
  const notify = useNotify()
  return <>
    <Confirm
      isOpen={open}
      title={`Standard-Preisgruppe setzen`}
      content="Möchten Sie diese Preisgruppe als Standard-Preisgruppe setzen? Dadurch wird die aktuelle Standard-Preisgruppe deaktiviert."
      onConfirm={async () => {
        await PatchRequest(
          `${process.env.REACT_APP_API}${record.id}/set_default`,
          {}
        ).then( ( response ) => {
          notify( "Preisgruppe erfolgreich als Standard-Preisgruppe gesetzt", { type: "success" } )
          record.defaultPriceSet = true
          setOpen( false )
        }).catch( ( error ) => {
          notify( `Fehler beim bearbeiten: ${error.message}`, { type: "warning" } )
        })
      }}
      onClose={() => setOpen( false )}
    />
    <Button label={"Als Standard-Preisgruppe setzen"} onClick={() => setOpen(true)} disabled={record.defaultPriceSet}/>
  </>
}

const EntityForm = (props) => {
  const transformDataOnSubmit = values => {
    let priceSets = []
    values.groupedPriceSets.forEach( ( priceSet ) => {
      priceSet.items.forEach( ( item ) => {
        priceSets.push( item )
      } )
    })

    values.priceSets = priceSets

    return values
  };

  return <FormWithRedirect
    {...props}
    render={( formProps ) => {
      return <form>
        <Box p={"1rem"} mb={"40px"}>

          <ParagraphBox>
            <InputContainer left>
              <TextInput source={"name"} label={"Name"} fullWidth/>
            </InputContainer>

            <InputContainer center>
              <TextInput source={"description"} label={"Beschreibung"} fullWidth/>
            </InputContainer>
          </ParagraphBox>

          <ParagraphBox>
            <InputContainer>
              <BooleanInput source={"active"} label={"Aktiv"} defaultValue={true}/>
            </InputContainer>
          </ParagraphBox>
          <ParagraphBox>
            <InputContainer>
              <SetDefaultPriceGroupButton record={formProps.record} />
            </InputContainer>
          </ParagraphBox>

          <Spacer />
          <ParagraphHeadline>Preisgruppen</ParagraphHeadline>
          <ParagraphBox>
            <ArrayInput source={"groupedPriceSets"} label={""}>
              <PriceGroupFormIterator/>
            </ArrayInput>
          </ParagraphBox>
        </Box>

        <Toolbar
          style={{
            position: "fixed",
            zIndex: 100000,
            bottom: 10,
            maxWidth: "100%",
            backgroundColor: "rgba(255,255,255, 0.8)",
            borderRadius: "5px",
          }}
        >
          {props.isCreate ? <Box width="100%">
                      <SaveButton
                        saving={formProps.saving}
                        handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                        label={"Preisgruppe anlegen"}
                        style={{ marginRight: "0.5rem" }}
                      />
                    </Box> :

           <Box width="100%">
             <SaveButton
               saving={formProps.saving}
               handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
               label={"Speichern und schließen"}
               style={{ marginRight: "0.5rem" }}
               transform={data => transformDataOnSubmit( data )}
             />

             <SaveButton
               saving={formProps.saving}
               redirect={false}
               label={"Speichern"}
               handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
               style={{ marginRight: "0.5rem" }}
               transform={data => transformDataOnSubmit( data )}
             />

            <RefreshButton/>

           </Box>}
        </Toolbar>
      </form>
    }}/>
};

const EntityTitle = ({ record }) => {
  return record.defaultPriceSet ? <>Standard-Preisgruppe</> : <>Preisgruppe {record.name}</>;
};

const PriceGroupEditActions = props => {
  return (
    <DefaultEditActions {...props}>
      <CloneGroupButton record={props?.data}/>
    </DefaultEditActions>
  );
}

export const EntityEdit = ( props ) => {

  return <Edit
    actions={<PriceGroupEditActions allowNew={true} {...props} />}
    title={<EntityTitle />}
    {...props}
  >
    <EntityForm
      isCreate={false} />
  </Edit>
}

export const EntityCreate = (props) => (
  <Create
    actions={<DefaultEditActions {...props} />}
    title={"Neuen Eintrag anlegen"}
    {...props}
  >
    <EntityForm
      isCreate={true}
      {...props}
    />
  </Create>
);
