import { GmapsLink } from '../../GmapsLink';
import MapIcon from '@material-ui/icons/Map';
import { Button } from 'react-admin';

const GmapsRouteButton = ( props ) => {
  const { style } = props
  const link = getGMapsRouteLink( props ) ?? null

  return (
    <Button
      style={style ?? {}}
      title="Route bei Google Maps anzeigen"
      target="_blank"
      href={link}
      children={<MapIcon/>}
      disabled={!link}
    >{props.children ? props.children : <MapIcon/>}</Button>
  );
};

const getGMapsRouteLink = ( props ) => {
  const { pickupLocation, deliveryLocation } = props
  if(
    !pickupLocation ||
    !deliveryLocation ||
    !pickupLocation.strasse ||
    !pickupLocation.ort ||
    !deliveryLocation.strasse ||
    !deliveryLocation.ort
  ) {
    return null;
  }

  const directions_start = `${pickupLocation.strasse},${pickupLocation.ort}`;
  const directions_end = `${deliveryLocation.strasse},${deliveryLocation.ort}`;
  return GmapsLink( directions_start, directions_end )
}

export { GmapsRouteButton, getGMapsRouteLink };
