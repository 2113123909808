import React from "react";
import {
  TextField, BooleanField

} from "react-admin";
import HotList from "../utils/components/HotList";
import DateField from "../utils/components/DateField";



const VwImportsList = ( props ) => (
  <HotList {...props} sort={"createdAt"} sortOrder={"DESC"} perPage={5}>
    <DateField source={"createdAt"} label={"Datum"}/>
    <TextField source={"title"} label={"Dateiname"}/>
    <BooleanField source={"imported"} label={"Importiert"}/>
    <BooleanField source={"send"} label={"Verschickt"}/>
  </HotList>
);


export { VwImportsList };
