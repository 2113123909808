import { Button, FormWithRedirect, NumberInput, SaveButton, SaveContextProvider, useDataProvider, useNotify, useRefresh } from "react-admin";
import React, { Fragment, useState } from 'react';

import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

import GlobalLoading from "../../../utils/components/GlobalLoading";
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@material-ui/core";
import { arrowButtonIconStyle, arrowButtonStyle } from "../../../utils/components/styles/ButtonStyles";
import blue from "@material-ui/core/colors/blue";
import IconCancel from "@material-ui/icons/Cancel";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  outline: {
    border: "1px solid black",
    padding: "3px 5px",
    borderRadius: "5px",
    display: "inline-block",
    marginBottom: "2px",
  },
  error: {
    border: "1px solid black",
    padding: "3px 5px",
    borderRadius: "5px",
    display: "inline-block",
    marginBottom: "2px",
    backgroundColor: "#C82333",
    borderColor: "#BD2130",
    color: "white",
    hover: {
      color: "yellow",
    },
  },
};

function DialogChangeSortOrder( { children, record } ) {
  const dataProvider = useDataProvider();

  const [showDialog, setShowDialog] = useState( false );
  const [saving, setSaving] = useState( false );

  const notify = useNotify();

  const handleOpenClick = () => {
    setShowDialog( true );
  };

  const handleCloseClick = () => {
    setShowDialog( false );
  };

  const save = async( values ) => {
    setSaving( true )
    dataProvider
      .update( "contracts", {
        id: record.id,
        data: values,
        previousData: record
      } ).then( ( response ) => {
      notify( `Position für Auftrag ${record.auftragsnummer} erfolgreich geändert`, { type: "success" } );
      setSaving(false )
      setShowDialog( false )
    } ).catch( ( error ) => {
      setSaving( false )
      notify( `Error: ${error.message}`, { type: "warning" } );
    } );
  };

  const mutationMode = "optimistic";

  return (
    <SaveContextProvider value={{ save, saving, mutationMode }}>
      <div style={{ display: "inline" }} onClick={handleOpenClick}>
        {children}
      </div>

      <Dialog fullWidth open={showDialog} onClose={handleCloseClick}>
        <DialogTitle>Auftrag {record.auftragsnummer}</DialogTitle>

        <FormWithRedirect
          resource="contracts"
          save={save}
          render={( { handleSubmitWithRedirect, pristine, saving } ) => (
            <>
              <DialogContent>
                <DialogContentText style={{ marginBottom: "0", marginTop: "1rem" }}>
                  <Typography variant="subtitle2">Sortierung ändern</Typography>
                </DialogContentText>

                <NumberInput
                  source="sortOrder"
                  label="Sortierung"
                  fullWidth
                  initialValue={record.sortOrder}
                  min={-999}
                  max={999}
                  helperText={"Bitte eine Zahl zwischen maximal -999 und 999 eingeben. Je niedriger die Zahl, desto weiter oben wird die Position angezeigt."}
                />
              </DialogContent>

              <DialogActions>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                />

                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                >
                  <IconCancel/>
                </Button>
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </SaveContextProvider>
  );
}

const SortOrderFieldView = ( { record, currentSortOrder, classes } ) => {
  return (
    <Fragment>
      <DialogChangeSortOrder record={record}>
        <div style={{ cursor: "pointer" }} className={"sortOrder"}>
          { currentSortOrder === 0 ? '-' : currentSortOrder }
        </div>
      </DialogChangeSortOrder>
    </Fragment>
  );
};

export const SortOrderField = withStyles( styles )( SortOrderFieldView );

export const SortOrderButtons = ( { record, style = arrowButtonStyle, ...rest } ) => {
  const dataProvider = useDataProvider();

  const [loading, setLoading] = useState( false );
  const notify = useNotify();

  let currentSortOrder = record.sortOrder;
  let debounceTimer = null;

  if( !record ) {
    return null
  }

  const handleClick = async( direction ) => {
    currentSortOrder = currentSortOrder + direction;

    if( debounceTimer ) {
      clearTimeout( debounceTimer );
    }

    debounceTimer = setTimeout( () => {
        updateSortOrder( currentSortOrder );
        }
        , 500 );


  }

  const updateSortOrder = async( sortOrder ) => {
    setLoading( true )

      dataProvider
        .update( "contracts", {
          id: record.id,
          data: {
            sortOrder: sortOrder
          },
          previousData: record
        } ).then( ( response ) => {
        notify( `Position für Auftrag ${record.auftragsnummer} erfolgreich geändert`, { type: "success" } );
        setLoading(false )
        // refresh();
      } ).catch( ( error ) => {
        setLoading( false )
        notify( `Error: ${error.message}`, { type: "warning" } );
      } );
    // };

    setLoading( false );
  }

  const mutationMode = "optimistic";

  return (
    <>
    <SaveContextProvider value={{ handleClick, loading, mutationMode }}>
    {loading &&<GlobalLoading/>}
      <Box display={"flex"} alignItems={"center"} flexDirection={"row"}>
        <Button
          title={"+1"}
          onClick={() => handleClick( 1 )}
          children={<><AddIcon style={arrowButtonIconStyle}/></>}
          style={style}
          {...rest} />

        <Box color={blue[ "500" ]} pl={"0.25rem"} pr={"0.25rem"} width={"5px"}>
          <SortOrderField record={record} currentSortOrder={currentSortOrder}>
            {( currentSortOrder-1000 === 0 ) ? '-' : currentSortOrder-1000}
          </SortOrderField>

        </Box>

        <Button
          title={"-1"}
          onClick={() => handleClick( -1 )}
          children={<><RemoveIcon style={arrowButtonIconStyle}/></>}
          style={style}
          {...rest}
        />
      </Box>
      </SaveContextProvider>
    </>
  );
};
