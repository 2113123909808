import { AutocompleteInput, ReferenceInput } from "react-admin";
import React from "react";

export const ContractReferenceInput = ( props ) => {
  return <ReferenceInput
    reference={"contracts"}
    emptyText={"Kein Anschlussauftrag"}
    fullWidth
    resettable
    format={( v ) => v !== null && typeof v === "object" && v.hasOwnProperty( "@id" ) ? v[ "@id" ] : v}
    filterToQuery={( searchText ) => ( {
      auftragsnummer: searchText,
      abgeschlossen: 0,
    } )}
    allowEmpty={true}
    helperText={false}
    {...props}
  >
    {props.children ?? <AutocompleteInput
      optionText="auftragsnummer"
    />
    }
  </ReferenceInput>

}
