import { AutocompleteInput, AutocompleteArrayInput, BooleanInput } from "react-admin";
import ReferenceInput from "../../utils/components/ReferenceInput";
import ReferenceArrayInput from "../../utils/components/ReferenceArrayInput";
import translateDriverLabel from "../../contracts/components/TranslateDriverLabel";
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";


const autocompleteFilter = ( searchText ) => {
  return searchText ? { _name: searchText } : null;
}

const defaultFilterValues = {
  user: {
    active: true
  }
}
const defaultSort = { field: "kuerzel", order: "ASC" }

const DriverReferenceInput = ( props ) => {
  const {filter, ...rest} = props
  let filterValues = {
    ...defaultFilterValues,
    ...filter
  }

  return (
    <>
      <ReferenceInput
        fullWidth
        resettable
        helperText={false}
        sort={defaultSort}
        filter={filterValues}
        {...rest}
      >
        {props.children ??
         <AutocompleteInput
           style={{ minWidth: "200px" }}
           optionText={( driver ) => translateDriverLabel( driver )}
           helperText={false}
         />
        }
      </ReferenceInput>
      {props.displaySetDriverForAdditionalRides && (
        <BooleanInput initialValue={props.setDriverForAdditionalRides} helperText={false} source={"setDriverForAdditionalRides"} label={"Auch für Anschluss setzen"} />
      )}
    </>
  );
};

DriverReferenceInput.propTypes = {
  source: PropTypes.string,
  reference: PropTypes.string,
  allowEmpty: PropTypes.bool,
  label: PropTypes.string,
  filter: PropTypes.object,
  sort: PropTypes.object,

  format: PropTypes.func,
  filterToQuery: PropTypes.func,

  perPage: PropTypes.number,
  setDriverForAdditionalRides: PropTypes.any,

  displaySetDriverForAdditionalRides: PropTypes.bool,
}

DriverReferenceInput.defaultProps = {
  source: "driver",
  reference: "drivers",
  allowEmpty: true,
  label: "Fahrer",

  filter: defaultFilterValues,
  sort: defaultSort,

  format: ( v ) => v !== null && typeof v === "object" && v.hasOwnProperty( "@id" ) ? v[ "@id" ] : v,
  filterToQuery: autocompleteFilter,

  perPage: 200,
  setDriverForAdditionalRides: "setDriverForAdditionalRides",

  displaySetDriverForAdditionalRides: false
}

const DriverReferenceArrayInput = ( props ) => {
  const { filter, ...rest } = props

  let filterValues = {
    ...defaultFilterValues,
    ...filter
  }

  return (
    <ReferenceArrayInput
      label={"Fahrer"}
      source={"abholer"}
      reference={"drivers"}
      filter={filterValues}
      sort={defaultSort}
      alwaysOn
      filterToQuery={autocompleteFilter}
      {...rest}
      allowEmpty={false}
    >
      {props.children ?? (
        <AutocompleteArrayInput
          style={{ minWidth: "200px" }}
          optionText={( driver ) => translateDriverLabel( driver )}
          helperText={false}
        />
      )}
    </ReferenceArrayInput>
  );
};
const DriverReferenceWithSuggestionInput = ( {autofocus = false, ...props} ) => {
  const inputRef = useRef();
  useEffect(() => {
    if( autofocus ) {
      document.querySelector('#driver-suggestion-input').focus()
    }
  }, []); // [] sorgt dafür, dass der Effekt nur beim ersten Rendern ausgeführt wird


  return (
    <DriverReferenceInput {...props}>
      <AutocompleteInput
        style={{ minWidth: "200px" }}
        optionText={<DriverLabelWithSuggestions appliedDrivers={props.suggestions}/>}
        matchSuggestion={( filterValue, suggestion ) => {
          return translateDriverLabel( suggestion )?.toLowerCase()?.includes(
            filterValue.toLowerCase()
          )
        }}
        id="driver-suggestion-input"
        inputText={choice => translateDriverLabel( choice )}
        helperText={false}
      />
    </DriverReferenceInput>
  );
};

const DriverLabelWithSuggestions = ( { record, appliedDrivers } ) => {
  if( !record ) {
    return null;
  }

  let name = translateDriverLabel( record );

  if( appliedDrivers && appliedDrivers.find(
    driver =>
      driver[ '@id' ] === record[ '@id' ]
  ) ) {
    name = <strong>{name}</strong>
  }

  return name;
};

export { DriverReferenceInput, DriverReferenceArrayInput, DriverReferenceWithSuggestionInput };
