import * as React from 'react';
import { Fragment, useState } from 'react';
import {
  Button,
  Confirm,
  useCreate,
  useNotify,
  useUnselectAll,
  useRefresh, linkToRecord
} from 'react-admin';
import ReceiptIcon from '@material-ui/icons/Receipt';

const BulkCreateInvoiceButton = ( { selectedIds } ) => {
  const unselectAll = useUnselectAll();
  const notify = useNotify();
  const refresh = useRefresh();
  const [open, setOpen] = useState( false );
  const [loading, setLoading] = useState( false );
  const [create] = useCreate()

  const handleClick = () => setOpen( true );
  const handleDialogClose = () => setOpen( false );

  const handleConfirm = () => {
    setOpen( false );
    setLoading( true );

    const postData = {
      'contracts': selectedIds,
      'type': 'Gutschrift',
      'invoice_commercial': '0',
      'resetInvoiceEntries': true,
      'resetArticles': true,
      'resetAddress': true
    }

    create('invoices', postData, {
      onSuccess: ( { data } ) => {
        unselectAll( 'contracts' );
        notify( 'Gutschrift erstellt', { type: 'success' } );
        setLoading( false );
        window.open( process.env.REACT_APP_ADMIN + linkToRecord( '/invoices', data.id ), '_blank' )
        refresh();
      },
      onFailure: ( error ) => {
        let text = error?.response?.statusText
        notify( 'Error: Gutschrift nicht erstellt: '+text, { type: 'warning' } )
        setLoading( false );
      }
    });
  };

  return (
    <Fragment>
      <Button
        label="Gutschrift erstellen"
        onClick={handleClick}
        children={<ReceiptIcon />}
      />
      <Confirm
        isOpen={open}
        loading={loading}
        title="Gutschriftenerstellung"
        content="Gutschrift aus ausgewählten Aufträgen erstellen?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

const BulkCreateInvoiceByFiltersButton = ( props ) => {
  return null;
};

export { BulkCreateInvoiceButton, BulkCreateInvoiceByFiltersButton };
