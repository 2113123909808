import * as React from "react";
import {
  Create,
  Edit,
  SelectInput,
  BooleanInput,
  TextInput,
  ArrayInput,
  TabbedForm,
  FormTab,
  SaveButton,
  TopToolbar,
  ListButton,
  FormDataConsumer, CreateButton,
} from "react-admin";
import DefaultEditActions from "../utils/DefaultEditActions";
import RefreshButton from "../utils/components/RefreshButton";

import { Box, Toolbar } from "@material-ui/core";

import { FormTabContainerBox, LeftBox, RightBox, ParagraphBox, ParagraphHeadline, InputContainer, Spacer } from "../utils/components/FormElements";
import { DateInput, TimeInput } from "../utils/components/DateInput";
import { BillingAddressReferenceInput } from "../billingAddress/components/BillingAddressReferenceInput";
import CustomBooleanInput from "../utils/components/CustomBooleanInput";
import { BillingPDFButton, BillingExportButton } from "./components/Buttons";
import { ResetArticlesButton } from "../utils/components/Buttons";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import BillingPositionsFormIterator from "./components/BillingPositionsFormIterator";
import useLocalStorage from "../utils/useLocalStorage";
import { useState } from "react";

const StyledTextInput = ( props ) => {
  const {left, right, center, ...rest } = props;
  return <InputContainer {...props}>
    <TextInput fullWidth  {...rest}/>
  </InputContainer>
}

const EntityToolbar = ( props ) => (
  <Toolbar
    style={{
      position: "fixed",
      zIndex: 100000,
      bottom: 10,
      maxWidth: "100%",
      backgroundColor: "rgba(255,255,255, 0.8)",
      borderRadius: "5px",
    }}
    {...props}
  >
    <Box width="100%">
      <SaveButton
        saving={props.saving}
        handleSubmitWithRedirect={props.handleSubmitWithRedirect}
        label={"Speichern und schließen"}
        style={{ marginRight: "0.5rem" }}
      />
      <SaveButton
        saving={props.saving}
        redirect={false}
        label={"Speichern"}
        handleSubmitWithRedirect={props.handleSubmitWithRedirect}
        style={{ marginRight: "0.5rem" }}
      />

      <RefreshButton label="Formular zurück setzen" style={{ margin: '0 1rem' }}/>
      {!props.isCreate && <ResetArticlesButton {...props} />}
    </Box>
  </Toolbar>
);

const BillingEditActions = ( props ) => {
  return (
    <TopToolbar>
      <ListButton label="Zurück zur Liste" icon={<ChevronLeft/>}/>
      <BillingPDFButton
        record={props.data}
        title={"Rechnung anzeigen"}
      />
      <CreateButton label={"neu"}/>
    </TopToolbar>
  );
};

/**
 * @param {boolean} props.record.isAbgeschlossen
 * @param {boolean} props.isCreate
 * @constructor
 */
const EntityForm = ( props ) => {
  const [posPage, setPosPage] = useState(0);
  const [posPerPage, setPosPerPage] = useLocalStorage('billing.positions.perPage', 10);

  React.useEffect(() => {
    setPosPage(0);
    return () => {
      setPosPage(0);
    }
    }, []);

  return <TabbedForm
    style={{ paddingBottom: '30px' }}
    toolbar={<EntityToolbar {...props} />}
    {...props}
  >
    <FormTab label="Stammdaten">
      <FormTabContainerBox style={{width: '100%'}}>
        <ParagraphBox>
          <LeftBox>
            <Box display={"flex"}>
              <InputContainer left>
                <SelectInput
                  source={"formulartyp"}
                  label={"Formulartyp"}
                  choices={[
                    { id: 'Rechnung', name: 'Rechnung' },
                    { id: 'Gutschrift', name: 'Gutschrift' },
                    { id: 'Angebot', name: 'Angebot' },
                  ]} fullWidth
                  disabled={props.record.isAbgeschlossen}
                />
              </InputContainer>

              <StyledTextInput source={"auftragsnummer"} disabled={props.record.isAbgeschlossen}/>
            </Box>

            <Box display={"flex"}>
              <InputContainer left>
                <DateInput source={"datum"} fullWidth/>
              </InputContainer>
              <InputContainer right>
                <TimeInput source={"zeit"} label={"Uhrzeit"} fullWidth/>
              </InputContainer>
            </Box>
          </LeftBox>

          <RightBox>
            <CustomBooleanInput source={"abgeschlossen"}/>
          </RightBox>
        </ParagraphBox>

        <Spacer/>

        <ParagraphBox>
          <LeftBox>
            <ParagraphHeadline>Empfänger</ParagraphHeadline>


            <Box display={"flex"}>
              <Box flex={1} style={{ paddingRight: "0.25rem" }}>

                <SelectInput source={"anrede"} choices={[
                  { id: 'Herr', name: 'Herr' },
                  { id: 'Frau', name: 'Frau' },
                ]} label={"Anrede"} resettable fullWidth/>
              </Box>
              <StyledTextInput source={"vorname"} center/>
              <StyledTextInput source={"nachname"} right/>
            </Box>

            <Box display={"flex"}>
              <StyledTextInput source={"strasse"} flex={4} left/>
              <StyledTextInput source={"hausnr"} flex={1} center/>
              <StyledTextInput source={"plz"} flex={2} center/>
              <StyledTextInput source={"ort"} flex={4} center/>
              <StyledTextInput source={"land"} flex={1} right/>
            </Box>

            <Box display={"flex"}>
              <StyledTextInput source={"email"} left/>
              <StyledTextInput source={"telefon"} right/>
            </Box>

            <Box display={"flex"}>
              <StyledTextInput source={"kundennr"} left/>
              <InputContainer right>
                <BillingAddressReferenceInput source={"alternativeRechnungsadresse"}/>
              </InputContainer>
            </Box>
          </LeftBox>

          <RightBox>
            <ParagraphHeadline>Unternehmensdaten</ParagraphHeadline>

            <StyledTextInput source={"firma"}/>
            <Box display={"flex"}>
              <StyledTextInput source={"ustid"} left/>
              <StyledTextInput source={"steuerid"} center/>
              <StyledTextInput source={"steuernummer"} left/>
            </Box>

            <CustomBooleanInput source={"endkundenrechnung"}/>
            <CustomBooleanInput source={"gewerblicheRechnung"} initialValue={"1"}/>
          </RightBox>
        </ParagraphBox>

        <Spacer/>


        <ParagraphBox>
          <LeftBox>
            <ParagraphHeadline>Summe</ParagraphHeadline>

            <Box display={"flex"}>
              <StyledTextInput source={"rechnungsbetrag"} left/>
              <StyledTextInput source={"nettosumme"} center/>
              <StyledTextInput source={"mwst"} right/>
            </Box>
            <Box display={"flex"}>
              <CustomBooleanInput source={"ohneMwst"}/>
              <CustomBooleanInput source={"nettogleichbrutto"}/>
            </Box>
          </LeftBox>
          <RightBox>
            <ParagraphHeadline>Skonto & Zahlungsziel</ParagraphHeadline>
            <Box display={"flex"}>
              <StyledTextInput source={"tage1"} left/>
              <StyledTextInput source={"tage2"} left right/>
              <StyledTextInput source={"tage3"} right/>
            </Box>
            <Box display={"flex"}>
              <StyledTextInput source={"skonto1"} left/>
              <StyledTextInput source={"skonto2"} right/>
              <InputContainer/>
            </Box>
          </RightBox>
        </ParagraphBox>
        <Spacer/>


        <ParagraphBox>
          <LeftBox display={"flex"}>

          </LeftBox>
          <RightBox></RightBox>
        </ParagraphBox>
        <ParagraphBox>
          <LeftBox display={"flex"}>

          </LeftBox>
          <RightBox></RightBox>
        </ParagraphBox>
      </FormTabContainerBox>
    </FormTab>

    <FormTab label="Rechnungspositionen">
      <ArrayInput source="artikeldatenArray" label={"Einträge"}>
        <BillingPositionsFormIterator
          perPage={posPerPage}
          setPerPage={setPosPerPage}
          page={posPage}
          setPage={setPosPage} />
        {/*<SimpleFormIterator>*/}
        {/*  <ArrayItemTextInput label={"Position"} source={"beschreibung"} width={"45%"} multiline/>*/}
        {/*  <ArrayItemTextInput label={"Beschreibung"} source={"beschreibung2"} width={"40%"} multiline/>*/}
        {/*  <ArrayItemTextInput label={"Wert"} source={"wert"} width={"5%"}/>*/}
        {/*  <ArrayItemTextInput label={"Mwst"} source={"mwst"} width={"5%"} initialValue={19}/>*/}
        {/*</SimpleFormIterator>*/}
      </ArrayInput>
    </FormTab>

    <FormTab label="Dokumente">
      <ParagraphBox>
        <LeftBox>
          <ParagraphHeadline>Rechnungsdokument</ParagraphHeadline>

          <Box display={"flex"}>
            <FormDataConsumer>
              {( { formData } ) =>
                formData.auftragsnummer ? (
                  <BillingPDFButton
                    record={props.record}
                    title={"Rechnung anzeigen"}
                  />
                ) : <span>Zum anzeigen des Rechnungsdokumentes bitte eine Rechnungsnummer und den Formular-Typ eingeben</span>
              }
            </FormDataConsumer>

          </Box>
        </LeftBox>
        <RightBox></RightBox>
      </ParagraphBox>
      <ParagraphBox>
        <LeftBox>
          <ParagraphHeadline>Kundenspezifische Dokumente</ParagraphHeadline>

          <Box display={"flex"}>
            <InputContainer left style={{ alignContent: "center", display: "flex" }}>
              <FormDataConsumer>
                {( { formData } ) =>
                  <>
                    <SelectInput label={"Vorlage auswählen"}
                                 source={"template"}
                                 choices={[
                                   { id: 'export_outlay_vw', name: 'Auslagen VW' },
                                   { id: 'export_contracts_vw', name: 'Aufträge VW' },
                                   { id: 'export_outlay_athlon', name: 'Auslagen Athlon' },
                                   { id: 'export_contracts_athlon', name: 'Aufträge Athlon' },
                                 ]}
                                 defaultValue={'export_outlay_vw'} helperText={false}/>

                    {( formData.template === 'export_outlay_vw' || formData.template === 'export_outlay_athlon' ) &&
                     <BooleanInput label="refresh" source="force_refresh" helperText={"neu laden erzwingen"} size={"small"} style={{ alignSelf: 'center', margin: '0 15px' }}/>}
                    <BillingExportButton template={formData.template} refresh={formData.force_refresh} {...props}/>
                  </>
                }
              </FormDataConsumer>
            </InputContainer>
          </Box>
        </LeftBox>
      </ParagraphBox>
    </FormTab>
  </TabbedForm>
}

const ArrayItemTextInput = ( props ) => {
  const { width, ...rest } = props;
  return <TextInput style={{ margin: "8px 3px 4px 3px", float: 'left', width: width }} helperText={false} {...rest}/>
}


const EntityTitle = ( { record } ) => {
  return <span>Rechnung {record ? `"${record.auftragsnummer}"` : ""}</span>;
};

const EntityEdit = ( props ) => (
  <Edit
    toolbar={<EntityToolbar {...props} />}
    actions={<BillingEditActions {...props}  />}
    title={<EntityTitle/>}
    {...props}
  >
    <EntityForm isCreate={false}/>
  </Edit>
);

const EntityCreate = (props) => (
  <Create
    actions={<DefaultEditActions allowCreate={true} {...props} />}
    title={"Neuen Eintrag anlegen"}
    {...props}
  >
    <EntityForm isCreate={true} {...props} />
  </Create>
);

export { EntityEdit, EntityCreate };
