import { Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from "@material-ui/core";
import ChatIcon from '@material-ui/icons/Chat';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import { ArrayInput, Button, FormWithRedirect, SaveButton, SaveContextProvider, useDataProvider, useNotify } from "react-admin";
import * as React from "react";
import { useState } from "react";
import { ComplaintFormIterator } from "../ComplaintFormIterator";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import { makeStyles } from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";

const ProtocolComplaintList = ( { protocol, ...props } ) => {
  const [showDialog, setShowDialog] = useState( false );
  const notify = useNotify()
  const dataProvider = useDataProvider();
  const mutationMode = "optimistic";

  const save = ( values ) => {

    dataProvider.update( "protocols", {
      id: protocol.id,
      data: {
        ...protocol,
        ...values
      }
    } ).then( () => {
      notify( 'Protokollbeanstandungen wurden gespeichert', { type: 'info' } )
      setShowDialog( false )
    } ).catch( ( error ) => {
      notify( error.message, { type: 'error' } );
    } )
  }

  return (
    <>
      <div style={{ display: "inline-flex", cursor: "pointer" }} onClick={() => setShowDialog( true )}>
        <ComplaintTooltip record={protocol}/>
      </div>


      <Dialog fullWidth maxWidth="lg" open={showDialog} onClose={() => setShowDialog( false )}>
        <DialogTitle>Protokollbeanstandungen</DialogTitle>
        <FormWithRedirect
          resource="contracts"
          save={save}
          render={( { handleSubmitWithRedirect, pristine, saving } ) => {
            return <SaveContextProvider value={{ saving, mutationMode }}>
              <DialogContent>
                <ArrayInput source={"complaints"} label={""} fullWidth>
                  <ComplaintFormIterator recordId={protocol[ '@id' ]}/>
                </ArrayInput>
              </DialogContent>
              <DialogActions>

                <Button onClick={() => setShowDialog( false )} label="Schließen"/>
                <SaveButton handleSubmitWithRedirect={handleSubmitWithRedirect}
                            pristine={pristine}
                            saving={saving}/>
              </DialogActions>
            </SaveContextProvider>
          }}
        />
      </Dialog>
    </>
  );
}

const useStyles = makeStyles( ( theme ) => ( {
  noMaxWidth: {
    maxWidth: 'none',
  },
  tableCell: {
    padding: '0px 10px',
    color: 'white'
  },
  tableHead: {
    padding: '0px 10px',
    color: 'white',
    fontWeight: 'bold'
  }
} ) );


const ComplaintTooltip = ( { record } ) => {
  const complaints = record.complaints;
  const classes = useStyles();
  return <Tooltip placement="left" title={<ProtocolComplaintTooltip complaints={complaints}/>} arrow classes={{ tooltip: classes.noMaxWidth }}>
    {complaints?.length > 0 ? <AnnouncementIcon color={"primary"} style={{ fontSize: "20px" }}/> : <ChatIcon color={"primary"} style={{ fontSize: "20px" }}/>}
  </Tooltip>
}

const ProtocolComplaintTooltip = ( { complaints } ) => {
  const classes = useStyles();
  if( complaints?.length === 0 ) {
    return <>Keine Beanstandungen aufgenommen</>
  }

  return <Table>
    <TableHead>
      <TableRow>
        <TableCell className={classes.tableHead}>#</TableCell>
        <TableCell className={classes.tableHead}>Art</TableCell>
        <TableCell className={classes.tableHead}>Beschreibung</TableCell>
        <TableCell className={classes.tableHead}>Aufgenommen am</TableCell>
        <TableCell className={classes.tableHead}>Aufgenommen von</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {complaints.map( ( complaint, index ) => {
        return <TableRow>
          <TableCell className={classes.tableCell}>{index+1}</TableCell>
          <TableCell className={classes.tableCell}>{complaint.complaint.type}</TableCell>
          <TableCell className={classes.tableCell}>{complaint.description}</TableCell>
          <TableCell className={classes.tableCell}>{complaint.updatedAtFormatted}</TableCell>
          <TableCell className={classes.tableCell}>{complaint.reporter.username}</TableCell>
        </TableRow>
      } )}
    </TableBody>
  </Table>
}

export { ProtocolComplaintList };
