import { FieldGuesser } from "@api-platform/admin";
import React from "react";
import { BooleanField } from "react-admin";
import DateField from "../utils/components/DateField";
import HotList from "../utils/components/HotList";
import BillingPDFButton from "./components/Buttons/BillingPDFButton";
import { billingListFilters } from "./components/BillingListFilter";

const BillingList = ( props ) => (
  <HotList {...props} sort="timestamp" sortOrder="desc" filters={billingListFilters} allowCreate={true}>
        <DateField label={"Datum"}
                   sortBy={"timestamp"}
                   source={"timestamp"}
                   showTime={true}
                   options={{ year: "numeric", month: "2-digit", day: "2-digit", hour: '2-digit', minute: '2-digit' }}/>
        <FieldGuesser source={"billingRecipient"} label={"Empfänger"}/>
        <FieldGuesser source={"rechnungsbetrag"}/>
        <FieldGuesser source={"formulartyp"}/>
        <BooleanField source={"isAbgeschlossen"} label={"Abgeschlossen"}/>
        <FieldGuesser source={"auftragsnummer"}/>

      <BillingPDFButton
        {...props}
      />
  </HotList>
);

export default BillingList;
