import * as React from "react";
import { Create, Edit, SelectInput, SimpleForm, TopToolbar, useRedirect } from "react-admin";
import Link from "../utils/components/Link";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import TextInput from "../utils/components/TextInput";

const EntityForm = ( props ) => (
  <SimpleForm {...props}>
    <SelectInput
      source={"state"}
      label={"Zeitpunkt"}
      choices={[
        { id: 'STARTED', name: 'Abholung' },
        { id: 'SUBMITTED', name: 'Zustellung' },
      ]}/>
    <TextInput source={"image"} label={"Schlüssel"}/>
  </SimpleForm>
);

const EntityTitle = ( { record } ) => {
  return <span>App Event {record ? `"${record.name}"` : ""}</span>;
};

const EntityEdit = ( props ) => (
  <Edit
    actions={<Toolbar {...props} />}
    title={<EntityTitle/>}
    {...props}
  >
    <EntityForm/>
  </Edit>
);

const EntityCreate = ( props ) => (
  <Create
    actions={<Toolbar {...props} />}
    {...props}
    title={"Neuen Eintrag anlegen"}
  >
    <EntityForm {...props} style={{ width: "100%" }}/>
  </Create>
);

const Toolbar = ( props ) => {
  const redirect = useRedirect();

  const redirectToCustomRoute = () => {
    redirect( '/protocolImages' );
  };

  return <TopToolbar>
    <Link onClick={redirectToCustomRoute} icon={<ChevronLeft/>}>Zurück zur Liste</Link>
  </TopToolbar>
}

export { EntityEdit, EntityCreate };
