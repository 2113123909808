import React from "react";
import { HydraAdmin, ResourceGuesser } from "@api-platform/admin";

import { Resource } from "react-admin"
import merge from "lodash/merge";

import "./env";

import authProvider from "./authProvider";
import dataProvider from "./dataProvider";

import vermacoTheme from "./theme";
import vermacoLayout from "./layout/Layout";
import customRoutes from "./customRoutes";
import contracts from "./contracts";
import drivers from "./drivers";
import protocols from "./protocols";
import accessories from "./accessories";
import services from "./services";
import customers from "./customers";
import address from "./address";
import billingAddress from "./billingAddress";
import complaints from "./complaint";
import vwImports from "./vwImports";
import categories from "./category";
import appEvents from "./appEvent";
import billings from "./billing";
import mail from "./mail";
import email from "./emails";
import bundles from "./bundles";
import pricing from "./pricing";

import polyglotI18nProvider from "ra-i18n-polyglot";
import germanMessages from "ra-language-german";
import * as domainMessages from "./i18n";
import ImportExport from "@material-ui/icons/ImportExport";
import jobCategory from "./jobCategory";
import logs from "./logs";
import Invoices from "./invoice";
import Notifications from "./notification";
import dispositionLocks from "./dispositionLocks";
import applications from "./applications";
import { getData } from "./utils/request/GetRequest"
import roles from "./roles";
import user from "./user";
import customerDepartment from "./customerDepartment";
import { SnackbarProvider } from "notistack";
import news from "./news";
import priceTypes from "./priceTypes";
import priceGroups from "./priceGroups";
import mailLog from "./mailLog";
import { withStyles } from "@material-ui/core";
import userGroups from "./userGroups";
import leasingProtocols from "./leasingProtocols";
import protocolImage from "./protocolImage";

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
const messages = {
  de: merge( germanMessages, domainMessages.de ),
};

const i18nProvider = polyglotI18nProvider( ( locale ) => messages[ locale ], "de" );

const interval = 1000 * 60;
const poll = () => {
    if( !localStorage.getItem( "lastPoll" ) ) {
        localStorage.setItem( "lastPoll", new Date().valueOf()-(interval*2)+'' );
    }
    const lastPoll = parseInt( localStorage.getItem( "lastPoll" ) );
    const now = new Date().valueOf();
    if( now-lastPoll > interval && localStorage.getItem( 'token' ) ) {
        localStorage.setItem( "lastPoll", now+'' );
        getData()
          .then( response => {
              Object.keys( response ).forEach( key => {
                  if( typeof response[ key ] === "object" ) {
                      localStorage.setItem( key, JSON.stringify( response[ key ] ) );
                  } else {
                      localStorage.setItem( key, response[ key ] );
                  }
              } )

              const userSettings = JSON.parse( localStorage.getItem( "userSettings" ) );
              if( userSettings.favorites ) {
                  localStorage.setItem( "favorites", userSettings.favorites );
                  window.dispatchEvent(new Event('favoritesUpdated'));
              }


          } )
          .catch( error => {
              // localStorage.setItem("vorversandmails", 0);
              console.error( error );
          } );
    }
    setTimeout( () => {
        poll();
    }, interval );
};
poll();

const GlobalCss = withStyles( {
                                  // @global is handled by jss-plugin-global.
                                  '@global': {
                                      // You should target [class*="MuiButton-root"] instead if you nest themes.
                                      '.list-page > .MuiToolbar-root > form': {
                                          marginTop: '0',
                                          paddingTop: '10px'
                                      },
                                      '[data-source="boolchar_auftragbestaetigt"], [data-source="verzoegert"], [data-source="fahrer_fehlt"], [data-source="fahrer_vorhanden"], [data-source="custom__abgeschlossen"], [data-source="testContract"], [data-source="custom__storniert"]': {
                                          paddingBottom: '17px'
                                      },
                                  },
                              } )( () => null );

// …

const App = () => (
  <SnackbarProvider maxSnack={10} preventDuplicate={true}>
    <GlobalCss/>

    <HydraAdmin
      customRoutes={customRoutes()}
      layout={vermacoLayout}
      theme={vermacoTheme()}
      entrypoint={entrypoint}
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
    >
        <Resource {...contracts} />
        <Resource {...protocols}/>
        <Resource {...drivers} />
        <Resource {...address} />
        <Resource {...services}/>
        <Resource {...billings}/>
        <Resource {...email}/>
        <Resource {...accessories}/>
        <Resource {...pricing}/>
        <Resource {...customers} />
        <Resource {...billingAddress}/>
        <Resource {...mail} />
        <Resource {...categories}/>
        <Resource {...vwImports}/>
        <Resource {...jobCategory}/>
        <Resource {...user}/>
        <Resource {...dispositionLocks} />
        <Resource {...applications} />
        <Resource {...bundles} />
        <Resource {...roles} />
        <Resource {...customerDepartment} />
        <Resource {...logs} />
        <Resource {...Invoices} />
        <Resource {...Notifications} />
        <Resource {...news} />
        <Resource {...mailLog} />
        <Resource {...priceTypes} />
        <Resource {...priceGroups} />
        <Resource {...complaints} />
        <Resource {...appEvents} />
        <Resource {...userGroups} />
        <Resource {...leasingProtocols} />
        <Resource {...protocolImage} />

        <Resource name={"import_contracts"} />
        <Resource name={"serviceAddresses"}/>
        <Resource name={"driver_documents"}/>
        <Resource name={"prices"}/>
        <Resource name="bundle_contracts"/>

        <ResourceGuesser name="invoice_entries"/>
        <ResourceGuesser name="contacts"/>
        <ResourceGuesser name="address_mails" />
        <ResourceGuesser name="protocol_complaints" />
        <ResourceGuesser name="customer_services" />
    </HydraAdmin>
  </SnackbarProvider>
);

export default App;
