import CustomersList from "./CustomerList";
import { EntityCreate, EntityEdit } from "./CustomerEdit";

const Customers = {
  list: CustomersList,
  edit: EntityEdit,
  create: EntityCreate,
  name: "customers"
};

export default Customers;
