import React from 'react';
import { defaultButtonStyle } from "../../../utils/components/styles/ButtonStyles";
import SettingsIcon from '@material-ui/icons/Settings';
import { IconEditRetoure } from "../../../utils/components/icons/IconEditRetoure";
import { IconEditRetoureProtocol } from "../../../utils/components/icons/IconEditRetoureProtocol";
import { Box } from "@material-ui/core";
import { CreateBundleButtonWithDialog } from "../ListButtons/CreateBundleButton";
import { ToggleDisplayInOfferListButton } from "../ListButtons/ToggleDisplayInOfferListButton";
import { CancelContractButton, ConfirmButton, ExportPDFButton, GetButton, RemoveDriverButton, SendDriverMailButton } from "../ListButtons";
import { RemoveAppUserButton } from "../ListButtons/RemoveAppUserButton";
import EntityLink from "../../../utils/components/EntityLink";
import { SortOrderButtons } from "../ListButtons/SortOrderButtons";
import UserSettings from "../../../utils/components/UserSettings";
import { EditButton } from "react-admin";
const t = UserSettings()?.openLinksInNewTab ? '_blank' : '_self';

const ButtonList = ({ record }) => {
  return (
    <Box style={{ display: "flex", justifyContent: "flex-end" }}>
      <CreateBundleButtonWithDialog record={record} style={defaultButtonStyle}/>
      <ToggleDisplayInOfferListButton record={record} style={defaultButtonStyle} />
      <ConfirmButton record={record} style={defaultButtonStyle} sendConfirmationEmail={true} title={"Auftrag bestätigen und E-Mail senden"}/>
      <ConfirmButton record={record} style={defaultButtonStyle}/>
      <GetButton record={record} style={defaultButtonStyle}/>
      <ExportPDFButton showRegenerate={false} disabled={record.protocol ? null : true} style={defaultButtonStyle} record={record}/>
      <CancelContractButton style={defaultButtonStyle} record={record}/>
      <RemoveDriverButton disabled={record.driver ? null : true} record={record} style={defaultButtonStyle}/>
      <RemoveAppUserButton disabled={record.benutzer ? null : true} record={record} style={defaultButtonStyle}/>
      <SendDriverMailButton id={record._id} IsFahrerMailGesendet={record.isFahrerMailGesendet} disabled={record.driver ? null : true} style={defaultButtonStyle}/>
      <EntityLink record={record?.protocol} icon={<SettingsIcon/>} title={"Admin"} style={defaultButtonStyle}/>
      <EntityLink record={record?.anschlussAuftrag?.protocol} icon={<IconEditRetoureProtocol/>} title={"Admin Retoure"} style={defaultButtonStyle}/>
      <EditButton title={"Bearbeiten"} label={""} record={record} style={defaultButtonStyle} target={t}/>
      <EntityLink record={record?.anschlussAuftrag} icon={<IconEditRetoure/>} style={defaultButtonStyle}/>
      <SortOrderButtons record={record} />
    </Box>
  );
};

export default ButtonList;
