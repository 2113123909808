import { TextInput } from "../../utils/components";
import { Button, FormDataConsumer, FormWithRedirect, SaveButton, SaveContextProvider, useNotify } from "react-admin";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import React, { useState } from "react";
import IconCancel from "@material-ui/icons/Cancel";
import { PostRequest } from "../../utils/request/PostRequest";
import { getContractsBySearch } from "../../utils/request/GetRequest";
import GlobalLoading from "../../utils/components/GlobalLoading";


const DialogAddContract = ( { children, bundle, setBundle }) => {
  const [ showDialog, setShowDialog ] = useState( false );
  const [ loading, setLoading ] = useState( false );
  const notify = useNotify()

  const onSave = async (values) => {
    setLoading( true )

    getContractsBySearch( values.contractNumber ).then( ( result ) => {
      const size = Object.keys( result ).length
      // if( size > 1 ) {
      //   notify( `Es wurden mehrere Aufträge mit der Auftragsnummer ${values.contractNumber} gefunden. Bitte geben Sie eine eindeutige Auftragsnummer ein.`, { type: "warning" } );
      //   setLoading( false )
      // } else
        if( size === 0 ) {
        notify( `Es wurde kein Auftrag mit der Auftragsnummer ${values.contractNumber} gefunden.`, { type: "warning" } );
        setLoading( false )
      } else {

        const postData = {
          'bundleId': bundle._id,
          'contractId': result[0]._id,
        }

        PostRequest( `${process.env.REACT_APP_API_ENTRYPOINT}/bundles/add_contract`, postData )
          .then( ( response ) => {
            notify( 'Auftrag hinzugefügt', { type: 'success' } );
            setBundle( response )
            setShowDialog( false );
            setLoading( false )
          } ).catch( ( error ) => {
          notify( `Error: ${error.response.data[ "hydra:description" ]}`, { type: "warning" } );
          setLoading( false )
        } )
        }
    })
  }

  return <FormWithRedirect
    resource="contracts"
    save={ onSave }
    render={ ( { handleSubmitWithRedirect, pristine, saving } ) => (
    <SaveContextProvider>
      <div style={ { display: "inline" } } onClick={ () => setShowDialog(true) }>{ children }</div>
      <Dialog fullWidth open={ showDialog } onClose={ () =>  setShowDialog( false ) }>
      {loading &&<GlobalLoading/>}

        <DialogTitle>Auftrag über Auftragsnummer hinzufügen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TextInput id="contractNumber" label="Auftragsnummer" source={"contractNumber"} />
            <DialogActions>
              <FormDataConsumer>
                { ( { formData, ...rest } ) => (
                  <SaveButton
                    handleSubmitWithRedirect={ handleSubmitWithRedirect }
                    pristine={ pristine }
                    saving={ saving }
                    label="Hinzufügen"
                  />
                )}
              </FormDataConsumer>

              <Button
                label="ra.action.cancel"
                onClick={ () => setShowDialog(false) }
                // disabled={loading}
              >
                <IconCancel />
              </Button>
            </DialogActions>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </SaveContextProvider>
    )}
  />
}

export default ( props ) => {
  return <DialogAddContract {...props}>
    <Button children={<><AddToPhotosIcon />&nbsp;Auftrag via Auftragsnummer hinzufügen</>}/>
  </DialogAddContract>

}
