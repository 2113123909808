import * as React from "react";
import { Fragment } from "react";
import { EditButton, FunctionField, TextField } from "react-admin";
import { BulkSendButton, HideButton, protocolFilter, protocolFilterDefaultValues, SendButton } from "./components";
import { Box, Chip } from "@material-ui/core";
import HotList from "../utils/components/HotList";
import { GmapsRouteButton } from "../utils/components/Buttons";
import { ExportPDFButton } from "../contracts/components/ListButtons";
import * as ButtonStyles from "../utils/components/styles/ButtonStyles";
import EntityLink from "../utils/components/EntityLink";
import { IconEditRetoure } from "../utils/components/icons/IconEditRetoure";
import { ProtocolListTags } from "../utils/components/ListTags";
import { InfoButton } from "../contracts/components/InfoField";
import { DriverViewField } from "../contracts/components/DriverField";
import ImageIcon from "@material-ui/icons/Image";
import UserSettings from "../utils/components/UserSettings";
import { DisableProtocolMailButton } from "./components/Buttons/DisableProtocolMailButton";
import { ProtocolComplaintList } from "./components/Buttons/ProtocolComplaintList";


const ProtocolBulkActionButtons = ( props ) => (
  <Fragment>
    <BulkSendButton {...props} />
  </Fragment>
);

const RangeDifferenceField = ( { record } ) => {
  if( !record ) {
    return null;
  }


  const { contract, status } = record
  const { differenceBetweenGoogleAndActualKm } = contract;

  const chipColor = () => {
    const diff = parseInt( differenceBetweenGoogleAndActualKm )

    if( status < 3 ) {
      return "#C4C4C4";
    }

    const isVorholung = contract.datum != contract.bringdatum;

    if (isVorholung && (diff > 100 || diff < -100)) {
        return '#F44336';
    } else if ((!isVorholung && diff > 50) || diff < -50) {
        return '#F44336';
    }

    return "#C4C4C4";
  };

  return (
    <>
      <Box>
        <Box display="flex" gridGap="3px">
          <TextField source="googleKm" record={contract}/>
          <span> / </span> {contract.istkm} Km

        </Box>
        {status >= 3 && <Chip
          size="small"
          label={differenceBetweenGoogleAndActualKm+" km"}
          style={{
            backgroundColor: chipColor(),
            color: "white",
          }}
        />}

        {contract.additionalDistance > 0 && <>&nbsp; <Chip
          size="small"
          label={contract.additionalDistance+" km"}
          style={{
            backgroundColor: 'green',
            color: "white",
          }}
          title={"Erlaubte Mehrkilometer"}
        /></>}
      </Box>
    </>
  );
};

const t = UserSettings()?.openLinksInNewTab ? '_blank' : '_self';

const ProtocolList = (props) => (
  <HotList
    {...props}
    filters={protocolFilter}
    filterDefaultValues={protocolFilterDefaultValues}
    bulkActionButtons={<ProtocolBulkActionButtons/>}
    showSortButton={true}
    hasEdit={false}
    sortButtonFields={['auftrag.datum', 'auftrag.auftraggeber.firma']}
    sort="auftrag.datum"
    sortOrder="DESC"
  >
    <TextField source="orderNumber" label="Auftragsnummer"/>
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
      gridGap="3px"
    >
      <FunctionField
        render={( record ) => <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <ProtocolListTags record={record} />
          <ProtocolListTags record={record.contract} />
          <InfoButton record={record} />
          {!record.allUploadsDone && <ImageIcon style={{display: "flex", color: "#DC3545"}} title={"Es wurden noch nicht alle Bilder hoch geladen"}/>}
        </Box>}
      />
    </Box>

    <Box label={"Fahrer / Kunde"} sortBy={"auftrag.auftraggeber.firma"}>
      <FunctionField
        label="Tags"
        render={( record ) =>
          record.contract?.driver && (
            <DriverViewField record={record.contract}/>
                                  )
        }
      />

      <TextField source={"contract.contractClientAddress.kuerzel"}/>
    </Box>

    <Box label={"Abholort / Kennzeichen"}>
      <TextField source={"pickupLocation.ort"}/>
      <br/>
      <TextField source={"contract.licensePlate"}/>
    </Box>

    <Box label={"Datum / Uhrzeit"} sortBy={"auftrag.datum"}>
      <FunctionField
        label="Tags"
        render={( record ) =>
          record.contract && (
                            <Box>
                              <TextField source={"contract.pickupDate"}/>
                              <br/>
                              <TextField source={"contract.pickupTimeFrom"}/> -{" "}
                              <TextField source={"contract.pickupTimeTo"}/>
                            </Box>
                          )
        }
      />

    </Box>

    <Box label={"Zielort"}>
      <TextField source={"deliveryLocation.ort"}/>
    </Box>

    <Box label={"Datum / Uhrzeit"} sortBy={"auftrag.bringdatum"}>
      <FunctionField
        label="Tags"
        render={( record ) =>
          record.contract && (
                            <Box>
                              <TextField source={"contract.deliveryDate"}/>
                              <br/>
                              <TextField source={"contract.deliveryTimeFrom"}/> -{" "}
                              <TextField source={"contract.deliveryTimeTo"}/>
                            </Box>
                          )
        }
      />

    </Box>

    <Box label={"Soll & Ist Km"}>
      <FunctionField
        label="Soll zu Ist km."
        render={( record ) => <RangeDifferenceField record={record} status={record.status}/>}
      />
    </Box>

    <Box label={"Retoure"}>
      <TextField source={"anschlussProtokoll.deliveryLocation.ort"}/>
      <br/>
      <TextField source={"anschlussProtokoll.contract.licensePlate"}/>
    </Box>

    <Box label={"Datum / Uhrzeit"} sortBy={"contract.anschlussAuftrag.datum"}>
      <TextField source={"anschlussProtokoll.contract.deliveryDate"}/>
      <br/>
      <FunctionField
        render={( record ) =>
          record.anschlussProtokoll && (
                                      <Box>
                                        <TextField source={"anschlussProtokoll.contract.deliveryTimeFrom"}/>{" "}
                                        - <TextField source={"anschlussProtokoll.contract.deliveryTimeTo"}/>
                                      </Box>
                                    )
        }
      ></FunctionField>
    </Box>

    <Box label={"Soll & Ist Km"}>
      <FunctionField
        label="Soll zu Ist km."
        render={( record ) =>
          record.anschlussProtokoll &&
          <RangeDifferenceField record={record.anschlussProtokoll}/>
        }
      />
    </Box>

    <TextField source="translatedStatus" label="Status"/>

    <FunctionField
      render={( record ) => (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <ProtocolComplaintList protocol={record} style={ButtonStyles.noMargin}/>
          <HideButton record={record}/>
          <ExportPDFButton
            record={record.contract}
            style={ButtonStyles.noMargin}
            selectOnClick={true}
          />
          <DisableProtocolMailButton record={record} style={ButtonStyles.noMargin}/>
          <SendButton record={record} style={ButtonStyles.noMargin} disabled={record.mailLocked} />
          <GmapsRouteButton
            pickupLocation={record.pickupLocation}
            deliveryLocation={record.deliveryLocation}
            style={ButtonStyles.noMargin}
          />
          <EditButton
            title={"Bearbeiten"}
            label={""}
            {...props}
            record={record}
            target={t}
            style={ButtonStyles.noMargin}
          />
          <EntityLink
            title={"Anschluss bearbeiten"}
            label={""}
            icon={<IconEditRetoure/>}
            record={record?.anschlussProtokoll}
            style={ButtonStyles.noMargin}
          />
        </div>
      )}/>
  </HotList>
);

export default ProtocolList;
