import { Box, IconButton, InputAdornment } from "@material-ui/core";
import * as React from "react";
import { useState } from "react";
import { useForm } from "react-final-form";
import CasinoIcon from '@material-ui/icons/Casino';
import { useTranslate } from 'ra-core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import TextInput from './TextInput';

export const PasswordWithSuggestionInput = props => {
  const { source, ...rest } = props;
  return <Box>
    <PasswordInput initiallyVisible={true} helperText={false} fullWidth source={source} {...props} />
  </Box>
}

function generatePassword() {
  const length = 10;
  const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
  let password = "";
  for( let i = 0, n = charset.length; i < length; ++i ) {
    password += charset.charAt( Math.floor( Math.random() * n ) );
  }
  return password;
}

export const PasswordInput = ( props ) => {
  const { change } = useForm();
  const { initiallyVisible = false, showInitialPassword = false, source, ...rest } = props;
  const [visible, setVisible] = useState( initiallyVisible );
  const translate = useTranslate();

  const handleClick = () => {
    setVisible( !visible );
  };

  return (
    <TextInput
      {...rest}
      source={source}
      type={visible ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label={translate(
                visible
                ? 'ra.input.password.toggle_visible'
                : 'ra.input.password.toggle_hidden'
              )}
              onClick={handleClick}
            >
              {visible ? <Visibility/> : <VisibilityOff/>}
            </IconButton>
            <IconButton
              aria-label="generate password"
              onClick={() => {
                const password = generatePassword();
                change( source, password );
              }}
            >
              <CasinoIcon/>
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordInput;
