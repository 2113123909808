import { useEffect } from "react";
import { SelectInput } from "react-admin";

const TimeRangeInput = ( props ) => {

  const { step } = props;

  const generateSeries = (step = 30) => {
    const date = new Date(1970, 0, 1);
    const timeSeries = [];
    while (date.getDate() === 1) {
      const timeString = date.toLocaleTimeString('de-DE', {timeStyle: 'short'});
      timeSeries.push({
        id: timeString,
        name: timeString,
      });
      date.setMinutes(date.getMinutes() + step);
    }
    return timeSeries;
  }

  return (
    <SelectInput
      choices={generateSeries(step)}
      {...props}
    />
  )
};

export default TimeRangeInput;
