import BillingList from "./BillingList";
import { EntityCreate, EntityEdit } from "./BillingEdit";

const Billings = {
  list: BillingList,
  edit: EntityEdit,
  create: EntityCreate,
  name: "billings"
};

export default Billings;
