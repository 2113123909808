import { AutocompleteInput } from "react-admin";


export const DamageDropdown = props => {
  return <AutocompleteInput
    choices={ [
      { id: 'Steinschlag', name: 'Steinschlag' },
      { id: 'SS großflächig', name: 'SS großflächig' },
      { id: 'SS mit Rissbildung', name: 'SS mit Rissbildung' },
      { id: 'Glasbruch', name: 'Glasbruch' },
      { id: 'Kratzer', name: 'Kratzer' },
      { id: 'Lackplatzer', name: 'Lackplatzer' },
      { id: 'Lackschaden', name: 'Lackschaden' },
      { id: 'Delle', name: 'Delle' },
      { id: 'Verformung', name: 'Verformung' },
      { id: 'Beschädigung', name: 'Beschädigung' },
      { id: 'Abschürfung', name: 'Abschürfung' },
      { id: 'Verschmutzung', name: 'Verschmutzung' },
      { id: 'Unfallschaden', name: 'Unfallschaden' },
      { id: 'Hagelschaden', name: 'Hagelschaden' },
      { id: 'Fehlermeldung', name: 'Fehlermeldung' },

    ] }
    { ...props }>

  </AutocompleteInput>
}
