import * as React from "react";
import { Create, Edit, SimpleForm } from "react-admin";
import { InputGuesser } from "@api-platform/admin";
import DefaultEditActions from "../utils/DefaultEditActions";

const EntityForm = (props) => (
  <SimpleForm {...props}>
    <InputGuesser source={"name"} />
    <InputGuesser source={"beschreibung"} />
    <InputGuesser source={"kostenlos"} />
    <InputGuesser source={"stundenpreis"} />
    <InputGuesser source={"anschriftnotwendig"} />
    <InputGuesser source={"sortierungsnummer"} />
  </SimpleForm>
);

const EntityTitle = ({ record }) => {
  return <span>Zusatzleistung {record ? `"${record.name}"` : ""}</span>;
};

const EntityEdit = (props) => (
  <Edit
    actions={<DefaultEditActions {...props} />}
    title={<EntityTitle />}
    {...props}
  >
    <EntityForm />
  </Edit>
);

const EntityCreate = (props) => (
  <Create
    actions={<DefaultEditActions {...props} />}
    {...props}
    title={"Neuen Eintrag anlegen"}
  >
    <EntityForm style={{ width: "100%" }} />
  </Create>
);

export { EntityEdit, EntityCreate };
