const translateCustomerLabel = (record, emptyLabel) => {
  if (!record) {
    return null;
  }

  let name = record.kuerzel;
  if (record.kuerzel && record.firma) {
    name += ` - ${record.firma}`;
  } else if (!record.kuerzel && record.firma) {
    name = record.firma;
  }

  return name ?? emptyLabel;
};

export default translateCustomerLabel;
