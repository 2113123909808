import { Grid as DefaultGrid } from "@material-ui/core";
import { AddressReferenceInput } from "../../address/components/AddressReferenceInput";
import { TextInput as DefaultTextInput } from "../../utils/components";
import { makeStyles } from "@material-ui/core/styles";
import { ArrayInput, useTranslate } from "react-admin";
import ContactFormIterator from "../../utils/components/InputComponents/ArrayInput/ContactFormIterator";
import * as React from "react";
import Typography from "@material-ui/core/Typography";
import CountrySelectInput from "../../utils/components/CountrySelectInput";
import { useForm, useFormState } from "react-final-form";
import { useEffect, useState } from "react";
import { getAddressData } from "../../utils/request";


const useStyles = makeStyles( {
  MuiFormControlMarginDense: {
    margin: '2px 0',
    padding: '0',
  },
  GridItem: {
    paddingRight: '0.25rem',
    '&:last-item': {
      paddingRight: '0'
    }
  }
} );

const Grid = ( props ) => {
  const classes = useStyles();
  return <DefaultGrid {...props} className={classes.GritItem} />
}

const TextInput = ( props ) => {
  const classes = useStyles();
  return <DefaultTextInput
    helperText={false}
    autoComplete="off"
    fullWidth
    className={classes.MuiFormControlMarginDense}
    {...props}
  />
}


const AddressInputBlock = ( { source, type = 'pickup', record, displayHeader = true } ) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { values } = useFormState( { subscription: { values: true } } );
  const [ bestandsadresse, setBestandsadresse ] = useState( record[source]?.source );


  const { change, batch, getState } = useForm();
  const handleAddressChange = async ( addressId ) => {
    if( !addressId ) {
      setBestandsadresse( null )
      return;
    }

    const newAddress = await getAddressData( addressId );
    setBestandsadresse( newAddress )

    batch( () => {
      change( `${source}.name`, newAddress.name );
      change( `${source}.firma`, newAddress.firma );
      change( `${source}.street`, newAddress.street );
      change( `${source}.houseNum`, newAddress.houseNum );
      change( `${source}.zip`, newAddress.zip );
      change( `${source}.city`, newAddress.city );
      change( `${source}.country`, newAddress.country );
      change( `${source}.contacts`, newAddress.contacts );
    } );

    change( `${source}.saveLocation`, false )

    if( newAddress.stellplatz ) {
      change('contractType', type === 'pickup' ? 'DELIVERY' : 'PICKUP' )
    }
  }

  useEffect( () => {
    if( !bestandsadresse ) {
      return;
    }

    let check = true
    const state = getState()

    const fields = [
      'firma',
      'name',
      'street',
      'houseNum',
      'zip',
      'city',
    ]

    for( let field of fields ) {
      // const value = formData[prefix][field];
      const value = state.values[source][field] ?? ''
      check &= bestandsadresse[field] === value
    }

    if( check ) {
      change( `${source}.source`, bestandsadresse['@id'] )
    } else {
      change( `${source}.source`, null )
    }

  }, [ values[source] ])

  return <Grid container spacing={1}>
    {displayHeader && <Grid item xs={12}>
      <Typography variant="h6" gutterBottom>
        {translate(type)}
      </Typography>
    </Grid>}
    <Grid item xs={12}>
      <AddressReferenceInput
        source={`${source}.source`}
        label={"Bekannte Adresse"}
        onChange={ ( address ) => {
          return handleAddressChange( address );
        } }
      />
    </Grid>

    <Grid item xs={12} lg={6}>
      <TextInput source={`${source}.name`} label={"Name"}/>
    </Grid>

    <Grid item xs={12} lg={6}>
      <TextInput source={`${source}.firma`} label={"Firma"}/>
    </Grid>

    <Grid item xs={12} lg={9}>
      <TextInput source={`${source}.street`} label={"Straße"}/>
    </Grid>

    <Grid item xs={12} lg={3}>
      <TextInput source={`${source}.houseNum`} label={"Hausnummer"}/>
    </Grid>

    <Grid item xs={12} lg={2}>
      <TextInput source={`${source}.zip`} label={"PLZ"}/>
    </Grid>

    <Grid item xs={12} lg={6}>
      <TextInput source={`${source}.city`} label={"Ort"}/>
    </Grid>

    <Grid item xs={12} lg={4}>
      <CountrySelectInput source={`${source}.country`} className={classes.MuiFormControlMarginDense}/>
    </Grid>

    <Grid item xs={12}>
      <Typography variant="subtitle1">
        Ansprechpartner
      </Typography>

      <ArrayInput source={`${source}.contacts`} label={""} className={classes.MuiFormControlMarginDense}>
        <ContactFormIterator inputClassName={classes.MuiFormControlMarginDense}/>
      </ArrayInput>
    </Grid>
  </Grid>
}

export default AddressInputBlock;
