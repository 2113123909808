import { BooleanInput } from 'react-admin';
import { InputContainer } from "./FormElements";

const ReverseBooleanInput = ( props ) => (
  <InputContainer>
    <BooleanInput parse={v => v ? "0" : "1"} format={v => v === "0"} helperText={false} {...props} />
  </InputContainer>
);

export default ReverseBooleanInput;
