import { Button, useNotify, useRefresh, useDataProvider } from "react-admin";
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
export const RemoveAppUserButton = ( { record, ...rest } ) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  if( !record ) {
    return null;
  }

  const remove = () =>
    dataProvider
      .update("contracts", {
        id: record.id,
        data: {
          ...record,
          benutzer: null,
        },
      })
      .then((response) => {
        // success side effects go here
        refresh();
        notify("App Benutzer entfernt für Auftrag "+ record.auftragsnummer, "success");
      })
      .catch((error) => {
        // failure side effects go here
        notify(`Fehler beim entfernen des App Benutzers: ${error.message}`, {
          type: "warning",
        });
      });

  return (
    <Button
    title={record.benutzer ?  record.benutzer.kuerzel + ' | '+record.benutzer.name + ' entfernen' : ''}
      onClick={remove}
      children={<PersonAddDisabledIcon />}
      {...rest}
    />
  );
};
