import { MailList } from "./MailList";
import MailIcon from "@material-ui/icons/Mail";
import { EntityCreate, EntityEdit } from "./MailEdit";

const Mail = {
  name: "mails",
  options: {
    label: "Vorversand"
  },
  list: MailList,
//   icon: MailIcon,
  // show: EntityShow,
  edit: EntityEdit,
  // create: EntityCreate,
}

export default Mail;
