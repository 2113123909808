import * as React from "react";
import { Create, Edit, FormDataConsumer, SimpleForm } from "react-admin";
import DefaultEditActions from "../utils/DefaultEditActions";

import { TextInput } from "../utils/components";
import { CustomerReferenceArrayInput } from "../customers/components/CustomerReferenceInput";
import BooleanInput from "../utils/components/BooleanInput";
import { Box } from "@material-ui/core";


const EntityForm = ( props ) => (
  <SimpleForm {...props}>
    <TextInput
      source={"slug"}
      label={"Eindeutiger Name"}
      helperText={false}
      disabled={!props.isCreate}
      minWidth={"300px"}/>
    <TextInput
      source={"name"}
      label={"Beschreibung"}
      helperText={false}
      minWidth={"300px"}/>
    <Box display={"flex"} alignItems={"center"}>
    <BooleanInput
      source={"restrictAccess"}
      label={"Zugriff einschränken"}
      helperText={false}
      fullWidth={true}/>
    </Box>
    <FormDataConsumer>
      {( { formData, ...rest } ) => {
        if( formData.restrictAccess ) {
          return <CustomerReferenceArrayInput
            source={"customers"}
            label={"Kunden"}
            helperText={false}
            fullWidth={true}/>
        }
        return null;
      }}
    </FormDataConsumer>

  </SimpleForm>
);

const EntityTitle = ( { record } ) => {
  return <span>Leasing-Protokoll {record.name}</span>;
};

export const EntityEdit = ( props ) => (
  <Edit
    actions={<DefaultEditActions {...props} />}
    title={<EntityTitle/>}
    {...props}
  >
    <EntityForm
      isCreate={false}/>
  </Edit>
);

export const EntityCreate = ( props ) => (
  <Create
    actions={<DefaultEditActions {...props} />}
    title={"Neuen Eintrag anlegen"}
    {...props}
  >
    <EntityForm
      isCreate={true}
      {...props}
    />
  </Create>
);
