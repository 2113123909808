import { Box, Button, Card, IconButton } from "@material-ui/core";
import { InputContainer, ParagraphBox, ParagraphHeadline } from "../../../utils/components/FormElements";
import { ArrayInput, SelectInput } from "react-admin";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import TextInput from "../../../utils/components/TextInput";
import { RemoveCircleOutline } from "@material-ui/icons";
import CustomDatePicker from "../../../utils/components/CustomDatePicker";
import { BooleanInput } from "../../../utils/components";
import { useForm } from "react-final-form";
import { DriverReferenceInput } from "../../../drivers/components/DriverReferenceInput";
import TimeRangeInputAlternative from "../../../utils/components/TimeRangeInputAlternative";

export default ( props ) => {
  return <Card className={props.classes.cardFullWidth}>
    <ParagraphHeadline>Individuelle Daten</ParagraphHeadline>
    <ArrayInput source="customData" label={""} fullWidth>
      <CustomDataIterator />
    </ArrayInput>
  </Card>
}


const CustomDataIterator = ( props ) => {
  const form = useForm()
  const addButtonClickHandler = () => {
    let amount = form.getState().values['addAmount']
    for( let i = 0; i < amount; i++ ) {
      const newElement = {
        pickupDate: form.getState().values.pickupDate ?? '',
        pickupTimeFrom: form.getState().values.pickupTimeFrom ?? '',
        pickupTimeTo: form.getState().values.pickupTimeTo ?? '',
        deliveryDate: form.getState().values.deliveryDate ?? '',
        deliveryTimeFrom: form.getState().values.deliveryTimeFrom ?? '',
        deliveryTimeTo: form.getState().values.deliveryTimeTo ?? '',
        licensePlate: form.getState().values.licensePlate,
        fin: '',
        leasingvertragsnummer: '',
        verzoegerteAbgabe: false,
        angemeldet: true
      }

      props.fields.push(newElement);
    }
  }

  const removeItem = (id) => {
    // remove the item at the index from elements
    // const newElements = elements.filter((item, i) => item.id !== id)
    // setElements([...newElements])
    props.fields.remove(id);
  }

  const classes = useStyles();

  return (
    <>
      { props.fields.map( ( item, index ) => {
        return (

          <ParagraphBox key={item.id}>

            <Box flex={1} display={"flex"}>
              <Box flex={1} mr={"0.5rem"}>
                <div class="MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth">
                  <div style={{ width: "100%" }} class="MuiInputBase-root MuiFilledInput-root MuiFilledInput-underline MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-marginDense MuiFilledInput-marginDense">
                    <label class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense MuiInputLabel-filled MuiFormLabel-filled">Abholdatum</label>
                    <CustomDatePicker fieldName={`${item}.pickupDate`} />
                  </div>
                </div>
              </Box>

              <Box flex={1} mr={"0.5rem"}>
                <Box display={"flex"}>
                  <TimeRangeInputAlternative
                    sourceFrom={`${item}.pickupTimeFrom`}
                    sourceTo={`${item}.pickupTimeTo`}
                  />
                </Box>
              </Box>
            </Box>

            <Box flex={1} display={"flex"}>
              <Box flex={1} mr={"0.5rem"}>
                <div class="MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth">
                  <div style={{ width: "100%" }} class="MuiInputBase-root MuiFilledInput-root MuiFilledInput-underline MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-marginDense MuiFilledInput-marginDense">
                    <label class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense MuiInputLabel-filled MuiFormLabel-filled">Zustelldatum</label>
                    <CustomDatePicker fieldName={`${item}.deliveryDate`} />
                  </div>
                </div>
              </Box>

              <Box flex={1} mr={"0.5rem"}>
                <Box display={"flex"}>
                  <TimeRangeInputAlternative
                    sourceFrom={`${item}.deliveryTimeFrom`}
                    sourceTo={`${item}.deliveryTimeTo`}
                  />
                </Box>
              </Box>
            </Box>

            <Box flex={4} display={"flex"} alignItems={"center"}>
              <InputContainer flex={2} left>
                <TextInput
                  source={`${item}.licensePlate`}
                  label={"Nummernschild"}
                  fullWidth/>
              </InputContainer>
              <InputContainer flex={2} center>
                <TextInput
                  source={`${item}.carFin`}
                  label={"Fahrgestellnummer"}
                  fullWidth />
              </InputContainer>
              <InputContainer flex={2} center>
                <TextInput
                  source={`${item}.leasingvertragsnummer`}
                  label={"Leasingvertragsnummer"}
                  fullWidth />
              </InputContainer>
              <InputContainer flex={2} center>
                <TextInput
                  source={`${item}.kostenstelle`}
                  label={"Kostenstelle"}
                  fullWidth />
              </InputContainer>
              <InputContainer flex={2} center>
                <DriverReferenceInput source={`${item}.abholer`} label={"Fahrer"} />
              </InputContainer>
              <InputContainer center display={"flex"}>
                <BooleanInput
                  source={`${item}.verzoegerteAbgabe`}
                  label={"Vollelektrisch"}
                  fullWidth />
              </InputContainer>
              <InputContainer right display={"flex"}>
                <BooleanInput
                  source={`${item}.flexContract`}
                  label={"Flex"}
                  fullWidth />
              </InputContainer>
            </Box>

            <Box className={classes.listItemControl}>
              <IconButton onClick={() => removeItem(index)}>
                <RemoveCircleOutline />
              </IconButton>
            </Box>
          </ParagraphBox>
        )
      })}
      <Box display={"flex"} alignItems={"center"}>
        <SelectInput
          source="addAmount"
          choices={[
            {id: 1, name: '1'},
            {id: 5, name: '5'},
            {id: 10, name: '10'},
            {id: 20, name: '20'}
          ]}
          style={{maxWidth: "160px"}}
          label="Anzahl"
          defaultValue={1}
          helperText={false}
          fullWidth />
        <Button
          color={'primary'}
          label="ra.action.add"
          onClick={() => addButtonClickHandler()}>
          <AddIcon/> Neu
        </Button>
      </Box>

    </>
  )
}

const useStyles = makeStyles( ( theme ) => ( {
  col: {
    padding: "0 5px 0 5px",
    textAlign: "left",
    '> p': {
      margin: "0 0 0 0",
    }
  },
  listItem: {
    padding: '0 5px 0 5px',
    display: 'flex',
    backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.default,
    // borderBottom: "2px solid rgba(128,128,128, 0.75)",
    '&:nth-child(odd)': {
      // boxShadow: "rgb(0,0,0,0.26) 0px 2px 20px 0px",
      backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.odd,
    },
    '&:hover': {
      backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.hover,
    },
  },

  listItemControl: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center"
  },

  currentPos: {
    display: "flex",
    alignItems: "center",
  },
  textInput: {
    margin: "8px 3px 4px 3px",
    float: 'left',
  }
} ) )

