import React from "react";
import { FunctionField, ReferenceField, TextField } from "react-admin";
import HotList from "../utils/components/HotList";

const CustomerDepartmentList = ( props) => (
  <HotList {...props} sort={"id"} sortOrder={"DESC"} allowCreate={true} hasEdit={true}>
    {/*<TextField source={"id"} />*/}
    <TextField source={"name"} label={"Name"}/>
    <ReferenceField label="Kunde" source="customer" reference="customers">
      <TextField source="anzeigename"/>
    </ReferenceField>
    <FunctionField render={record => record.users ? record.users.length : "0"} label={"Anzahl Mitarbeiter"}/>
  </HotList>
);

export default CustomerDepartmentList;
