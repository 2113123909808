import React, { useState } from "react";
import { useNotify, useRefresh } from "react-admin";
import GlobalLoading from "../../../utils/components/GlobalLoading";
import Button from "@material-ui/core/Button";
import { setContractDriver } from "../../../utils/request/PatchRequest";
import PersonIcon from "@material-ui/icons/Person";

export default ( { itemId, driverId = '/api/drivers/15', disabled, record, ...props } ) => {
  const [loading, setLoading] = useState( null );
  const refresh = useRefresh();
  const notify = useNotify();

  return <>
    {loading && <GlobalLoading/>}
    <Button
      key={"vermaco"}
      color={"primary"}
      size={"small"}
      onClick={() => {
        setLoading( true );
        setContractDriver( itemId, driverId, "api/import_contracts" )
          .then( ( response ) => {
            record.driver = driverId;
            if( record.anschluss && !record.anschluss.driver ) {
              record.anschluss.driver = driverId
              setContractDriver( record.anschluss.id, driverId, "api/import_contracts" )
                .catch( error => {
                  notify( `Fehler beim ändern des Fahrers: ${error.message}`, { type: "warning" } );
                  refresh();
                } )
            }
            notify( `custom.message.success`, { type: "success" } );
            setLoading( false );
            refresh();
          } ).catch( ( error ) => {
          // failure side effects go here
          notify( `Fehler beim ändern des Fahrers: ${error.message}`, { type: "warning" } );
          setLoading( false );
          refresh();
        } )
      }}
      {...disabled}>
      <PersonIcon style={{ marginRight: "0.5rem" }}/>

      {props.children}
    </Button>
  </>
}
