import { SearchInput, BooleanInput } from "react-admin";
import "moment/locale/de";
import React from "react";

const customerFilter = [
  <SearchInput placeholder="Firma, Kürzel, Vorname, Nachname, Ort..." source="_name" style={{minWidth: "350px", marginTop: 0}} alwaysOn />,
  <BooleanInput
    label="Nur aktive anzeigen"
    options={{ size: "small" }}
    source="aktiv"
    parse={(v) => (v ? "1" : null)}
    format={(v) => v === "1"}
    alwaysOn
  />,
];

const customerFiltersDefaultValues = {
  aktiv: "1",
  // "abgeschlossen": "0"
};

export { customerFilter, customerFiltersDefaultValues };
