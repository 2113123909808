import { ListButton, TopToolbar, TextInput, SimpleForm, useNotify, useRedirect } from "react-admin";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Search from "@material-ui/icons/Search";
import { ExportPDFButton } from "../../contracts/components/ListButtons";
import * as React from "react";
import { Button } from "@material-ui/core";
import { getProtocolByOrderNumberSearch } from "../../utils/request/GetRequest";
import EntityLink from "../../utils/components/EntityLink";
import { IconEditRetoureProtocol } from "../../utils/components/icons/IconEditRetoureProtocol";
import SettingsIcon from "@material-ui/icons/Settings";

const MyChildrenContainerComponent = props => (
  <div style={{ display: "flex", alignItems: 'center' }}>
    {props.children}
  </div>
);

export const EditActions = ( props ) => {
  const notify = useNotify()
  const redirect = useRedirect();
  const record = props.data

  const onSubmit = ( event ) => {
    event.preventDefault()
    const orderNumber = document.querySelector( '#orderNumber' ).value

    getProtocolByOrderNumberSearch( orderNumber ).then( ( result ) => {
      if( typeof result[ '0' ] === "undefined" ) {
        notify( `Kein Auftrag mit Auftragsnummer ${orderNumber} gefunden`, { type: "warning" } );
      } else {
        redirect( 'edit', '/protocols', `/api/protocols/${result[ '0' ].orderNumber}` );
      }

    } ).catch( ( error ) => {
      notify( `Fehler: ${error.message}`, { type: "warning" } );
    } )
  }


  return (
    <TopToolbar>
      <SimpleForm id={"ordernumber_searchform"}
                  toolbar={null}
                  margin="none"
                  style={{ position: "absolute", left: 0, top: 0, margin: 0, padding: 0 }}
                  component={MyChildrenContainerComponent}
                  onSubmit={onSubmit}>
        <TextInput
          label={"Auftragsnummer"}
          placeholder={"Auftragsnummer"}
          source={"orderNumber"}
          record={record}
          style={{ padding: '0' }}
          helperText={false}/>
        <Button
          label={"Suchen"}
          children={<Search/>}
          onClick={onSubmit}
          color={"secondary"}
          style={{ width: "auto", marginLeft: '5px' }}>
          Suchen
        </Button>
      </SimpleForm>
      <ListButton label="Zurück zur Liste" icon={<ChevronLeft/>}/>

      <EntityLink
        record={props?.data?.previousRide}
        title={"Anschluss"}
        icon={<SettingsIcon/>}>
        {'A -> B'}
      </EntityLink>

      <EntityLink
        record={props?.data?.anschlussProtokoll}
        title={"Anschluss"}
        icon={<IconEditRetoureProtocol/>}
        {...props}>
        {props?.data?.contract?.abafahrt ? 'B -> A' : 'B -> C'}
      </EntityLink>

      <EntityLink record={props?.data?.contract} title={"Auftrag"} {...props}>
        AE3
      </EntityLink>

      <ExportPDFButton
        record={props.data?.contract}
      />
    </TopToolbar>
  );
};
