import CategoryList from "./JobCategoryList";
import { EntityCreate, EntityEdit } from "./JobCategoryEdit";

const JobCategories = {
  list: CategoryList,
  edit: EntityEdit,
  create: EntityCreate,
  name: "job_categories"
};

export default JobCategories;
