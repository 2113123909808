import { defaultTheme } from "react-admin";
import merge from "lodash/merge";
import blue from "@material-ui/core/colors/blue";
import indigo from "@material-ui/core/colors/indigo";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const vermacoTheme = () => {
  if(typeof( localStorage.getItem( "darkModeEnabled" ) ) === "undefined" ) {
    localStorage.setItem( "darkModeEnabled", useMediaQuery( '(prefers-color-scheme: dark)' ).toString())
  }

  const prefersDarkMode = localStorage.getItem( "darkModeEnabled" ) === "1";

  const defaultStyles = {
    palette: {
      primary: blue,
      secondary: indigo,
      type: prefersDarkMode ? "dark" : "light",
      text: {
        secondary: indigo
      },
      action: {
        tableHover: "rgba(0,0,0,0.08)"
      },

    },
  }

  const lightStyles = {
    overrides: {
      dayRow: {
        backgroundColor: "rgb(186, 222, 251)",
      },

      invoiceTable: {
        row: {
          backgroundColor: {
            default: 'rgba(128,128,128,0 )',
            hover: 'rgba(128,128,128,0.15 )',
            odd: 'rgba(128,128,128,0.1)',
          },
        }
      }
    }
  }

  const darkStyles = {
    overrides: {
      dayRow: {
        backgroundColor: defaultStyles.palette.primary[ 500 ],
      },

      invoiceTable: {
        row: {
          backgroundColor: {
            default: 'rgba(255,255,255,0)',
            hover: 'rgba(255,255,255,0.15)',
            odd: 'rgba(128,128,128,0.1)',
          },
        }
      }
    }
  }

  return merge(
    {},
    defaultTheme,
    defaultStyles,
    prefersDarkMode ? darkStyles : lightStyles
  )
}

export default vermacoTheme;
