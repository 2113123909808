import { Box, Typography } from "@material-ui/core";


const ParagraphHeadline = ( props ) => (
  <Typography variant="h6" gutterBottom { ...props }>
    { props.children }
  </Typography>
);

const LeftBox = ( props ) => (
  <Box mr={ "1rem" } flex={ 1 } { ...props }>
    { props.children }
  </Box>
);

const FormTabContainerBox = ( props ) => (
  <Box p={ "1rem" } { ...props }>
    { props.children }
  </Box>
);

const ParagraphBox = ( props ) => (
  <Box display={ "flex" } { ...props }>
    { props.children }
  </Box>
);

const ParagraphBoxLeft = ( props ) => (
  <Box display={ "flex" } style={ { "justify-content": "flex-start" } } { ...props }>
    { props.children }
  </Box>
);

const RightBox = ( props ) => (
  <Box ml={ "1rem" } flex={ 1 } { ...props }>
    { props.children }
  </Box>
);

const InputContainer = ( props ) => {
  let { left, right, center, flex, style, ...rest } = props;
  if ( center ) {
    left = right = true;
  }
  if ( !flex ) {
    flex = 1;
  }
  const styles = {
    paddingRight: left ? "0.25rem" : "0",
    paddingLeft: right ? "0.25rem" : 0,
    ...style
  }

  return <Box flex={ flex } style={styles} { ...rest }>
    { props.children }
  </Box>
}
const Spacer = ( { space = "2rem", ...rest } ) => (
  <div style={ { marginTop: space, marginBottom: space, ...rest.style } }></div>
);

export { FormTabContainerBox, ParagraphBox, ParagraphBoxLeft, ParagraphHeadline, LeftBox, RightBox, InputContainer, Spacer };
