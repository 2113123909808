import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@material-ui/core";
import { useState } from "react";
import { useNotify, useUpdate } from "react-admin";

export default function MoveToPositionFormDialog(props) {
  const { moveFunc, from, isOpen, setIsOpen, maxValue, items, saveFunc } = props;
  const [to, setTo] = useState( null );

  const handleClose = () => {
    setIsOpen( false );
    setTo( null );
  }

  const handleMoveBtnClick = () => {
    // Don't move if field is empty
    if (to !== null) {
      try {
        if( items?.value[ from ] && items?.value[ from ][ '@id' ] ) {
          saveFunc(from, to)
        }
      } catch( error ) {
        console.log( error )
      }
      // update();
      moveFunc( from, to-1 );
    }

    handleClose();
  }

  const handleToChange = (e) => {
    // Numeric only, can't start with 0
    const re = /^[1-9][0-9]*$/;
    const value = e.target.value;
    const targetIndex = value === '' || re.test( value ) ? ( value > maxValue ? maxValue : value ) : ''

    setTo( targetIndex );
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Position verschieben</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Bitte geben Sie einen neuen Index für den Auftrag ein
        </DialogContentText>
        <TextField
          autoFocus
          id="position"
          label="Index der Position"
          margin="dense"
          fullWidth
          required={true}
          placeholder={from + 1}
          value={to}
          onChange={handleToChange}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Abbrechen</Button>
        <Button color={'primary'} onClick={handleMoveBtnClick}>Verschieben</Button>
      </DialogActions>
    </Dialog>
  );
}
