import React, { useState } from "react";
import { useNotify, useRefresh } from "react-admin";
import { cancelContract } from "../../../utils/request/PatchRequest";
import GlobalLoading from "../../../utils/components/GlobalLoading";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import CancelIcon from "@material-ui/icons/Cancel";

export default ( props ) => {
  const [loading, setLoading] = useState( false );
  const notify = useNotify();
  const refresh = useRefresh();

  const handleResetClick = async( id, declined ) => {
    await cancelContract( id, declined, "api/import_contracts" )
  };

  const { record, ...rest } = props
  const buttonText = record.imported ? `${record.isDeclinedByVermaco ? ' freigeben' : 'stornieren'}` : `${record.isDeclinedByVermaco ? ' freigeben' : 'ablehnen'}`
  const buttonStyle = {
    border: "0 none",
    color: '#C82333',
  }

  return <>
    {loading && <GlobalLoading/>}

    <Button
      style={buttonStyle}
      onClick={() => {
        setLoading( true );
        handleResetClick( record.id, {
          declined: !record.isDeclinedByVermaco,
          silent: false
        } ).then( ( response ) => {
          notify( `Auftrag erfolgreich ${record.isDeclinedByVermaco ? 'freigegeben' : 'abgelehnt'}`, { type: "success" } );
          refresh();
        } ).catch( ( error ) => {
          setLoading( false );
          notify( `Fehler beim stornieren: ${error.message}`, { type: "warning" } );
        } );
      }}
      {...rest}>
      {record.isDeclinedByVermaco ? <CheckIcon style={{ marginRight: "0.5rem" }}/> : <CancelIcon style={{ marginRight: "0.5rem" }}/>} {buttonText}
    </Button>
  </>;
}
