import React, { useState } from "react";
import { Button, Confirm, useDataProvider, useNotify } from "react-admin";
import GlobalLoading from "../../utils/components/GlobalLoading";




export const CancelRideConnectionButton = ( props ) => {
  const { record } = props;
  const [loading, setLoading] = useState( false );
  const [open, setOpen] = useState( false );
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const cancelRideConnection = async () => {
    setLoading( true );

    const additionalRide = record.anschlussAuftrag ? record.anschlussAuftrag['@id'] : record['@id'];
    const originContract = record.anschlussAuftrag ? record['@id'] : record.anschlussvon;
    console.log( 'origin', originContract)
    console.log( 'additional', additionalRide)

    dataProvider.update(
      'contracts', {
        id: additionalRide,
        data: {
          anschlussvon: null,
          abcfahrt: '0',
          abafahrt: '0'
        }
      } ).then( ( response ) => {
      // success side effects go here

      dataProvider.update(
        'contracts', {
          id: originContract,
          data: {
            abcfahrt: '0',
            abafahrt: '0'
          }
        } ).then( ( response ) => {
        // success side effects go here
        setLoading( false );
        setOpen( false )
        notify( `Auftragsverknüpfung entfernt`, { type: "success" })
      } ).catch( ( error ) => {
        // failure side effects go here
        setLoading( false );
        notify( `Fehler beim bearbeiten des Hauptauftrags: ${error.message}`, { type: "warning" } )
      } )
    } ).catch( ( error ) => {
      // failure side effects go here
      setLoading( false );
      notify( `Fehler beim bearbeiten des Anschlusses: ${error.message}`, { type: "warning" } )
    } )
  }


  return <>
    <Confirm
      title="Verknüpfung entfernen"
      content="Möchten Sie die Verknüpfung wirklich entfernen?"
      onConfirm={() => cancelRideConnection()}
      onClose={() => setOpen(false)}
      isOpen={open}
      loading={loading}
    />
    <Button label="Auftragsverknüpfung entfernen" onClick={() => setOpen(true)} {...props} />
  </>
}
