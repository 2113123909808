import { Button, useNotify, useRefresh } from "react-admin";
import axios from "axios";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";

export const apiEndpoint = process.env.REACT_APP_API;

const PatchRequest = async( requestUrl, data ) => {
  try {
    return await axios.patch( requestUrl, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem( "token" )}`,
        "Content-Type": "application/merge-patch+json",
      },
    } );
  } catch( err ) {
    return Promise.reject( err );
    // notify( `Es ist ein Fehler aufgetreten:<br /> ${err.message}`, { type: 'warning' } );
  }
};

const resetBillingPos = async( id, entityIRI = "/api/billings", useIdAsIri = false ) => {
  if( !id ) {
    return null;
  }

  const requestURL = `${apiEndpoint}${useIdAsIri ? id : entityIRI + '/' + id}`;

  return await PatchRequest( requestURL, {
    resetArticles: true,
    resetInvoiceEntries: true,
  } );
};

const cancelContract = async( id, declined = {}, entityIRI = "api/contracts" ) => {
  if( !id ) {
    return null;
  }

  const requestURL = `${apiEndpoint}/${entityIRI}/${id}`;

  return await PatchRequest( requestURL, {
    isDeclinedByVermaco: declined.declined,
    isDeclinedSilent: declined.silent
  } );
};

const sendProtocolMail = async( id ) => {
  if( !id ) {
    return null;
  }

  const requestURL = `${apiEndpoint}${id}`;

  return await PatchRequest( requestURL, {
    sendProtocolMail: true,
  } );
};

const setContractDriver = async( contractId, driverID = null, entityIRI = "api/contracts" ) => {
  if( !contractId ) {
    return null;
  }

  const requestURL = `${apiEndpoint}/${entityIRI}/${contractId}`;
  if( typeof driverID === "string" && !driverID.includes( "/api/drivers/" ) ) {
    driverID = `/api/drivers/${driverID}`;
  }

  return await PatchRequest( requestURL, {
    driver: driverID
  } );
};

/**
 * @deprecated use updateContract
 * @param contractId
 * @param confirm
 * @param entityIRI
 * @returns {Promise<AxiosResponse<any>|undefined|null>}
 */
const setContractConfirm = async(
  contractId,
  confirm = true,
  entityIRI = "api/contracts"
) => {
  if( !contractId ) {
    return null;
  }

  const requestURL = `${apiEndpoint}/${entityIRI}/${contractId}`;

  return await PatchRequest( requestURL, {
    confirm: confirm,
  } );
};

const updateContract = async(
  contractId,
  data = {},
  entityIRI = "api/contracts"
) => {
  if( !contractId ) {
    return null;
  }

  const requestURL = `${apiEndpoint}/${entityIRI}/${contractId}`;

  return await PatchRequest( requestURL, data );
};

export { cancelContract, setContractDriver, resetBillingPos, updateContract, sendProtocolMail, PatchRequest };
