import * as React from "react";
import { useState } from "react";
import { ArrayInput, AutocompleteArrayInput, Create, DeleteWithConfirmButton, Edit, FormTab, RefreshButton, SaveButton, SelectInput, TabbedForm } from "react-admin";
import DefaultEditActions from "../utils/DefaultEditActions";
import { CategoryReferenceInput } from "../category/components/CategoryReferenceInput";
import CustomBooleanInput from "../utils/components/CustomBooleanInput";
import NumberInput from "../utils/components/NumberInput";
import { FormTabContainerBox, InputContainer, ParagraphBox, ParagraphHeadline } from "../utils/components/FormElements";
import DefaultTextInput from "../utils/components/TextInput";
import { unset } from "lodash";
import { Box, Toolbar } from "@material-ui/core";
import UserFormIterator from "./components/UserFormIterator";
import useLocalStorage from "../utils/useLocalStorage";
import ReferenceInput from "../utils/components/ReferenceInput";
import CountrySelectInput from "../utils/components/CountrySelectInput";
import { AddressFormIterator, BillingAddressFormIterator } from "./components/AddressFormIterator";
import { ServiceReferenceArrayInput } from "../services/components/ServiceReferenceInput";
import SelectArrayInput from "../utils/components/SelectArrayInput";
import DefaultServiceFormIterator from "./components/DefaultServiceFormIterator";
import { UMailFormIterator } from "./components/UMailFormIterator";
import ReferenceArrayInput from "../utils/components/ReferenceArrayInput";
import BooleanInput from "../utils/components/BooleanInput";
import { LeasingProtocolReferenceInput } from "../leasingProtocols/components/LeasingProtocolReferenceInput";

const TextInput = ( props ) => <DefaultTextInput {...props} fullWidth/>;

const EntityToolbar = ( props ) => {
  return <Toolbar
    style={{
      position: "fixed",
      zIndex: 100000,
      bottom: 10,
      maxWidth: "100%",
      backgroundColor: "rgba(255,255,255, 0.8)",
      borderRadius: "5px",
    }}
  >
    {props.isCreate ? <Box width="100%">
                      <SaveButton
                        saving={props.saving}
                        handleSubmitWithRedirect={props.handleSubmitWithRedirect}
                        label={"Kunden anlegen"}
                        style={{ marginRight: "0.5rem" }}
                      />
                    </Box> :
     <Box width="100%">
       <SaveButton
         transform={data => transformCustomerSubmit( data )}
         saving={props.saving}
         handleSubmitWithRedirect={props.handleSubmitWithRedirect}
         label={"Speichern und schließen"}
         style={{ marginRight: "0.5rem" }}
       />
       <SaveButton
         saving={props.saving}
         redirect={false}
         transform={data => transformCustomerSubmit( data )}
         label={"Speichern"}
         handleSubmitWithRedirect={props.handleSubmitWithRedirect}
         style={{ marginRight: "0.5rem" }}
       />
       <DeleteWithConfirmButton
         confirmTitle={`Löschen bestätigen`}
         record={props.record}
         label={"Löschen"}
         confirmContent={`Möchten Sie den eintrag wirklich löschen?`}
       />
       <RefreshButton label="Zurücksetzen"/>
     </Box>
    }
  </Toolbar>
}

const EntityForm = ( props ) => {
  const [posPage, setPosPage] = useState( 0 );
  const [posPerPage, setPosPerPage] = useLocalStorage( 'customer.positions.perPage', 100 );

  return <TabbedForm actions={<DefaultEditActions/>} autocomplete="off" {...props}>
    <FormTab label={"Stammdaten"}>
      <ParagraphBox flexWrap={"wrap"} style={{ width: "100%" }}>
        <FormTabContainerBox flex={1} minWidth={"550px"}>
          <ParagraphHeadline>Stammdaten</ParagraphHeadline>
          <ParagraphBox>
            <InputContainer left>
              <TextInput source={"kundennr"} label={"Kundennummer"} disabled={true}/>
            </InputContainer>
            <InputContainer center>
              <TextInput source={"kuerzel"}/>
            </InputContainer>
            <InputContainer right display={"flex"} alignItems={"center"}>
              <CustomBooleanInput source={"aktiv"} defaultValue={"1"}/>
            </InputContainer>
          </ParagraphBox>
          <ParagraphBox>
            <InputContainer left>
              <TextInput source={"firma"}/>
            </InputContainer>
            <InputContainer center>
              <TextInput source={"anrede"}/>
            </InputContainer>
            <InputContainer center>
              <TextInput source={"vorname"}/>
            </InputContainer>
            <InputContainer right>
              <TextInput source={"nachname"}/>
            </InputContainer>
          </ParagraphBox>
          <ParagraphBox>
            <InputContainer left>
              <TextInput source={"strasse"}/>
            </InputContainer>
            <InputContainer center>
              <TextInput source={"hausnr"}/>
            </InputContainer>
            <InputContainer center>
              <TextInput source={"plz"}/>
            </InputContainer>
            <InputContainer center>
              <TextInput source={"ort"}/>
            </InputContainer>
            <InputContainer right>
              <CountrySelectInput source={"land"} fullWidth/>
            </InputContainer>
          </ParagraphBox>

          <ParagraphBox>
            <InputContainer left>
              <TextInput source={"ustid"}/>
            </InputContainer>
            <InputContainer center>
              <TextInput source={"steuernummer"}/>
            </InputContainer>

            <InputContainer center>
              <TextInput source={"handelsregisternummer"} label={"HR Nummer"}/>
            </InputContainer>
            <InputContainer right>
              <TextInput source={"amtsgericht"}/>
            </InputContainer>
          </ParagraphBox>
        </FormTabContainerBox>

        <FormTabContainerBox flex={1} minWidth={"550px"}>
          <ParagraphHeadline>Kontaktdaten</ParagraphHeadline>

          <ParagraphBox>
            <InputContainer>
              <TextInput source={"contactPerson"} label={"Ansprechpartner"}/>
            </InputContainer>
          </ParagraphBox>

          <ParagraphBox>
            <InputContainer left>
              <TextInput source={"telefonVorwahl"}/>
            </InputContainer>
            <InputContainer center>
              <TextInput source={"telefon"}/>
            </InputContainer>
            <InputContainer center>
              <TextInput source={"email"}/>
            </InputContainer>
            <InputContainer right>
              <TextInput source={"email2"}/>
            </InputContainer>
          </ParagraphBox>

          <ParagraphBox>
            <InputContainer>
              <TextInput source={"handynr"}/>
            </InputContainer>
          </ParagraphBox>
          <ParagraphBox>
            <InputContainer>
              <TextInput source={"mailConfirmContractSubject"}
                         label={"Individueller Betreff für Auftragsbestätigung"}
                         helperText={"Mögliche Variablen sind [AUFTRAGSNUMMER], [KENNZEICHEN] und [LVNUMMER]"}/>
            </InputContainer>
          </ParagraphBox>
          <ParagraphBox>
            <InputContainer>
              <TextInput source={"notizen"} multiline={true}/>
            </InputContainer>
          </ParagraphBox>
        </FormTabContainerBox>
      </ParagraphBox>

      <ParagraphBox flexWrap={"wrap"} style={{ width: "100%" }}>

        <FormTabContainerBox flex={1} minWidth={"550px"}>
          <ParagraphHeadline>Zahlungsdaten</ParagraphHeadline>
          <ParagraphBox>
            <InputContainer left>
              <TextInput source={"zahlarten"}/>
            </InputContainer>
            <InputContainer center>
              <NumberInput source={"zahlzielRechnung"} label="Zahlziel Rechnung" defaultValue={10}/>
            </InputContainer>
            <InputContainer center>
              <NumberInput source={"zahlzielLastschrift"} label="Zahlziel Lastschrift" defaultValue={10}/>
            </InputContainer>
            <InputContainer right>
              <NumberInput source={"wartestundenpreis"} label="Wartestunden Preis" defaultValue={"20.00"} step={0.01}/>
            </InputContainer>
          </ParagraphBox>
          <ParagraphBox>
            <InputContainer left>
              <CategoryReferenceInput source="kategorie" fullWidth/>
            </InputContainer>

            <InputContainer right>
              <ReferenceInput source="priceGroup" reference={"price_groups"} label="Preisgruppe" resettable={true}>
                <SelectInput optionText={"name"} optionValue={"id"} fullWidth helperText={false}/>
              </ReferenceInput>
            </InputContainer>
          </ParagraphBox>

          <ParagraphBox>
            <InputContainer>
              <TextInput source={"alternativBillingMailAddress"} label={"Abweichende Email für Rechnungen"}/>
            </InputContainer>
          </ParagraphBox>
        </FormTabContainerBox>
      </ParagraphBox>

      <ParagraphBox>
        <FormTabContainerBox minWidth={"550px"}>
          <ServiceReferenceArrayInput source={"defaultServices"} label={"Standard-Zusatzleistungen"} fullWidth>
            <SelectArrayInput
              optionValue="id"
              optionText={( service ) => {
                return service ? service.name : null
              }}
              style={{ minWidth: "200px" }}
              helperText={false}
            />
          </ServiceReferenceArrayInput>

          <LeasingProtocolReferenceInput source={"defaultLeasingProtocol"} label={"Standard-Leasing-Protokoll"} fullWidth/>

          <ReferenceArrayInput reference="leasing_protocols" source="leasingProtocols" label="Leasing-Protokolle" fullWidth>
            <AutocompleteArrayInput optionText={"name"}/>
          </ReferenceArrayInput>
        </FormTabContainerBox>
      </ParagraphBox>

      <ParagraphBox>
        <FormTabContainerBox minWidth={"550px"}>
        </FormTabContainerBox>
      </ParagraphBox>

      <ParagraphBox>
        <FormTabContainerBox minWidth={"550px"}>
          <CustomBooleanInput fullWidth source={"auftragsliste"} label={"Auftragsliste"}/>
          <CustomBooleanInput fullWidth source={"auftragseingabe"} label={"Auftragseingabe"}/>
          <CustomBooleanInput fullWidth source={"auftragmitanschlussfahrt"} label={"Auftrag mit Anschlussfahrt"}/>
          <CustomBooleanInput fullWidth source={"zusatzpreislisteerlaubt"} label={"Zusatzpreisliste erlaubt"}/>
          <CustomBooleanInput fullWidth source={"nureigeneadressen"} label={"Nur eigene Adressen"}/>
          <CustomBooleanInput fullWidth source={"benoetigtauftragsfreigabe"} label={"Benötigt Auftragsfreigabe"}/>
          <CustomBooleanInput fullWidth source={"emailsanadressenbeibestaetigung"} label={"Emails an Adressen bei Bestätigung"}/>
          <CustomBooleanInput fullWidth source={"emailsanadressenansprechpartnerbeibestaetigung"} label={"Emails an Adressen Ansprechpartner bei Bestätigung"}/>
          <CustomBooleanInput fullWidth source={"leistungsadresseerlaubt"} label={"Leistungsadresse erlaubt"}/>
          <CustomBooleanInput fullWidth source={"kostenstelleerlaubt"} label={"Kostenstelle erlaubt"}/>
          <CustomBooleanInput fullWidth source={"lvnummererlaubt"} label={"LV Nummer erlaubt"}/>
          <CustomBooleanInput fullWidth source={"lvprotokollerlaubt"} label={"LV Protokoll erlaubt"}/>
          <CustomBooleanInput fullWidth source={"filialenerlaubt"} label={"Filial- und Nutzerverwaltung im Kundenportal freigeschaltet"}/>
          <BooleanInput source={"sendProtocolToContractContact"} label={"Protokoll an Auftrags-Ansprechpartner senden"}/>
        </FormTabContainerBox>
      </ParagraphBox>

    </FormTab>

    <FormTab label={"Adressen"}>
      <ArrayInput source={"addresses"} label={""} fullWidth>
        <AddressFormIterator/>
      </ArrayInput>
    </FormTab>

    <FormTab label={"Rechnungsadressen"}>
      <ArrayInput source={"alternativeBillingAddresses"} label={""} fullWidth>
        <BillingAddressFormIterator/>
      </ArrayInput>
    </FormTab>

    <FormTab label={"Nutzer"}>
      <ArrayInput source={"users"} label={""} fullWidth>
        <UserFormIterator
          perPage={posPerPage}
          setPerPage={setPosPerPage}
          page={posPage}
          setPage={setPosPage}
          fieldName={"users"}/>
      </ArrayInput>
    </FormTab>
    <FormTab label={"Automatischer Mailversand"}>
      <ArrayInput source="addressMails" label={""}>
        <UMailFormIterator/>
      </ArrayInput>
    </FormTab>

    <FormTab label={"Zusatzleistungen"}>
      <ArrayInput source={"defaultServices"} label={""} fullWidth>
        <DefaultServiceFormIterator {...props} />
      </ArrayInput>
    </FormTab>

  </TabbedForm>
}

function transformCustomerSubmit( data ) {

  let transformedData = {
    ...data,
  };
  unset( transformedData, 'preislisten' )
  unset( transformedData, 'priceList' )
  unset( transformedData, 'roles' )
  unset( transformedData, 'creditPriceList' )
  unset( transformedData, 'creditPriceListE' )
  unset( transformedData, 'id' )

  return transformedData;
}

const EntityTitle = ( { record } ) => {
  return <span>Kunde {record ? `"${record.firma}"` : ""}</span>;
};

const EntityEdit = ( props ) => (
  <Edit
    actions={<DefaultEditActions {...props} allowNew={true}/>}
    title={<EntityTitle/>}
    style={{ marginBottom: "75px" }}
    {...props}
  >
    <EntityForm isCreate={false} toolbar={<EntityToolbar/>}/>
  </Edit>
);

const EntityCreate = ( props ) => (
  <Create
    actions={<DefaultEditActions {...props} />}
    style={{ marginBottom: "50px" }}
    {...props}
    title={"Neuen Eintrag anlegen"}
  >
    <EntityForm toolbar={<EntityToolbar isCreate={true}/>}  {...props} style={{ width: "100%" }}/>
  </Create>
);


export { EntityEdit, EntityCreate };
