import { Fragment, useState } from "react";
import { Button, Confirm, useNotify, useRefresh, useUpdate } from "react-admin";
import MailIcon from "@material-ui/icons/Mail";
import * as React from "react";

export const SendMailButton = ( props ) => {
  const { record, ...rest } = props;
  const [isOpened, setIsOpened] = useState( false );
  const refresh = useRefresh();
  const notify = useNotify();

  const [update, { loading }] = useUpdate(
    'invoices',
    record[ '@id' ],
    { sendMail: true },
    record,
    {
       onSuccess: () => {
         refresh();
         notify(`${record.type} wurde verschickt`, { type: 'success' } );
       },
       onFailure: ( error ) => {
         notify(
           error.message || `Fehler beim Verschicken der ${record.type} `,
           { type: 'warning', multiLine: true }
         )
       }
     }
  );

  const handleConfirm = () => {
    update();
    setIsOpened( false );
  };

  return (
    <Fragment>
      <Button
        title={`${record.type} versenden`}
        onClick={() => setIsOpened( true )}
        children={<MailIcon/>}
        {...rest}
      />

      <Confirm
        isOpen={isOpened}
        loading={loading}
        title={`Mail für ${record.type} #${record.orderNumber} versenden`}
        content={`${record.type} ${record.mailSend ? " erneut" : "" } versenden?`}
        onConfirm={handleConfirm}
        onClose={() => setIsOpened( false )}
      />
    </Fragment>
  );
}
