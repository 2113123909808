import React, { useState } from "react";
import { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { Box } from "@material-ui/core";

import {
  Button,
  SaveButton,
  BooleanInput,
  useNotify,
  FormWithRedirect,
  TextField,
  useDataProvider,
  useRefresh,
  SaveContextProvider, Confirm, FormDataConsumer,
} from "react-admin";
import IconCancel from "@material-ui/icons/Cancel";
import { DriverReferenceInput } from "../../drivers/components/DriverReferenceInput";
import { PostRequest } from "../../utils/request/PostRequest";
import SaveIcon from "@material-ui/icons/Save";

const styles = {
  outline: {
    border: "1px solid black",
    padding: "3px 5px",
    borderRadius: "5px",
    display: "inline-block",
    marginBottom: "2px",
  },
  error: {
    border: "1px solid black",
    padding: "3px 5px",
    borderRadius: "5px",
    display: "inline-block",
    marginBottom: "2px",
    backgroundColor: "#c82333",
    borderColor: "#bd2130",
    color: "white",
    hover: {
      color: "yellow",
    },
  },
};

function DialogChangeDriver({ children, contracts, setSelectedContracts }) {
  const [showDialog, setShowDialog] = useState(false);
  const dataProvider = useDataProvider();

  const notify = useNotify();

  const handleOpenClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };
  const entrypoint = process.env.REACT_APP_API;
  const [open, setOpen] = useState( false );

  const onSave = async (values) => {
    for( const contract of contracts ) {
      await dataProvider.update("contracts", {
        id: `/api/contracts/${contract}`,
        data: {
          driver: values.driver,
          driverChangedWithMail: values.driverChangedWithMail,
          driverChangedWithStornoMail: values.driverChangedWithStornoMail,
          setDriverForAdditionalRides: values.setDriverForAdditionalRides,        },
      }).then((response) => {
        // success side effects go here
        notify("Auftrag erfolgreich bearbeitet", { type: "success" });
      }).catch((error) => {
        // failure side effects go here
        notify(`Fehler beim bearbeiten: ${error.message}`, { type: "warning" });
      })
    }
    setSelectedContracts(new Set());
  };

  return (
    <SaveContextProvider>
      <Button label={`Fahrer für ${contracts.size} Aufträge wechseln`} variant="contained" onClick={() => handleOpenClick()} style={{ marginTop:"5px" }}/>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick}>
        <DialogTitle>Fahrer für Aufträge setzen</DialogTitle>

        <Divider variant="middle" />

        <FormWithRedirect
          resource="contracts"
          save={onSave}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <DialogContentText
                  style={{ marginBottom: "0", marginTop: "1rem" }}
                >
                  <Typography variant="subtitle2">Neuer Fahrer</Typography>
                </DialogContentText>

                <DriverReferenceInput
                  source={"driver"}
                  displaySetDriverForAdditionalRides={true}
                  setDriverForAdditionalRides={true}
                />

                <BooleanInput
                  label="Storno an alten versenden"
                  source="driverChangedWithStornoMail"
                  initialValue={true}
                  fullWidth
                />

                <BooleanInput
                  label="Auftragsbestätigung an neuen Fahrer versenden"
                  source="driverChangedWithMail"
                  initialValue={true}
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <FormDataConsumer>
                  { ( { formData, ...rest } ) => (
                      formData.driver ? <SaveButton
                          handleSubmitWithRedirect={ handleSubmitWithRedirect }
                          pristine={ pristine }
                          saving={ saving }
                      /> : <Box>
                        <Button
                            title={"Speichern"}
                            color="primary"
                            children={<>
                              <SaveIcon style={{marginRight: '0.5rem', fontSize: '18px'}} />
                              Speichern
                            </>}
                            variant="contained"
                            style={{padding: "6px 16px", fontSize: "0.875rem"}}
                            onClick={() => setOpen(true)}
                        />
                        <Confirm
                            isOpen={open}
                            title={`Fahrer entfernen`}
                            content="Möchten Sie wirklich den Fahrer entfernen und nicht durch einen neuen Fahrer ersetzen? Die Fahrt wird damit zu einer Fahrer Fehlt Fahrt."
                            onConfirm={() => onSave(formData)}
                            onClose={() => setOpen( false )}
                        />
                      </Box>
                  )}
                </FormDataConsumer>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  // disabled={loading}
                >
                  <IconCancel />
                </Button>
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </SaveContextProvider>
  );
}

const DriverFieldView = ({ contracts, setSelectedContracts, classes }) => {
  return (
    <Fragment>
      <DialogChangeDriver contracts={contracts} setSelectedContracts={setSelectedContracts}>
        <Button label={`Fahrer für ${contracts.length} Aufträge wechseln`} variant="contained"/>
      </DialogChangeDriver>
    </Fragment>
  );
};

const DriverFieldOnlyView = ({ record, classes }) => {
  return (
    <Fragment>
      <div className={"driverKuerzel"}>
        {record.driver ? (
          <Tooltip
            placement="right"
            title={<DriverTooltipContent driver={record.driver} />}
            arrow
          >
            <span className={classes.outline}>
              <TextField
                record={record}
                source={"driver.kuerzel"}
                label="Fahrer"
              />
            </span>
          </Tooltip>
        ) : (
          <span className={classes.error}>FF</span>
        )}
      </div>
    </Fragment>
  );
};


export const MultipleDriverField = withStyles(styles)(DriverFieldView);
