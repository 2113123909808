import { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';

const CustomIFrame = (props) => {
  return (
    <iframe
      width="100%"
      frameBorder="0"
      src={props.src}
      style={{ height: '84vh' }}
      {...props}
    ></iframe>
  );
};

const DAMAGE_URL = process.env.REACT_APP_API_DAMAGE_URL

const TabPanel = ( props ) => {
  const { children, value, index, page, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`bodyparts-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Paper style={{ marginTop: '1rem' }}>
          <CustomIFrame
            src={`${DAMAGE_URL}/?page=${page}`}
          />
        </Paper>
      )}
    </div>
  );
};

const BodyPartsPage = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <AppBar position="static" color="inherit" elevation={1}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Bauformen" />
          <Tab label="Bauteile" />
          <Tab label="Schäden" />
          <Tab label="Bauteile Seiten" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} page="bauformen" />
      <TabPanel value={value} index={1} page="bauteile" />
      <TabPanel value={value} index={2} page="schaeden" />
      <TabPanel value={value} index={3} page="bauteile_seiten" />
    </div>
  );
};

export default BodyPartsPage;
