import React, { useState } from "react";
import { Box, ClickAwayListener, Typography } from "@material-ui/core";

export default ( { location, children } ) => {
  const [open, setOpen] = useState( false );

  const handleClick = () => {
    setOpen( ( prev ) => !prev );
  };

  const handleClickAway = () => {
    setOpen( false );
  };

  const styles = {
    color: '#FFF',
    padding: '4px 8px',
    fontSize: '0.625rem',
    maxWidth: '300px',
    minWidth: '200px',
    wordWrap: 'break-word',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '500',
    lineHeight: '1.4em',
    borderRadius: '4px',
    backgroundColor: 'rgba(97, 97, 97, 0.9)',

    position: 'absolute',
    top: 28,
    right: 0,
    left: 0,
    zIndex: 1100,
    border: '1px solid',
    p: 1,
    opacity: open ? '1' : '0',
    transition: 'all 1s',

  };

  if( !location ) {
    return <>{children}</>;
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{ position: 'relative' }}>
        <Box onClick={handleClick}>{children}</Box>

        {open ? (
          <Box sx={styles}>
            <LocationTooltipContent location={location}/>
          </Box>
        ) : null}
      </Box>
    </ClickAwayListener>
  );
};

const LocationTooltipContent = ( { location } ) => {
  return (
    <>
      {location.firma && (
        <Typography variant="body2" align="center">
          {location.firma}
        </Typography>
      )}
      {location.strasse && (
        <Typography variant="body2" align="center">
          {location.strasse}
        </Typography>
      )}
      {location.ort && (
        <Typography variant="body2" align="center">
          {location.ort}
        </Typography>
      )}
      {location.ansprechparter1 && (
        <Typography variant="body2" align="center">
          {location.ansprechparter1}
        </Typography>
      )}
      {location.anspr1telefon && (
        <Typography variant="body2" align="center">
          <a href={`tel:${location.anspr1telefon}`}>
            {location.anspr1telefon}
          </a>
        </Typography>
      )}
    </>
  );
};
