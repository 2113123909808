import RolesList from "./RolesList";
import { EntityCreate, EntityEdit } from "./RolesEdit";

export default {
  name: "roles",
  options: {
    label: "Rollen"
  },
  list: RolesList,
  // show: EntityShow,
  edit: EntityEdit,
  create: EntityCreate,
}
