import Paper from '@material-ui/core/Paper';
import CustomIFrame from '../../utils/components/Iframe';


const JOURNAL_URL = process.env.REACT_APP_API_JOURNAL
const JOURNAL_TOKEN = process.env.REACT_APP_API_JOURNAL_TOKEN

export default ( props ) => {
  const { children, value, index, page, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`journal-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Paper style={{ marginTop: '1rem' }}>
          <CustomIFrame
            src={`${JOURNAL_URL}/?token=${JOURNAL_TOKEN}`}
          />
        </Paper>
      )}
    </div>
  );
};
