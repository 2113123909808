import axios from "axios";

export const GetRequest = async (requestUrl, entity = true) => {
  // try {
    const baseUrl = entity
      ? process.env.REACT_APP_API
      : process.env.REACT_APP_API_ENTRYPOINT;

    const { data } = await axios.get(baseUrl + requestUrl, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/merge-patch+json",
      }    });

    return data;
  // } catch (err) {
  //   return err;
  //   notify( `Es ist ein Fehler aufgetreten:<br /> ${err.message}`, { type: 'warning' } );
  // }
};

const getAddressData = async (customerId) => {
  const address = await GetRequest(customerId);
  return { ...address };
};

const getCurrentUserData = async() => {
  const user = await GetRequest(
    `${process.env.REACT_APP_API_AUTH_USER}`,
    false
  );

  return { ...user };
};

const getProtocolByOrderNumberSearch = async( orderNumber ) => {
  let url = `/api/protocols.json?orderNumber=${orderNumber}`
  const protocols = await GetRequest(
    url,
  );

  return { ...protocols };

}

const getContractsBySearch = async( orderNumber ) => {
  let url = `/api/contracts.json?auftragsnummer=${orderNumber}&specialFilter=`
  const contracts = await GetRequest(
    url,
  );

  return { ...contracts };
};

const getDefaultAccessories = async( idsOnly = true ) => {
  let url = `/api/accessories.json?boolchar_voreingestellt=true`
  let accessories = await GetRequest(
    url
  );

  if( idsOnly ) {
    accessories = accessories.map( function( item ) {
      return item[ '_id' ]
    } )
  }

  return { ...accessories };
};

const getAppVersion = async() => {
  let url = `/api/apps/current`
  const appVersion = await GetRequest(
    url
  )
  return { ...appVersion }
}

const getMailsCount = async() => {
    let url = `/api/mails?isSend=false`
    const mails = await GetRequest(
        url
    )


    return { ...mails }
}

export const getData = async() => {
    let url = `/app/data`
    const data = await GetRequest(
        url
    )


    return { ...data }
}

export { getAddressData, getCurrentUserData, getContractsBySearch, getProtocolByOrderNumberSearch, getDefaultAccessories, getAppVersion, getMailsCount };
