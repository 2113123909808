import { regex, required } from "react-admin";

const validateRequired = required("Pflichtfeld");
const validateZip = [
  required("Pflichtfeld"),
  regex(/^\d{5}$/, "Bitte im Format [12345] eingeben"),
];
const validateTime = [
  required("Pflichtfeld"),
  regex(/^\d{2}:\d{2}$/, "Bitte im Format HH:MM eingeben"),
];

const validateCurrency = [
  regex(/^[0-9]{0,9}\.\d{2}$/, "Bitte im Format 0.00 eingeben")
]

export const validateAdditionalRide = (values) => {
  const errors = {};

  if (values.anschlussAuftrag && !values.abafahrt && !values.abcfahrt ) {
    errors.abafahrt = 'Bei Anschlussfahrt bitte immer ABA oder ABC angeben';
    errors.abcfahrt = 'Bei Anschlussfahrt bitte immer ABA oder ABC angeben';
  }

  return errors
}

export { validateTime, validateZip, validateRequired, validateCurrency };
