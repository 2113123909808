import Paper from '@material-ui/core/Paper';
import CustomIFrame from '../../utils/components/Iframe';
import { Card, CardContent, CardHeader } from "@material-ui/core";


const LOGBOOK_URL = process.env.REACT_APP_API_LOGBOOK
const TOKEN = process.env.REACT_APP_API_JOURNAL_TOKEN

export default ( props ) => {
  const { children, value, index, page, ...other } = props;

  return (
    <Card>
      <CardHeader title={"Fahrtenbuch"} />
      <CardContent>
        <CustomIFrame
          src={`${LOGBOOK_URL}/?token=${TOKEN}`}
        />

      </CardContent>
    </Card>
  );
};
