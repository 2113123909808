import React from "react";
import { SelectInput } from "react-admin";
import { DateInput } from "../../utils/components/DateInput";

export const bundleFilter = [
  <SelectInput choices={[
    { id: false, name: 'Nur Inaktive' },
    { id: true, name: 'Nur Aktive' },
  ]}
               emptyText={"Alle"}
               emptyValue={null}
               label={"Aktiv"}
               source={"active"}
               alwaysOn />,

  <SelectInput choices={[
    { id: false, name: 'Nur ausgeblendete' },
    { id: true, name: 'Nur angezeigte' },
  ]}
               emptyText={"Alle"}
               emptyValue={null}
               label={"In Angebotsliste anzeigen"}
               source={"openForApplications"}
               alwaysOn
  style={{minWidth: "250px"}}/>,

  <SelectInput choices={[
    { id: false, name: 'Nur nicht disponierte' },
    { id: true, name: 'Nur disponierte' },
  ]}
               emptyText={"Alle"}
               emptyValue={null}
               label={"Disponiert"}
               source={"disposed"}
               alwaysOn
  style={{minWidth: "250px"}}/>,

  <DateInput source={"dateFrom"} label={"Mit Aufträgen ab"} alwaysOn />,
  <DateInput source={"dateTo"} label={"Mit Aufträgen bis"} alwaysOn />,
];

export const bundleFilterDefaultValues = {
  active: true,
};
