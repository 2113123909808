import "moment/locale/de";
import React from "react";
import CustomBooleanInput from "../../../utils/components/CustomBooleanInput";
import { BooleanInput } from "react-admin";

export const athlonFilter = [
  <BooleanInput
    label={"Importiert"}
    source="isImported"
    alwaysOn />
];

export const athlonFilterDefaultValues = {
  isImported: false,
  // "abgeschlossen": "0"
};
