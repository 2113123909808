import { FieldGuesser } from "@api-platform/admin";
import React from "react";
import { BooleanField } from 'react-admin';
import HotList from "../utils/components/HotList";

const EmailList = props => (
  <HotList {...props} sort={'sortierungsnummer'}>
        <FieldGuesser source={"name"}/>
        <FieldGuesser source={"betreff"}/>
        <FieldGuesser source={"absenderemail"}/>
        <FieldGuesser source={"absendername"}/>
  </HotList>
);


export default EmailList;
