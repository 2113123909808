import DispositionLocksList from "./DispositionLocksList";
import { EntityCreate, EntityEdit } from "./DispositionLocksEdit";

export default {
  name: "disposition_locks",
  options: {
    label: "Dispositions-Sperren"
  },
  list: DispositionLocksList,
  // show: EntityShow,
  edit: EntityEdit,
  create: EntityCreate,
}
