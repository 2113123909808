import { useEffect, useState, useRef, useCallback } from "react";
import { useForm } from "react-final-form";
import { SelectInput } from "react-admin";
import TextInput from "./TextInput";
import { Box } from "@material-ui/core";
import { validate } from "@material-ui/pickers";

const TimeRangeInputAlternative = (props) => {
  const { record, sourceFrom, sourceTo, ...rest } = props;

  const { change } = useForm();
  const toTimeRef = useRef(null);
  const from = record && record.hasOwnProperty(sourceFrom) ? record[sourceFrom] : "";
  const to = record && record.hasOwnProperty(sourceTo) ? record[sourceTo] : "";

  const [fromTime, setFromTime] = useState(from);
  const [toTime, setToTime] = useState(to);

  const formatTime = (input) => {
    // Extract numeric components from the input using a regular expression
    const match = input.match(/\d+/g);

    if (!match) {
      return input === '' ? '' : "Invalid time format";
    }

    // Extract hours and minutes
    let [hours, minutes = 0] = match.map(Number);

    if(hours > 99) {
      const lastTwoDigits = hours % 100;
      minutes = lastTwoDigits;
      hours = Math.floor(hours / 100);
    }

    // Ensure hours and minutes are two digits
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    // Return the formatted time string
    const formattedTime = `${formattedHours}:${formattedMinutes}`;

    return formattedTime;

  };

  const validateTime = (value) => {

    if (fromTime && toTime) {

      const matchFrom = parseInt(fromTime.replace(":", ""));
      const matchTo = parseInt(toTime.replace(":", ""));

      if (matchFrom && matchTo) {
        if (matchFrom < matchTo) {
          return;
        }
      }
    }
    return "Ungültige Zeit";

  }

  const handleFromTimeChange = (value) => {
    if (value.includes("-")) {
      const split = value.split("-");
      if (split.length > 1 && split[1].length > 0) {
        change(sourceFrom, formatTime(split[0]));
        change(sourceTo, formatTime(split[1]));
      } else {
        toTimeRef.current.focus();
      }
    }
    setFromTime(value);
  };

  const handleToTimeChange = (value) => {
    setToTime(value);
   }


  const handleFromTimeBlur = useCallback((value) => {
    const formattedTime = formatTime(value);
    change(sourceFrom, formattedTime);

  },[formatTime]);

  const handleToTimeBlur = useCallback((value) => {
    const formattedTime = formatTime(value);
     change(sourceTo, formattedTime);
  },[formatTime]);

  return (
    <>
      <Box display="flex">
        <Box flex={1} mr="0.25em">
          <TextInput
            label={"Von"}
            source={sourceFrom}
            onChange={(e) => {
              handleFromTimeChange(e.target.value);
            }}
            onBlur={(e) => {
              handleFromTimeBlur(e.target.value);
            }}
            onFocus={(e) => {
              e.target.select();
            }}
            // validate={validateTime}
            fullWidth
            {...rest}
          />
        </Box>
        <Box flex={1}>
          <TextInput
            label={"Bis"}
            source={sourceTo}
            inputRef={toTimeRef}
            onChange={(e) => {
              handleToTimeChange(e.target.value);
            }}
            onFocus={(e) => {
              e.target.select();
            }}
            onBlur={(e) => {
              handleToTimeBlur(e.target.value);
            }}
            fullWidth
            // validate={validateTime}
            {...rest}
          />
        </Box>
      </Box>
    </>
  );
};

export default TimeRangeInputAlternative;

