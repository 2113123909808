import { SelectArrayInput as DefaultSelectArrayInput } from 'react-admin';
import { withStyles } from '@material-ui/core';


const styles = {
  root: {
    minWidth: "200px",
    marginTop: "8px",
    marginBottom: "4px",
  }
}

const SelectArrayInput = ( props ) => {
  return <DefaultSelectArrayInput
    {...props}
  />;
}

SelectArrayInput.defaultProps = {
  perPage: 200,
}

export default withStyles( styles )( SelectArrayInput );
