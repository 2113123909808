import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Create,
  DeleteWithConfirmButton,
  Edit,
  FormDataConsumer,
  FormTab,
  maxLength,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  useDataProvider,
  usePermissions
} from "react-admin";
import createDecorator from "final-form-calculate";
import { Box, Button, Toolbar, Typography } from "@material-ui/core";
import translateDriverLabel from "./components/TranslateDriverLabel";
import DefaultEditActions from "../utils/DefaultEditActions";
import RefreshButton from "../utils/components/RefreshButton";
import { validateAdditionalRide, validateRequired } from "../utils/validators/DefaultValidators";
import { CustomerReferenceInput } from "../customers/components/CustomerReferenceInput";
import { BillingAddressReferenceInput } from "../billingAddress/components/BillingAddressReferenceInput";
import { DriverReferenceInput, DriverReferenceWithSuggestionInput } from "../drivers/components/DriverReferenceInput";
import { AddressReferenceInput } from "../address/components/AddressReferenceInput";
import AccessoryReferenceInput from "../accessories/Components/AccessoryReferenceInput";
import { ServiceReferenceArrayInput, ServiceReferenceInput } from "../services/components/ServiceReferenceInput";
import SettingsIcon from "@material-ui/icons/Settings";
import EntityLink from "../utils/components/EntityLink";
import TextInput from "../utils/components/TextInput";
import { useForm, useFormState } from "react-final-form";
import CustomBooleanInput from "../utils/components/CustomBooleanInput";
import { CloneContractButton, SendDriverMailButton } from "./components/ListButtons";
import CurrencyInput from "../utils/components/InputComponents/CurrencyInput";
import { InputContainer, ParagraphBox, Spacer } from "../utils/components/FormElements";
import { CalculateSollKMButton } from "./components/CalculateSollKMButton";
import { getGoogleDirections } from "../utils/request/PostRequest";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import { defaultButtonStyle } from "../utils/components/styles/ButtonStyles";
import { ResetNeuMerkerButton } from "./components/ListButtons/ResetNeuMerkerButton";
import { ConfirmButtonGroup } from "./components/ListButtons/ConfirmButton";
import SaveIcon from "@material-ui/icons/Save";
import { CustomSaveButtonWithAdvancedRetreivalCheckButton } from "./components/CustomSaveButtonWithAdvancedRetreivalCheckButton";
import CloneContractAsReturnContractButton from "./components/ListButtons/CloneContractAsReturnContractButton";
import AddressInputBlock from "./components/AddressInputBlock";
import DatePicker from "../utils/components/DatePicker";
import TimeRangeInputAlternative from "../utils/components/TimeRangeInputAlternative";
import { ContractReferenceInput } from "./components/ContractReferenceInput";
import { CancelRideConnectionButton } from "./components/CancelRideConnectionButton";
import { GmapsRouteButton } from "../utils/components/Buttons";
import MapIcon from "@material-ui/icons/Map";
import { CustomerDepartmentReferenceInput } from "../customerDepartment/components/CustomerDepartmentReferenceInput";
import { LeasingProtocolReferenceArrayInput } from "../leasingProtocols/components/LeasingProtocolReferenceInput";


const formatTime = ( time ) => time.toLocaleTimeString( 'de-DE', { timeStyle: 'short' } );

const roundTimeToNearestMin = ( inDt, minutes = 30 ) => {
  const ms = 1000 * 60 * minutes;
  const outDt = new Date( Math.ceil( inDt.getTime() / ms ) * ms );

  return formatTime( outDt );
}

const formState = {
  isFinalFormHooksEnabled: false,
}

const NumberInputWithSubmitOnEnter = ( props ) => {
  return <NumberInput
    step={0.01}
    helperText={false}
    fullWidth
    onKeyDown={async( e ) => {
      if( e.keyCode === 13 ) {
        e.preventDefault();
        document.querySelector( '#submitFormButton' ).click()
      }
    }}

    {...props}
  />
}

const calculator = ( isCreate ) => useMemo( () => createDecorator(
  {
    field: [
      "pickupLocation.strasse",
      "pickupLocation.ort",
      "deliveryLocation.strasse",
      "deliveryLocation.ort",
      "serviceAddresses",
    ], // when address changes...
    // field: /\.strasse/,
    updates: async( value, name, allValues, prevValues ) => {
      const { pickupLocation, deliveryLocation, googleKm, googleFahrzeit, totalGooglefahrtzeitwert, serviceAddresses, editGoogleKm } =
        allValues;
      if( Object.keys( prevValues ).length === 0 ) {
        return { googleKm, googleFahrzeit, totalGooglefahrtzeitwert };
      }

      if(
        pickupLocation &&
        pickupLocation.strasse &&
        pickupLocation.ort &&
        deliveryLocation &&
        deliveryLocation.strasse &&
        deliveryLocation.ort &&
        !editGoogleKm && (
          pickupLocation.strasse !== prevValues.pickupLocation.strasse ||
          pickupLocation.ort !== prevValues.pickupLocation.ort ||
          deliveryLocation.strasse !== prevValues.deliveryLocation.strasse ||
          deliveryLocation.ort !== prevValues.deliveryLocation.ort
        )
      ) {


        const filteredServiceAddress = serviceAddresses ? serviceAddresses.map( ( serviceAddress ) => {
          if( serviceAddress && serviceAddress.city && serviceAddress.street && serviceAddress.houseNumber && serviceAddress.zipCode ) {
            return serviceAddress;
          }
        } ) : [];
        try {
          const { distanceKm, durationFormatted, durationSec } = await getGoogleDirections(
            pickupLocation,
            deliveryLocation,
            filteredServiceAddress
          );
          return {
            googleKm: distanceKm,
            googleFahrzeit: durationFormatted,
            totalGooglefahrtzeitwert: durationSec,
          };
        } catch( error ) {
          console.log( "Error getting distance", error );
          return {
            googleKm: null,
            googleFahrzeit: null,
            totalGooglefahrtzeitwert: null
          };
        }
      }

      return { googleKm, googleFahrzeit, totalGooglefahrtzeitwert };
    },
  },
  {
    field: ['totalGooglefahrtzeitwert', 'pickupTimeTo'],
    updates: ( value, name, allValues ) => {
      if( formState.isFinalFormHooksEnabled === false || !isCreate ) {
        return {};
      }
      const { pickupTimeTo, totalGooglefahrtzeitwert, pickupDateRaw, deliveryDateRaw } = allValues;
      if( !( pickupTimeTo && totalGooglefahrtzeitwert ) || new Date( pickupDateRaw ).valueOf() !== new Date( deliveryDateRaw ).valueOf() ) {
        return {};
      }

      const deliveryTimeFrom = new Date( `1970-01-01 ${pickupTimeTo}:00` );
      const offset = 30 * 60;

      deliveryTimeFrom.setSeconds( deliveryTimeFrom.getSeconds()+totalGooglefahrtzeitwert+offset );

      return {
        deliveryTimeFrom: roundTimeToNearestMin( deliveryTimeFrom )
      };
    }
  },
  {
    field: ['pickupTimeFrom', 'deliveryTimeFrom'],
    updates: ( value, name, allValues ) => {
      if( formState.isFinalFormHooksEnabled === false || !isCreate ) {
        return {};
      }
      const timeFrom = new Date( `1970-01-01 ${value}:00` );
      timeFrom.setHours( timeFrom.getHours()+2 );

      const timeTo = name.replace( 'From', 'To' );
      if( allValues[ name.replace( 'From', 'To' ) ] ) {
        return {};
      }

      return {
        [ timeTo ]: roundTimeToNearestMin( timeFrom )
      };
    }
  }
), [] );

const CustomerReferenceInputWithServices = (props) => {
  const {isCreate} = props;
  const [customer, setCustomer] = useState( null );
  const [trackedServices, setTrackedServices] = useState( [] );
  const { values } = useFormState( { subscription: { values: true } } );
  const { change } = useForm();
  const dataProvider = useDataProvider();

  // Keep track of the previous value
  const prevContractClientAddressRef = useRef();
  useEffect( () => {
    prevContractClientAddressRef.current = values.contractClientAddress;
  } );
  const prevContractClientAddress = prevContractClientAddressRef.current;

  const fetchData = async( customerId ) => {
    const cc = await dataProvider.getOne( 'customers', { id: customerId } );
    setCustomer( cc.data );
  }

  // fetches the case that only the iri is set for the customer and loads the customer afterward
  useEffect( async() => {
    if( values.contractClientAddress && values.contractClientAddress !== prevContractClientAddress && typeof values.contractClientAddress === 'string' ) {
      fetchData( values.contractClientAddress );
    }
  }, [values.contractClientAddress] );

  const addService = ( serviceId ) => {
    if( trackedServices.includes( serviceId ) ) {
      return;
    }

    const id = serviceId.replace( '/api/services/', '' ) * 1;

    const newServiceList = [...new Set( [id, ...values.zusatzleistungenArray ?? []] )];
    change( 'zusatzleistungenArray', newServiceList );
    setTrackedServices( [...trackedServices, serviceId] );
  }

  useEffect( () => {
    if( customer && customer.defaultServices) {
      for( const defaultService of customer.defaultServices ) {
        if( defaultService?.conditions?.length ) {
          let result = true
          for( const condition of defaultService.conditions ) {
            let address = values[ condition.field ]

            if( typeof address !== "undefined" ) {
              const mandatoryFields = [
                'ort',
                'firma',
                'strasse'
              ]

              let allFieldsSet = true

              for( const field of mandatoryFields ) {
                if( !address.hasOwnProperty( field ) || !address[ field ] ) {
                  allFieldsSet = false
                  break
                }
              }

              if( !allFieldsSet ) {
                result = false
                continue
              }

              const field = values[ condition.field ]
              if( condition.condition === 'eq' ) {
                result &= field?.hasOwnProperty( '@id' ) && field[ '@id' ] === condition.value
              }

              if( condition.condition === 'neq' ) {
                result &= !field?.hasOwnProperty( '@id' ) || ( field?.hasOwnProperty( '@id' ) && field[ '@id' ] !== condition.value )
              }
            } else {
              result = false
            }
          }

          if( result ) {
            addService( defaultService.service );
          }

        } else {
          addService( defaultService.service );
        }
      }
    }

  }, [customer, values.pickupLocation, values.deliveryLocation] );

  useEffect( () => {
    if( customer && isCreate ) {
      change( 'sendProtocolToContractContact', customer.sendProtocolToContractContact );
      if( customer.defaultLeasingProtocol ) {
        const id = customer.defaultLeasingProtocol;
        const newLeasingProtocolList = [...new Set( [id, ...values.leasingProtocols ?? []] )];
        change( 'leasingProtocols', newLeasingProtocolList );
      }
    }
  }, [customer] );


  return <CustomerReferenceInput source={"contractClientAddress"}/>;
}

export const getFilter = ( source, filterBy ) => {
  if( source ) {
    if( typeof source === 'string' ) {
      return { [ filterBy ]: parseInt( source.split( '/' )[ 3 ] ) };
    }
    if( typeof source === 'object' && !Array.isArray( source ) && source[ '@id' ] ) {
      return { [ filterBy ]: parseInt( source[ '@id' ].split( '/' )[ 3 ] ) };
    }
  }

  return {};
};

const ChangeInfoTransferNumberPlateButton = () => {
  const { change } = useForm();

  const nl = '\n'
  const onClick = () => {
    change(
      'infoTransferNumberPlate',
      '1. Fahrzeugklasse und Art des Aufbaus:'+nl+nl+
      '2. Hersteller-Kurzbezeichnung:'+nl+nl+
      '3. Fahrzeug-Identifikationsnummer:'+nl+nl+
      '4. Hubraum in cm3:'+nl+
      '4. Leistung in kW:'+nl+nl+
      '5. Datum der Erstzulassung:'+nl+nl+
      '6. Zulässige Gesamtmasse in kg:'+nl+nl+
      '7. Zulässige Achslasten in kg:'+nl+
      'Achse 1:'+nl+
      'Achse 2:'+nl+nl+
      '8. Höchstgeschwindigkeit in km/h:'
    );
  }

  return <Button color={"primary"} onClick={onClick}>Standard-Text für Ü-Kennzeichen übernehmen</Button>;
}

const ContractForm = ( allProps ) => {
  const { permissions } = usePermissions()


  useEffect( () => {
    return () => {
      formState.isFinalFormHooksEnabled = false;
    }
  } );

  useEffect(() => {

  }, ['customer'])

  const { isCreate, basePath, ...props } = allProps;

  return (
    <TabbedForm
      decorators={[calculator( isCreate )]}
      validate={validateAdditionalRide}
      {...props}
    >
      <FormTab label={"Auftragsdaten"}>
        <Box p="1em" style={{ margin: "0 0 40px 0", width: "100%" }}>
          <Box mb="1rem" display="flex">
            <Box flex={2} mr="1em">
              <Box display="flex" alignItems={"center"}>
                <Box flex={1} mr="0.5em" style={{ minWidth: "200px" }}>
                  <TextInput
                    source="auftragsnummer"
                    label={"Auftragsnummer"}
                    fullWidth
                    disabled={true}
                  />
                </Box>
                <Box flex={1}>

                      <RadioButtonGroupInput
                    choices={[
                          { id: "PICKUP", name: "Rückholung" },
                          { id: "DELIVERY", name: "Auslieferung" },
                    ]}
                    source="contractType"
                    label={"Auftragstyp"}
                    helperText={false}
                    fullWidth
                  />
                </Box>
                <Box flex={4} ml="0.5em">
                  <Box display="flex" alignItems={"center"}>
                    <Box flex={1}>
                      <BooleanInput
                        source="isStorniert"
                        label={"Auftrag storniert"}
                        helperText={false}
                        fullWidth
                      />
                    </Box>

                    <Box flex={1}>
                      <BooleanInput
                        source="abgeschlossen"
                        label={"Auftrag abgeschlossen"}
                        helperText={false}
                        fullWidth
                      />
                    </Box>

                    <Box flex={1}>
                      <BooleanInput
                        source={"testContract"}
                        label={"Testauftrag"}
                        helperText={false}
                        fullWidth
                      />
                    </Box>
                    <Box flex={1}>
                      <BooleanInput
                        source="noDamageControl"
                        label={"Schadenskontrolle entfällt"}
                        helperText={false}
                        fullWidth
                      />
                    </Box>
                        {!props.isCreate && <Box flex={1}>
                      <ConfirmButtonGroup record={props.record}/>
                        </Box>}
                  </Box>
                </Box>
              </Box>

              <FormDataConsumer>
                {( { formData } ) => (
                  <Box display={"flex"} alignItems={"center"}>

                        <Box flex={1} mr={"0.5rem"}>

                        {props.record.anschlussAuftrag ?
                         <ContractReferenceInput
                        source={"anschlussAuftrag"}
                        label={"Anschlussauftrag"}
                           disabled={true} /> : <ContractReferenceInput
                           source={"anschlussvon"}
                           label={"Anschluss von"}
                           resettable={false} />
                         }
                    </Box>
                        <Box flex={1} ml={"0.5rem"}>
                          <Box display={"flex"} alignItems={"center"}>
                           <Box flex={1} mr="0.5rem">
                             <BooleanInput source={"abafahrt"} label={"ABA"} helperText={false}/>
                           </Box>
                            <Box flex={1} mr="0.5rem">
                             <BooleanInput source={"abcfahrt"} label={"ABC"} helperText={false}/>
                           </Box>
                            <Box flex={1}>
                              <CancelRideConnectionButton record={props.record} disabled={!props.record?.anschlussvon && !props.record?.anschlussAuftrag}/>
                             {/*<ConfirmButton record={props.record} sendConfirmationEmail={true} title={"Auftrag bestätigen und E-Mail senden"} buttonText={"Auftrag bestätigen"}/>*/}
                           </Box>
                         </Box>
                       </Box>
                  </Box>
                )}
              </FormDataConsumer>

              <Box display="flex">
                    <Box flex={1} mr="0.25em">
                      <CustomerReferenceInputWithServices isCreate={props.isCreate}/>
                </Box>

                    <Box flex={1} mr="0.25em" ml={"0.25em"}>
                  <FormDataConsumer>
                    {( { formData } ) => (
                      <BillingAddressReferenceInput
                        filter={getFilter( formData.contractClientAddress, 'adressbesitzer' )}
                        source={"billingClientAddress"}/>
                    )}
                  </FormDataConsumer>
                </Box>
                    <Box flex={1} ml={"0.25em"}>
                      <FormDataConsumer>
                        {({ formData }) => (
                          <CustomerDepartmentReferenceInput
                            source={"customerDepartment"}
                            filter={getFilter(formData.contractClientAddress, 'customer')}
                          />
                        )}
                      </FormDataConsumer>
                      {/*<ReferenceInput */}
                      {/*  reference="customer_departments" */}
                      {/*  source="customerDepartment" */}
                      {/*  label="Abteilung" */}
                      {/*  fullWidth */}
                      {/*  helperText={false}>*/}
                      {/*  <SelectInput optionText="name" />*/}
                      {/*</ReferenceInput>*/}
                    </Box>
              </Box>

              <Box display="flex">
                <Box flex={1} mr="0.5em">
                  <TextInput
                    source={"leasingVertragsnummer"}
                    label={"Leasingvertrags-Nr."}
                    fullWidth
                  />
                </Box>
                    <Box flex={1}>
                      <LeasingProtocolReferenceArrayInput source={"leasingProtocols"} />
                </Box>
              </Box>

              <Box display="flex">
                <Box flex={2} mr={"0.5em"}>
                  <FormDataConsumer>
                    {( { formData } ) => (
                      <AddressReferenceInput
                        source={"leistungsadresse"}
                        label={"Leistungsempf\xe4nger"}
                        filter={getFilter( formData.contractClientAddress, 'allowedCustomers' )}
                      />
                    )}
                  </FormDataConsumer>

                </Box>
                    <Box flex={1}>
                  <TextInput
                    source={"kostenstelle"}
                    label={"Kostenstelle"}
                    fullWidth
                  />
                </Box>
              </Box>

              <Box display={"flex"}>
                {permissions && ( permissions.includes( 'vermaco_admin' ) || permissions.includes( 'vermaco_buchhaltung' ) || permissions.includes( 'vermaco_dispo' ) ) && <>
                     <Box flex={1} mr={"0.25rem"}>
                    <TextInput
                      multiline
                      fullWidth
                      source={"rechnungsnotiz"}
                      label={"Rechnungsnotiz"}
                    />
                  </Box>
                    <Box flex={1} ml={"0.25rem"} mr={"0.25rem"}>
                    <TextInput
                      multiline
                      fullWidth
                      source={"gutschriftnotiz"}
                      label={"Gutschriftnotiz"}
                    />
                  </Box>
                </>}
                    <Box flex={1} ml={"0.25rem"} mr={"0.25rem"}>
                  <TextInput
                    multiline
                    fullWidth
                    source={"infoBackend"}
                    label={"Interne Notizen"}
                  />
                    </Box>
                    <Box flex={1} ml={"0.25rem"}>
                      <TextInput
                        multiline
                        fullWidth
                        source={"infoShared"}
                        label={"Mit VM geteilt"}
                      />
                </Box>
              </Box>

              <Spacer/>

              <Typography variant="h5" gutterBottom>
                Adresseingabe
              </Typography>

              <Box display={"flex"} mb={"1rem"}>
                <Box flex={2} mr="0.5rem">
                  <AddressInputBlock source={"pickupLocation"} type="pickup" record={props.record}/>
                </Box>

                <Box flex={2}>
                  <AddressInputBlock source={"deliveryLocation"} type="delivery" record={props.record}/>
                </Box>
              </Box>

              <Box display={"flex"}>
                <Box flex={1} mr={'0.25rem'}>
                  <FormDataConsumer>
                    {( { formData } ) => (
                      <BooleanInput
                        source={`pickupLocation.saveLocation`}
                        label={"Adresse für weitere Aufträge speichern"}
                        disabled={!!formData?.pickupLocation?.source}
                        helperText={false}/>
                    )}
                  </FormDataConsumer>

                  <Box display={"flex"}>
                    <InputContainer left>
                      <div className={`MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth`} style={{ marginRight: "2px" }}>
                        <div style={{ zIndex: "2" }}
                             className={"MuiInputBase-root MuiFilledInput-root MuiFilledInput-underline MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-marginDense MuiFilledInput-marginDense"}>
                          <label className={"MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense MuiInputLabel-filled MuiFormLabel-filled"}>
                            Abholdatum
                          </label>

                          <DatePicker fieldName={`pickupDate`}/>
                        </div>
                      </div>
                    </InputContainer>
                    <InputContainer right>
                      <TimeRangeInputAlternative record={props.record} sourceFrom={`pickupTimeFrom`} sourceTo={`pickupTimeTo`}/>
                    </InputContainer>
                  </Box>

                  <TextInput
                    multiline
                    fullWidth
                    source={'abholinfos'}
                    label={`AbholInfos`}
                  />
                </Box>

                <Box flex={1} ml={'0.25rem'}>
                  <FormDataConsumer>
                    {( { formData } ) => (
                      <BooleanInput
                        source={`deliveryLocation.saveLocation`}
                        label={"Adresse für weitere Aufträge speichern"}
                        disabled={!!formData?.deliveryLocation?.source}
                        helperText={false}/>
                    )}
                  </FormDataConsumer>

                  <Box display={"flex"}>
                    <InputContainer left>
                      <div className={`MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth`} style={{ marginRight: "2px" }}>
                        <div style={{ zIndex: "2" }}
                             className={"MuiInputBase-root MuiFilledInput-root MuiFilledInput-underline MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-marginDense MuiFilledInput-marginDense"}>
                          <label className={"MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense MuiInputLabel-filled MuiFormLabel-filled"}>
                            Zustelldatum
                          </label>

                          <DatePicker fieldName={`deliveryDate`}/>
                        </div>
                      </div>
                    </InputContainer>
                    <InputContainer right>
                      <TimeRangeInputAlternative record={props.record} sourceFrom={`deliveryTimeFrom`} sourceTo={`deliveryTimeTo`}/>
                    </InputContainer>
                  </Box>

                  <TextInput
                    multiline
                    fullWidth
                    source={'bringinfos'}
                    label={`Bringinfos`}
                  />
                </Box>
              </Box>

                  <Box display={"flex"}>
                    <BooleanInput source={"sendProtocolToContractContact"} label={"Protokoll an Ansprechpartner senden"} />
                  </Box>

              <Spacer/>

              <Typography variant="h5" gutterBottom>
                Fahrzeugdaten
              </Typography>

              <Box display={"flex"}>
                <Box flex={1} mr="0.5rem">
                  <Typography variant="h6" gutterBottom>
                    Fahrzeug
                  </Typography>

                  <Box display="flex">
                    <Box flex={1} mr="0.5em">
                      <TextInput
                        source="manufacturer"
                        label={"Hersteller"}
                        fullWidth
                      />
                    </Box>

                    <Box flex={1} ml="0.5em">
                      <TextInput
                        source="carModel"
                        label={"Fahrzeugtyp"}
                        fullWidth
                        validate={isCreate && validateRequired}
                      />
                    </Box>
                  </Box>

                  <Box display="flex">
                    <Box flex={1} mr="0.5em">
                      <TextInput
                        source="licensePlate"
                        label={"Nummernschild"}
                        fullWidth
                      />
                    </Box>
                    <Box flex={1} ml="0.5em">
                      <TextInput source="carFin" label={"FIN"} validate={maxLength( 17 )} fullWidth/>
                    </Box>
                  </Box>

                  <BooleanInput
                    source={"verzoegerteAbgabe"}
                    label={"KFZ Vollelektrisch"}
                    fullWidth
                    helperText={false}
                  />

                  <br/>

                  <Box display="flex" alignItems={"center"}>
                    <Box flex={1} mr="0.5em">
                      <TextInput source="tuev" label={"Tuev f\xe4llig"} fullWidth/>
                    </Box>
                    <Box flex={1}>

                    </Box>
                  </Box>

                  <Box display="flex" alignItems={"center"}>
                    <Box flex={1} mr="0.5em">
                      <RadioButtonGroupInput
                        source="angemeldet"
                        label={"Angemeldet"}
                        defaultValue={true}
                        optionValue={"value"}
                        choices={[
                          { id: "0", value: false, name: "Nein" },
                          { id: "1", value: true, name: "Ja" },
                        ]}
                        helperText={false}
                      />
                    </Box>
                    <Box flex={1}>

                    </Box>
                  </Box>

                  <FormDataConsumer>
                    {( { formData } ) => (
                      !formData.angemeldet && <>
                        <Box display="flex" alignItems={"center"}>
                          <Box flex={1} mr="0.5em">
                            <TextInput source={"alternativeLicensePlate"} label={"Ü-Kennzeichen"} fullWidth/>
                          </Box>
                          <Box flex={1}>

                          </Box>
                        </Box>

                        <br/>
                        <ChangeInfoTransferNumberPlateButton/>
                        <TextInput source="infoTransferNumberPlate" label={"Hinweise zum Überführungskennzeichen"} multiline={true} fullWidth/>
                      </>
                    )}
                  </FormDataConsumer>
                </Box>


                <Box flex={1} ml="0.5rem">
                  <Typography variant="h6" gutterBottom>
                    Bereifung
                  </Typography>

                  <Box display="flex">
                    <Box flex={1} mr="0.5em">
                      <SelectInput
                        source={"bereifung"}
                        label={"Bereifung"}
                        choices={[
                          { id: "Sommer", name: "Sommer" },
                          { id: "Winter", name: "Winter" },
                          { id: "Ganzjahres", name: "Ganzjahres" },
                        ]}
                        fullWidth
                        helperText={false}
                      />
                    </Box>

                    <Box flex={1} mr="0.5em">
                      <SelectInput
                        source={"felge"}
                        label={"Felge"}
                        choices={[
                          { id: "Stahl", name: "Stahl" },
                          { id: "Alu", name: "Alu" },
                        ]}
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                  </Box>

                  <Box display="flex">
                    <Box flex={1} mr="0.5em">
                      <SelectInput
                        source={"zusatzBereifung"}
                        emptyText={"Keine zus\xe4tzliche Bereifung"}
                        allowEmpty={true}
                        label={"Zus\xe4tzliche Bereifung"}
                        choices={[
                          { id: "Sommer", name: "Sommer" },
                          { id: "Winter", name: "Winter" },
                          { id: "Ganzjahres", name: "Ganzjahres" },
                        ]}
                        fullWidth
                      />
                    </Box>

                    <Box flex={1} mr="0.5em">
                      <SelectInput
                        source={"zusatzFelge"}
                        emptyText={"Keine zus\xe4tzliche Felge"}
                        allowEmpty={true}
                        label={"Zus\xe4tzliche Felge"}
                        choices={[
                          { id: "Stahl", name: "Stahl" },
                          { id: "Alu", name: "Alu" },
                          { id: "None", name: "Keine" },
                        ]}
                        fullWidth
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box flex={1} mr={"1em"}>
              <Typography variant="h6" gutterBottom>
                Fahrer
              </Typography>

              <Box display={"flex"} alignItems={"center"}>
                <Box flex={1} display={"flex"} mr={"0.25em"}>
                  <SendDriverMailButton
                    id={props.record._id}
                    IsFahrerMailGesendet={props.record.isFahrerMailGesendet}
                    disabled={props.record.driver ? null : true}
                    style={{ ...defaultButtonStyle }}/>
                </Box>
                <Box flex={8} mr="0.5em">
                  <DriverReferenceWithSuggestionInput
                    suggestions={props.record.appliedDrivers}
                    source={"driver"}
                    setDriverForAdditionalRides={props.record.setDriverForAdditionalRides}
                  />
                </Box>

                <Box flex={8}>
                  <DriverReferenceInput source={"benutzer"} label={"App Benutzer"}>
                    <AutocompleteInput
                      optionText={( record ) => translateDriverLabel( record )}
                      source={"benutzer.name"}
                      optionValue={"@id"}
                    />
                  </DriverReferenceInput>
                </Box>
                {props.record.anschlussAuftrag && <Box flex={10} ml="0.5em">
                  <BooleanInput helperText={false} source={"setDriverForAdditionalRides"} label={"Auch für Anschluss setzen"}/>
                </Box>}
              </Box>
              <Box display={"flex"}>
                <Box flex={3}>
                  <BooleanInput
                    label="Storno an alten versenden"
                    source="driverChangedWithStornoMail"
                    initialValue={false}
                    helperText={false}
                    fullWidth
                  />
                </Box>
                <Box flex={5}>
                  <BooleanInput
                    label="Auftragsbestätigung an neuen Fahrer versenden"
                    source="driverChangedWithMail"
                    initialValue={false}
                    helperText={false}
                    fullWidth
                  />
                </Box>
              </Box>

              <FormDataConsumer>
                {( { formData } ) => {
                  const { change } = useForm();
                  const handleApplicationChange = async( applicationId ) => {
                    const currentApplication = props.record.openApplications.filter( ( application ) => {
                      return application[ '@id' ] === applicationId
                    } )

                    change( "driver['@id']", currentApplication[ 0 ][ "driver" ][ '@id' ] );

                  };

                  return !formData.driver && typeof formData.openApplications !== "undefined" && formData.openApplications?.length > 0 && <ReferenceInput
                    source={"openApplications"}
                    reference={"applications"}
                    label={"Beworbene Fahrer"}
                    fullWidth
                    onChange={( event ) => {
                      return event.target.value ? handleApplicationChange( event.target.value ) : null
                    }}
                    helperText={false}
                    format={( v ) => v[ '@id' ]}
                    filter={{ contract: formData.id, status: 'pending' }}
                  >
                    <SelectInput optionText={( application ) => {
                      return application.driver.name
                    }}/>
                  </ReferenceInput>
                }
                }
              </FormDataConsumer>

              <TextInput
                source={"infoDriver"}
                label={"Info Fahrer"}
                fullWidth
              />

              <Box display="flex">
                <Box flex={1} mr="0.5em">
                  <BooleanInput
                    source="sperrenFuerAngebotsliste"
                    label={"Für Angebotsliste sperren"}
                  />
                </Box>
                <Box flex={1} ml="0.5em">
                  <ResetNeuMerkerButton record={props.record} isCreate={isCreate}/>
                </Box>
              </Box>

              <Box mt="1rem">
                <Typography variant="h6" gutterBottom>
                  Sonstiges
                </Typography>

                <Box display="flex" alignItems={"center"}>
                  <Box flex={2} mr="0.5em">
                    <ServiceReferenceArrayInput/>
                  </Box>

                  <Box flex={1} ml="0.5em">
                    <CustomBooleanInput source={"waschen"} label={"Waschen"}/>
                  </Box>
                </Box>

                <Box display="flex" alignItems={"center"}>

                  <ArrayInput source="serviceAddresses" label={"Zusatzleistungen mit Adresse"} fullWidth>
                    <SimpleFormIterator>
                      <FormDataConsumer>
                        {( { getSource } ) => {
                          return <Box style={{ width: "100%" }}>
                            <Box display={"flex"}>
                              <ServiceReferenceInput source={getSource( "service" )} filter={{ boolchar_anschriftnotwendig: true }} helperText={false}/>
                            </Box>
                            <Box display={"flex"}>
                              <Box flex={1} mr={"0.5rem"}>
                                <TextInput source={getSource( "contactPerson" )} label={"Name"} fullWidth/>
                              </Box>
                              <Box flex={1} mr={"0.5rem"}>
                                <TextInput source={getSource( "company" )} label={"Firma"} fullWidth/>
                              </Box>
                              <Box flex={1}>
                                <TextInput source={getSource( "phone" )} label={"Telefonnummer"} fullWidth/>
                              </Box>
                            </Box>
                            <Box display={"flex"}>
                              <Box flex={1} mr={"0.5rem"}>
                                <TextInput source={getSource( "street" )} label={"Strasse"} fullWidth/>
                              </Box>
                              <Box flex={1} mr={"0.5rem"}>
                                <TextInput source={getSource( "houseNumber" )} label={"Haunummer"} fullWidth/>
                              </Box>
                              <Box flex={1} mr={"0.5rem"}>
                                <TextInput source={getSource( "zipCode" )} label={"PLZ"} fullWidth/>
                              </Box>
                              <Box flex={1}>
                                <TextInput source={getSource( "city" )} label={"Ort"} fullWidth/>
                              </Box>
                            </Box>
                            <Box>
                              <TextInput source={getSource( "info" )} label={"Info"} fullWidth/>
                            </Box>

                          </Box>
                        }}
                      </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>
                </Box>

                <AccessoryReferenceInput/>

                <FormDataConsumer>
                  {( { formData } ) =>
                    formData.zubehoerArray && formData.zubehoerArray.includes( 4 ) && (
                                             <NumberInput
                                               source="anzahlSchluessel"
                                               label={"Anzahl Schlüssel"}
                                               type={"number"}
                                               min={0}
                                               max={5}
                                               // validate={isCreate && validateRequired}
                                               fullWidth
                                               allowEmpty={true}

                                               resettable={true}
                                             />
                                           )
                  }
                </FormDataConsumer>

                <FormDataConsumer>
                  {( { formData } ) =>
                    formData.zubehoerArray && formData.zubehoerArray.includes( 7 ) && (
                                             <NumberInput
                                               source="anzahlTankkarten"
                                               label={"Anzahl Tankkarten"}
                                               type={"number"}
                                               min={0}
                                               max={5}
                                               // validate={isCreate && validateRequired}
                                               fullWidth
                                             />
                                           )
                  }
                </FormDataConsumer>

                <FormDataConsumer>
                  {( { formData } ) =>
                    formData.zubehoerArray && formData.zubehoerArray.includes( 33 ) && (
                                             <NumberInput
                                               source="anzahlLadekabel"
                                               label={"Anzahl Ladekabel"}
                                               type={"number"}
                                               min={0}
                                               max={5}
                                               // validate={isCreate && validateRequired}
                                               fullWidth
                                             />
                                           )
                  }
                </FormDataConsumer>
              </Box>

              <Spacer/>

              <Typography variant="h6" gutterBottom>
                Soll Kilometer & Fahrzeit
              </Typography>

              <Box display={"flex"}>
                <Box flex={2}>
                  <NumberInputWithSubmitOnEnter
                    source="sollkm"
                    label={"Soll KM"}/>
                </Box>

                <FormDataConsumer>
                  {( { formData } ) => (
                    <Box flex={1} ml={"0.5rem"} mr={"0.5rem"} display={"flex"} alignItems={"center"}>
                      <CalculateSollKMButton record={formData}/>
                    </Box>
                  )}
                </FormDataConsumer>

                <Box flex={2}>
                  <NumberInput
                    source="istkm"
                    label={"Ist KM"}
                    helperText={false}
                    fullWidth
                    disabled
                  />
                </Box>
              </Box>

              <FormDataConsumer>
                {( { formData } ) => (
                  <Box display={"flex"} alignContent={"center"} alignItems={"center"}>
                        <Box flex={2}>
                      <NumberInputWithSubmitOnEnter
                        source="googleKm"
                        label={"Google Soll-KM"}
                        step={0.01}
                        helperText={false}
                        disabled={!formData.editGoogleKm}
                        fullWidth
                      />
                    </Box>
                        <Box flex={1} ml={"0.5rem"} mr={"0.5rem"} >
                          <GmapsRouteButton
                            pickupLocation={formData.pickupLocation}
                            deliveryLocation={formData.deliveryLocation}>
                            <>
                              <MapIcon/> <span style={{textAlign: "center"}}>Route anzeigen</span>
                            </>
                          </GmapsRouteButton>
                        </Box>

                        <Box flex={2}>
                      <BooleanInput source="editGoogleKm" label={"Manuell eingeben"} helperText={false}/>
                    </Box>
                  </Box>
                )}
              </FormDataConsumer>

              <TextInput
                source="googleFahrzeit"
                label={"Fahrzeit"}
                disabled={true}
                fullWidth
              />
              <NumberInput
                style={{ display: 'none' }}
                source="totalGooglefahrtzeitwert"
                disabled={true}
              />

              <ParagraphBox alignContent={"center"} alignItems={"center"}>
                <InputContainer left>
                  <FormDataConsumer>
                    {( { formData } ) => (
                      <NumberInputWithSubmitOnEnter
                        source="billingDistance"
                        label={"Rechnungskilometer"}
                        step={0.01}
                        helperText={false}
                        disabled={!formData.billingDistanceSet}
                        fullWidth
                      />
                    )}
                  </FormDataConsumer>
                </InputContainer>
                <InputContainer right>
                  <BooleanInput source="billingDistanceSet" helperText={false} label={"Manuell eingeben"}/>
                </InputContainer>
              </ParagraphBox>

              <ParagraphBox alignContent={"center"} alignItems={"center"}>
                <InputContainer left>
                  <FormDataConsumer>
                    {( { formData } ) => (
                      <NumberInputWithSubmitOnEnter
                        source="creditDistance"
                        label={"Gutschriftkilometer"}
                        step={0.01}
                        helperText={false}
                        disabled={!formData.creditDistanceSet}
                        fullWidth
                      />
                    )}
                  </FormDataConsumer>
                </InputContainer>
                <InputContainer right>
                  <BooleanInput source="creditDistanceSet" helperText={false} label={"Manuell eingeben"}/>
                </InputContainer>
              </ParagraphBox>

              {permissions && ( permissions.includes( 'vermaco_admin' ) || permissions.includes( 'vermaco_buchhaltung' ) || permissions.includes( 'vermaco_dispo' ) ) && <>
                <Spacer/>
                <Typography variant="h6" gutterBottom>
                  Auftragswert
                </Typography>

                <ParagraphBox alignContent={"center"} alignItems={"center"}>
                  <InputContainer left>
                    <FormDataConsumer>
                      {( { formData } ) => (
                        <CurrencyInput
                          source={"auftragswert"}
                          label="Auftragswert"
                          disabled={!formData.auftragswertSet}
                          onKeyDown={async( e ) => {
                            if( e.keyCode === 13 ) {
                              e.preventDefault();
                              document.querySelector( '#submitFormButton' ).click()
                            }
                          }} fullWidth/>
                      )}
                    </FormDataConsumer>
                  </InputContainer>
                  <InputContainer right>
                    <BooleanInput source="auftragswertSet" helperText={false} label={"Manuell eingeben"}/>
                  </InputContainer>
                </ParagraphBox>

                <ParagraphBox alignContent={"center"} alignItems={"center"}>
                  <InputContainer left>
                    <FormDataConsumer>
                      {( { formData } ) => (
                        <CurrencyInput
                          source={"gutschriftpreis"}
                          label="Gutschriftwert"
                          disabled={!formData.gutschriftpreisSet}
                          onKeyDown={async( e ) => {
                            if( e.keyCode === 13 ) {
                              e.preventDefault();
                              document.querySelector( '#submitFormButton' ).click()
                            }
                          }} fullWidth/>
                      )}
                    </FormDataConsumer>
                  </InputContainer>
                  <InputContainer right>
                    <BooleanInput source="gutschriftpreisSet" helperText={false} label={"Manuell eingeben"}/>
                  </InputContainer>
                </ParagraphBox>
              </>}
            </Box>
          </Box>
        </Box>
      </FormTab>
      <FormTab label={"Historie"}>
        <Box p={1} style={{ width: "100%" }}>

          <Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Datum</TableCell>
                  <TableCell>Benutzer</TableCell>
                  <TableCell>Feld</TableCell>
                  <TableCell>Änderung von</TableCell>
                  <TableCell>Änderung zu</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.record.logHistory && props.record.logHistory.map( ( logEntry, index ) => {
                  return <TableRow key={index}>
                    <TableCell>{logEntry.logged_at}</TableCell>
                    <TableCell>{logEntry.username}</TableCell>
                    <TableCell>{logEntry.field}</TableCell>
                    <TableCell>
                      {logEntry.previous && <>
                        <HistoryField source={`logHistory[${index}].previous`} logEntry={logEntry}/>
                      </>}
                    </TableCell>
                    <TableCell>
                      <HistoryField source={`logHistory[${index}].data`} logEntry={logEntry}/>
                    </TableCell>
                  </TableRow>
                } )}
              </TableBody>
            </Table>

          </Box>
        </Box>
      </FormTab>
    </TabbedForm>
  );
};

const HistoryField = ( { source, logEntry } ) => {
  if( ['appUser', 'abholer'].includes( logEntry.slug ) ) {
    return <ReferenceField reference="drivers" source={source}>
      <TextField source="name"/>
    </ReferenceField>
  }

  return <TextField source={source}/>
}

const ContractToolbar = props => {
  const { isCreate } = props;
  return <Toolbar
    style={{
      position: "fixed",
      zIndex: 100000,
      bottom: 10,
      maxWidth: "100%",
      backgroundColor: "rgba(255,255,255, 0.8)",
      borderRadius: "5px",
    }}
  >
    {isCreate ?
     <Box width="100%">
       <CustomSaveButtonWithAdvancedRetreivalCheckButton
         saving={props.saving}
         handleSubmitWithRedirect={props.handleSubmitWithRedirect}
         label={"Auftrag erstellen"}
         style={{ marginRight: "0.5rem" }}
         isCreate={isCreate}
       ><SaveIcon style={{ marginRight: '0.5rem', fontSize: '18px' }}/> Auftrag erstellen</CustomSaveButtonWithAdvancedRetreivalCheckButton>
       {props.record.createBundleWith && <CustomSaveButtonWithAdvancedRetreivalCheckButton
         saving={props.saving}
         handleSubmitWithRedirect={props.handleSubmitWithRedirect}
         label={`Auftrag im Paket mit ${props.record.createBundleContractNumber} erstellen`}
         style={{ marginRight: "0.5rem" }}
         isCreate={isCreate}
         createBundleWithContract={props.record.createBundleWith}
       ><SaveIcon style={{ marginRight: '0.5rem', fontSize: '18px' }}/> Auftrag erstellen</CustomSaveButtonWithAdvancedRetreivalCheckButton>}
     </Box> :
     <Box width="100%">
       <CustomSaveButtonWithAdvancedRetreivalCheckButton
         saving={props.saving}
         handleSubmitWithRedirect={props.handleSubmitWithRedirect}
         label={"Speichern und schließen"}
         redirect={true}
         isCreate={isCreate}
         style={{ marginRight: "0.5rem" }}
       ><SaveIcon style={{ marginRight: '0.5rem', fontSize: '18px' }}/> Speichern und schließen</CustomSaveButtonWithAdvancedRetreivalCheckButton>
       <CustomSaveButtonWithAdvancedRetreivalCheckButton
         id={"submitFormButton"}
         saving={props.saving}
         redirect={false}
         isCreate={isCreate}
         handleSubmitWithRedirect={props.handleSubmitWithRedirect}
         style={{ marginRight: "0.5rem" }}
       ><SaveIcon style={{ marginRight: '0.5rem', fontSize: '18px' }}/> Speichern</CustomSaveButtonWithAdvancedRetreivalCheckButton>
       <DeleteWithConfirmButton
         confirmTitle={`Löschen bestätigen`}
         record={props.record}
         label={"Löschen"}
         confirmContent={`Möchten Sie den Auftrag ${props.record.auftragsnummer} wirklich löschen?`}
       />
       <RefreshButton label="Zurücksetzen"/>
     </Box>
    }
  </Toolbar>
}

const ContractEditActions = props => {
  return (
    <DefaultEditActions {...props}>
      <CloneContractButton record={props?.data}/>
      <CloneContractAsReturnContractButton record={props?.data}/>
      <EntityLink record={props?.data?.protocol} icon={<SettingsIcon/>} title={"Protokoll"}>Zur Auftragsnachbearbeitung</EntityLink>
    </DefaultEditActions>
  );
}

const ContractTitle = ( { record } ) => {
  return <span>Auftrag {record ? `"${record.auftragsnummer}"` : ""}</span>;
};

const ContractEdit = ( props ) => (
  <Edit
    actions={<ContractEditActions allowNew={true} {...props} />}
    title={<ContractTitle/>}
    {...props}
  >
    <ContractForm isCreate={false} toolbar={<ContractToolbar isCreate={false}/>}/>
  </Edit>
);

const ContractCreate = ( props ) => (
  <Create
    actions={<DefaultEditActions allowNew={true} {...props} />}
    title="neuen Auftrag anlegen"
    {...props}
  >
    <ContractForm isCreate={true} toolbar={<ContractToolbar isCreate={true}/>}/>
  </Create>
);

export { ContractEdit, ContractCreate };
