import React from "react";
import { BooleanInput, SearchInput, SelectArrayInput, useListContext } from 'react-admin';
import moment from 'moment';
import "moment/locale/de";
import { DriverReferenceInput } from "../../../drivers/components/DriverReferenceInput";
import { DateInputWithLocalStorage } from "../../../utils/components/DateInput";

moment.locale( "de" );

const creditFilters = [
  <SearchInput
    placeholder="Auftrag, KZ, FIN, Fahrer"
    source="specialFilter"
    style={{ minWidth: "250px", marginTop: "0" }}
    alwaysOn
  />,

  <DriverReferenceInput label="Fahrer" source="logistics_and_subs" filter={{ groups_and_subs: ["3", "5", "6", "7", "8"] }} alwaysOn/>,

  <SelectArrayInput
    source={"groups_and_subs"}
    label={"Jobkategorie"}
    optionText={'name'}
    choices={[
      {id: '1', name: 'Minijob'},
      {id: '2', name: 'Teilzeit'},
      {id: '3', name: 'Vollzeit'},
      {id: '4', name: 'Bürokraft'},
      {id: '5', name: 'Subunternehmer'},
      {id: '6', name: 'Logistiker'},
      {id: '7', name: 'Vermaco'},
      {id: '8', name: 'Kleinunternehmer'},

    ]}
    alwaysOn
  />,

  <DateInputWithLocalStorage
    label="Leistungsdatum ab"
    source="custom__bringdatum[after]"
    origin={"creditFilters"}
    style={{ minWidth: "200px" }}
    alwaysOn
  />,

  <DateInputWithLocalStorage
    label="Leistungsdatum bis"
    source="custom__bringdatum[before]"
    origin={"creditFilters"}
    style={{ minWidth: "200px" }}
    alwaysOn
  />,

  <BooleanInput label="Nur Abgerechnete anzeigen"
                title={"Bereits abgerechnete Aufträge anzeigen"}
                options={{ size: "medium" }}
                source="auftraegeExt.creditNoteCreated"
                style={{ paddingBottom: '10px' }}
                alwaysOn/>,

  <BooleanInput label="Für Gutschrift gesperrt"
                options={{ size: "medium" }}
                source="boolchar_sperrenfuergutschrift"
                style={{ paddingBottom: '10px' }}
                alwaysOn/>,
]

let creditFiltersDefaultValues = {
  // Protocol: {
  //   closed: {
  //     before: moment().startOf( 'month' ).subtract( 1, "month" ).format( "Y-M-D" ),
  //     after: moment().startOf( 'month' ).format( "Y-M-D" ),
  //   },
  // },
  auftraegeExt: {
    creditNoteCreated: false,
  },
  boolchar_sperrenfuergutschrift: false,
  groups_and_subs:  ['5', '6', '8'],
};

export { creditFilters, creditFiltersDefaultValues };
