import LeasingProtocolList from "./LeasingProtocolList";
import { EntityCreate, EntityEdit } from "./LeasingProtocolEdit";

export default {
  name: "leasing_protocols",
  options: {
    label: "Leasing-Protokolle"
  },
  list: LeasingProtocolList,
  // show: EntityShow,
  edit: EntityEdit,
  create: EntityCreate,
}
