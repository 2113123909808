import EmailList from "./EmailList";
import { EntityEdit, EntityCreate } from "./EmailEdit";

const Emails = {
  list: EmailList,
  edit: EntityEdit,
  create: EntityCreate,
  name: "emails"
}

export default Emails;
