import CustomerDepartmentList from "./CustomerDepartmentList";
import { EntityCreate, EntityEdit } from "./CustomerDepartmentEdit";

export default {
  name: "customer_departments",
  options: {
    label: "Filiale"
  },
  list: CustomerDepartmentList,
  // show: EntityShow,
  edit: EntityEdit,
  create: EntityCreate,
}
