import * as React from "react";
import { CloneButton } from "react-admin";
import { unset } from "lodash";

export const CloneGroupButton = (props) => {
  const { record, ...rest } = props;
  if( !record ) {
    return null;
  }

  const _clone = JSON.parse(JSON.stringify(record));

  _clone.defaultPriceSet = false;
  _clone.name = "Kopie " + record.name;
  _clone.priceSets.map( priceSet => {
    unset(priceSet, 'id');
    unset(priceSet, '@id');
    unset(priceSet, 'priceGroup');
  })

  unset(_clone, 'customer');

  return <CloneButton record={_clone} target="_blank"  {...rest} />;
};
