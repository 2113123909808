import InvoiceList from "./InvoiceList";
import { EntityCreate, EntityEdit } from "./InvoiceEdit";

const Invoices = {
  list: InvoiceList,
  edit: EntityEdit,
  create: EntityCreate,
  name: "invoices"
};

export default Invoices;
