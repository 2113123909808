export default () => {
  let settings = {
    openLinksInNewTab: true,
    showNotifications: true,
  }
  if( localStorage.getItem('userSettings') !== null ) {
    try {
      settings = JSON.parse( localStorage.getItem( "userSettings" ) )
    } catch ( e ) {
      console.log( e )
    }
  }

  return settings
}
