import React from "react";
import HotList from "../utils/components/HotList";
import { TextField } from "react-admin";
import { useListContext } from "ra-core";

const ComplaintList = ( props) => {
  return <HotList {...props} allowCreate={true} hasEdit={true} title={"Protokollbeanstandung"}>
    <TextField source={"type"} label={"Typ"} />
  </HotList>
}

export default ComplaintList;
