import * as React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { SimpleForm, Title, FileInput, FileField } from "react-admin";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useRedirect } from 'react-admin';

const ENDPOINT = process.env.REACT_APP_API_IMPORT_XLSX;

const ImportPage = () => {
  const [error, setError] = React.useState( null );
  const [errorMessage, setErrorMessage] = React.useState( null );
  const [success, setSuccess] = React.useState( null );
  const redirect = useRedirect();
  const save = async( event ) => {
    try {
      setError( null );
      setSuccess( null );
      setErrorMessage( null );
      const file = event.files.rawFile;
      const formData = new FormData();
      formData.append( "xlsxFile", file, file.name );

      const res = await axios.post( ENDPOINT, formData, {
        timeout: 300000,
        headers: {
          Authorization: `Bearer ${localStorage.getItem( "token" )}`,
        },
      } );
      setSuccess( "Erfolgreich importiert." );
      redirect( 'edit', 'vw_imports', res.data.recordLink );

    } catch (err) {
      console.log( err.response )
      setError( err.message );
      setErrorMessage( err.response?.data?.detail );
    }
  };
  return (
    <Card>
      <Snackbar open={error}>
        <MuiAlert severity="error" elevation={6} variant="filled">{errorMessage}</MuiAlert>
      </Snackbar>
      <Snackbar open={success}>
        <MuiAlert severity="success" elevation={6} variant="filled">
          {success}
        </MuiAlert>
      </Snackbar>
      <Title title="VW Import: File Upload"/>
      <CardContent>
        <SimpleForm save={save}>
          <FileInput
            source="files"
            label="Import .xlsx Datei"
            accept=".xlsx"
            placeholder="Datei hochladen"
          >
            <FileField source="src" title="title"/>
          </FileInput>
        </SimpleForm>
      </CardContent>
    </Card>
  );
};

export default ImportPage;
