import { Confirm, FormDataConsumer, FormWithRedirect, useDataProvider, useNotify, useRedirect, useRefresh } from "react-admin";
import React, { useState } from 'react';

import CancelIcon from "@material-ui/icons/Cancel";
import ConfirmIcon from "@material-ui/icons/CheckCircleOutline";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { BooleanInput } from "../../../utils/components";
import { defaultButtonStyle } from "../../../utils/components/styles/ButtonStyles";

const CancelContractIsPartOfBundleDialog = ( { formData, ...props } ) => {
  const [open, setOpen] = useState( false );
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();

  const showWarning = !props.create && formData.isPartOfBundle && "@id" in formData && ( formData?.isStorniert || formData?.hasOwnProperty( 'driver' ) )

  const handleConfirm = async( data ) => {
    if( data.deactivateBundle ) {
      for( const bundle of formData.bundles ) {
        await dataProvider.update( 'bundles', {
          id: bundle[ '@id' ],
          data: {
            active: false,
          }
        } ).then( ( response ) => {
          // success side effects go here
          notify( `Fahrtpaket ${bundle.bundle} erfolgreich aufgelöst`, { type: "success" } );
        } ).catch( ( error ) => {
          // failure side effects go here
          notify( `Fehler beim bearbeiten: ${error.message}`, { type: "warning" } );
        } )
      }
    }

    dataProvider.update( 'contracts', {
      id: formData[ '@id' ],
      data: {
        ...formData,
      }
    } ).then( ( response ) => {
      // success side effects go here
      notify( "Auftrag erfolgreich bearbeitet", { type: "success" } );
      setOpen( false );
      if( props.redirect ) {
        redirect( 'list', '/contracts' )
      }
    } ).catch( ( error ) => {
      // failure side effects go here
      notify( `Fehler beim bearbeiten: ${error.message}`, { type: "warning" } );
      setOpen( false );
    } )
  }

  return <>
    <Dialog fullWidth open={open} onClose={() => setOpen( false )}>
      <FormWithRedirect
        {...props}
        save={handleConfirm}
        render={() => (
          <form>
            <DialogTitle>Auftrag ist Teil eines Fahrtpakets</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Dieser Auftrag ist Teil eines Fahrtpaketes. Wenn Sie diesen Auftrag stornieren, kann das Fahrtpaket aufgelöst werden:
                <br/>
                <br/>
                <BooleanInput source="deactivateBundle" label="Fahrtpaket auflösen" initialValue={true}/>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={ () => setOpen( false ) } color="primary">
                Abbrechen
              </Button>
              <FormDataConsumer>
                {({ formData }) => {
                  return <Button onClick={ () => handleConfirm( formData ) } color="primary" variant={"contained"} autoFocus label={"Speichern"}>Speichern</Button>
                }}
              </FormDataConsumer>            </DialogActions>
          </form>
        )}/>
    </Dialog>

    {showWarning && <Button style={defaultButtonStyle} color={"primary"} children={<CancelIcon />} {...props} onClick={() => setOpen( true )}/>}
  </>
}

export const CancelContractButton = ( { record, style = {}, ...props } ) => {
  const [open, setOpen] = useState( false );
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  if( !record ) {
    return null;
  }
  const resource = "contracts";
  const showButtonText = false;
  const buttonText = record.isStorniert ? "Auftrag freigeben" : "Auftrag stornieren";
  const buttonIcon = record.isStorniert ? <ConfirmIcon/> : <CancelIcon/>;

  const cancel = () =>
    dataProvider
      .update( resource, {
        id: record.id,
        data: {
          ...record,
          isStorniert: !record.isStorniert
        },
      } )
      .then( ( response ) => {
        // success side effects go here
        refresh();
        notify( "Auftrag erfolgreich bearbeitet", { type: "success" } );
      } )
      .catch( ( error ) => {
        // failure side effects go here
        notify( `Fehler beim bearbeiten: ${error.message}`, { type: "warning" } );
      } );

  const handleClick = () => setOpen( true );
  const handleDialogClose = () => setOpen( false );
  const handleConfirm = () => {
    cancel();
    setOpen( false );
  };

  const showWarning = !props.create && record.isPartOfBundle && !record?.isStorniert

  const formData = { ...record, isStorniert: !record.isStorniert }

  return (
    showWarning ?
    <CancelContractIsPartOfBundleDialog formData={formData} {...props} /> :
    <>
      <Button
        title={buttonText}
        onClick={handleClick}
        children={
          <>
            {buttonIcon}
            {showButtonText && buttonText}
          </>
        }
        style={style}
        color={"primary"}
      />
      <Confirm
        isOpen={open}
        title={`Auftrag ${record.auftragsnummer} ${record.isStorniert ? "freigeben" : "stornieren"}`}
        content="Sind Sie sicher?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};
