import CategoryList from "./CategoryList";
import { EntityCreate, EntityEdit } from "./CategoryEdit";

const Categories = {
  list: CategoryList,
  edit: EntityEdit,
  create: EntityCreate,
  name: "categories"
};

export default Categories;
