import React from "react";
import { ReferenceField, DateField, TextField, BooleanField, FunctionField } from 'react-admin';
import HotList from "../utils/components/HotList";
import { userFilter, userFiltersDefaultValues } from "./components/UserFilter";


const UserList = props => (
  <HotList sort='id' {...props}
           filters={userFilter}
  filterDefaultValues={userFiltersDefaultValues}>
    <TextField source={"username"} label={"Nutzername"} />
    <TextField source={"email"} />
    <BooleanField source={"active"} label={"Aktiv"} />
    <FunctionField label={"Nutzer-Konto"} render={record => (
      <>
        {record.driver && <ReferenceField source={"driver"} reference={"drivers"} label={"Fahrer"}>
          <><TextField source={"name"}/>&nbsp;(Fahrer)</>
        </ReferenceField>}
        {record.customer && record.driver && <br />}
        {record.customer && <ReferenceField source={"customer"} reference={"customers"} label={"Kunde"}>
          <><TextField source={"firma"}/>&nbsp;(Kunde)</>
        </ReferenceField>}
      </>
    )} />

  </HotList>
);

export default UserList;
