import { Button, useNotify, useRefresh, useDataProvider } from "react-admin";
import React from 'react';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import ListIcon from "@material-ui/icons/List";
import { red } from "@material-ui/core/colors";

export const ToggleDisplayInOfferListButton = ( { record, style = {} } ) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  if( !record || record.driver ) {
    return null;
  }
  const resource = "contracts";
  const showButtonText = false;
  const buttonText = record.sperrenFuerAngebotsliste ? "Auftrag für Angebotsliste freigeben" : "Auftrag für Angebotsliste sperren";
  let buttonIcon = <PlaylistAddCheckIcon />;
  if( record.sperrenFuerAngebotsliste ) {
     buttonIcon = <ListIcon style={{color: red[500]}} />
  }

  const setSperrenFuerAngebotsliste = () =>
    dataProvider
      .update( resource, {
        id: record.id,
        data: {
          ...record,
            sperrenFuerAngebotsliste: !record.sperrenFuerAngebotsliste
        },
      } )
      .then( ( response ) => {
        // success side effects go here
        notify( "Auftrag erfolgreich bearbeitet", { type: "success" } );
      } )
      .catch( ( error ) => {
        // failure side effects go here
        notify( `Fehler beim bearbeiten: ${error.message}`, { type: "warning" } );
      } );


  return (
    <>
      <Button
        title={buttonText}
        onClick={setSperrenFuerAngebotsliste}
        children={
          <>
            {buttonIcon}
            {showButtonText && buttonText}
          </>
        }
        style={style}
      />
    </>
  );
};

export const BulkToggleDisplayInOfferList = ( { contracts, setSelectedContracts } ) => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const update = async ( state ) => {
      for( const contract of contracts ) {
            await dataProvider.update("contracts", {
                id: `/api/contracts/${contract}`,
                data: {
                    ...contract,
                    sperrenFuerAngebotsliste: state
                },
            }).then((response) => {
                // success side effects go here
              notify("Auftrag erfolgreich bearbeitet", { type: "success" });
            }).catch((error) => {
                // failure side effects go here
                notify(`Fehler beim bearbeiten: ${error.message}`, { type: "warning" });
            })
      }
      setSelectedContracts(new Set());
    }

    return <>
        <Button label={`Aufträge Für Angebotsliste sperren`} variant="contained" onClick={() => update(true)} style={{ marginTop: "5px" }}/>&nbsp;
        <Button label={`Aufträge Für Angebotsliste freigeben`} variant="contained" onClick={() => update(false)} style={{ marginTop: "5px" }}/>
    </>
}
