import { FieldGuesser } from "@api-platform/admin";
import React from "react";
import HotList from "../utils/components/HotList";
import { TextField } from "react-admin";

const UserGroupsList = ( props) => (
  <HotList {...props} sort={"id"} sortOrder={"DESC"} allowCreate={true} hasEdit={true}>
    <TextField source={"name"} label={"Name"} />
    <TextField source={"description"} label={"Beschreibung"} />
  </HotList>
);

export default UserGroupsList;
