import "moment/locale/de";
import React from "react";
import { CustomerReferenceArrayInput } from "../../customers/components/CustomerReferenceInput";
import { BooleanInput } from "react-admin";

const pricingFilter = [
  <CustomerReferenceArrayInput label="Kunden" source="kunde" alwaysOn/>,
  <BooleanInput label="Nur aktive Kunden" options={{ size: "small" }} source="custom__kunde_aktiv" parse={v => v ? 1 : null} format={v => v === 1} alwaysOn/>,
];

const pricingFiltersDefaultValues = {
  // boolchar_aktiv: true,
  // "abgeschlossen": "0"
};

export { pricingFilter, pricingFiltersDefaultValues };
