import * as React from "react";
import { CloneButton } from "react-admin";
import moment from "moment/moment";

const objectKeys = [
  "@type",

  "testContract",
  "flexContract",

  "contractClientAddress",
  "billingClientAddress",
  "leistungsadresse",

  "services",
  "accessories",
  "zubehoerArray",
]

export default (props) => {
  const { record, ...rest } = props;
  if( !record ) {
    return null;
  }

  const returnOrder = { };
  const _clone = JSON.parse( JSON.stringify( record ) );
  objectKeys.forEach( key => {
    if( _clone[ key ]) {
      returnOrder[key] = _clone[ key ];
    }
  })

  returnOrder[ "pickupDate" ] = _clone[ "deliveryDate" ];
  returnOrder[ "pickupDateRaw" ] = _clone[ "deliveryDateRaw" ];
  returnOrder[ "pickupTime" ] = _clone[ "deliveryTime" ];
  returnOrder[ "pickupTimeFrom" ] = _clone[ "deliveryTimeFrom" ];
  returnOrder[ "pickupTimeTo" ] = _clone[ "deliveryTimeTo" ];
  returnOrder[ "pickupLocation" ] = _clone[ "deliveryLocation" ];

  // returnOrder[ "deliveryDateRaw" ] = _clone[ "pickupDateRaw" ];
  returnOrder[ "deliveryLocation" ] = _clone[ "pickupLocation" ];

  if( _clone["pickupLocationIsSet" ] ) {
    returnOrder["deliveryTimeFrom"] = moment( _clone['pickupLocation']['startzeit'] ).format( 'LT' )
    returnOrder["deliveryTimeTo"] = moment( _clone['pickupLocation']['endzeit'] ).format( 'LT' )
  }

  returnOrder.neumerker = '1';
  returnOrder.createBundleWith = record.id
  returnOrder.createBundleContractNumber = record.auftragsnummer

  return <CloneButton record={returnOrder} target="_blank" label={"Rückauftrag anlegen"} {...rest} />;
};
