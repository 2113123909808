import {
  AutocompleteArrayInput,
  AutocompleteInput,
  ReferenceInput,
} from "react-admin";
import ReferenceArrayInput from "../../utils/components/ReferenceArrayInput";
import React from "react";
import { translateAddressLabel } from "../../address/components/AddressReferenceInput";

const getAddressOptionText = ( address ) => {
  if( address == null || address?.id == null ) {
    return null;
  }
  let text = address.ort;
  if( typeof address.firma !== undefined ) {
    text = address.firma+' | '+address.ort;
  }

  if( address.searchLabel !== '' ) {
    text += ' | '+address.searchLabel;
  }

  return text;
}

const BillingAddressReferenceInput = ( props ) => {
  const { filter, onSelect } = props

  let filterValues = {
    boolchar_cleared: false,
    ...filter
  }


  return <ReferenceInput
    label={"Abweichende Rechnungsanschrift"}
    reference={"billing_addresses"}
    // allowEmpty={true}
    fullWidth
    resettable
    filter={filterValues}
    format={( v ) =>
      v !== null &&
      typeof v === "object" &&
      v.hasOwnProperty( "@id" )
      ? v[ "@id" ]
      : v
    }
    filterToQuery={( searchText ) => ( { _name: searchText } )}
    perPage={200}
    helperText={false}
    {...props}
  >
    <AutocompleteInput
      optionText={getAddressOptionText}
      source={"billingClientAddress.firma"}
      onSelect={onSelect}
    />
  </ReferenceInput>
}

const BillingAddressReferenceArrayInput = ( props ) => {
  const { filter, optionText = translateAddressLabel } = props

  let filterValues = {
    boolchar_cleared: false,
    ...filter
  }

  return (
    <ReferenceArrayInput
      label={"Rechnungsadressen"}
      source={"leistungsadresse"}
      reference={"billing_addresses"}
      filterToQuery={( searchText ) => ( { _name: searchText } )}

      {...props}
      filter={filterValues}
      allowEmpty={false}
      fullWidth
      style={{ width: '100%' }}
    >
      {props.children ?? (
        <AutocompleteArrayInput
          style={{ minWidth: "200px" }}
          optionText={getAddressOptionText}
          source={"leistungsadresse.firma"}
          helperText={false}
        />
      )}
    </ReferenceArrayInput>
  );
};

export { BillingAddressReferenceInput, BillingAddressReferenceArrayInput }
