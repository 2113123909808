import {
  useNotify,
  useRefresh,
  useDataProvider, Confirm
} from 'react-admin';
import Switch from '@material-ui/core/Switch';
import React, { useState } from "react";

const HideButton = ( { record } ) => {
  const dataProvider = useDataProvider();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  if( !record ) {
    return null;
  }

  const toggleVisibility = () => {
    setLoading( true )
    dataProvider
    .update( 'protocols', { id: record.id, data: { ...record, aktiv: !record.aktiv } } )
    .then( response => {
      refresh();
      notify( `${response.aktiv ? 'Auftrag eingeblendet' : 'Auftrag ausgeblendet'}`, 'success' );
      setLoading( false )
    } )
    .catch(error => {
      notify(`Fehler: ${error.message}`, { type: 'warning' });
      setLoading( false )
    });
  }

  return <>
    <Confirm
      title="Auftrag ausblenden"
      content="Möchten Sie diesen Auftrag wirklich ausblenden?"
      onConfirm={() => toggleVisibility()}
      onClose={() => setOpen(false)}
      isOpen={open}
      loading={loading}
    />
    <Switch size="small" checked={record.aktiv} title={record.aktiv ? 'Auftrag ausblenden' : 'Auftrag einblenden'} onClick={() => setOpen(true)} />
  </>
}

export default HideButton;
