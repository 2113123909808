import NewsList from "./NewsList";
import { EntityEdit, EntityCreate } from "./NewsEdit";

const News = {
  name: "news",
  list: NewsList,
  edit: EntityEdit,
  create: EntityCreate,
}

export default News;
