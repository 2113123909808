import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import PictureAsPdfIconOutlined from "@material-ui/icons/PictureAsPdfOutlined";
import CryptoJS from "crypto-js";
import Link from '../../../utils/components/Link';
import React, { useCallback } from "react";

export const ExportPDFButton = React.memo(({ record, icon = <PictureAsPdfIcon/>, showRegenerate = true, selectOnClick = false, selectType = 'protocols', ...props }) => {
  const { style } = props
  if( !record ) {
    return null;
  }

  const handleClick = useCallback((e) => {
    if( e.ctrlKey || e.metaKey ) {
      const event = new MouseEvent( 'click', {
        view: window,
        bubbles: true,
        cancelable: true
      });

      e.target.closest( 'tr' )?.querySelector( 'input[type="checkbox"]' )?.dispatchEvent( event )
    }
  }, []);

  let encrypted = CryptoJS.SHA512( `QNv41WV9ZsG07jtM${record.auftragsnummer}X4cXAJHN37Q9i5SP` ).toString()
  let protocolURL = `${process.env.REACT_APP_API_PROTOCOL_URL}/?id=${record.auftragsnummer}&hash=${encrypted}`
  // if( record.leasingProtokoll ) {
  //   protocolURL += '&pdftyp='+( record.leasingProtokoll === 'BFMUEBEL' ? 'bfm' : record.leasingProtokoll.toLowerCase() );
  // }

  return (
    <>
      <Link
        isExternal={true}
        title="PDF Anzeigen"
        to={protocolURL}
        icon={icon}
        style={style ?? {}}
        onClick={handleClick}/>

      {showRegenerate &&
       <Link
         isExternal={true}
         title="PDF neu generieren"
         to={protocolURL+'&ueberschreiben=1'}
         icon={<PictureAsPdfIconOutlined/>}
         style={style ?? {}}
         onClick={handleClick}
       />
      }
    </>
  );
});
