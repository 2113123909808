import { useState } from "react";
import { Button } from "react-admin";
import { useForm } from "react-final-form";
import { getGoogleDirections } from "../../utils/request/PostRequest";
import GlobalLoading from "../../utils/components/GlobalLoading";


export const CalculateSollKMButton = ( { record }) => {
  const { change } = useForm();
  const [loading, setLoading] = useState(false)

  let disabled = false
  if( !record ||
      !record.pickupLocation?.strasse ||
      !record.pickupLocation?.ort ||
      !record.deliveryLocation?.strasse ||
      !record.deliveryLocation?.ort ) {
    disabled = true
  }

  const handleClick = async() => {
    setLoading( true )

    const { distanceKm } = await getGoogleDirections(
      record.pickupLocation,
      record.deliveryLocation,
      record.serviceAddresses
    );

    setLoading( false )

    change(
      "sollkm",
      distanceKm
    )
  }

  return <>
    {loading &&<GlobalLoading/>}
    <Button
      disabled={disabled}
      onClick={() => handleClick()}
      children={<>Soll km berechnen</>}
    />
  </>
}
