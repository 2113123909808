import React, { useState } from "react";
import { IconButtonWithTooltip, useDataProvider, useDelete, useNotify, useUpdate } from "react-admin";
import { Box, Button, Toolbar } from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import CloseIcon from "@material-ui/icons/RemoveCircleOutline";
import MoveToPositionFormDialog from "./MoveToPositionFormDialog";
import { makeStyles } from "@material-ui/core/styles";
import { defaultButtonStyle } from "../../utils/components/styles/ButtonStyles";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import GlobalLoading from "../../utils/components/GlobalLoading";
import { AddContractToBundleModalButtonWithDialog } from "./AddContractToBundleModalButton";
import AddContractByContractNumberInputForModal from "./AddContractByContractNumberInputForModal";

const BundleModalFormIterator = ( { displayButtons= true, bundle, setBundle, ...props }) => {
  const [isMoveToPosDialogOpen, setIsMoveToPosDialogOpen] = useState(false);
  const [moveToPosFrom, setMoveToPosFrom] = useState(0);
  const [update] = useUpdate()
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState( false );
  const [ deleteOne ] = useDelete()
  const contractBundles = bundle.contractBundles

  const updateBundleData = () => {
    setLoading(true)
    dataProvider.getOne(
      'bundle_contracts',
      { id: bundle['@id'] }
    ).then ( ( response ) => {
      setBundle( response.data )
      setLoading(false)
    }).catch( ( error ) => {
      notify( error.message || `Fehler beim aktualisieren der Daten`, { type: 'warning', multiLine: true } )
      setLoading(false)
    })
  }

  const updatePos = (from, to) => {
    setLoading(true)
    let move = from > to ? -1 : 1;
    update(
      'bundle_contracts',
      contractBundles[ from ][ '@id' ], {
        sortOrder: contractBundles[ to ]['sortOrder']
      },
      contractBundles[ from ], {
        onSuccess: () => {
          notify( `Eintrag erfolgreich verschoben`, { type: 'success' } );
          setLoading(false)
          updateBundleData()
        },
        onFailure: ( error ) => {
          setLoading( false )
          notify( error.message || `Fehler beim verschieben`, { type: 'warning', multiLine: true } )
        }
      }
    );
  }

  const removePositionClickHandler = ( index ) => {
    setLoading(true)
    deleteOne(
      'bundle_contracts',
      contractBundles[ index ][ '@id' ],
      contractBundles[ index ],
      {
        onSuccess: () => {
          notify( `Eintrag erfolgreich entfernt`, { type: 'success' } );
          setLoading( false )
          updateBundleData()
        },
        onFailure: ( error ) => {
          setLoading( false )
          notify( error.message || `Fehler beim verschieben`, { type: 'warning', multiLine: true }
          )
        }
      })
  }

  const total = contractBundles.length ?? 0;
  const lastItem = contractBundles?.length - 1;

  return (
    <>
      {loading &&<GlobalLoading/>}

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Auftrag</TableCell>
              <TableCell>Von</TableCell>
              <TableCell>Nach</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {contractBundles.map( ( item, index ) => {
            return (
             <TableRow key={`modal_bundles_${index}`}>
               <TableCell>
                 {displayButtons && <ListItemControl index={ index } total={ total } moveToPos={ updatePos } moveFunc={ props.fields.move } { ...props } />}
               </TableCell>
               <TableCell>{item.contract.auftragsnummer}</TableCell>
               <TableCell>{item.contract.pickupLocation.concatAddressData}</TableCell>
               <TableCell>{item.contract.deliveryLocation.concatAddressData}</TableCell>
               <TableCell>
                 {displayButtons && <Button
                   color={ 'primary' }
                   title="Position entfernen"
                   style={ { minWidth: '32px' } }
                   onClick={ () => removePositionClickHandler( index ) }>
                   <CloseIcon />
                 </Button>}

               </TableCell>
             </TableRow>
             )
          } )}
          </TableBody>
        </Table>
      </TableContainer>

      {displayButtons && <>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Box display={"flex"} justifyContent={"center"} flexDirection={"column"} >
            <AddContractToBundleModalButtonWithDialog record={contractBundles[ lastItem ].contract} style={defaultButtonStyle} bundle={bundle} setBundle={setBundle}/>
            <AddContractByContractNumberInputForModal bundle={props.record} style={defaultButtonStyle} setBundle={setBundle}/>
          </Box>
        </Toolbar>

        <MoveToPositionFormDialog
          isOpen={isMoveToPosDialogOpen}
          setIsOpen={setIsMoveToPosDialogOpen}
          moveFunc={props.fields.move}
          saveFunc={updatePos}
          from={moveToPosFrom}
          maxValue={total}
          items={props.fields}
        />
      </>}
    </>
  )
}

const ListItemControl = ( props ) => {
  const { index, total, moveToPos, moveFunc } = props;
  const classes = useStyles();
  return <Box className={classes.listItemControl}>
    <IconButtonWithTooltip
      label="ra.action.move_up"
      size="small" onClick={() => {
      moveFunc( index, index-1 )
      moveToPos( index, index-1 )
    }}
      disabled={index <= 0}>
      <ArrowUpwardIcon/>
    </IconButtonWithTooltip>

    <Box className={classes.currentPos}>{index+1}</Box>

    <IconButtonWithTooltip label="ra.action.move_down" size="small" onClick={() => {
      moveFunc( index, index+1 )
      moveToPos( index, index+1 )
    }} disabled={total == null || index >= total-1}>
      <ArrowDownwardIcon/>
    </IconButtonWithTooltip>
  </Box>
}

const useStyles = makeStyles( ( theme ) => ( {
  listItem: {
    padding: '0 5px 0 5px',
    display: 'flex',
    // backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.default,
    // borderBottom: "2px solid rgba(128,128,128, 0.75)",
    '&:nth-child(odd)': {
      // boxShadow: "rgb(0,0,0,0.26) 0px 2px 20px 0px",
      // backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.odd,
    },
    '&:hover': {
      // backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.hover,
    },
  },

  listItemControl: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center"
  },

  currentPos: {
    display: "flex",
    alignItems: "center",
  },
  textInput: {
    margin: "8px 3px 4px 3px",
    float: 'left',
  }
} ) )

BundleModalFormIterator.defaultProps = {
  fieldName: 'contractBundles'
}


export default BundleModalFormIterator;
