import DefaultPagination from "../DefaultPagination";
import DefaultListActions from "../DefaultListActions";
import { Datagrid, EditButton, Empty, List } from "react-admin";
import { withStyles } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import { useListContext } from "ra-core";

const styles = {
  rowCell: {
    verticalAlign: "top",
  },
};

class HotList extends React.Component {
  render () {
    const {
      classes,
      itemsPerPage,
      sort,
      sortOrder,
      allowCreate,
      showSortButton,
      sortButtonFields,
      filterDefaultValues,
      hasList = true,
      hasCreate = false,
      hasEdit = false,
      hasShow = false,
      isRowExpandable,
      syncWithLocation = true,
      ...rest
    } = this.props;



    // return
    return (
      <List
        pagination={ <DefaultPagination /> }
        filterDefaultValues={ filterDefaultValues }
        actions={
          <DefaultListActions
            hsfilterDefaultValues={ filterDefaultValues }
            hsShowFilter={ this.props.filters?.length > 0 }
            showSortButton={ showSortButton }
            sortButtonFields={ sortButtonFields }
            allowCreate={ allowCreate }
          />
        }
        sort={ { field: sort, order: sortOrder } }
        hasEdit={ hasEdit }
        hasShow={ hasShow }
        syncWithLocation={ syncWithLocation }
        hasCreate={ hasCreate }
        { ...rest }
      >
        <Datagrid classes={ classes } expand={ rest.expand } isRowExpandable={ isRowExpandable }>
          ${ this.props.children }${ this.props.hasEdit && <EditButton /> }
        </Datagrid>

      </List>
    );
  }
}

HotList.propTypes = {
  classes: PropTypes.object.isRequired,

  perPage: PropTypes.number,
  sort: PropTypes.string,
  sortOrder: PropTypes.string,
  hasShow: PropTypes.bool,
  hasEdit: PropTypes.bool,
  showSortButton: PropTypes.bool,
  sortButtonFields: PropTypes.any,
  allowCreate: PropTypes.bool,
  filters: PropTypes.any,
  filterDefaultValues: PropTypes.any,
  children: PropTypes.any,
  isRowExpandable: PropTypes.func,
};

HotList.defaultProps = {
  perPage: parseInt( process.env.REACT_APP_LIST_ITEMS ),
  sort: "id",
  sortOrder: "ASC",
  isRowExpandable: () => false,
};

export default withStyles( styles )( HotList );
