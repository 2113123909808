import React from "react";

import { SelectInput } from "react-admin";
import { Box, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/RemoveCircleOutline";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { Spacer } from "../../utils/components/FormElements";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { TextInput } from "../../utils/components";
import ReferenceInput from "../../utils/components/ReferenceInput";

export const ComplaintFormIterator = ( { recordId, ...props } ) => {
  const classes = useStyles();

  const addButtonClickHandler = () => {
    const newItem = {
      protocol: recordId,
      complaint: null,
      description: null,
      createdAt: null,
      updatedAt: null,
      createdAtFormatted: '',
      updatedAtFormatted: ''
    }

    props.fields.push( newItem )
  }

  return (
    <>
      <Box>
        <Grid container className={classes.listHead}>
          <Grid item xs={4} className={classes.listItem}>Typ</Grid>
          <Grid item xs={3} className={classes.listItem}>Beschreibung</Grid>
          <Grid item xs={2} className={classes.listItemRight}>Aufgenommen am</Grid>
          <Grid item xs={2} className={classes.listItemRight}>Aufgenommen von</Grid>
          <Grid item xs={1} className={classes.listItem}></Grid>
        </Grid>

        {props.fields.map( ( item, index ) => {
          return <Grid container className={classes.listRow} key={index}>
            <Grid item xs={4} className={classes.listItem}>
              <ReferenceInput
                source={`${item}.complaint`}
                reference="protocol_complaint_types"
                label="Beanstandung"
                allowEmpty={false}
                helperText={false}
                resettable={false}
                fullWidth>
                <SelectInput optionText="type"/>
              </ReferenceInput>
            </Grid>
            <Grid item xs={3} className={classes.listItem}><TextInput source={`${item}.description`} label={"Beschreibung"} fullWidth/></Grid>
            <Grid item xs={2} className={classes.listItemRight}>{props.fields.value[ index ].updatedAtFormatted}</Grid>
            <Grid item xs={2} className={classes.listItemRight}>{props.fields.value[ index ].reporter?.username}</Grid>
            <Grid item xs={1} className={classes.listItemRight}>
              <Button
                color={'primary'}
                title="Position entfernen"
                style={{ minWidth: '32px' }}
                onClick={() => props.fields.remove( index )}>
                <CloseIcon/>
              </Button>
            </Grid>
          </Grid>
        } )}
      </Box>
      <Spacer space="1rem"/>

      <Box>
        <Grid spacing={2} container alignItems="center">

          <Grid item>
            <Button
              color={'primary'}
              onClick={addButtonClickHandler}
              label="ra.action.add">
              <AddIcon/> Neu
            </Button>
          </Grid>
        </Grid>
      </Box>


    </>
  )
}

const useStyles = makeStyles( ( theme ) => ( {
  listRow: {
    alignItems: 'center',
    backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.default,
    // borderBottom: "2px solid rgba(128,128,128, 0.75)",
    '&:nth-child(odd)': {
      // boxShadow: "rgb(0,0,0,0.26) 0px 2px 20px 0px",
      backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.odd,
    },
    '&:hover': {
      backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.hover,
    },
  },
  listHead: {
    padding: '0 5px 5px 5px',
    fontSize: '0.9rem',
    fontWeight: 'bold',
    borderBottom: '1px solid #fefefe'

  },
  listItem: {
    padding: '0px 5px'
  },
  listItemRight: {
    padding: '0px 5px',
    textAlign: 'right'
  },

  textInput: {
    margin: "8px 3px 4px 3px",
    float: 'left',
  }
} ) )
