import { Button, useNotify } from "react-admin";
import * as React from "react";
import { getPDFLink } from "../../../utils/request/PostRequest";

const BillingExportButton = ( { template, refresh, ...props } ) => {
  function transformIds( auftragsnummern ) {
    let getRequestIds = ''
    auftragsnummern.map( function( a ) {
      if( a ) {
        getRequestIds += `&id[]=${a}`
      }
    } )

    return getRequestIds
  }

  const notify = useNotify();

  const handleClick = async() => {
    const protocolURL = `${process.env.REACT_APP_API_PROTOCOL_URL}/?rechnungsnummer=${props.record.auftragsnummer}`;
    const apiURL = `${process.env.REACT_APP_API}`;
    let url = ''

    if( props?.record?.auftragsnummern.length === 0 ) {
      notify( `ACHTUNG! Rechnung besitzt keine zugeordneten Aufträge`, { type: "error" } );
      return;
    }


    if( template === 'export_outlay_vw' ) {
      url = protocolURL+'&pdftyp=vwprotokollundauslagen';
      url += refresh ? '&ueberschreiben=1' : ''
      url += transformIds( props?.record?.auftragsnummern )
    } else if( template === 'export_outlay_athlon' ) {
      url = protocolURL+'&pdftyp=athlonausgaben';
      url += refresh ? '&ueberschreiben=1' : ''
      url += transformIds( props?.record?.auftragsnummern )
    } else if( template === 'export_contracts_vw' ) {
      url = apiURL+'/'
      url += await getPDFLink( props?.record[ '_id' ], 'euroleasing' )
    } else if( template === 'export_contracts_athlon' ) {
      url = apiURL+'/'
      url += await getPDFLink( props?.record[ '_id' ], 'athlon_rental' )
    }


    window.open( url, "_blank" );
    // window.open( url, "_blank" );
  }

  return <Button
    label={"Dokument anzeigen"}
    onClick={() => {
      handleClick()
        .then( () => {
          notify( `PDF erfolgreich generiert`, { type: "success" } );
        } )
        .catch( ( error ) => {
          let text = error.message
          if( typeof error.response?.data[ 'hydra:description' ] !== "undefined" ) {
            text += `\n${error.response?.data[ 'hydra:description' ]}`
          }
          notify( text, { type: "warning", multiLine: true } );
        } )
    }} {...props}/>
}

export default BillingExportButton;
