// in src/groupByDay.js
const sortByDate = (a, b) => new Date(a).valueOf() - new Date(b).valueOf();
const sortBySortOrder = (a, b) => a.sortOrder === b.sortOrder ? a.id < b.id ? -1 : 1 : a.sortOrder < b.sortOrder ? -1 : 1;
// const sortByDriver = (a, b) => a.sortOrder === b.sortOrder ? a.id < b.id ? -1 : 1 : a.sortOrder < b.sortOrder ? -1 : 1;
const sortByDriver = (a, b) =>{
  if(!a.driver){
    return -1;
  }
  if(!b.driver){
    return 1;
  }

  if(a.driver['@id'] === b.driver['@id']){
    return 0;
  }

  return a.driver['kuerzel'] < b.driver['kuerzel'] ? -1 : 1;
}



const getDayForItem = (item, type) => {
  if( !item ||
      ( type === 'delivery' && !item.bringdatum ) ||
      ( type === 'pickup' && !item.datum ) ) {
    return "Unbekannt";
  }

  const dateString = type === "delivery" ? item.bringdatum : item.datum;
  if( !dateString ) {
    return "Unbekannt";
  }
  const date = new Date( dateString );
  if( isNaN( date.getFullYear() ) ) {
    return "Unbekannt";
  }

  date.setMilliseconds(0);
  date.setSeconds(0);
  date.setMinutes(0);
  date.setHours(0);
  return date.toISOString();
};

const pushItemToDays = (day, daysArray, item) => {
  if (!daysArray[day]) {
    daysArray[day] = [];
  }
  daysArray[day] = daysArray[day].concat(item);

  return daysArray;
};

const groupByDay = (items) => {
  const groups = items.reduce((days, item) => {
    const pickupDay = getDayForItem(item, "pickup");
    const deliveryDay = getDayForItem(item, "delivery");

    if (pickupDay === deliveryDay) {
      pushItemToDays(pickupDay, days, {...item, "isPickupDayItem": true });
    } else {
      pushItemToDays(pickupDay, days, {...item, "isPickupDayItem": true });
      pushItemToDays(deliveryDay, days, {...item, "isDeliveryDayItem": true });
    }

    return days;
  }, {});

  const keys = Object.keys(groups)
    keys.forEach(key => {
        const vhPickUpToday = groups[key].filter(item => getDayForItem(item, "pickup") === key && getDayForItem(item, "delivery") !== key);
        const vhDeliveryToday = groups[key].filter(item => getDayForItem(item, "pickup") !== key && getDayForItem(item, "delivery") === key);
        const defaults = groups[key].filter(item => getDayForItem(item, "pickup") === key && getDayForItem(item, "delivery") === key);

        vhPickUpToday.sort(sortBySortOrder);
        vhDeliveryToday.sort(sortBySortOrder);
        defaults.sort(sortBySortOrder);

        const sortedContracts = vhDeliveryToday.concat( defaults ).concat( vhPickUpToday ).sort( sortByDriver )
        let groupedList = []
        let sortedListItems = []

        // creating a grouped list to be able to sort bundle contracts within the same group. The list will be flattened afterwards.
        for( let item of sortedContracts ) {
          if( !item.bundles?.length) {
            groupedList.push(item);
            continue
          }

          for( let bundle of item.bundles ) {
            let group = groupedList.find( (element) => element.groupId === bundle.bundle )
            if( !group ) {
              const group = {
                groupId: bundle.bundle,
                contracts: [item]
              }
              groupedList.push(group);
              continue
            }
            group.contracts.push( item )
          }
        }

        for( let item of groupedList ) {
          if( item.contracts ) {
            let sortedList = item.contracts.sort( (a,b) => {
              // For contracts which contain itself in bundles, the iri is provided in the contract object.
              // So we need to check if the contract is an object or a string.
              const sortOrderA = a.bundles[0].bundleContracts.find( bundleContract => {
                if( typeof bundleContract.contract === 'object' ) {
                  return bundleContract.contract['@id'] === a.id
                }
                return bundleContract.contract === a.id
              } )?.sortOrder

              const sortOrderB = a.bundles[0].bundleContracts.find( bundleContract => {
                if( typeof bundleContract.contract === 'object' ) {
                  return bundleContract.contract['@id'] === b.id
                }
                return bundleContract.contract === b.id
              } )?.sortOrder

              return sortOrderA > sortOrderB ? 1 : -1
            } )

            for( let contract of sortedList ) {
              sortedListItems.push( contract )
            }
            continue
          }

          sortedListItems.push(item)
        }

        groups[key] = sortedListItems;
  })

  return {
    days: keys.sort(sortByDate),
    eventsByDay: groups,
  };
};

const dateIsValid = (date) => {
  const timestamp = Date.parse(date);
  return isNaN(timestamp) === false;
};

export default groupByDay;
