import { VwImportsList } from "./ImportsList";
import EntityEdit from "./VWImportsEdit";

const VWImports = {
  list: VwImportsList,
  edit: EntityEdit,
  name: "vw_imports"
};

export default VWImports;
