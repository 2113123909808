import React, { useState } from "react";
import {
  Edit,
  FormWithRedirect, linkToRecord,
} from "react-admin";
import { Box, Toolbar, Popper, IconButton, Collapse, Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import { useNotify, useRefresh } from "react-admin";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckIcon from "@material-ui/icons/Check";
import InfoIcon from '@material-ui/icons/Info';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { setContractDriver, updateContract} from "../utils/request/PatchRequest";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import DefaultEditActions from "../utils/DefaultEditActions";
import { DriverReferenceInput } from "../drivers/components/DriverReferenceInput";
import ChangeDriverButton from "./components/Buttons/ChangeDriverButton";
import GlobalLoading from "../utils/components/GlobalLoading";
import DeclineButton from "./components/Buttons/DeclineButton";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import classnames from "classnames";
import EntityLink from "../utils/components/EntityLink";
import WarningIcon from "@material-ui/icons/ReportProblem";
import { SaveButtonGroup } from "./components/Buttons/SaveButtonGroup";
import Link from "../utils/components/Link";
import IconLink from "@material-ui/icons/InsertLink";



const useStyles = makeStyles( theme => ( {
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: "rgba(64,64,64, 0.04)"
    },

    '&.hinfahrt, &.rueckfahrt': {
      backgroundColor: "rgba(100,181,246,0.12)",

    },

    '&.hinfahrt td': {
      borderBottomWidth: 0
    },

    '&.closed': {
      backgroundColor: "rgba(250,195,30,0.08)",
    },

    '&:hover': {
      //   filter: "brightness(85%)",
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  },

  tableCell: {
    paddingBottom: 0,
    paddingTop: 0
  }
} ), { name: 'FancyTableStyles' } )

const StyledWarningIcon = ( props ) => (
  <WarningIcon style={{ color: "#FFC75F", margin: 0 }}/>
)

const DeactivateBundleCheckbox = ({bundleId}) => {
  const [checked, setChecked] = useState( true );
  return <FormControlLabel
    style={{"padding": 0}}
    label={'Bundle auflösen'}
    control={<Checkbox
      checked={checked}
      title={'Bundle auflösen'}
      inputProps={{ 'aria-label': 'Änderungen übernehmen', 'data-id': bundleId }}

      name="bundles[]"
      onChange={( event ) => {
        setChecked( event.target.checked );
      }}
    />}
  />
}


const EntityForm = ( props ) => {
  const [loading, setLoading] = useState( false );

  const notify = useNotify();
  const refresh = useRefresh();

  const classes = useStyles();

  const HsContractTableRow = ( { item, index, formProps } ) => {
    const [open, setOpen] = useState( true );
    const disabled = { disabled: item.imported || item.isDeclinedByVermaco }

    const CustomCheckbox = ( { item, field = "confirm", title="Änderungen übernehmen", label = "Änderungen"}  ) => {
      return <FormControlLabel
        style={{"padding": 0, minWidth: '160px'}}
        label={label}
        control={<Checkbox
          source={`auftraegeArray[${index}].$field`}
          checked={item[field]}
          title={title}
          inputProps={{ 'aria-label': 'Änderungen übernehmen' }}

          onChange={( event ) => {
            setLoading( true );
            item[field] = event.target.checked;
            if( item.anschluss ) {
              item.anschluss[field] = event.target.checked;
            }
            let dataObj = {[field]: item[field]}
            updateContract( item.id, dataObj, "api/import_contracts" )
              .then( () => {
                // refresh()
                setLoading( false );
                notify( `custom.message.success`, { type: "success" } );
              } ).catch( error => {
              setLoading( false );
              notify( `Fehler beim ändern des Status: ${error.message}`, { type: "warning" } );
              refresh()
            } );
          }
        }
      />} />
    }

    const StyledCell = ( props ) => (
      <TableCell className={classnames( classes.tableCell )} {...props} />
    )

    const CollapsableCell = ( props ) => (
      <StyledCell {...props}>
        <Collapse in={open} timeout={"auto"}>
          {props.children}
        </Collapse>
      </StyledCell>

    )

    const StyledTableRow = ( { className, ...props } ) => {
      const cl = classnames( classes.tableRow )+" "+className;

      return (
        <TableRow hover={false} className={cl}>
          {props.children}
        </TableRow>
      )
    };

    let classNames = "";

    if( item.anschluss ) {
      classNames += " hinfahrt";
    } else if( item.is_anschlussauftrag ) {
      classNames += " rueckfahrt";
    } else if( item.previousContract?.abgeschlossen ) {
      classNames += " closed";
    }
    return <React.Fragment>
      <StyledTableRow key={item.id.toString()} className={classNames}>
        <StyledCell style={{ padding: "0 5px" }} align="center">
          {item.anschluss && <IconButton aria-label="expand row" size="small" onClick={() => setOpen( !open )}>
            <Box display={"flex"} flexDirection={"column"}>
              {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
              <Box style={{ fontSize: "0.9rem" }}>{item.labels.abx}</Box>
            </Box>
          </IconButton>}

        </StyledCell>

        <StyledCell width={"25px"} style={{ padding: "0 5px" }} align="center">
          {( ( item.differences && !item.storno ) || item.infoFahrDL ) && (
            <InfoPopper record={item}/>
          )}
        </StyledCell>

        <StyledCell>
          <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
            {item.previousContract?.abgeschlossen && <StyledWarningIcon/>} {item.labels.type}
          </Box>
        </StyledCell>


        <StyledCell>
          {item.kennzeichen}<br/>
          <EntityLink record={item?.previousContract} context={"Contract"} label={item?.previousContract?.auftragsnummer}/><br />
          {item.bundleId && <Link to={() => linkToRecord( '/bundles', item.bundleId, 'edit')} icon={<IconLink />} style={{justifyContent: 'left'}}>{item?.bundleName}</Link>}
        </StyledCell>

        <StyledCell>
          <Box style={{ whiteSpace: "nowrap" }}>
            {item.labels.pickup}
          </Box>

          <Box style={{ whiteSpace: "nowrap" }}>
            {item.labels.delivery}
          </Box>
        </StyledCell>

        <StyledCell>
          {item.vwkm} KM
        </StyledCell>

        <StyledCell>
          {item.confirm && <>
            <strong style={{ color: item.isDeclinedByVermaco ? '#C82333FF' : '#4CAF50', display: 'flex', alignItems: 'center' }}>
              {item.isDeclinedByVermaco ? <>
                <CancelIcon style={{ marginRight: "5px" }}/>
                {item.imported ? `Am ${item.formattedLastChangeDate} Abgelehnt` : 'wird abgelehnt'}
              </> : <>
                 <CheckIcon style={{ marginRight: "5px" }}/>
                 {item.imported ? `Am ${item.formattedLastChangeDate} importiert` : 'wird erstellt / bearbeitet'}
               </>
              }
            </strong>
          </>}
        </StyledCell>

        <StyledCell style={{ minWidth: "250px" }}>
          <DriverReferenceInput source={`auftraegeArray[${index}].driver`}
                                onChange={ resourceId => {
                                  if( loading ) {
                                    return;
                                  }

                                  setLoading( true );
                                  if( !resourceId ) {
                                    resourceId = null;
                                  }

                                  setContractDriver( item.id, resourceId, "api/import_contracts" )
                                    .then( () => {
                                      formProps.record.auftraegeArray[index].driver = resourceId
                                      setLoading( false );
                                      refresh()
                                      notify( `custom.message.success`, { type: "success" } );
                                    } ).catch( error => {
                                    setLoading( false );
                                    notify( `Fehler beim ändern des Fahrers: ${error.message}`, { type: "warning" } );
                                    refresh();
                                  } );
                                }}
                                {...disabled}/>
        </StyledCell>

        <StyledCell>
          <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
            <Box p={"0 2rem 0 0"}>Übernehmen:</Box>
            <FormGroup>
              <CustomCheckbox item={formProps.record.auftraegeArray[ index ]} />
              <CustomCheckbox item={formProps.record.auftraegeArray[ index ]} field={"saveInfoFahrDL"} title={"Info-Text übernehmen"} label="Info-Text"/>
              {item.showCancelBundleButton && <DeactivateBundleCheckbox bundleId={item.bundleId} />}
            </FormGroup>
          </Box>

        </StyledCell>

        <StyledCell>
          <ButtonGroup orientation="horizontal">
            {[
              <ChangeDriverButton
                key="removeDriver"
                itemId={item.id}
                driverId={null}
                {...disabled}
                record={formProps.record.auftraegeArray[ index ]}>
                entfernen
              </ChangeDriverButton>,

              <ChangeDriverButton
                key="setVermaco"
                itemId={item.id}
                {...disabled}
                record={formProps.record.auftraegeArray[ index ]}>
                Vermaco
              </ChangeDriverButton>,

              <DeclineButton
                key={"decline"}
                record={item}
              />
            ]}
          </ButtonGroup>
        </StyledCell>
      </StyledTableRow>

      {item.anschluss && <StyledTableRow className={"rueckfahrt"}>
        <CollapsableCell style={{ padding: "0 5px" }} align={"center"}>
        </CollapsableCell>

        <CollapsableCell width={"25px"} style={{ padding: "0 5px" }} align="center">
          {( ( item.anschluss.differences && !item.anschluss.storno ) || item.anschluss.infoFahrDL ) && (
            <InfoPopper record={item.anschluss}/>
          )}
        </CollapsableCell>


        <CollapsableCell>
          <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
            {item.anschluss?.previousContract?.abgeschlossen && <StyledWarningIcon/>}{item.anschluss.labels.type}
          </Box>
        </CollapsableCell>

        <CollapsableCell>
          {item.anschluss.kennzeichen}<br/>
          {item.anschluss.previousContract && <EntityLink record={item.anschluss.previousContract} context={"Contract"} label={item.anschluss.previousContract.auftragsnummer}/>}
          {item.anschluss.bundleId && <Link to={() => linkToRecord( '/bundles', item.anschluss.bundleId, 'edit')} icon={<IconLink />}  style={{justifyContent: 'left'}}>{item?.anschluss.bundleName}</Link>}
        </CollapsableCell>

        <CollapsableCell>
          <Box style={{ whiteSpace: "nowrap" }}>
            {item.anschluss.labels.pickup}
          </Box>

          <Box style={{ whiteSpace: "nowrap" }}>
            {item.anschluss.labels.delivery}
          </Box>
        </CollapsableCell>

        <CollapsableCell>{item.anschluss.vwkm} KM</CollapsableCell>

        <CollapsableCell>
          {item.anschluss.confirm && <>
            <strong style={{ color: item.anschluss.isDeclinedByVermaco ? '#C82333FF' : '#4CAF50', display: 'flex', alignItems: 'center' }}>
              {item.anschluss.isDeclinedByVermaco ? <>
                <CancelIcon style={{ marginRight: "5px" }}/>
                {item.anschluss.imported ? `Am ${item.anschluss.formattedLastChangeDate} Abgelehnt` : 'wird abgelehnt'}
              </> : <>
                 <CheckIcon style={{ marginRight: "5px" }}/>
                 {item.anschluss.imported ? `Am ${item.anschluss.formattedLastChangeDate} importiert` : 'wird erstellt / bearbeitet'}
               </>
              }
            </strong>
          </>}
        </CollapsableCell>

        <CollapsableCell>
          <DriverReferenceInput source={`auftraegeArray[${index}].anschluss.driver`}
                                onChange={resourceId => {
                                  setLoading( true );
                                  if( !resourceId ) {
                                    resourceId = null;
                                  }

                                  setContractDriver( item.anschluss.id, resourceId, "api/import_contracts" )
                                    .then( () => {
                                      formProps.record.auftraegeArray[ index ].anschluss.driver = resourceId
                                      setLoading( false );
                                      notify( `custom.message.success`, { type: "success" } );
                                    } ).catch( error => {
                                    notify( `Fehler beim ändern des Fahrers: ${error.message}`, { type: "warning" } );
                                    setLoading( false );
                                    refresh();
                                  } );
                                }}
                                {...disabled}/>
        </CollapsableCell>

        <CollapsableCell>
          <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
            <Box p={"0 2rem 0 0"}>Übernehmen:</Box>
            <FormGroup>
              <CustomCheckbox item={formProps.record.auftraegeArray[ index ].anschluss} />
              <CustomCheckbox item={formProps.record.auftraegeArray[ index ].anschluss} field={"saveInfoFahrDL"} title={"Info-Text"} label="Info-Text" />
              {item.anschluss.showCancelBundleButton && <DeactivateBundleCheckbox bundleId={item.anschluss.bundleId} />}
            </FormGroup>
          </Box>
        </CollapsableCell>

        <CollapsableCell>
          <ButtonGroup orientation="horizontal">
            {[
              <ChangeDriverButton
                key="removeDriver"
                itemId={item.anschluss.id}
                driverId={null}
                {...disabled}
                record={formProps.record.auftraegeArray[ index ].anschluss}>
                entfernen
              </ChangeDriverButton>,

              <ChangeDriverButton
                key="setVermaco"
                itemId={item.anschluss.id}
                {...disabled}
                record={formProps.record.auftraegeArray[ index ].anschluss}>
                Vermaco
              </ChangeDriverButton>,

              <DeclineButton
                key={"decline"}
                record={item.anschluss}
              />
            ]}
          </ButtonGroup>
        </CollapsableCell>
      </StyledTableRow>}
    </React.Fragment>

  }

  return (
    <FormWithRedirect
      warnWhenUnsavedChanges
      {...props}
      render={( formProps ) => {

        return (
          // here starts the custom form layout
          <form style={{ padding: "1rem", marginBottom: "5rem", position: "relative" }}>
            {loading &&<GlobalLoading/>}

            <TableContainer>
              <Table size="small">
                <TableBody>
                  {formProps.record.auftraegeArray?.map( function( item, index ) {
                    return item.hide ? null : <HsContractTableRow key={`${item.id}`} item={item} index={index} formProps={formProps} />
                  } )}
                </TableBody>
              </Table>
            </TableContainer>


                <SaveButtonGroup />

          </form>
        );
      }}
    />
  );
};

const ImportContractDifferencesList = ( { record = [] } ) => {
  if( !record ) {
    return null;
  }
  const toArray = Array.isArray( record.differences )
                  ? record.differences
                  : Object.values( record.differences );

  if( toArray.length === 0 ) {
    return null;
  }

  return (
    <TableContainer>
      <Table size="small" stickyHeader style={{ width: "100%" }}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell colSpan={2}>
              Version {record.previousContract.vwfahrtversion}
            </TableCell>
            <TableCell>Version {record.vwfahrtversion}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {toArray.map( ( diff, index ) => (
            <TableRow key={{ index }}>
              <TableCell>{diff.fieldName}</TableCell>
              <TableCell>{diff.old}</TableCell>
              <TableCell>{"=>"}</TableCell>

              <TableCell>{diff.new}</TableCell>
            </TableRow>
          ) )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};


const InfoPopper = ( { record } ) => {
  const [anchorEl, setAnchorEl] = React.useState( null );

  const handleClick = ( event ) => {
    setAnchorEl( anchorEl ? null : event.currentTarget );
  };

  const open = Boolean( anchorEl );
  const id = open ? 'simple-popper' : undefined;
  const infoIcon = open ? <InfoIcon/> : <InfoOutlinedIcon/>;

  return (
    <Box key={record.id}>
      <button aria-describedby={id}
              type="button"
              onClick={handleClick}
              style={{ background: "none", border: "none", cursor: "pointer", padding: "0", display: "flex", alignItems: "center" }}>{infoIcon}</button>
      <Popper id={id} open={open} anchorEl={anchorEl} style={{ zIndex: 1 }}>
        {/*<ThemeProvider theme={vermacoTheme}>*/}
        <Box sx={{
          bgcolor: 'background.paper',
          borderRadius: 8,
          p: 2,
          border: 1,
          borderColor: '#000',
          borderStyle: 'solid',
        }}>
          <Box>
            {record.infoFahrDL && <>
              <strong>Infos</strong>
              <br/>
              {record.infoFahrDL}
            </>}

            {record.differences && !record.storno && (
              <>
                <br/>
                <br/>

                <strong>Änderungen</strong>
                <br/><br/>
                <ImportContractDifferencesList
                  record={record}
                />
              </>
            )}
          </Box>
        </Box>
        {/*</ThemeProvider>*/}
      </Popper>
    </Box>
  );
}

const EntityTitle = ( { record } ) => {
  return (
    <span>
      VW Import {record?.title} vom {record?.formattedCreatedAt}
    </span>
  );
};

const EntityEdit = ( props ) => (
  <Edit
    actions={<DefaultEditActions {...props}/>}
    title={<EntityTitle/>}
    {...props}
  >
    <EntityForm/>
  </Edit>
);

export default EntityEdit;
