import React from "react";

import { useDataProvider } from "react-admin";
import { Button, IconButton } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/RemoveCircleOutline";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CurrencyInput from "../../utils/components/InputComponents/CurrencyInput";
import NumberInput from "../../utils/components/NumberInput";
import { useRecordContext } from "ra-core";


const PriceFormIterator = (props) => {
  const {type} = props;
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const addButtonClickHandler = () => {
    let item = {
      distance:  props.fields.value.length * 50 + 50,
      price: 0,
      type: type,
    }

    if( record ) {
      item.priceGroup = record.id;
    }

    props.fields.push( item );
  }

  const removeItem = (index) => {
    if( props.fields.value[index]['@id'] ) {
      dataProvider.delete('prices', {id: props.fields.value[index]['@id']})
        .then(() => {
          props.fields.remove(index)
        })
    } else {
      props.fields.remove(index)
    }

  }

  const classes = useStyles();
  props.fields.value.sort( ( a, b ) => { return a.distance - b.distance } )
  return (
    <>
      <Table>
        <TableBody>
          {props.fields.map( ( item, index ) => {
            return <TableRow key={index}>
              <TableCell class={classes.col}>
                <NumberInput source={`${item}.distance`}
                           label={"KM"}
                           helperText={false}
                           inputProps={{ tabindex: "-1" }}
                           resettable={false}
                           style={{marginBottom: "0"}}/>
              </TableCell>
              <TableCell class={classes.col}>
                <CurrencyInput source={`${item}.price`} label={"Preis"} />
              </TableCell>
              <TableCell class={classes.col}>
                <IconButton color={"primary"} tabIndex={"-1"} onClick={() => removeItem(index)}>
                  <CloseIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          } )}

          <TableRow>
            <TableCell colSpan={3} class={classes.col}>
              <Button
                color={'primary'}
                label="ra.action.add"
                onClick={() => addButtonClickHandler()}>
                <AddIcon/> Neu
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  )
}

const useStyles = makeStyles( ( theme ) => ( {
  col: {
    padding: "0 5px 0 5px",
    textAlign: "left",
    '> p': {
      margin: "0 0 0 0",
    }
  },
  listItem: {
    padding: '0 5px 0 5px',
    display: 'flex',
    backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.default,
    // borderBottom: "2px solid rgba(128,128,128, 0.75)",
    '&:nth-child(odd)': {
      // boxShadow: "rgb(0,0,0,0.26) 0px 2px 20px 0px",
      backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.odd,
    },
    '&:hover': {
      backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.hover,
    },
  },

  listItemControl: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center"
  },

  currentPos: {
    display: "flex",
    alignItems: "center",
  },
  textInput: {
    margin: "8px 3px 4px 3px",
    float: 'left',
  }
} ) )


export default PriceFormIterator;
