import { ReferenceArrayInput as DefaultReferenceArrayInput } from "react-admin";

const ReferenceArrayInput = DefaultReferenceArrayInput;

ReferenceArrayInput.defaultProps = {
  perPage: 200,
  filter: { aktiv: "1" },
};

export default ReferenceArrayInput;
