import {
  Button, Confirm,
  useNotify,
  useRefresh
} from 'react-admin';
import { resetBillingPos } from "../../request/PatchRequest";
import { Fragment, useState } from "react";
import ReceiptIcon from "@material-ui/icons/Receipt";
import * as React from "react";
import GlobalLoading from "../GlobalLoading";

export default ( props ) => {
  const [open, setOpen] = useState( false );
  const [loading, setLoading] = useState( null );

  const notify = useNotify();
  const refresh = useRefresh();
  if( !props ) {
    return null;
  }


  const { record, ...rest } = props
  const buttonStyle = {
    backgroundColor: '#C82333',
    color: "white",
    padding: '0.25rem 1rem'
  }

  const handleClick = () => setOpen( true );
  const handleDialogClose = () => setOpen( false );

  const handleConfirm = async() => {
    setLoading( true );
    await resetBillingPos( record['@id'], '', true );
    setOpen( false );
    setLoading( false );
    refresh();
  };

  return <>
    {loading && <GlobalLoading/>}

    <Button
      label={"Rechnungspositionen zurück setzen"}
      style={buttonStyle}
      onClick={() => {
        handleClick()
      }} {...rest}/>
    <Confirm
      isOpen={open}
      loading={loading}
      title="Rechnungspositionen zurücksetzen"
      content="Möchten Sie die Rechnungspositionen wirklich zurück setzen? Nicht gespeicherte Änderungen im Formular gehen verloren!"
      onConfirm={handleConfirm}
      onClose={handleDialogClose}
    />
  </>

};
