import React from "react";
import { BooleanField, TextField } from "react-admin";
import HotList from "../utils/components/HotList";
import DateField from "../utils/components/DateField";
import { Box } from "@material-ui/core";
import { ParagraphHeadline, Spacer } from "../utils/components/FormElements";


export default ( props ) => (
  <Box>
    <Spacer />

    <ParagraphHeadline><strong>IN VORBEREITUNG!</strong></ParagraphHeadline>
    <HotList {...props}
             sort={"createdAt"}
             sortOrder={"DESC"}
             filter={{ isSend: false }}
             allowCreate={true}>
        <TextField source={"entityId"} label={"#"} sortBy="id"/>
      <TextField source={"title"} label={"Titel"} />
      <DateField source={"createdAt"} label={"Erstellt am"} />
      <BooleanField source={"showInFahrerportal"} label={"Fahrerportal"} />
      <BooleanField source={"showInKundenportal"} label={"Kundenportal"} />
      <BooleanField source={"showInApp"} label={"App"} />
      <BooleanField source={"showForEmployees"} label={"Für Mitarbeiter"} />


    </HotList>
  </Box>
);

