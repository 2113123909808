import {
  AutocompleteArrayInput,
  AutocompleteInput,
  ReferenceInput,
} from "react-admin";
import ReferenceArrayInput from "../../utils/components/ReferenceArrayInput";


export const LeasingProtocolReferenceInput = ( props ) => {
  let filterValues = {
    ...props.filter
  }

  return <ReferenceInput
    label={"Leasing-Protokoll"}
    reference={"leasing_protocols"}
    // allowEmpty={true}
    fullWidth
    resettable
    filter={filterValues}
    format={
      item => item && item.hasOwnProperty('@id') ? item['@id'] : item
    }
    filterToQuery={( searchText ) => ( { _name: searchText } )}
    perPage={200}
    helperText={false}
    {...props}
  >
    <AutocompleteInput
      optionText={"name"}
    />
  </ReferenceInput>
}

export const LeasingProtocolReferenceArrayInput = ( props ) => {
  const { filter, onSelect, customer } = props

  let filterValues = {
    ...filter
  }


  return <ReferenceArrayInput
    label={"Leasing-Protokolle"}
    reference={"leasing_protocols"}
    // allowEmpty={true}
    fullWidth
    resettable
    filter={filterValues}
    format={
      list => list && list.map(
        item => {
          return item.hasOwnProperty('@id') ? item['@id'] : item
        }
    )}
    filterToQuery={( searchText ) => ( { _name: searchText } )}
    perPage={200}
    helperText={false}
    {...props}
  >
    <AutocompleteArrayInput
      optionText={"name"}
      onSelect={onSelect}
    />
  </ReferenceArrayInput>
}
