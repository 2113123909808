import { SearchInput, BooleanInput, AutocompleteArrayInput, DateInput, SelectArrayInput, ChipField } from "react-admin";
import "moment/locale/de";
import React from "react";
import { CustomerReferenceArrayInput } from "../../customers/components/CustomerReferenceInput";
import ReferenceArrayInput from "../../utils/components/ReferenceArrayInput";
import translateDriverLabel from "../../contracts/components/TranslateDriverLabel";
import moment from 'moment';
import "moment/locale/de";
import { DriverReferenceArrayInput } from "../../drivers/components/DriverReferenceInput";

moment.locale( "de" );

const billingAddressFilter = [
  <SearchInput
    placeholder="Suche"
    source="_name"
    style={{ minWidth: "250px", marginTop: "0" }}
    alwaysOn
  />,
  <BooleanInput source="active" label="Aktiv" alwaysOn/>,
];

const billingAddressFiltersDefaultValues = {
  // "abgeschlossen": "0"
  'active': true
};

export {
  billingAddressFilter,
  billingAddressFiltersDefaultValues
}
