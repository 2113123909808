import { ArrayInput, DateTimeInput, Edit, FormDataConsumer, FormTab, SelectInput, TabbedForm, TextInput, useDataProvider, useNotify, } from "react-admin";
import ImageInput from '../utils/components/ImageInput';
import { Lightbox } from 'react-modal-image';
import loadImage from 'blueimp-load-image';
import * as React from "react";
import { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import DefaultEditActions from "../utils/DefaultEditActions";
import DateField from "../utils/components/DateField";
import { PatchRequest } from '../utils/request/PatchRequest';
import { CloseButton, EditActions, imageTypes, ProtocolToolbar, ResetSignatureButton } from "./components";
import SimpleFormIndexableIterator from "../utils/components/SimpleFormIndexableIterator/SimpleFormIndexableIterator";
import AccessoriesTab from "./components/Formtabs/Accessories";
import { InputContainer, LeftBox, RightBox } from "../utils/components/FormElements";
import AWSImagesTab from "./components/Formtabs/AWSImagesTab";
import DamagesTab from "./components/Formtabs/DamagesTab";
import DefaultImagesTab from "./components/Formtabs/DefaultImagesTab";
import DriverExpenses from "./components/Formtabs/DriverExpenses";
import moment from "moment/moment";
import SnapshotsTab from "./components/Formtabs/SnapshotsTab";
import MailsTab from "./components/Formtabs/MailsTab";
import DrivingDataTab from "./components/Formtabs/DrivingDataTab";
import LazyImageField from "../utils/components/LazyImageField";
import ComplaintsTab from "./components/Formtabs/ComplaintsTab";
import AppEventsTab from "./components/Formtabs/AppEventsTab";

moment.locale( "de" );


/**
 * @property record.orderNumber
 * @param record
 * @constructor
 */
const ProtocolTitle = ( { record } ) => {
  return <span>Auftragsnachbearbeitung {record ? `"${record.orderNumber}"` : ""}</span>;
};



const addImgPlaceholders = record => {
  const orphanedImages = record.images.filter(image => ! image.imageType);
  const imagesWithPlaceholders = imageTypes.map((imageType, _) => record.images.find(o => o.imageType === imageType.id) ?? {
    "file": null,
    "imageType": imageType.id,
  });
  return [...imagesWithPlaceholders, ...orphanedImages];
};

const removeImgPlaceholders = record => record.images.filter(image => image.file !== null);

const ProtocolForm = ( props ) => {

  const [isImagePopupOpen, setIsImagePopupOpen] = useState( false );
  const [imagePopupRef, setImagePopupRef] = useState( null );
  const [imagePopupSrc, setImagePopupSrc] = useState( null );
  const [imagePopupFileInputId, setImagePopupFileInputId] = useState( null );
  const [imagePopupIri, setImagePopupIri] = useState( null );

  props.record.images = addImgPlaceholders(props.record);

  const r = props.record;

  const onChangeImageInput = ( newFile, property ) => {
    try {
      const oldFile = property.split( '.' ).reduce( ( o, i ) => o[ i ], r );
      if( oldFile?.imageId ) {
        delete oldFile.imageId;
      }
    } catch (e) {
      console.warn(e.message);
    }
  }

  const getOrientation = ( angle ) => {
    const orientations = {
      '90': 6,
      '180': 3,
      '270': 8,
      'default': 1
    }

    return orientations[ angle ] || orientations[ 'default' ];
  }

  const notify = useNotify();

  const toggleRotateBtn = (active) => {
    const rotateBtn = document.querySelector( '.__react_modal_image__icon_menu > a:nth-child(3)' );
    if( rotateBtn ) {
      if( active === true ) {
        rotateBtn.style.opacity = 1;
        rotateBtn.style.pointerEvents = 'auto';
        rotateBtn.style.cursor = 'pointer';
      } else {
        rotateBtn.style.opacity = .5;
        rotateBtn.style.pointerEvents = 'none';
        rotateBtn.style.cursor = 'default';
      }
    }
  }

  const observer = new MutationObserver((mutations, observer) => {
    mutations.forEach((mutation) => {
      if (mutation.type === 'attributes') {
        toggleRotateBtn();
        if (imagePopupIri) {
          PatchRequest(process.env.REACT_APP_API + imagePopupIri, {
            rotateAngle: '90'
          }).then((response) => {
            if (response && response.status === 200) {
              imagePopupRef.src = response.data.contentUrl
              notify( 'Bild wurde aktualisiert', { type: 'success' } );
            }
          }).finally(() => {
            toggleRotateBtn(true);
          });
        } else {
          const angle = parseRotationDegreeValue(mutation.target);
          imageAngles = Object.assign({}, imageAngles, {[imagePopupFileInputId]: angle});
          loadImage(imagePopupSrc, { orientation: getOrientation(angle) }).then((data) => {
            imagePopupRef.src = data.image.toDataURL();
          }).catch((err) => {
            notify( 'Bild konnte nicht gespeichert werden', { type: 'warning' } );
            console.warn( err );
          }).finally(() => {
            toggleRotateBtn(true);
          });
        }
      }
    });
  });

  const onImageClick = ( e, onlyPreview = false ) => {
    const image = e.target;

    setIsImagePopupOpen( true );
    setImagePopupRef( image );
    setImagePopupSrc( image.src );
    if( onlyPreview ) {
      setImagePopupFileInputId( null );
      setImagePopupIri( null );
    } else {
      const inputFile = image.closest( '.previews' ).parentNode.querySelector( 'input[type="file"]' );
      const iriSource = `r.${inputFile.id}?.imageId`;
      let iri = null;
      try {
        iri = eval( iriSource );
      } catch( e ) {
        console.warn( e.message );
      }
      setImagePopupFileInputId( inputFile.id );
      setImagePopupIri( iri );
    }

    toggleRotateBtn()
  };

  const onImagePopupClose = () => {
    setIsImagePopupOpen( false );
    setImagePopupSrc( null );
    setImagePopupFileInputId( null );
    setImagePopupRef( null );
    setImagePopupIri( null );

    observer.disconnect();
  }

  const onImagePopupOpen = () => {
    addMarginTop();
    handleImageRotation();
    if( !imagePopupIri ) {
      toggleRotateBtn()
    }
  }


  const handleImageRotation = () => {
    const image = document.getElementById( 'react-modal-image-img' );
    observer.observe( image, { attributes: true } );
  }

  const addMarginTop = () => {
    const imagePopup = document.getElementsByClassName( '__react_modal_image__modal_container' ).item( 0 );
    const appBar = document.getElementsByClassName( 'MuiAppBar-root' ).item( 0 );
    const isAppBarHidden = appBar && appBar.style.visibility === 'hidden';
    if( imagePopup && !isAppBarHidden ) {
      imagePopup.style.marginTop = '48px';
    }
  }

  const parseRotationDegreeValue = (el) => {
    const st = window.getComputedStyle(el, null);
    const tm = st.getPropertyValue("-webkit-transform") ||
      st.getPropertyValue("-moz-transform") ||
      st.getPropertyValue("-ms-transform") ||
      st.getPropertyValue("-o-transform") ||
      st.getPropertyValue("transform") ||
      "none";
    if (tm != "none") {
      const values = tm.split( '(' )[ 1 ].split( ')' )[ 0 ].split( ',' );
      const angle = Math.round( Math.atan2( values[ 1 ], values[ 0 ] ) * ( 180 / Math.PI ) );

      return angle < 0
             ? angle+360
             : angle;
    }
    return 0;
  }

  useEffect( () => {
    if( isImagePopupOpen === true ) {
      onImagePopupOpen();
    }
  }, [isImagePopupOpen] )

  const getSignatureImageSource = ( type, name ) => !type || type === 'SIGNATURE'
                                                    ? name+".signatureImage.file"
                                                    : name+".signatureAltPhoto.documentPicture.file"
  ;

  return (
    <>
      <TabbedForm actions={<DefaultEditActions/>} {...props}>
        <FormTab label="Unterschriften">
          <Box p="1em" style={{ width: "100%" }}>
            <InputContainer display={"flex"}>
              <LeftBox flex={1} style={{ display: "flex", alignItems: "center" }}>

                <Typography variant="h6" gutterBottom style={{ marginRight: "1rem", marginBottom: 0 }}>
                  Status:
                </Typography>

                <SelectInput
                  style={{ minWidth: "250px" }}
                  source="processState"
                  label={"Status"}
                  choices={[
                    { id: 'UNPROCESSED', name: 'Nicht begonnen' },
                    { id: 'STARTED', name: 'Begonnen' },
                    { id: 'SUBMITTED', name: 'Abgeholt (U2)' },
                    { id: 'RECEIVED', name: 'Ausgeliefert (U3)' },
                    { id: 'CLOSED', name: 'Abgeschlossen (U4)' },
                  ]}/>
              </LeftBox>

              <RightBox flex={2} style={{ display: "flex", alignItems: "center" }}>
                <FormDataConsumer>
                  {( { formData } ) =>
                    <>
                      <Typography variant="h6" style={{ marginBottom: 0 }} gutterBottom>
                        {formData.closed ? <>A</> : <>Nicht a</>}bgeschlossen:
                      </Typography>
                      &nbsp;
                      <Box>
                        {formData.closed && <>
                          Auftrag abgeschlossen am: <DateField source={"closed"}
                                                               showTime={true}
                                                               options={{ year: "numeric", month: "2-digit", day: "2-digit", hour: '2-digit', minute: '2-digit' }}/>
                        </>}

                      <CloseButton record={formData}/>
                      </Box>
                    </>
                  }
                </FormDataConsumer>
              </RightBox>

            </InputContainer>


            <hr/>
            <br/>
            <Box display="flex">
              <Box flex={2} mr="1em">
                <Typography variant="h6" gutterBottom>
                  Unterschriften
                </Typography>

                <Typography gutterBottom>Fahrer</Typography>
                <Box display="flex">
                  <Box flex={1} mr="0.5em">
                    <TextInput
                      source={"driverSignature.name"}
                      label={"Name"}
                      fullWidth
                    />
                    <DateTimeInput
                      source={"driverSignature.signatureTime"}
                      label={"Unterschrieben am"}
                      fullWidth
                    />

                    <ResetSignatureButton type={"driverSignature"} state={"STARTED"} resetState={"STARTED"} {...props}/>
                  </Box>

                  <Box flex={1} mr="0.5em">
                    <ImageInput
                      source={"driverSignature.signatureImage.file"}
                      label={"Unterschrift Fahrer"}
                      onChange={( e ) => onChangeImageInput( e, "driverSignature.signatureImage.file" )}
                    >
                      <LazyImageField
                        source="remoteFileName"
                        title="Unterschrift Fahrer"
                        onClick={onImageClick}
                        loading={"lazy"}
                      />
                    </ImageInput>
                  </Box>
                </Box>

                <Typography gutterBottom>Unterschrift Abholung</Typography>
                <Box display="flex">
                  <Box flex={1} mr="0.5em">
                    <Box flex={1} mr="0.5em">
                      <Box display={"flex"}>
                        <InputContainer left>
                          <TextInput
                            source={"submitterSignature.name"}
                            label={"Name"}
                            fullWidth
                          />

                        </InputContainer>

                        <InputContainer center>
                          <DateTimeInput
                            source={"submitterSignature.signatureTime"}
                            label={"Unterschrieben am"}
                            fullWidth
                          />

                        </InputContainer>

                        <InputContainer right>
                          <TextInput
                            source={"submitterSignature.email"}
                            label={"E-Mail"}
                            fullWidth
                          />
                        </InputContainer>
                      </Box>
                    </Box>
                    <SelectInput
                      source={"submitterSignature.signatureType"}
                      label={"Unterschriften Alternative"}
                      emptyText={"Bitte wählen"}
                      defaultValue={"SIGNATURE"}
                      allowEmpty={true}
                      resettable={true}

                      choices={[
                        { id: "PARKING_SPACE", name: "Stellplatzabholung ohne Unterschrift, keine Haftung für protokollierte Schäden" },
                        { id: "HYGIENE", name: "Aus Hygienegründen ohne Unterschrift" },
                        { id: "CAR_DEALER_WITHOUT_SIGNATURE", name: "Fahrzeugübernahme ohne Mitwirkung eines Asp. Keine Haftung für bei der Übernahme aufgenommene Mängel" },
                        { id: "SIGNATURE_ON_PAPER", name: "Unterschrift siehe Papier-Protokoll" },
                        { id: "UPON_EXPRESS_REQUEST", name: "Unb. Abh. auf ausdrücklichen Wunsch von", disabled: true  },
                        { id: "SIGNATURE_REFUSED", name: "Unterschrift verweigert" },
                        { id: "SIGNATURE_NOT_AVAILABLE", name: "Wegen technischer Probleme ohne Unterschrift" },
                      ]}
                      fullWidth
                    />

                    <ResetSignatureButton type={"submitterSignature"} state={"STARTED"} resetState={"SUBMITTED"}  {...props}/>
                  </Box>
                  <Box flex={1} ml="0.5em">
                    <FormDataConsumer>
                      {({ formData }) => {
                        const source = getSignatureImageSource(formData?.submitterSignature?.signatureType, 'submitterSignature');
                        return (
                          <ImageInput
                            source={source}
                            label={"Unterschrift Abholung"}
                            onChange={( e ) => onChangeImageInput( e, source )}
                          >
                            <LazyImageField
                              source="remoteFileName"
                              title="Unterschrift Abholung"
                              onClick={onImageClick}
                              loading={"lazy"}

                            />
                          </ImageInput>
                        );
                      }}
                    </FormDataConsumer>
                  </Box>
                </Box>

                <Typography gutterBottom>Unterschrift Zustellung</Typography>
                <Box display="flex">
                  <Box flex={1} mr="0.5em">
                    <Box display={"flex"}>
                      <InputContainer left>
                        <TextInput
                          source={"receiverSignature.name"}
                          label={"Name"}
                          fullWidth
                        />
                      </InputContainer>

                      <InputContainer center>
                        <DateTimeInput
                          source={"receiverSignature.signatureTime"}
                          label={"Unterschrieben am"}
                          fullWidth
                        />
                      </InputContainer>

                      <InputContainer right>
                        <TextInput
                          source={"receiverSignature.email"}
                          label={"E-Mail"}
                          fullWidth
                        />
                      </InputContainer>
                    </Box>
                    <SelectInput
                      source={"receiverSignature.signatureType"}
                      label={"Unterschriften Alternative"}
                      emptyText={"Bitte wählen"}
                      defaultValue={"SIGNATURE"}
                      allowEmpty={true}
                      resettable={true}
                      choices={[
                        { id: "KEY_SLOT", name: "Schlüsseleinwurf (mit Foto)" },
                        { id: "PROOF_OF_DELIVERY_LOCATION", name: "Abliefernachweis mit Standort-Foto" },
                        { id: "PROOF_OF_DELIVERY_PROTOCOL", name: "Abliefernachweis (mit Protokoll-Foto)" },
                        { id: "HYGIENE", name: "Aus Hygienegründen ohne Unterschrift (Foto von Standort oder Schlüsseleinwurf)" },
                        { id: "SIGNATURE_NOT_AVAILABLE", name: "Wegen technischer Probleme ohne Unterschrift" },

                      ]}
                      fullWidth
                    />

                    <ResetSignatureButton type={"receiverSignature"} state={"SUBMITTED"} resetState={"RECEIVED"}  {...props}/>
                  </Box>
                  <Box flex={1} ml="0.5em">
                    <FormDataConsumer>
                      {( { formData } ) => {
                        const source = getSignatureImageSource( formData?.receiverSignature?.signatureType, 'receiverSignature' );
                        return (
                          <ImageInput
                            source={source}
                            label={"Unterschrift Zustellung"}
                            onChange={( e ) => onChangeImageInput( e, source )}
                          >
                            <LazyImageField
                              source="remoteFileName"
                              title="Unterschrift Zustellung"
                              onClick={onImageClick}
                            />
                          </ImageInput>
                        );
                      }}
                    </FormDataConsumer>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </FormTab>

        <DrivingDataTab basePath={props.basePath} record={props.record} />

        <DriverExpenses basePath={props.basePath} onImageClick={onImageClick}/>

        <DefaultImagesTab basePath={props.basePath} onImageClick={onImageClick}/>

        <DamagesTab basePath={props.basePath} onImageClick={onImageClick}/>

        <AccessoriesTab record={props.record}/>

        <FormTab label="Zusatzprotokolle">
          <ArrayInput
            source="additionalPhotos"
            fullWidth
            label={"Zusätzliche Fotos"}
          >
            <SimpleFormIndexableIterator>
              <FormDataConsumer>
                {({ getSource }) => {
                  return <>
                    <TextInput source={getSource("title")} fullWidth label={"Titel"} helperText={false}/>
                    <SelectInput
                      source={getSource("documentPicture.processStateType")}
                      label={"Zustand"}
                      fullWidth
                      helperText={false}
                      choices={[
                        { id: "STARTED", name: "Abholung" },
                        { id: "SUBMITTED", name: "Zustellung" },
                      ]}
                      defaultValue={"STARTED"}
                    />
                    <ImageInput
                      source={getSource("documentPicture.file")}
                      label={""}
                      multiple={false}
                      accept="image/*"
                    >
                      <LazyImageField
                        source={"remoteFileName"}
                        title="Zusatzprotokoll"
                        onClick={onImageClick}
                        loading={"lazy"}
                      />
                    </ImageInput>
                    <span style={{ fontSize: "0.875rem" }}>
                      Aufgenommen am <DateField source={getSource("documentPicture.created")}
                                        options={{ year: "numeric", month: "2-digit", day: "2-digit", hour: '2-digit', minute: '2-digit', second: '2-digit' }}
                                        fullWidth/>
                      </span>
                </>
                }}
              </FormDataConsumer>

            </SimpleFormIndexableIterator>
          </ArrayInput>

          <ArrayInput
            source="additionalDocuments"
            fullWidth
            label={"Zusätzliche Dokumente"}
          >
            <SimpleFormIndexableIterator>
              <FormDataConsumer>
                {({ getSource }) => {
                  return <>
                    <TextInput source={getSource( "title" )} fullWidth label={"Titel"} helperText={false} />
                    <SelectInput
                      source={getSource( "documentPicture.processStateType" )}
                      label={"Zustand"}
                      helperText={false}
                      fullWidth
                      choices={[
                        { id: "STARTED", name: "Abholung" },
                        { id: "SUBMITTED", name: "Zustellung" },
                      ]}
                      defaultValue={"STARTED"}
                    />
                    <ImageInput
                      source={getSource( "documentPicture.file" )}
                      label={""}
                      helperText={false}
                      multiple={false}
                    >
                      <LazyImageField
                        source="remoteFileName"
                        title="Zusatzdokument"
                        onClick={onImageClick}
                        loading={"lazy"}
                      />
                    </ImageInput>
                    <span style={{ fontSize: "0.875rem" }}>
                      Aufgenommen am <DateField source={getSource( "documentPicture.created" )}
                                                options={{ year: "numeric", month: "2-digit", day: "2-digit", hour: '2-digit', minute: '2-digit', second: '2-digit' }}
                                                fullWidth/>
                      </span>
                  </>
                }}
              </FormDataConsumer>

            </SimpleFormIndexableIterator>
          </ArrayInput>
        </FormTab>
        <AWSImagesTab basePath={props.basePath} onImageClick={onImageClick}/>
        <SnapshotsTab basePath={props.basePath}/>
        <MailsTab basePath={props.basePath}/>
        <ComplaintsTab basePath={props.basePath}/>
        <AppEventsTab basePath={props.basePath}/>
      </TabbedForm>
      {
        isImagePopupOpen && (
                           <Lightbox
            large={imagePopupSrc}
            onClose={onImagePopupClose}
            showRotate={true}
          />
        )
      }
    </>
  )
};


let imageAngles = {};

const ProtocolEdit = (props) => {
  const dataProvider = useDataProvider();

  const createRequestPayload = ( props ) => {
    const { file, objectKeys, file_path, index } = props
    let inputFileKey = '';
    for( const [key, value] of Object.entries( objectKeys ) ) {
      if( typeof index !== 'undefined' && key === 'level_1' ) {
        inputFileKey += `${value}[${index}].`;
      } else {
        inputFileKey += value+'.';
      }
    }
    inputFileKey += 'file';

    const data = typeof imageAngles[ inputFileKey ] !== 'undefined'
               ? { data: { file, file_path, rotateAngle: imageAngles[ inputFileKey ] } }
               : { data: { file, file_path } }

    return data;
  }

  const parseFileNameFromUrl = (url) => {
    const explodedUrl = url.split('/');
    return explodedUrl[explodedUrl.length - 1];
  }

  const promiseObjectFactory = (data, file, objectKeys) => {

    const contractId = data.contract._id;
    const contractNumber = data.contract.auftragsnummer;
    const body = createRequestPayload( { file: file, objectKeys: objectKeys, file_path: contractNumber } );

    return dataProvider
      .create( "uploads", body )
      .then( ( response ) => {

        const { level_1, level_2, level_3 } = objectKeys;

        const isSynched = true;
        const remoteFileName = response.data.contentUrl;
        const localFileName = `${contractNumber}/${parseFileNameFromUrl( remoteFileName )}`;
        const imageId = response.data.id;
        const imageProcessStateType = { imageProcessStateType: "REMOTEDONE" };

        if( typeof level_3 !== "undefined" ) {
          return {
            [ level_1 ]: {
              ...data[ level_1 ],
              [ level_2 ]: {
                ...data[ level_1 ][ level_2 ],
                [ level_3 ]: {
                  ...data[ level_1 ][ level_2 ][ level_3 ],
                  ...imageProcessStateType,
                  file: {
                    ...data[ level_1 ][ level_2 ][ level_3 ].file,
                    isSynched,
                    localFileName,
                    remoteFileName,
                    imageId,
                  },
                },
              },
            },
          }
        }
        return {
          [level_1]: {
            ...data[level_1],
            [level_2]: {
              ...imageProcessStateType,
              ...data[ level_1 ][ level_2 ],
              file: {
                ...data[ level_1 ][ level_2 ].file,
                isSynched,
                localFileName,
                remoteFileName,
                imageId,
              },
            },
          },
        };
      })
      .catch((error) => {
        throw error;
      });
  }

  const promiseArrayItemFactory = (data, keys, index, file) => {

    const contractId = data.contract._id;
    const contractNumber = data.contract.auftragsnummer;
    const body = createRequestPayload( { file: file, objectKeys: keys, file_path: contractNumber, index: index } );

    return dataProvider
      .create( "uploads", body )
      .then( ( response ) => {

        const { level_1, level_2, image_type } = keys;

        const imageType = image_type ? { imageType: image_type } : null;
        const imageProcessStateType = { imageProcessStateType: "REMOTEDONE" };

        const isSynched = true;
        const remoteFileName = response.data.contentUrl;
        const localFileName = `${contractNumber}/${parseFileNameFromUrl( remoteFileName )}`;
        const imageId = response.data.id;

        const collection = {
          [ level_1 ]: [...data[ level_1 ]],
        };

        if( typeof level_2 !== "undefined" ) {
          return {
            ...collection[ level_1 ][ index ],
            [ level_2 ]: {
              ...collection[ level_1 ][ index ][ level_2 ],
              ...imageType,
              ...imageProcessStateType,
              file: {
                ...collection[ level_1 ][ index ][ level_2 ].file,
                isSynched,
                localFileName,
                remoteFileName,
                imageId
              },
            },
          }
        } else {
          return {
            ...collection[ level_1 ][ index ],
            ...imageType,
            ...imageProcessStateType,
            file: {
              ...collection[ level_1 ][ index ].file,
              isSynched,
              localFileName,
              remoteFileName,
              imageId,
            },
          }
        }

      } )
      .catch( ( error ) => {
        throw error;
      } );
  }

  // TODO: Move to module
  const uploadPictures = (data) => {
    // TODO: handle remove file case
    const promises = [];
    if (data?.driverSignature?.signatureImage?.file?.rawFile instanceof File) {
      const driverSigObjectKeys = {
        level_1: "driverSignature",
        level_2: "signatureImage",
      };
      promises.push(
        promiseObjectFactory(
          data,
          data.driverSignature.signatureImage.file,
          driverSigObjectKeys
        )
      );
    }

    const submitterSignatureType = data?.submitterSignature?.signatureType ?? 'SIGNATURE';

    const submitterSignatureFile = submitterSignatureType === 'SIGNATURE'
      ? data?.submitterSignature?.signatureImage?.file
      : data?.submitterSignature?.signatureAltPhoto?.documentPicture?.file
    ;
    const submitterSigObjectKeys = submitterSignatureType === 'SIGNATURE'
      ? {
          level_1: "submitterSignature",
          level_2: "signatureImage",
        }
      : {
          level_1: "submitterSignature",
          level_2: "signatureAltPhoto",
          level_3: "documentPicture",
        }
    ;

    if (typeof submitterSignatureFile !== 'undefined' && submitterSignatureFile?.rawFile instanceof File) {
      promises.push(
        promiseObjectFactory(
          data,
          submitterSignatureFile,
          submitterSigObjectKeys
        )
      );
    }

    const receiverSignatureType = data?.receiverSignature?.signatureType ?? 'SIGNATURE';

    const receiverSignatureFile = receiverSignatureType === 'SIGNATURE'
      ? data?.receiverSignature?.signatureImage?.file
      : data?.receiverSignature?.signatureAltPhoto?.documentPicture?.file
    ;
    const receiverSigObjectKeys = receiverSignatureType === 'SIGNATURE'
      ? {
        level_1: "receiverSignature",
        level_2: "signatureImage",
      }
      : {
        level_1: "receiverSignature",
        level_2: "signatureAltPhoto",
        level_3: "documentPicture",
      }
    ;

    if (typeof receiverSignatureFile !== 'undefined' && receiverSignatureFile?.rawFile instanceof File) {
      promises.push(
        promiseObjectFactory(
          data,
          receiverSignatureFile,
          receiverSigObjectKeys
        )
      );
    }

    const driverExpensesKeys = {
      level_1: "driverExpenses",
      level_2: "documentPicture",
      image_type: "driverExpense"
    };
    if (data[driverExpensesKeys.level_1]) {
      data[driverExpensesKeys.level_1].forEach((item, index) => {
        if (item[driverExpensesKeys.level_2]?.file?.rawFile instanceof File) {
          // TODO: Merge array items properly
          promises.push(
            promiseArrayItemFactory(
              data,
              driverExpensesKeys,
              index,
              item[ driverExpensesKeys.level_2 ].file
            )
          );
        }
      });
    }

    const imagesKeys = {
      level_1: "images",
      image_type: null
    };
    if (data[imagesKeys.level_1]) {
      data[imagesKeys.level_1].forEach((item, index) => {
        if (item?.file?.rawFile instanceof File) {
          // TODO: Merge array items properly
          promises.push(
            promiseArrayItemFactory(data, imagesKeys, index, item.file)
          );
        }
      });
    }

    const damagesKeys = {
      level_1: "damages",
      level_2: "image",
      image_type: "damage"
    };
    if (data[damagesKeys.level_1]) {
      data[damagesKeys.level_1].forEach((item, index) => {
        if (item[damagesKeys.level_2]?.file?.rawFile instanceof File) {
          // TODO: Merge array items properly
          promises.push(
            promiseArrayItemFactory(
              data,
              damagesKeys,
              index,
              item[damagesKeys.level_2].file
            )
          );
        }
      });
    }

    const additionalPhotosKeys = {
      level_1: "additionalPhotos",
      level_2: "documentPicture",
      image_type: "additionalPhoto"
    };
    if (data[additionalPhotosKeys.level_1]) {
      data[additionalPhotosKeys.level_1].forEach((item, index) => {
        if (item[additionalPhotosKeys.level_2]?.file?.rawFile instanceof File) {
          // TODO: Merge array items properly
          promises.push(
            promiseArrayItemFactory(
              data,
              additionalPhotosKeys,
              index,
              item[additionalPhotosKeys.level_2].file
            )
          );
        }
      });
    }

    const additionalDocumentsKeys = {
      level_1: "additionalDocuments",
      level_2: "documentPicture",
      image_type: "additionalDocument"
    };
    if (data[additionalDocumentsKeys.level_1]) {
      data[additionalDocumentsKeys.level_1].forEach((item, index) => {
        if (item[additionalDocumentsKeys.level_2]?.file?.rawFile instanceof File) {
          // TODO: Merge array items properly
          promises.push(
            promiseArrayItemFactory(
              data,
              additionalDocumentsKeys,
              index,
              item[additionalDocumentsKeys.level_2].file
            )
          );
        }
      });
    }

    if (promises.length > 0) {
      return Promise.all(promises);
    }

    return Promise.all([]);
  };

  const transform = (data) => {
    data.images = removeImgPlaceholders(data);
    return uploadPictures(data).then((uploadedPictures) => {
      const imageTypeIds = imageTypes.map(o => o.id);
      const arrayInputsMap = {
        damages: 'responseObject?.image?.imageType === "damage"',
        additionalDocuments: 'responseObject?.documentPicture?.imageType === "additionalDocument"',
        additionalPhotos: 'responseObject?.documentPicture?.imageType === "additionalPhoto"',
        driverExpenses: 'responseObject?.documentPicture?.imageType === "driverExpense"',
        images: 'imageTypeIds.find(imageTypeId => imageTypeId === responseObject?.imageType)',
      };
      Object.keys(arrayInputsMap).forEach(key => {
        uploadedPictures.map((responseObject, index) => {
          if (eval(arrayInputsMap[key])) {
            data[key].find((o, i) => {
              if (o.sortOrder === responseObject.sortOrder) {
                data[key][i] = responseObject;
                return true;
              }
            });
            delete uploadedPictures[index];
          }
        });
      });
      return {
        ...data,
        ...Object.assign({}, ...uploadedPictures),
      };
    })
  };

  return (
    <>
      <Edit
        {...props}
        transform={transform}
        title={<ProtocolTitle/>}
        actions={<EditActions/>}
      >
        <ProtocolForm
          toolbar={<ProtocolToolbar/>}
          style={{ width: "100%", marginBottom: "50px" }}
        />
      </Edit>
    </>
  );
};

export default ProtocolEdit;
