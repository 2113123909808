import { FieldGuesser } from "@api-platform/admin";
import React from "react";
import { BooleanField, FunctionField, TextField } from "react-admin";
import {
  customerFilter,
  customerFiltersDefaultValues,
} from "./components/CustomerFilter";
import HotList from "../utils/components/HotList";
import DateField from "../utils/components/DateField";

const CustomerList = (props) => (
  <HotList
    {...props}
    sort={"sortierung"}
    filters={customerFilter}
    allowCreate={true}
    filterDefaultValues={customerFiltersDefaultValues}
  >
    <FunctionField render={(record) => {
      return <>{record.id.replace('/api/customers/', '')}</>
    }} label={"ID"} />
    <DateField source={"createdAt"} label={"Erstellt"} />
    <FieldGuesser source={"kundennr"} label={"Kundennummer"} />
    <FieldGuesser source={"kuerzel"} label={"Kürzel"} />
    <FieldGuesser source={"anzeigename"} label={"Name"}/>
    <FieldGuesser source={"concatAddressData"} label={"Adresse"} sortable={false}/>
    <FieldGuesser source={"email"} label={"Email"}/>
    <FunctionField
            label="Telefon"
            render={record => {
                if (record.telefon)
                    return `${record.telefonVorwahl} ${record.telefon}`;
                else return ``;
            }}
        />
    <BooleanField source={"boolAktiv"} label={"Aktiv"} />
  </HotList>
);

export default CustomerList;
