import { Chip, Tooltip } from "@material-ui/core";
import { Confirm, useDataProvider, useNotify, usePermissions } from "react-admin";
import { useState } from "react";

export const red = "#DC3545";
export const orange = "#C97F1F";
export const blue = "#007BFF";
export const lightblue = "#00B2FF";
export const info = "#17A2B8";
export const purple = "#8A179A";
export const white = "#FFFFFF";
export const green = "#28A745";

const TagProps = {
  vorholung: { name: "Vorholung", shortcode: "VH", color: white, backgroundColor: orange },
  storniert: { name: "Storniert", shortcode: "STORNIERT", color: white, backgroundColor: red },
  abcfahrt: { name: "ABC-Fahrt", shortcode: "ABC" },
  abafahrt: { name: "ABA-Fahrt", shortcode: "ABA" },
  vwstationsfahrt: { name: "Stationsfahrt", color: white, backgroundColor: blue, shortcode: "SF" },
  flexContract: { name: "Flex", color: white, backgroundColor: orange, shortcode: "FLEX" },
  leerfahrt: { name: "Leerfahrt", shortcode: "LF", color: white, backgroundColor: purple },
  verzoegert: { name: "Verzögert", shortcode: "VZ", color: white, backgroundColor: red },
  neumerker: { name: "Neu", shortcode: "NEU", color: white, backgroundColor: lightblue },
  unbestaetigt: { name: "Auftrag Unbestätigt", color: white, backgroundColor: info, shortcode: "UB" },
  rechnungsnotiz: { name: "Rechnungsnotiz", shortcode: "INFO RE", color: white, backgroundColor: red },
  kostenstelle: { name: "Kostenstelle", shortcode: "KS", color: white, backgroundColor: green },
  gutschriftnotiz: { name: "Gutschriftnotiz", shortcode: "INFO GS", color: white, backgroundColor: green },
};

const CancellationRequestTag = ( { record } ) => {
  const [open, setOpen] = useState( false );
  const dataProvider = useDataProvider();
  const notify = useNotify();

  return <>
    <Confirm
      isOpen={open}
      title="Stornierung bestätigen"
      content="Möchten Sie den Stornierungswunsch bestätigen?"
      onClose={() => setOpen( false )}
      onConfirm={() => {
        dataProvider
          .update( "contracts", {
            id: record[ "@id" ],
            data: {
              isStorniert: true,
              stornierung: record.cancellationRequested,
              cancellationRequested: null,
            },
          } )
          .then( ( response ) => {
            setOpen( false );
            notify( "Stornierung bestätigt", { type: "success" } );
          } )
          .catch( ( error ) => {
            notify( `Fehler beim bearbeiten: ${error.message}`, { type: "warning" } );
          } );
      }}
    />

    <Tooltip
      title={`Stornierung am ${record.cancellationRequestedFormatted} bestätigen`}
      arrow
      interactive
      onClick={() => setOpen( true )}
    >
      <Chip
        label="STORNO"
        style={{
          backgroundColor: info,
          color: white,
          marginRight: "2px",
        }}
        size="small"
      />
    </Tooltip>
  </>
}

const BundleTag = ( { bundle } ) => {
  return (
    <Tooltip
      title={"Ist in Fahrtpaket"}
      key={bundle.bundle}
      arrow
      interactive
      onClick={() => window.open( `#/bundles/${bundle[ "@id" ]?.replaceAll( "/", "%2F" )}`, "_blank" )}
    >
      <Chip
        label={bundle.bundle}
        style={{
          backgroundColor: orange,
          color: white,
          marginRight: "2px",
        }}
        size="small"
      />
    </Tooltip>
  );
}

const InvoiceTag = ( { invoice } ) => {
  const { permissions } = usePermissions()

  const requiredRoles = [ 'vermaco_buchhaltung', 'vermaco_admin' ]
  if( !requiredRoles.some( role => permissions && permissions.includes( role ) ) ) {
    return null;
  }

  return (
    <Tooltip
      title={`${invoice.type} ${invoice.number}`}
      key={invoice.number}
      arrow
      interactive
      onClick={() => window.open( `#/invoices/${( '/api/invoices/'+invoice.id ).replaceAll( "/", "%2F" )}`, "_blank" )}
    >
      <Chip
        label={`${invoice.type === 'Rechnung' ? 'R' : 'G'}${invoice.number}`}
        style={{
          backgroundColor: green,
          color: white,
          marginRight: "2px",
        }}
        size="small"
      />
    </Tooltip>
  );
}

const TooltipTag = ( { tag } ) => {
  return <Tooltip title={tag.name} key={tag.shortcode} arrow interactive>
    <Chip
      label={tag.shortcode}
      style={{
        backgroundColor: tag.backgroundColor || "orange",
        color: tag.color,
        marginRight: "2px",
      }}
      size="small"
    />
  </Tooltip>
}


export const ProtocolListTags = ( { record } ) => {
  if( !record ) {
    return null;
  }

  const tags = [];
  if( record[ "@type" ] === "Contract" ) {
    record.vorholung && tags.push( { ...TagProps.vorholung, backgroundColor: record.isDeliveryDayItem ? "gray" : TagProps.vorholung.backgroundColor } );
    record.isStorniert && tags.push( TagProps.storniert );
    record.abcfahrt && tags.push( TagProps.abcfahrt );
    record.abafahrt && tags.push( TagProps.abafahrt );
    record.vwstationsfahrt && tags.push( TagProps.vwstationsfahrt );
    record.flexContract && tags.push( TagProps.flexContract );
  } else if( record[ "@type" ] === "Protocol" ) {
    record.emptyRunDone && tags.push( TagProps.leerfahrt );
  }

  return tags.map( ( tag ) => (
    <TooltipTag tag={tag}/>
  ) );
};

export const ContractTags = ( { record } ) => {
  const tags = [];

  record.leerfahrt === "1" && tags.push( TagProps.leerfahrt );
  record.verzoegert && tags.push( TagProps.verzoegert );
  record.neumerker === "1" && tags.push( TagProps.neumerker );
  record.vorholung && tags.push( { ...TagProps.vorholung, backgroundColor: record.isDeliveryDayItem ? "gray" : TagProps.vorholung.backgroundColor } );
  record.isStorniert && tags.push( TagProps.storniert );
  record.abcfahrt && tags.push( TagProps.abcfahrt );
  record.abafahrt && tags.push( TagProps.abafahrt );
  record.unbestaetigt && tags.push( TagProps.unbestaetigt );
  record.vwstationsfahrt && tags.push( TagProps.vwstationsfahrt );
  record.flexContract && tags.push( TagProps.flexContract );

  let tagCollection = tags.map( ( tag ) => (
    <TooltipTag tag={tag}/>
  ) );

  if( record.cancellationRequested ) {
    tagCollection.push(
      <CancellationRequestTag record={record}/>
    );
  }

  if( record.invoiceNumbers.length > 0 ) {
    for( const invoice of record.invoiceNumbers ) {
      tagCollection.push( <InvoiceTag invoice={invoice}/> );
    }
  }

  if( record.isPartOfBundle ) {
    for( const bundle of record.bundles ) {
      tagCollection.push( <BundleTag bundle={bundle}/> );
    }
  }

  return tagCollection;
};

export const BillingTags = ( { record } ) => {
  const tags = [];

  const getRechnungsAddresse = ( address ) => {
    let addressString = "";
    if( address.firma ) {
      addressString += address.firma+" | ";
    }
    if( address.name ) {
      addressString += address.name+" | ";
    }
    if( address.strasse ) {
      addressString += address.strasse+" "+address.hausnr+" ";
    }
    if( address.plz ) {
      addressString += address.plz+" "+address.ort;
    }
    return addressString;
  };

  const getLeistungsAddresse = ( address ) => {
    let addressString = "";
    if( address.firma ) {
      addressString += address.firma+" | ";
    }
    if( address.name ) {
      addressString += address.name+" | ";
    }
    if( address.strasse ) {
      addressString += address.strasse+" ";
    }
    if( address.ort ) {
      addressString += address.ort;
    }
    return addressString;
  };

  if( record.rechnungsnotiz ) {
    let re = TagProps.rechnungsnotiz
    re.name = record.rechnungsnotiz
    tags.push( re );
  }

  ( record.leerfahrt === "1" || record.anschlussAuftrag?.leerfahrt === "1" ) && tags.push( TagProps.leerfahrt );
  record.kostenstelle && tags.push( TagProps.kostenstelle );
  record.billingClientAddress && tags.push( { name: getRechnungsAddresse( record.billingClientAddress ), shortcode: "RA", color: white, backgroundColor: green } );
  record.leistungsadresse && tags.push( { name: getLeistungsAddresse( record.leistungsadresse ), shortcode: "LA", color: white, backgroundColor: green } );
  record.abcfahrt && tags.push( TagProps.abcfahrt );
  record.abafahrt && tags.push( TagProps.abafahrt );
  record.vwstationsfahrt && tags.push( TagProps.vwstationsfahrt );

  let tagCollection = tags.map( ( tag ) => (
    <TooltipTag tag={tag}/>
  ) );

  if( record.invoiceNumbers.length > 0 ) {
    for( const invoice of record.invoiceNumbers ) {
      tagCollection.push( <InvoiceTag invoice={invoice}/> );
    }
  }

  return tagCollection;
};

export const CreditTags = ( { record } ) => {
  const tags = [];

  if( record.gutschriftnotiz ) {
    let re = TagProps.gutschriftnotiz
    re.name = record.gutschriftnotiz
    tags.push( re );
  }

  record.leerfahrt === "1" && tags.push( TagProps.leerfahrt );
  record.abcfahrt && tags.push( TagProps.abcfahrt );
  record.abafahrt && tags.push( TagProps.abafahrt );
  record.vwstationsfahrt && tags.push( TagProps.vwstationsfahrt );

  let tagCollection = tags.map( ( tag ) => (
    <TooltipTag tag={tag}/>
  ) );

  if( record.invoiceNumbers.length > 0 ) {
    for( const invoice of record.invoiceNumbers ) {
      tagCollection.push( <InvoiceTag invoice={invoice}/> );
    }
  }

  return tagCollection;
};
