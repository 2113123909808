import React from "react";
import { DateInput as ReactDateInput, useListContext } from "react-admin";
import { TimePicker as ReactTimePicker } from '@material-ui/pickers'
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';

// options will be passed to JS Intl.DateTimeFormat()
export const DateInput = ( props ) => (
  <ReactDateInput
    locales="de-DE"
    options={{ year: "numeric", month: "2-digit", day: "2-digit" }}
    helperText={false}
    {...props}
  />
);

// options will be passed to JS Intl.DateTimeFormat()
export const TimeInput = ( props ) => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <ReactTimePicker {...props} />
  </MuiPickersUtilsProvider>
);

export const DateInputWithLocalStorage = (props) => {
  const { source, origin, ...rest } = props;
  const { setFilters, filterValues, displayedFilters } = useListContext();
  const uniqueSource = `${origin}.${source}`;
  const value = localStorage.getItem( uniqueSource );

  const before = localStorage.getItem( `${origin}.bringdatum[before]` )
  const after = localStorage.getItem( `${origin}.bringdatum[after]` )

  let filter = {
    ...filterValues,
  }
  if (before) {
    filter = {
      ...filter,
      bringdatum: {
        ...filter.bringdatum,
        before
      }
    }
  }
  if (after) {
    filter = {
      ...filter,
      bringdatum: {
        ...filter.bringdatum,
        after
      }
    }
  }

  if (JSON.stringify(filterValues) !== JSON.stringify(filter)) {
    setFilters(filter, displayedFilters, false);
  }

  return <DateInput
    source={source}
    value={value}
    onChange={e => {
      if( e ) {
        localStorage.setItem( uniqueSource, e.target.value );
      } else {
        localStorage.removeItem( uniqueSource );
      }
    }}
    {...rest} />;
}
