import { Button, FormWithRedirect, SaveButton, useDataProvider, useNotify, usePermissions, useRefresh } from "react-admin";
import React, { Fragment, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip, Typography } from "@material-ui/core";
import { arrowButtonStyle } from "../../utils/components/styles/ButtonStyles";
import InfoIcon from '@material-ui/icons/Info';
import IconCancel from "@material-ui/icons/Cancel";
import { withStyles } from "@material-ui/core/styles";
import TextInput from "../../utils/components/TextInput";
import { green } from "../../utils/components/ListTags";

const styles = {
  outline: {
    border: "1px solid black",
    padding: "3px 5px",
    borderRadius: "5px",
    display: "inline-block",
    marginBottom: "2px",
  },
  error: {
    border: "1px solid black",
    padding: "3px 5px",
    borderRadius: "5px",
    display: "inline-block",
    marginBottom: "2px",
    backgroundColor: "#C82333",
    borderColor: "#BD2130",
    color: "white",
    hover: {
      color: "yellow",
    },
  },
};

function DialogChangeInfo( { children, record, refreshAfterSave = false } ) {
  const { permissions } = usePermissions()
  const allowed = permissions && ( permissions.includes( 'vermaco_admin' ) || permissions.includes( 'vermaco_buchhaltung' ) || permissions.includes( 'vermaco_dispo' ) )
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const [showDialog, setShowDialog] = useState( false );
  const [saving, setSaving] = useState( false );

  const notify = useNotify();

  const handleOpenClick = () => {
    setShowDialog( true );
  };

  const handleCloseClick = () => {
    setShowDialog( false );
  };

  const save = async( values ) => {
    let data = {
      infoBackend: values.infoBackend ?? null,
      infoDriver: values.infoDriver ?? null,
      infoShared: values.infoShared ?? null
    }

    if( allowed ) {
      data = {
        ...data,
        rechnungsnotiz: values.rechnungsnotiz ?? null,
        gutschriftnotiz: values.gutschriftnotiz ?? null,
      }
    }

    setSaving( true )
    dataProvider
      .update( "contracts", {
        id: record['@id'],
        data: data,
        previousData: record
      } ).then( ( response ) => {
      notify( `Auftrag ${record.auftragsnummer ?? record.orderNumber} erfolgreich geändert`, { type: "success" } );
      setSaving( false )
      setShowDialog( false )
      if( refreshAfterSave ) {
        refresh()
      }
    } ).catch( ( error ) => {
      setSaving( false )
      notify( `Error: ${error.message}`, { type: "warning" } );
    } );
  }


  return <>
    <div style={{ display: "inline-flex", cursor: "pointer" }} onClick={handleOpenClick}>
      {children}
    </div>

    <Dialog fullWidth open={showDialog} onClose={handleCloseClick}>
      <DialogTitle>Auftrag {record.auftragsnummer ?? record.orderNumber}</DialogTitle>

      <FormWithRedirect
        resource="contracts"
        save={save}
        render={( { handleSubmitWithRedirect, pristine } ) => (
          <>
            <DialogContent>
              <DialogContentText style={{ marginBottom: "0", marginTop: "1rem" }}>
                <Typography variant="subtitle2">Notizen ändern</Typography>
              </DialogContentText>

              <TextInput
                source="infoBackend"
                label="Interne Notiz"
                initialValue={record.infoBackend}

                fullWidth
                allowEmpty
                resettable
                multiline
              />
              <TextInput
                source="infoDriver"
                label="Info Fahrer"
                initialValue={record.infoDriver}

                fullWidth
                allowEmpty
                resettable
                multiline
              />
              {allowed && <TextInput
                source="rechnungsnotiz"
                label="Rechnungsnotiz"
                initialValue={record.rechnungsnotiz}

                fullWidth
                allowEmpty
                resettable
                multiline
              />}

              {allowed && <TextInput
                source="gutschriftnotiz"
                label="Gutschriftnotiz"
                initialValue={record.gutschriftnotiz}

                fullWidth
                allowEmpty
                resettable
                multiline
              />}

              <TextInput
                source={"infoShared"}
                label={"Mit VM geteilt"}
                initialValue={record.infoShared}

                fullWidth
                allowEmpty
                multiline
              />
            </DialogContent>

            <DialogActions>
              <SaveButton
                handleSubmitWithRedirect={handleSubmitWithRedirect}
                pristine={pristine}
                saving={saving}
              />

              <Button
                label="ra.action.cancel"
                onClick={handleCloseClick}
              >
                <IconCancel/>
              </Button>
            </DialogActions>
          </>
        )}
      />
    </Dialog>
  </>
}


const InfoFieldView = ( { record, ...rest } ) => {
  const { permissions } = usePermissions()
  const allowed = permissions && ( permissions.includes( 'vermaco_admin' ) || permissions.includes( 'vermaco_buchhaltung' ) || permissions.includes( 'vermaco_dispo' ) )
  const hasInfo = record.infoBackend || ( allowed && ( record.rechnungsnotiz || record.gutschriftnotiz ) ) || record.infoDriver || record.infoShared
  return (
    <DialogChangeInfo record={record} {...rest}>
      <Tooltip
        placement="right"
        title={
          <>
            {!hasInfo && <Typography variant="subtitle2">Keine Notiz hinterlegt</Typography>}
            {record.infoBackend && <><Typography variant="subtitle2">Interne Notiz</Typography>{record.infoBackend}<br/></>}
            {allowed && record.rechnungsnotiz && <><Typography variant="subtitle2">Rechnungsnotiz</Typography>{record.rechnungsnotiz}<br/></>}
            {allowed && record.gutschriftnotiz && <><Typography variant="subtitle2">Gutschriftnotiz</Typography>{record.gutschriftnotiz}</>}
            {record.infoDriver && <><Typography variant="subtitle2">Info Fahrer</Typography>{record.infoDriver}</>}
            {record.infoShared && <><Typography variant="subtitle2">Mit VM geteilt</Typography>{record.infoShared}</>}
          </>
        }
        arrow
      >
        <InfoIcon style={{ color: hasInfo ? green : "grey" }}/>
      </Tooltip>
    </DialogChangeInfo>
  );
};

const InfoField = withStyles( styles )( InfoFieldView );

export const InfoButton = ( { record, style = arrowButtonStyle, ...rest } ) => {
  if( !record ) {
    return null
  }

  return (
    <InfoField record={record} {...rest} />
  )
};

export const InfoButtonOnlyView = ( { record, style = arrowButtonStyle, ...rest } ) => {
  if( !record ) {
    return null
  }

  return (
    <Tooltip
      placement="right"
      title={
        <>
          {!record.infoBackend && !record.rechnungsnotiz && !record.gutschriftnotiz && !record.infoDriver && <Typography variant="subtitle2">Keine Notiz hinterlegt</Typography>}
          {record.infoBackend && <Fragment mb={"1em"}><Typography variant="subtitle2">Interne Notiz</Typography>{record.infoBackend}<br/></Fragment>}
          {record.rechnungsnotiz && <Fragment mb={"1em"}><Typography variant="subtitle2">Rechnungsnotiz</Typography>{record.rechnungsnotiz}<br/></Fragment>}
          {record.gutschriftnotiz && <><Typography variant="subtitle2">Gutschriftnotiz</Typography>{record.gutschriftnotiz}</>}
          {record.infoDriver && <><Typography variant="subtitle2">Info Fahrer</Typography>{record.infoDriver}</>}
        </>
      }
      arrow
    >
      <InfoIcon style={{ color: record.infoBackend || record.rechnungsnotiz || record.gutschriftnotiz || record.infoDriver ? green : "grey" }}/>
    </Tooltip> )
};
