import { FieldGuesser } from "@api-platform/admin";
import React from "react";
import HotList from "../utils/components/HotList";

const RolesList = ( props) => (
  <HotList {...props} sort={"id"} sortOrder={"DESC"} allowCreate={true} hasEdit={true}>
    <FieldGuesser source={"name"} label={"Eindeutiger Name"} />
    <FieldGuesser source={"description"} label={"Beschreibung"} />
  </HotList>
);

export default RolesList;
