import DriverList from "./DriverList";
import { DriverEdit, DriverCreate } from "./DriverEdit";

const Drivers = {
  list: DriverList,
  edit: DriverEdit,
  create: DriverCreate,
  name: "drivers"
};

export default Drivers;
