import { FormTab, ArrayInput } from "react-admin";
import * as React from "react";
import SnapshotsTabFormIterator from "./SnapshotsTabFormIterator";


export default ( props ) => {

  return <FormTab label={"Wiederherstellungspunkte"} {...props}>
    <ArrayInput source={"availableSnapshots"} label={""} fullWidth>
      <SnapshotsTabFormIterator fieldName={"availableSnapshots"}/>
    </ArrayInput>
  </FormTab>
}
