import { Button } from "react-admin";
import { useRedirect } from 'react-admin';
import { IconEditRetoure } from "../icons/IconEditRetoure";

export const EditButton = ( { entity, redirectTo, ...rest } ) => {
  if( !entity || !entity[ "@type" ] ) {
    return null;
  }

  let type = '';
  if( entity[ "@type" ] === "Contract" ) {
    type = 'contracts';
  } else if( entity[ "@type" ] === "Protocol" ) {
    type = 'protocols';
  } else if( entity[ "@type" ] === "User" ) {
    type = '/users';
  } else if( entity[ "@type" ] === "Customer" ) {
    type = '/customers';
  }

  if( !type ) {
    console.warn( "Cant find type for entity", entity );
    return null;
  }

  const redirect = useRedirect();

  const onClick = () => {
    redirect( (redirectTo ? ((resource, id, data) => redirectTo(resource, id, data)) : 'edit'), type, entity[ '@id' ] )
  }

  return <Button
    children={<IconEditRetoure/>}
    label={""}
    title={"Retoure Bearbeiten"}
    record={entity}
    onClick={onClick}
    {...rest}
  />;
};
