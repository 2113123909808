import { PriceGroupList } from "./PriceGroupList";
import { EntityCreate, EntityEdit } from "./PriceGroupEdit";

const PriceGroup = {
  name: "price_groups",
  list: PriceGroupList,
  edit: EntityEdit,
  create: EntityCreate,
}

export default PriceGroup;
