import { ArrayInput, FormDataConsumer, FormTab, SelectInput } from "react-admin";
import SimpleFormIndexableIterator from "../../../utils/components/SimpleFormIndexableIterator/SimpleFormIndexableIterator";
import { Box } from "@material-ui/core";
import ImageInput from "../../../utils/components/ImageInput";
import NumberInput from "../../../utils/components/NumberInput";
import * as React from "react";
import { DriverReferenceInput } from "../../../drivers/components/DriverReferenceInput";
import DateField from "../../../utils/components/DateField";
import LazyImageField from "../../../utils/components/LazyImageField";

export default ( props ) => {
  return <FormTab label="Auslagen"  {...props}>
    <ArrayInput source="driverExpenses" label={"Auslagen"} fullWidth>
      <SimpleFormIndexableIterator classes={{ root: "protocol-grid-layout" }}>
        <FormDataConsumer>
          {( { formData, scopedFormData, getSource } ) => {
            return <>
              <Box display={"flex"} flexDirection={"row"}>
                <Box flex={1} mr="0.5em">
                  <ImageInput
                    source={getSource( "documentPicture.file" )}
                    label={""}
                    multiple={false}
                    fullWidth
                  >
                    <LazyImageField
                      source="remoteFileName"
                      onClick={props.onImageClick}
                      loading={"lazy"}
                    />
                  </ImageInput>
                  <span style={{ fontSize: "0.875rem" }}>
                  Aufgenommen am <DateField source={getSource( "documentPicture.created" )}
                                            options={{ year: "numeric", month: "2-digit", day: "2-digit", hour: '2-digit', minute: '2-digit', second: '2-digit' }}
                                            fullWidth/>
                  </span>
                  <SelectInput
                    source={getSource( "type" )}
                    label={"Ausgabe"}
                    optionText={"title"}
                    optionValue={"name"}
                    choices={[
                      { id: 1, name: "refueling", title: "Tanken" },
                      { id: 2, name: "washing", title: "Waschen" },
                      { id: 3, name: "refuelingElectroCar", title: "Elektro Aufladen" },
                      { id: 4, name: "longDistanceTrain", title: "Bahn Fernverkehr 7%", disabled: true },
                      { id: 5, name: "localDistanceTrain", title: "Bahn Nahverkehr 7%", disabled: true },
                      { id: 14, name: "train", title: "Bahn" },
                      { id: 6, name: "bus", title: "Bus 7%" },
                      { id: 7, name: "adBlue", title: "AdBlue" },
                      { id: 8, name: "motorOil", title: "Motor Oel" },
                      { id: 9, name: "toll", title: "Maut" },
                      { id: 10, name: "taxi", title: "Taxi" },
                      { id: 11, name: "hotel", title: "Hotel" },
                      { id: 12, name: "shipping", title: "Porto / Versand" },
                      { id: 13, name: "otherReason", title: "Eigener Grund / Sonstiges" },
                    ]}
                    fullWidth
                    required
                    helperText={false}
                  />

                  <NumberInput
                    source={getSource( "amount" )}
                    label={"Wert"}
                    required={true}
                    step={0.01}
                    helperText={false}
                  />

                  <SelectInput
                    source={getSource( "paymentType" )}
                    label={"Art der Bezahlung"}
                    resettable={true}
                    allowEmpty={true}
                    emptyText={"Bitte wählen..."}
                    emptyValue={""}
                    fullWidth
                    choices={[
                      { id: 'expense', name: "Auslage" },
                      { id: 'uta', name: "UTA" },
                      { id: 'bahnBusiness', name: "bahn.business Buchung" }
                    ]}
                    helperText={false}

                  />

                  <DriverReferenceInput
                    source={getSource( "userId" )}
                    label={"Fahrer"}
                    helperText={false}

                  />
                </Box>
              </Box>
            </>
          }}
        </FormDataConsumer>
      </SimpleFormIndexableIterator>
    </ArrayInput>
  </FormTab>
}
