import { Button, Confirm, useDataProvider, useNotify, useRefresh } from "react-admin";
import React, { Fragment, useState } from "react";
import { apiEndpoint, PostRequest } from "../../utils/request/PostRequest";
import MailIcon from "@material-ui/icons/Mail";
import GlobalLoading from "../../utils/components/GlobalLoading";

export const SendButton = ( props ) => {
  const { record, ...rest } = props;
  if( !record ) {
    return null;
  }
  const dataProvider = useDataProvider();

  const [isOpened, setIsOpened] = useState( false );
  const [loading, setLoading] = useState( false );
  const refresh = useRefresh();
  const notify = useNotify();

  const handleConfirm = async() => {
    setLoading( true )
    setIsOpened( false )
    await PostRequest(
      `${apiEndpoint}${record.id}/send_pre_mail`,
      {}
    ).then( ( data ) => {
        notify( 'Das Protokoll wurde verschickt', { type: 'success' } );
        setLoading( false )
        refresh()
      } )
      .catch( ( error ) => {
        setLoading( false );
        notify(
          error.message || 'Fehler beim Verschicken des Protokolls',
          { type: 'warning', multiLine: true }
        )
      } )
  };

  return (
    <Fragment>
      {loading &&<GlobalLoading/>}
      <Button
        title={'Protokoll versenden'}
        onClick={() => setIsOpened( true )}
        children={<MailIcon/>}
        {...rest}
      />
      <Confirm
        isOpen={isOpened}
        loading={loading}
        title={"Vorversandversand "+record.protocol.orderNumber}
        content={"Das Protokoll"+( record.mailSend ? " erneut" : "" )+" versenden?"}
        onConfirm={handleConfirm}
        onClose={() => setIsOpened( false )}
      />
    </Fragment>
  )
}
