import DatePicker, { registerLocale, setDefaultLocale } from  "react-datepicker";
import de from 'date-fns/locale/de';
import { FormDataConsumer } from "react-admin";
import React from "react";
import { useForm } from "react-final-form";
registerLocale('de', de)
setDefaultLocale('de')
import "react-datepicker/dist/react-datepicker.css";

export default ( { fieldName = "deliveryDate", className = null } ) => {
  const { change, batch } = useForm();
  let parsedDeliveryDate = ''


  const parseDate = (date) => {
    if( date === undefined ) {
      return ''
    }

    const [day, month, year] = date.split('.');

    // Create a new Date object with the parsed values
    parsedDeliveryDate = new Date();
    if (day && month && year) {
      parsedDeliveryDate = new Date(`${year}-${month}-${day}`);
    }

    parsedDeliveryDate.setHours( 12 )
    return parsedDeliveryDate
  }

  return <FormDataConsumer>
    {({ formData, ...rest }) => {
      return (
        <DatePicker className={`MuiInputBase-input MuiFilledInput-input MuiInputBase-inputMarginDense MuiFilledInput-inputMarginDense ${className}`}
                    dateFormat="dd.MM.yyyy"
                    selected={parseDate(formData[fieldName])}
                    onChange={ (date) => {
                      date.setHours( 12 )
                      const day = date.getDate();
                      const month = date.getMonth() + 1;
                      const year = date.getFullYear();
                      const formattedDate = `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${year}`;
                      batch(() => {
                        change( fieldName, formattedDate );
                        change( `${fieldName}Raw`, date );
                      } )
                      formData[ `${fieldName}` ] = formattedDate
                      formData[ `${fieldName}Raw`] = date
                    }}

        />
      );
    }}
  </FormDataConsumer>
}
