import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyCpL11GqTj-Dot8TFQHDBuLq1FXE_6L6-M",
  authDomain: "vermaco-12814.firebaseapp.com",
  projectId: "vermaco-12814",
  storageBucket: "vermaco-12814.appspot.com",
  messagingSenderId: "387908908629",
  appId: "1:387908908629:web:4c8d0c2d306f50664a86dc",
  measurementId: "G-B3BGVS3CP4"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app);


export const getMessageToken = () =>
  getToken(messaging, { vapidKey: 'BH3V_0GcrIwozX8UcFR6haQutLvVoscdNQocm22UA1CkfFb7e2iMUlzOXPoppW9M_RviKSDI7I46lVxIyfWkqiE' }).then((currentToken) => {
  if (currentToken) {
    // Send the token to your server and update the UI if necessary
    // ...
    console.log( currentToken )
    return Promise.resolve( currentToken );
  } else {
      console.log( 'Requesting permission...' );
    Notification.requestPermission().then( ( permission ) => {
      if( permission === 'granted' ) {
        console.log( 'Notification permission granted.' );
      }
    } )
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
  // ...
});
