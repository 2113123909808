import { FieldGuesser } from "@api-platform/admin";
import React from "react";
import HotList from "../utils/components/HotList";
import { BooleanField, FunctionField, TextField } from "react-admin";

const LeasingProtocolList = ( props) => (
  <HotList {...props} sort={"name"} allowCreate={true} hasEdit={true}>
    <TextField source={"name"} label={"Name"} />
    <TextField source={"slug"} label={"Eindeutiger Name"} />
    <BooleanField source={"restrictAccess"} label={"Zugriff eingeschränkt"} />
    {/*<FunctionField render={record => record.customers.length} label={"Anzahl Kunden"} />*/}
  </HotList>
);

export default LeasingProtocolList;
