import { Button, useNotify, useRefresh, useDataProvider } from "react-admin";

import CancelIcon from "@material-ui/icons/Cancel";

export const CancelContractButton = (
  { record },
) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  if( !record ) {
    return null;
  }
  const resource = "contracts";
  const showButtonText = false;
  const buttonText = "Auftrag stornieren";

  const cancel = () =>
    dataProvider
      .update( resource, {
        id: record.id,
        data: {
          isImported: false,
          isDeclinedByVermaco: true
        },
      } )
      .then( ( response ) => {
        // success side effects go here
        refresh();
        notify( "Auftrag erfolgreich storniert", "success" );
      } )
      .catch( ( error ) => {
        // failure side effects go here
        notify( `Fehler beim stornieren: ${error.message}`, { type: "warning" } );
      } );

  return (
    <Button
      title={buttonText}
      onClick={cancel}
      children={
        <>
          <CancelIcon/>
          {showButtonText && buttonText}
        </>
      }
    />
  );
};
