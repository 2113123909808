import { FieldGuesser } from "@api-platform/admin";
import React from "react";
import { BooleanField, TextField } from "react-admin";
import HotList from "../utils/components/HotList";
import DateField from "../utils/components/DateField";

const DispositionLocksList = (props) => (
  <HotList {...props} sort={"datum"} sortOrder={"DESC"} allowCreate={true} hasEdit={true}>
    <TextField source={"_id"} />
    <DateField source={"datum"}/>
    <BooleanField source={"active"} />
  </HotList>
);

export default DispositionLocksList;
