import React from "react";
import { AutocompleteInput, ReferenceInput, SearchInput } from 'react-admin';
import moment from 'moment';
import "moment/locale/de";

import SelectArrayInput from "../../utils/components/SelectArrayInput";

moment.locale( "de" );

const logFilter = [
  <SearchInput
    placeholder="Suche"
    source="_name"
    style={{ minWidth: "250px", marginTop: "0" }}
    alwaysOn
  />,

  <ReferenceInput
    source="user"
    reference="users"
    label={"Nutzer"}
    filterToQuery={( text ) => {return text ? { _name: text } : null}}
    alwaysOn
  >
    <AutocompleteInput
      optionText={(obj) => !obj || obj.hasOwnProperty( "id" ) && !obj.id  ? 'Bitte wählen' : obj.username}
      style={{ minWidth: "200px" }}
      helperText={false}
      options={{ InputProps: { autoComplete: 'reference-autocomplete'} }}
    />
  </ReferenceInput>,

  <SelectArrayInput
    choices={[
      { id: "mail", name: "E-Mails" },
      { id: "api_contract", name: "Aufträge" },
      { id: "api_protocol", name: "Protokolle" },
      { id: "api_change", name: "Änderungen" },
      { id: "custom", name: "Sonstiges" },
    ]}
    source="type"
    label={"Typ"}
    alwaysOn
  />,

  <SelectArrayInput
    choices={[
      { id: "info", name: "Info" },
      { id: "warning", name: "Warnung" },
      { id: "error", name: "Fehler" },
    ]}
    source="level"
    label={"Level"}
    alwaysOn
  />,
];

const logFilterDefaultValues = {};

export { logFilter, logFilterDefaultValues };
