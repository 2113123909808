import { ArrayInput, FormTab, SelectInput, TextField, useDataProvider, useGetList } from "react-admin";
import MailsTabFormIterator from "./MailsTabFormIterator";
import * as React from "react";
import { useRecordContext } from "ra-core";
import { Box, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ReferenceInput from "../../../utils/components/ReferenceInput";
import { TextInput } from "../../../utils/components";
import CloseIcon from "@material-ui/icons/RemoveCircleOutline";
import { Spacer } from "../../../utils/components/FormElements";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { makeStyles } from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import dataProvider from "../../../dataProvider";
import DateField from "../../../utils/components/DateField";

export default ( props ) => {
  const record = useRecordContext()

  return <FormTab label={"App Events"} {...props}>
    <Box style={{width: "100%"}} maxWidth={"1024px"}>
      <ArrayInput source={"additionalProperties.appEvents"} label={""} fullWidth>
        <AppEventFormIterator />
      </ArrayInput>
    </Box>
  </FormTab>
}

const AppEventFormIterator = ( props ) => {
  const classes = useStyles();
  // const dataProvider = useDataProvider()

  const eventList = props.fields?.value?.map( ( item, index ) => {
    return item.event
  })

  const events = [ ...new Set( eventList )]

  const { data, ids, loading, error } = useGetList(
    'app_events',
    {perPage: 1000, page: 1},
    { field: 'name', order: 'ASC' },
    { event: events }
  )

  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Typ</TableCell>
              <TableCell>Uhrzeit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.fields.map( ( item, index ) => {
              return <TableRow>
                <TableCell>
                  {loading ? "Bitte warten..." : (Object.values(data).find( ( event ) => event.event === props.fields.value[index].event )?.name ?? props.fields.value[index].event) }                  {/*<TranslateEventName event={props.fields.value[index].event} />*/}
                </TableCell>
                <TableCell><TextField helperText={false} className={classes.textInput} label={"type"} source={`${item}.type`} fullWidth /></TableCell>
                <TableCell><DateField helperText={false} className={classes.textInput} source={`${item}.timestamp`} displayTime={true} fullWidth /></TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>

      </TableContainer>
    </>
  )
}


const useStyles = makeStyles( ( theme ) => ( {
  listRow: {
    alignItems: 'center',
    backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.default,
    // borderBottom: "2px solid rgba(128,128,128, 0.75)",
    '&:nth-child(odd)': {
      // boxShadow: "rgb(0,0,0,0.26) 0px 2px 20px 0px",
      backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.odd,
    },
    '&:hover': {
      backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.hover,
    },
  },
  listHead: {
    padding: '0 5px 5px 5px',
    fontSize: '0.9rem',
    fontWeight: 'bold',
    borderBottom: '1px solid #fefefe'

  },
  listItem: {
    padding: '0px 5px'
  },
  listItemRight: {
    padding: '0px 5px',
    textAlign: 'right'
  },

  textInput: {
    margin: "8px 3px 4px 3px",
    float: 'left',
  }
} ) )
