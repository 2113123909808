import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Box, Card, CardContent, CardHeader, makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useDataProvider, useNotify, useQueryWithStore } from "react-admin";
import GlobalLoading from "../../utils/components/GlobalLoading";
import { useTranslate } from "ra-core";
import EntityLink from "../../utils/components/EntityLink";

const useStyles = makeStyles( ( theme ) => ( {
  listItem: {
    padding: theme.spacing( 1 ),
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.grey[ 200 ],
    }
  },
  formContainer: {
    display: "grid",
    position: "relative",
    height: "100%",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: theme.spacing( 2 ),
    [ theme.breakpoints.down( 'lg' ) ]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
  card: {
    margin: "0.5rem",
    padding: "1rem"
  },
} ) );

const ProtocolImages = () => {
  const classes = useStyles();

  return <Box className={classes.formContainer}>
    <DroppableItemList state={'STARTED'}/>
    <DroppableItemList state={'SUBMITTED'}/>
  </Box>;
}

const DroppableItemList = ( props ) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify()
  const [data, setData] = useState( [] );
  const translate = useTranslate();


  const queryProps = {
    type: 'getList',
    resource: 'protocol_images',
    payload: {
      pagination: {
        page: 1,
        perPage: 1000
      },
      sort: {
        field: 'sort',
        order: 'ASC'
      },
      filter: {
        state: props.state
      }
    }
  }

  const { data: fetchedData, loading, error } = useQueryWithStore( queryProps );

  useEffect( () => {
    if( fetchedData ) {
      setData( Object.values( fetchedData ) );
    }
  }, [fetchedData] );

  if( loading ) {
    return <GlobalLoading/>
  }

  async function handleOnDragEnd( result ) {
    if( !result.destination ) {
      return;
    }

    const itemId = result.draggableId;
    const destinationIndex = result.destination.index;
    const destinationSorting = data[ destinationIndex ].sort;
    data[ result.source.index ].sort = destinationSorting-1;
    setData( [...data] );

    // Update the item in the data source
    dataProvider.update( 'protocol_images', {
      id: itemId,
      data: { sort: destinationSorting },
    } ).then( ( { data: updatedItem } ) => {
      notify( 'Position aktualisiert', { type: 'info' } );

      dataProvider.getList( 'protocol_images', {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'sort', order: 'ASC' },
        filter: { state: props.state }
      } ).then( ( { data: updatedList } ) => {
        // Update the local state with the updated list
        setData( updatedList );
      } );
    } );
  }

  return (
    <Card elevation={1} className={classes.card}>
      <CardHeader title={translate( `protocolImages.${props.state}` )}/>
      <CardContent>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="items">
            {( provided ) => (
              <Grid container {...provided.droppableProps} ref={provided.innerRef}>

                {data.sort( ( a, b ) => a.sort > b.sort ? 1 : -1 ).map( ( item, index ) => {
                  const { state, id, image, sort, entityId } = item
                  return <Draggable key={id} draggableId={id} index={index}>
                    {( provided ) => (
                      <Grid container {...provided.draggableProps} {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            className={classes.listItem}>
                        <Grid item xs={1}>{index+1}</Grid>
                        <Grid item xs={1}>{sort}</Grid>
                        <Grid item xs={7}>
                          {translate( `protocolImages.${image}` )}
                        </Grid>
                        <Grid item>
                          <EntityLink record={item}>Bearbeiten</EntityLink>
                        </Grid>
                      </Grid>
                    )}
                  </Draggable>
                } )}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
      </CardContent>
    </Card>
  );
}

export default ProtocolImages;
