import { SearchInput, SelectArrayInput, SelectInput } from "react-admin";
import React from "react";

const driverFilter = [
  <SearchInput placeholder="Fahrer" source="_name" alwaysOn/>,
  <SelectInput choices={[
    { id: false, name: 'Nur Inaktive' },
    { id: true, name: 'Nur Aktive' },
  ]}
               emptyText={"Alle"}
               emptyValue={null}
               label={"Status"}
               source={"user.active"}
               alwaysOn/>,

  <SelectArrayInput

    source={"gruppe"}
    label={"Kategorie"}
    optionText={'name'}
    style={{ minWidth: "200px" }}
    choices={[
      { id: '1', name: 'Minijob' },
      { id: '2', name: 'Teilzeit' },
      { id: '3', name: 'Vollzeit' },
      { id: '4', name: 'Bürokraft' },
      { id: '5', name: 'Subunternehmer' },
      { id: '6', name: 'Logistiker' },
      { id: '7', name: 'Vermaco' },
      { id: '8', name: 'Kleinunternehmer' },

    ]}
    alwaysOn/>,
  <SelectInput choices={[
    { id: false, name: 'Nur Unbestätigte' },
    { id: true, name: 'Nur Bestätigte' },
  ]}
               emptyText={"Alle"}
               emptyValue={null}
               label={"Bestätigt"}
               source={"approved"}
               alwaysOn/>,

];

const driverFiltersDefaultValues = {
  user: {
    active: true,
  },
  approved: true,
};

export { driverFilter, driverFiltersDefaultValues };
