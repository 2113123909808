import { FormDataConsumer, FormWithRedirect, useDataProvider, useNotify, useRefresh, Button } from "react-admin";
import ConfirmIconDefault from "@material-ui/icons/CheckCircle";
import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { BooleanInput } from "../../utils/components";

export const ApplicationButton = ( { record, value, text, style, icon = <ConfirmIconDefault style={{color: "rgb(40, 167, 69)"}}/>, showBundleDialog = false, ...props } ) => {
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  if( !record ) {
    return null;
  }

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const resource = "applications";

  const click = async () =>
    dataProvider
      .update( resource, {
        id: record.id,
        data: {
          status: value
        },
      } )
      .then( ( response ) => {
        // success side effects go here
        refresh();
        notify( "Bewerbung erfolgreich bearbeitet", "success" );
      } )
      .catch( ( error ) => {
        // failure side effects go here
        notify( `Fehler beim bearbeiten: ${error.message}`, { type: "warning" } );
      } );

  const handleConfirm = async( data ) => {
    if( data.deactivateBundle ) {
      const contractBundle = record.contract.bundle
      await dataProvider.update( 'bundles', {
        id: contractBundle,
        data: {
          active: false,
        }
      } ).then( ( response ) => {
        // success side effects go here
        notify( `Fahrtpaket erfolgreich aufgelöst`, { type: "success" } );
      } ).catch( ( error ) => {
        // failure side effects go here
        notify( `Fehler beim bearbeiten: ${error.message}`, { type: "warning" } );
      } )
    }

    await click()
  }

  return (
    <>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <FormWithRedirect
          {...props}
          save={handleConfirm}
          render={() => (
            <form>
              <DialogTitle>Auftrag ist Teil eines Fahrtpakets</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Der beworbene Auftrag ist Teil eines Fahrtpaketes. Wenn Sie diese Bewerbung annehmen, kann das Fahrtpaket aufgelöst werden:
                  <br/>
                  <br/>
                  <BooleanInput source="deactivateBundle" label="Fahrtpaket auflösen" initialValue={true}/>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={ () => setOpen( false ) } color="primary" children={<>Abbrechen</>} />
                <FormDataConsumer>
                  {({ formData }) => (
                    <Button onClick={ () => handleConfirm( formData ) } color="primary" variant={"contained"} autoFocus label={"Speichern"} />
                  )}
                </FormDataConsumer>
              </DialogActions>
            </form>
          )}/>
      </Dialog>
      <Button
        title={text}
        onClick={showBundleDialog ? handleOpen : click}
        children={<>{icon}</>}
        style={style}
      />
    </>

  );
};
