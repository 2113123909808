export default ( value ) => {
  if( !value || value === '0.00' ) {
    return '-';
  }

  return new Intl.NumberFormat(
    'de-DE', {
      style: 'currency',
      currency: 'EUR'
    } ).format( value );
}
