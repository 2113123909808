import { ArrayInput, FormDataConsumer, FormTab, ImageField, SelectInput, TextInput } from "react-admin";
import SimpleFormIndexableIterator from "../../../utils/components/SimpleFormIndexableIterator/SimpleFormIndexableIterator";
import { DamageDropdown } from "./Damage";
import { LocationDropdown } from "./Location";
import { ComponentDropdown } from "./Component";
import ImageInput from "../../../utils/components/ImageInput";
import * as React from "react";
import DateField from "../../../utils/components/DateField";
import LazyImageField from "../../../utils/components/LazyImageField";

export default ( props ) => <FormTab label="Schäden" {...props}>
  <ArrayInput source="damages" label={"Schadensbilder"} fullWidth>
    <SimpleFormIndexableIterator>
      <FormDataConsumer>
        {( { getSource, scopedFormData } ) => {
          return <>
            <DamageDropdown source={getSource("damage")} fullWidth label={"Schaden"} helperText={false}/>
            <LocationDropdown source={getSource("location")} fullWidth label={"Ort"} helperText={false}/>
            <ComponentDropdown source={getSource("component")} fullWidth label={"Komponente"} helperText={false}/>
            {/*<ProcessStateTypeSelectInput source={"image.processStateType"}/>*/}
            <SelectInput
              source={getSource("image.processStateType" )}
              label={"Zustand"}
              fullWidth
              choices={[
                { id: "STARTED", name: "Abholung" },
                { id: "SUBMITTED", name: "Zustellung" },
                { id: "RECEIVED", name: "Nach Zustellung" },
              ]}
              defaultValue={"SUBMITTED"}
              helperText={false}
            />
            <TextInput source={getSource("image.imageProcessStateType")} value={"REMOTEDONE"} defaultValue={"REMOTEDONE"} style={{ display: "none" }} helperText={false}/>


            <ImageInput source={getSource("image.file")} label={""} multiple={false}>
              <LazyImageField
                source="remoteFileName"
                title="Schaden"
                onClick={props.onImageClick}
                loading={"lazy"}
              />
            </ImageInput>
            <span style={{fontSize: "0.875rem"}}>
              Aufgenommen am <DateField source={getSource("image.created")} options={ { year: "numeric", month: "2-digit", day: "2-digit", hour: '2-digit', minute: '2-digit', second: '2-digit' } } fullWidth/>
            </span>
          </>
        }}
      </FormDataConsumer>

    </SimpleFormIndexableIterator>
  </ArrayInput>
</FormTab>
