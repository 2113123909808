import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { withStyles, makeStyles } from '@material-ui/core/styles';

const StyledTableCell = withStyles( ( theme ) => ( {
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: "5px",

  },
  body: {
    fontSize: 14,
    padding: "5px",
    '& > table tr:last-of-type td': {
      borderBottom: '0 none',
    }
  },
} ) )( TableCell );

const StyledTableRow = withStyles( ( theme ) => ( {
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
} ) )( TableRow );

export default ( { changeSet = {} || null } ) => {
  if( !changeSet || Object.keys( changeSet ).length === 0 ) {
    return null;
  }

  return <TableContainer>
    <Table size="small">
      <TableHead>
        <TableRow>
          <StyledTableCell>Field title</StyledTableCell>
          <StyledTableCell>Changeset</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>

        {Object.entries( changeSet ).map( function( entry ) {
          let title = entry[ 0 ]
          let data = entry[ 1 ]

          return <StyledTableRow key={title}>
            <StyledTableCell component="th" scope="row">{title}</StyledTableCell>
            <StyledTableCell style={{ padding: 0 }}>
              <Table size="small">
                <TableBody>
                  <DataFieldType field={data[ 0 ]} prefix={"before"}/>
                  <DataFieldType field={data[ 1 ]} prefix={"after"}/>
                </TableBody>
              </Table>
            </StyledTableCell>
          </StyledTableRow>

        } )}
      </TableBody>
    </Table>
  </TableContainer>
}
const DataFieldType = ( { field, prefix = '' } ) => {
  if( field === null ) {
    return null;
  }
  return <TableRow>
    <StyledTableCell style={{ width: "50px", color: "grey" }}><small>{prefix}</small></StyledTableCell>
    <StyledTableCell style={{ width: "50px" }}><small>{typeof field}</small></StyledTableCell>
    <StyledTableCell>{print( field )}</StyledTableCell>
  </TableRow>
}

function print( result ) {
  if( typeof result === 'object' ) {
    result = <pre>{JSON.stringify( result, null, 2 )}</pre>
  }
  return result;
}
