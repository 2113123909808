import { FieldGuesser } from "@api-platform/admin";
import React from "react";
import HotList from "../utils/components/HotList";

const CategoryList = (props) => (
  <HotList {...props}>
    <FieldGuesser source={"kategorie"} label={"Kategorie"} />
  </HotList>
);

export default CategoryList;
