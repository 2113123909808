import { Button, useNotify, useRefresh, useDataProvider } from "react-admin";

import ConfirmIconDefault from "@material-ui/icons/CheckCircle";

const ConfirmIcon = ConfirmIconDefault
ConfirmIcon.styles = {
  root: {
    color: "green",
  },
};

export const ConfirmContractButton = ( { record } ) => {
  
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  if( !record ) {
    return null;
  }
  const resource = "import_contracts";
  const showButtonText = false;
  const buttonText = "Auftrag importieren";

  const confirm = () =>
    dataProvider
      .update( resource, {
        id: record.id,
        data: {
          ...record,
          isImported: true
        },
      } )
      .then( ( response ) => {
        // success side effects go here
        refresh();
        notify( "Auftrag erfolgreich Angenommen", "success" );
      } )
      .catch( ( error ) => {
        // failure side effects go here
        notify( `Fehler beim Annehmen: ${error.message}`, { type: "warning" } );
      } );

  return (
    <Button
      title={buttonText}
      onClick={confirm}
      children={
        <>
          <ConfirmIcon/>
          {showButtonText && buttonText}
        </>
      }
    />
  );
};
