import * as React from "react";
import {
  BooleanInput,
  Button,
  Create,
  DeleteWithConfirmButton,
  Edit,
  FormTab,
  PasswordInput as DefaultPasswordInput,
  ReferenceArrayInput,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  TabbedForm,
  TextField,
  useNotify,
  usePermissions
} from "react-admin";

import { Box, Toolbar } from "@material-ui/core";
import CustomBooleanInput from "../utils/components/CustomBooleanInput";
import ReverseBooleanInput from "../utils/components/ReverseBooleanInput";
import { DriverReferenceInput } from "./components/DriverReferenceInput";
import ReferenceInput from "../utils/components/ReferenceInput";
import DefaultEditActions from "../utils/DefaultEditActions";
import { DateInput } from "../utils/components/DateInput";
import { FormTabContainerBox, InputContainer, ParagraphBox, ParagraphHeadline } from "../utils/components/FormElements";
import RefreshButton from "../utils/components/RefreshButton";
import DefaultTextInput from "../utils/components/TextInput";
import DateField from "../utils/components/DateField";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import EntityLink from "../utils/components/EntityLink";
import { GetRequest } from "../utils/request/GetRequest";
import { AddressReferenceArrayInput } from "../address/components/AddressReferenceInput";
import CountrySelectInput from "../utils/components/CountrySelectInput";
import { PasswordInput } from "../utils/components/PasswordWithSuggestionInput";

const TextInput = ( props ) => <DefaultTextInput {...props} fullWidth/>;

const DriverForm = ( props ) => {
  const { permissions } = usePermissions()
  return <TabbedForm
    {...props}
    autocomplete="off">
    <FormTab label={"Allgemein"}>
      <Box style={{ width: "100%" }}>
        <ParagraphBox flexWrap={"wrap"}>
          <FormTabContainerBox flex={1} minWidth={"550px"}>
            <ParagraphHeadline>Anschrift</ParagraphHeadline>
            <ParagraphBox>
              <InputContainer left>
                <TextInput source={"anrede"}/>
              </InputContainer>
              <InputContainer center>
                <TextInput source={"firma"} label={"Firma"}/>
              </InputContainer>
              <InputContainer right>
                <TextInput source={"vorname"}/>
              </InputContainer>
              <InputContainer right>
                <TextInput source={"nachname"}/>
              </InputContainer>
            </ParagraphBox>

            <ParagraphBox>
              <InputContainer left>
                <TextInput source={"strasse"}/>
              </InputContainer>
              <InputContainer center>
                <TextInput source={"hausnr"}/>
              </InputContainer>
              <InputContainer center>
                <TextInput source={"plz"}/>
              </InputContainer>
              <InputContainer center>
                <TextInput source={"ort"}/>
              </InputContainer>
              <InputContainer right>
                <CountrySelectInput source={"land"}/>
              </InputContainer>
            </ParagraphBox>

            <ParagraphBox>
              <InputContainer left>
                <TextInput source={"telefon"}/>
              </InputContainer>
              <InputContainer center>
                <TextInput source={"telefon2"}/>
              </InputContainer>
              <InputContainer center>
                <TextInput source={"email"}/>
              </InputContainer>
              <InputContainer right>
                <TextInput source={"fax"} label={"Email 2"}/>
              </InputContainer>
            </ParagraphBox>
          </FormTabContainerBox>
          <FormTabContainerBox flex={1} style={{ minWidth: "550px" }}>
            <ParagraphHeadline>Fahrerdaten</ParagraphHeadline>

            <ParagraphBox>
              <InputContainer left flex={2}>
                <TextInput source={"name"} label={"Vorname, Name"}/>
              </InputContainer>
              <InputContainer center>
                <TextInput source={"kuerzel"}/>
              </InputContainer>
              <InputContainer right>
                <DateInput
                  source={"birthday"}
                  label={"Geburtstag"}
                  fullWidth
                  helperText={false}
                />
              </InputContainer>
            </ParagraphBox>

            <ParagraphBox>
              <InputContainer left>
                <TextInput source={"fuehrerschein"}/>
              </InputContainer>

              <InputContainer center>
                <TextInput source={"klassen"}/>
              </InputContainer>

              <InputContainer center style={{ display: "flex", alignItems: "center" }}>
                <BooleanInput source="hasDriverCard" label={"Fahrerkarte"} helperText={false}/>
              </InputContainer>

              <InputContainer right>
                <TextInput source={"fahrerregion"}/>
              </InputContainer>
            </ParagraphBox>

            <ParagraphBox>
              <InputContainer left>
                <BooleanInput source="certificateOfConductAvailable" label={"Führungszeugnis liegt vor"} helperText={false}/>
              </InputContainer>
            </ParagraphBox>

            <ParagraphBox>
              <InputContainer>
                <AddressReferenceArrayInput
                  source={"parkingSpaces"}
                  label={"Stellplätze"}
                  filter={{ kategorie: "1" }}
                  sort={{ field: "plz", order: "ASC" }}
                  format={( value ) => {
                    return value ? value.map( item => {
                      if( typeof item === "undefined" ) {
                        return null
                      }
                      return typeof item === "object" ? item[ '@id' ] : item
                    } ) : null
                  }}
                  fullWidth>
                  <SelectArrayInput
                    style={{ minWidth: "200px" }}
                    optionText={( item ) => `${item.suchname} ${item.plz}`}
                    helperText={false}
                  />
                </AddressReferenceArrayInput>
              </InputContainer>
            </ParagraphBox>

            <ParagraphBox>
              <InputContainer left>
                <TextInput source={"verfuegbarstandort"}/>
              </InputContainer>
              <InputContainer center>
                <TextInput source={"verfuegbarstandortplz"}/>
              </InputContainer>
              <InputContainer right>
                <TextInput source={"verfuegbarkommentar"}/>
              </InputContainer>
            </ParagraphBox>

          </FormTabContainerBox>
        </ParagraphBox>


        <ParagraphBox flexWrap={"wrap"}>

          <FormTabContainerBox flex={1} style={{ minWidth: "550px" }}>
            <ParagraphHeadline>Login, Rollen & Rechte</ParagraphHeadline>
            <ParagraphBox>
              <InputContainer left>
                <DriverReferenceInput source={"fahrervon"} label={"Fahrer von"}/>
              </InputContainer>
              {permissions && permissions.includes( 'vermaco_admin' ) && <InputContainer center>
                <ReferenceInput source={"gruppe"} reference="job_categories" label="Gruppe" helperText={false}>
                  <SelectInput optionValue={"id"} optionText={"kategorie"}/>
                </ReferenceInput>
              </InputContainer>}
              {permissions && permissions.includes( 'vermaco_admin' ) && <InputContainer right>
                <ReferenceArrayInput reference="roles" source="userRoles" label="Rollen" fullWidth helperText={false}>
                  <SelectArrayInput optionValue={"id"} optionText={"description"}/>
                </ReferenceArrayInput>
              </InputContainer>}
            </ParagraphBox>

            <ParagraphBox>
              <InputContainer left>
                <TextInput source={"user.username"} label={"Nutzername"}/>
              </InputContainer>
              <InputContainer center>
                <PasswordInput source={"user.plainPassword"} initiallyVisible={true} label={"Neues Passwort"} fullWidth helperText={false}/>
              </InputContainer>
              <InputContainer right>
                <DefaultPasswordInput source={"user.initialPassword"} disabled={true} label={"Initial-Password"} helperText={false} fullWidth/>
              </InputContainer>
            </ParagraphBox>

            <ParagraphBox>
              <InputContainer>
                <BooleanInput source={"user.active"} defaultValue={true} label={"Aktiv"} helperText={false}/>
              </InputContainer>
              <CustomBooleanInput source={"mitarbeiter"} label={"Fahrerportal"} defaultValue={'1'}/>
              <CustomBooleanInput source={"dispositionssperre"} label="Dispositionssperre"/>
            </ParagraphBox>

            <ParagraphBox>
              {permissions && permissions.includes( 'vermaco_admin' ) && <CustomBooleanInput source={"admin"} label="Adminoberfläche"/>}
              <CustomBooleanInput source={"darfsubfahreranlegen"} label={"Darf Subfahrer anlegen"}/>
              <ReverseBooleanInput source={"keineangebotsinfomail"} label="Angebotsinfomail"/>
            </ParagraphBox>

            <ParagraphBox>
              <CustomBooleanInput source={"testauftragdurchgefuehrt"} label="Testauftrag durchgeführt"/>
              <InputContainer>
                <BooleanInput source="approved" label={"Fahrer bestätigt"} defaultValue={true} helperText={false}/>
              </InputContainer>

              <InputContainer>
              </InputContainer>
            </ParagraphBox>
            <ParagraphBox>
              <InputContainer>
                <BooleanInput source="showOfferList" label={"Angebotsliste anzeigen"} defaultValue={true} helperText={false}/>
              </InputContainer>
              <InputContainer>
                <BooleanInput source="manageShowOfferList" label={"Subfahrer Angebotsliste anzeigen"} defaultValue={true} helperText={false}/>
              </InputContainer>
              <InputContainer>
              </InputContainer>
            </ParagraphBox>

          </FormTabContainerBox>
          <FormTabContainerBox flex={1} style={{ minWidth: "550px" }}>
            <ParagraphHeadline>App Daten</ParagraphHeadline>
            <ParagraphBox>
              <InputContainer left>
                <BooleanInput source="appAccess" label={"App Zugriff"} helperText={false} defaultValue={true}/>
              </InputContainer>
              <InputContainer right>
                <BooleanInput source="enableLogisticFunctions" label={"Logistiker Funktionen in App"} helperText={false}/>
              </InputContainer>
            </ParagraphBox>

            <ParagraphBox>
              <InputContainer left>
                <TextInput source="deviceId" label={"Geräte-ID"}/>
              </InputContainer>
              <InputContainer right>
                <TextInput source="deviceName" label={"Gerätename"} disabled/>
              </InputContainer>


            </ParagraphBox>

            <ParagraphBox>
              <InputContainer left>
                <TextInput source="currentVersion" label={"Version"} disabled/>
              </InputContainer>
              <InputContainer center>
                <TextInput source="osVersion" label={"OS-Version"} disabled/>
              </InputContainer>
              <InputContainer right>
                <TextInput source="pushNotificationToken" label={"Push Notification ID"} disabled/>
              </InputContainer>
            </ParagraphBox>
          </FormTabContainerBox>
        </ParagraphBox>

        <ParagraphBox flexWrap={"wrap"}>
          <FormTabContainerBox flex={1} style={{ minWidth: "550px" }}>
            <ParagraphHeadline>Abrechnung & Steuern</ParagraphHeadline>
            <ParagraphBox>
              <InputContainer left>
                <TextInput source={"bank"}/>
              </InputContainer>
              <InputContainer center>
                <TextInput source={"iban"}/>
              </InputContainer>
              <InputContainer right>
                <TextInput source={"bic"}/>
              </InputContainer>
            </ParagraphBox>

            <ParagraphBox>
              <InputContainer left>
                <TextInput source={"ustid"}/>
              </InputContainer>
              <InputContainer center>
                <TextInput source={"steuernummer"}/>
              </InputContainer>
              <InputContainer right>
                <TextInput source={"steuerid"}/>
              </InputContainer>
            </ParagraphBox>

            <CustomBooleanInput source={"steuerbescheinigung"}/>

            <DateInput
              source={"steuerbescheinigungDatum"}
              label={"Steuerbescheinigung Datum"}
              helperText={false}
              style={{ minWidth: "300px" }}
            />

          </FormTabContainerBox>
          <FormTabContainerBox flex={1} style={{ minWidth: "550px" }}>
            <ParagraphHeadline>Alternative Gutschriftadresse</ParagraphHeadline>
            <ParagraphBox>
              <BooleanInput source="useCreditAddress" label={"Alternative Adresse nutzen"} helperText={false}/>
            </ParagraphBox>
            <ParagraphBox>
              <InputContainer left>
                <SelectInput
                  source={"creditSalutation"}
                  choices={[
                    { id: 'Herr', name: 'Herr' },
                    { id: 'Frau', name: 'Frau' },
                  ]}
                  label={"Anrede"}
                  allowEmpty={true}
                  fullWidth
                  helperText={false}
                />
              </InputContainer>
              <InputContainer center>
                <TextInput source={"creditCompany"} label={"Firma"}/>
              </InputContainer>
              <InputContainer center>
                <TextInput source={"creditFirstName"} label={"Vorname"}/>
              </InputContainer>
              <InputContainer right>
                <TextInput source={"creditLastName"} label={"Nachname"}/>
              </InputContainer>
            </ParagraphBox>

            <ParagraphBox>
              <InputContainer left>
                <TextInput source={"creditStreet"} label={"Strasse"}/>
              </InputContainer>
              <InputContainer center>
                <TextInput source={"creditHouseNumber"} label={"Hausnummer"}/>
              </InputContainer>
              <InputContainer center>
                <TextInput source={"creditZip"} label={"Plz"}/>
              </InputContainer>
              <InputContainer center>
                <TextInput source={"creditCity"} label={"Ort"}/>
              </InputContainer>
              <InputContainer right>
                <CountrySelectInput source={"creditCountry"}/>
              </InputContainer>
            </ParagraphBox>
            <ParagraphBox>
              <InputContainer left>
                <TextInput source={"creditEmail"} label={"Email"}/>
              </InputContainer>
              <InputContainer right>
                <TextInput source={"creditPhone"} label={"Telefon"}/>
              </InputContainer>
            </ParagraphBox>
          </FormTabContainerBox>
        </ParagraphBox>

      </Box>
    </FormTab>
    <FormTab label={"Statistiken"}>
      <Box p={1} style={{ width: "100%" }}>
        <Box>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: "250px" }}>Angelegt am</TableCell>
                <TableCell><DateField source={"createdAt"} /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "250px" }}>Letzter Login</TableCell>
                <TableCell><DateField source={"lastLogin"}
                                      label={"Last login"}
                                      options={{ year: "numeric", month: "2-digit", day: "2-digit", hour: '2-digit', minute: '2-digit', second: '2-digit' }}/></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "250px" }}>Letzte Aufträge</TableCell>
                <TableCell>
                  {props.record?.lastContracts?.map( ( contract, index ) => {
                    return <Box key={index} display={"flex"} alignItems={"center"}>
                      <EntityLink
                        record={contract}
                        target={"_blank"}
                        resource={'contracts'}>{contract.ordernumber} ({contract.date})</EntityLink>
                    </Box>
                  } )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "250px" }}>Nächste Aufträge</TableCell>
                <TableCell>
                  {props.record?.nextContracts?.map( ( contract, index ) => {
                    return <Box key={index}>
                      <EntityLink
                        record={contract}
                        target={"_blank"}
                        resource={'contracts'}>{contract.ordernumber} ({contract.date})</EntityLink>
                    </Box>
                  } )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "250px" }}>Bewerbungen gesamt</TableCell>
                <TableCell><TextField source={"applicationsCount"}/></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "250px" }}>Angenommene Bewerbungen</TableCell>
                <TableCell><TextField source={"acceptedApplicationsCount"}/></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "250px" }}>Abgelehnte Bewerbungen</TableCell>
                <TableCell><TextField source={"declinedApplicationsCount"}/></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "250px" }}>Zurückgezogene Bewerbungen</TableCell>
                <TableCell><TextField source={"revokedApplicationsCount"}/></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ width: "250px" }}>Gelöschte Bewerbungen</TableCell>
                <TableCell><TextField source={"deletedApplicationsCount"}/></TableCell>
              </TableRow>
            </TableBody>
          </Table>

        </Box>
      </Box>
    </FormTab>
    <FormTab label={"Dokumente"}>
      <Box p={1} style={{ width: "100%" }}>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Hochgeladen am</TableCell>
                <TableCell>Datei</TableCell>
                <TableCell>Typ</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

              {props.record?.documents?.map( ( document, index ) => {

                return <TableRow>
                  <TableCell>{document.createdAtFormatted}</TableCell>
                  <TableCell>{document.name}</TableCell>
                  <TableCell>{document.type}</TableCell>
                  <TableCell>
                    <Button children={<>Download</>}
                            onClick={async() => {
                              await GetRequest( `${document[ '@id' ]}/download`, true ).then( ( response ) => {
                                window.open( response.url, '_blank' );
                              } )
                            }}
                            variant="outlined"/>
                  </TableCell>
                </TableRow>
              } )}
            </TableBody>
          </Table>

        </Box>
      </Box>
    </FormTab>
  </TabbedForm>
};

/**
 * @property record.orderNumber
 * @param record
 * @constructor
 */
const DriverTitle = ( { record } ) => {
  return <span>{record.userId ? ` [${record.userId}]` : ""} Fahrer {record.name ? `"${record.name}"` : ""}</span>;
};

const DriverEditActions = props => (
  <DefaultEditActions {...props} />
);
const DriverToolbar = props => {
  const notify = useNotify();
  const checkForGroup = ( data ) => {
    if( !data.gruppe ) {
      notify( "Für den Fahrer wurde keine Gruppe ausgewählt", { type: "warning" } )
    }
    return data
  }

  return <Toolbar
    style={{
      position: "fixed",
      zIndex: 100000,
      bottom: 10,
      maxWidth: "100%",
      backgroundColor: "rgba(255,255,255, 0.8)",
      borderRadius: "5px",
    }}
  >
    {props.isCreate ? <Box width="100%">
                      <SaveButton
                        saving={props.saving}
                        handleSubmitWithRedirect={props.handleSubmitWithRedirect}
                        label={"Fahrer anlegen"}
                        style={{ marginRight: "0.5rem" }}
                        transform={data => checkForGroup( data )}
                      />
                    </Box> :
     <Box width="100%">
       <SaveButton
         saving={props.saving}
         handleSubmitWithRedirect={props.handleSubmitWithRedirect}
         label={"Speichern und schließen"}
         style={{ marginRight: "0.5rem" }}
         transform={data => checkForGroup( data )}

       />
       <SaveButton
         saving={props.saving}
         redirect={false}
         label={"Speichern"}
         handleSubmitWithRedirect={props.handleSubmitWithRedirect}
         transform={data => checkForGroup( data )}
         style={{ marginRight: "0.5rem" }}
       />
       <DeleteWithConfirmButton
         confirmTitle={`Löschen bestätigen`}
         record={props.record}
         label={"Löschen"}
         confirmContent={`Möchten Sie den eintrag wirklich löschen?`}
       />
       <RefreshButton label="Zurücksetzen"/>
     </Box>
    }
  </Toolbar>
}

const DriverEdit = ( props ) => (
  <Edit
    actions={<DriverEditActions allowNew={true} {...props} />}
    title={<DriverTitle/>}
    style={{ marginBottom: "75px" }}
    {...props}
  >
    <DriverForm isCreate={false} toolbar={<DriverToolbar isCreate={false}/>} {...props} style={{ width: "100%" }}/>
  </Edit>
);

const DriverCreate = ( props ) => (
  <Create
    actions={<DriverEditActions allowNew={true} {...props} />}
    title={<DriverTitle/>}
    style={{ marginBottom: "75px" }}
    {...props}
  >
    <DriverForm isCreate={true} toolbar={<DriverToolbar isCreate={true}/>} {...props} style={{ width: "100%" }}/>
  </Create>
);


export { DriverEdit, DriverCreate };
