import React from "react";
import { DateInput, SearchInput, BooleanInput, SelectInput } from 'react-admin';
import { makeStyles, Chip, Select } from '@material-ui/core';
import moment from 'moment';
import "moment/locale/de";
import { CustomerReferenceArrayInput } from "../../customers/components/CustomerReferenceInput";
import { DriverReferenceArrayInput } from "../../drivers/components/DriverReferenceInput";
import { AddressReferenceArrayInput, translateStellplatzLabel } from "../../address/components/AddressReferenceInput";
import SelectArrayInput from "../../utils/components/SelectArrayInput";

moment.locale("de");

const useQuickFilterStyles = makeStyles((theme) => ({
  chip: {
    marginBottom: theme.spacing(1),
  },
}));

const QuickFilter = ({ label }) => {
  const classes = useQuickFilterStyles();
  return <Chip className={classes.chip} label={label} />;
};

const contractsFilters = [
  <SearchInput
    placeholder="Auftrag, FIN, Fahrer, Paket..."
    source="specialFilter"
    style={{ width: "250px", marginTop: "0" }}
    alwaysOn
  />,
  <SearchInput
    placeholder="Kennzeichen"
    source="licensePlateFilter"
    style={{ width: "150px", marginTop: "0" }}
    alwaysOn
  />,

  <CustomerReferenceArrayInput label="Kunden" source="auftraggeber" alwaysOn/>,
  <DriverReferenceArrayInput source="logistics_and_subs" filter={{ groups_and_subs: ["3", "5", "6", "7", "8"] }} alwaysOn/>,

  <DateInput
    label="Datum von"
    source="bringdatum[after]"
    style={{ minWidth: "125px" }}
    alwaysOn
  />,
  <DateInput
    label="Datum bis"
    source="bringdatum[before]"
    style={{ minWidth: "125px" }}
    alwaysOn
  />,

  <AddressReferenceArrayInput
    label="Stellplatz"
    source="address"
    filter={{ kategorie: "1" }}
    optionText={translateStellplatzLabel}
    alwaysOn/>,

  <SelectArrayInput
    label="PLZ-Gebiet"
    source="zipArea"
    choices={[
      { id: "0", name: "0xxxx" },
      { id: "1", name: "1xxxx" },
      { id: "2", name: "2xxxx" },
      { id: "3", name: "3xxxx" },
      { id: "4", name: "4xxxx" },
      { id: "5", name: "5xxxx" },
      { id: "6", name: "6xxxx" },
      { id: "7", name: "7xxxx" },
      { id: "8", name: "8xxxx" },
      { id: "9", name: "9xxxx" },
    ]}
    emptyLabel={"Alle"}
    alwaysOn
  />,

  <BooleanInput label="UB"
                title={"Unbestätigt"}
                options={{ size: "small" }}
                source="boolchar_auftragbestaetigt"
                parse={v => v ? false : null}
                format={v => typeof v !== "undefined"}
                alwaysOn/>,
  <BooleanInput label="VZ"
                title={"Überfällig"}
                size={"small"}
                options={{ size: "small" }}
                source="verzoegert"
                alwaysOn/>,

  <BooleanInput label="FF" title={"Fahrer fehlt"} options={{ size: "small" }} source="fahrer_fehlt" parse={v => v ? 1 : null} format={v => v === 1} alwaysOn/>,
  <BooleanInput label="FV" title={"Fahrer vorhanden"} options={{ size: "small" }} source="fahrer_vorhanden" parse={v => v ? 1 : null} format={v => v === 1} alwaysOn/>,

  <BooleanInput label="U4"
                title={"Abgeschlossen"}
                size={"small"}
                options={{ size: "small" }}
                source="custom__abgeschlossen"
                parse={v => v ? true : null}
                format={v => typeof v !== "undefined"}
                alwaysOn/>,

  <BooleanInput label="Test" title={"Testauftrag"} options={{ size: "small" }} source="testContract" alwaysOn/>,
  <BooleanInput label="Storno" title={"Storniert"} options={{ size: "small" }} source="custom__storniert" alwaysOn/>,
];

const contractsFiltersDefaultValues = {
  bringdatum: {
    after: moment().format( "Y-M-D" )
  }
};

export { contractsFilters, contractsFiltersDefaultValues };
