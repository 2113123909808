import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent } from '@material-ui/core';

const PreviewButton = ({ record }) => {
  const [open, setOpen] = useState(false);
  const [srcDoc, setSrcDoc] = useState('');

  useEffect(() => {
    const blob = new Blob([record.htmlPreview], { type: 'text/html' });
    setSrcDoc(URL.createObjectURL(blob));
  }, [record.htmlPreview]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="text" color="primary" onClick={handleClickOpen} disabled={!record.htmlPreview}>
        Vorschau
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>E-Mail Vorschau</DialogTitle>
        <DialogContent>
          <iframe src={srcDoc} title="Preview" style={{ width: '100%', height: '80vh' }} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PreviewButton;
