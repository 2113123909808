import * as React from "react";
import { Create, Edit, SimpleForm } from "react-admin";
import DefaultEditActions from "../utils/DefaultEditActions";
import { Box } from "@material-ui/core";

import { TextInput } from "../utils/components";


const EntityForm = (props) => (
  <SimpleForm {...props}>
      <TextInput
        source={"name"}
        label={"Eindeutiger Name"}
        helperText={false}
        minWidth={"300px"} />
      <TextInput
        source={"description"}
        label={"Beschreibung"}
        helperText={false}
        minWidth={"300px"} />
  </SimpleForm>
);

const EntityTitle = ({ record }) => {
  return <span>Rolle {record.name}</span>;
};

export const EntityEdit = (props) => (
  <Edit
    actions={<DefaultEditActions {...props} />}
    title={<EntityTitle />}
    {...props}
  >
    <EntityForm
      isCreate={false} />
  </Edit>
);

export const EntityCreate = (props) => (
  <Create
    actions={<DefaultEditActions {...props} />}
    title={"Neuen Eintrag anlegen"}
    {...props}
  >
    <EntityForm
      isCreate={true}
      {...props}
    />
  </Create>
);
