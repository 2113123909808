import { PriceTypeList } from "./PriceTypeList";
import { EntityCreate, EntityEdit } from "./PriceTypeEdit";

const PriceType = {
  name: "price_types",
  list: PriceTypeList,
  edit: EntityEdit,
  create: EntityCreate,
}

export default PriceType;
