import jwt_decode from "jwt-decode";
import { getAppVersion } from "./utils/request/GetRequest";
import { getMessageToken, token } from "./firebaseMessaging";
import { PostRequest } from "./utils/request/PostRequest";

const login_path = process.env.REACT_APP_API_AUTH_URL;
const TOKEN_REFRESH_ENDPOINT = `${process.env.REACT_APP_API_ENTRYPOINT}/token/refresh`;
const ENV = process.env.REACT_APP_ENV;

const refreshToken = async () => {
  if (!localStorage.getItem("token")) {
    return;
  }
  console.log(
    "%cTrying to refresh token...",
    "background:#1D4ED8;color:#DBEAFE;padding:3px 10px;"
  );

  const request = new Request(TOKEN_REFRESH_ENDPOINT, {
    method: "POST",
    headers: new Headers({ "Content-Type": "application/json", "Authorization": `Bearer ${localStorage.getItem("token")}`}),
    body: JSON.stringify({
      refresh_token: localStorage.getItem("refresh_token"),
    }),
  });

  try {
    const response = await fetch(request);
    if (response.status !== 200) {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      throw new Error("Token renewal failure");
    } else {
      const { token, refresh_token } = await response.json();

      if( token && refresh_token ) {
        localStorage.setItem( "token", token );
        localStorage.setItem( "refresh_token", refresh_token );

        const appVersion = await getAppVersion()
        if( appVersion.version !== process.env.REACT_APP_VERSION ) {
          console.info( "New version available\n"
                        +"API:\t"+appVersion.version+"\n"
                        +"React:\t"+process.env.REACT_APP_VERSION );

          localStorage.setItem( 'outdated', "1" )
        } else {
          localStorage.removeItem( 'outdated' )
          localStorage.removeItem( 'outdatedDismissed' )
        }
      } else {
        throw new Error( "Token renewal failure" );
      }
    }
  } catch (error) {
    console.error(error);
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
  }
};

const authProvider = {
  login: ({ username, password }) => {
    const request = new Request(login_path, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    return fetch(request)
      .then( (response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }

        return response.json();
      })
      .then( async ({ token, refresh_token }) => {
        localStorage.setItem("token", token);
        localStorage.setItem("refresh_token", refresh_token);

        const messageToken = await getMessageToken()
        if( messageToken ) {
          PostRequest(
            `${process.env.REACT_APP_API_ENTRYPOINT}/users/setMessageToken`,
            {
              messageToken: messageToken
            } )
        }
      });
  },

  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      return Promise.reject({ message: error.message });
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },

  checkAuth: async () => {
    await refreshToken();
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
  },

  logout: () => {
    // flush complete local storage
    localStorage.clear();
    return Promise.resolve();
  },

  getPermissions: () => {
    if (!localStorage.getItem("token")) {
      return Promise.reject();
    }

    if( !localStorage.getItem("roles") ) {
      let { roles } = authProvider.getDecodedData();
      if( roles ) {
        localStorage.setItem("roles", roles);
      }
    }

    return localStorage.getItem("roles") ? Promise.resolve(localStorage.getItem("roles")) : Promise.reject();
  },

  getIdentity: () => {
    try {
      let decoded = authProvider.getDecodedData();
      let id = decoded.username;
      let fullName = decoded.username;
      let avatar = null;

      return Promise.resolve({ id, fullName, avatar });
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getDecodedData: () => {
    if (!localStorage.getItem("token")) {
      return null;
    }

    return jwt_decode(localStorage.getItem("token"));
  },
};

export default authProvider;
