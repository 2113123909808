import { ImageInput } from "react-admin";
import * as React from "react";
import { withStyles } from "@material-ui/core";

const styles = {
  root: {
    '& > div': {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",

      '& .previews': {
        width: "50%",

      }
    },
  },
  dropZone: {
    width: "45%",
    display: 'flex',
    alignItems: 'center'
  },

  removeButton: {
    '& button': {
      position: "absolute",
      right: "-10px",
      top: "-5px",
      padding: "0",
      opacity: 0,
      transition: "opacity 0.2s ease-in-out",
      '&:hover': {
        transition: 'background-color 0.2s ease-in-out',
        backgroundColor: 'rgba(0, 0, 0, 0.25)'
      }
    },
    '&:hover button': {
      opacity: 1
    },
    '& img': {
      width: "100%",
      height: "100%",
      objectPosition: "center",
      objectFit: "cover",
      margin: 0,
      maxHeight: "15rem"
    }
  },
}

ImageInput.defaultProps = {
  helperText: false
}

export default withStyles( styles )( ImageInput );
