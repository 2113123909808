import { Box, Toolbar } from "@material-ui/core";
import { SaveButton } from "react-admin";
import RefreshButton from "./RefreshButton";
import * as React from "react";

const EntityToolbar = ( { showSaveAndCloseButton = true, showSaveButton = true, showResetButton = true, saveButtonLabel = 'Speichern', isCreate = false, createButtonLabel = "Fahrer anlegen", saveAndCloseButtonLabel = 'Speichern und schließen',...props } ) => {
  if( !showSaveButton && !showSaveAndCloseButton && !showResetButton && props.children.length === 0 ) {
    return null;
  }

  return <Toolbar
    style={{
      position: "fixed",
      zIndex: 100000,
      bottom: 10,
      maxWidth: "100%",
      backgroundColor: "rgba(255,255,255, 0.8)",
      borderRadius: "5px",
    }}
  >
    {isCreate ? <Box width="100%">
                <SaveButton
                  saving={props.saving}
                  handleSubmitWithRedirect={props.handleSubmitWithRedirect}
                  label={createButtonLabel}
                  style={{ marginRight: "0.5rem" }}
                />
              </Box> :
    <Box width="100%">
      {showSaveAndCloseButton && <SaveButton
        saving={props.saving}
        handleSubmitWithRedirect={props.handleSubmitWithRedirect}
        label={saveAndCloseButtonLabel}
        style={{ marginRight: "0.5rem" }}
      />}
      {showSaveButton && <SaveButton
        saving={props.saving}
        redirect={false}
        label={saveButtonLabel}
        handleSubmitWithRedirect={props.handleSubmitWithRedirect}
        style={{ marginRight: "0.5rem" }}
      />}

      {showResetButton && <RefreshButton/>}

      {props.children}
    </Box>}
  </Toolbar>
};

EntityToolbar.defaultProps = {
  showSaveAndCloseButton: true,
  showSaveButton: true,
  showResetButton: true,
  saveButtonLabel: 'Speichern',
  isCreate: false,
  createButtonLabel: "Fahrer anlegen",
  saveAndCloseButtonLabel: 'Speichern und schließen'
}

export default EntityToolbar;
