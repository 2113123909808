import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import { Button, FormWithRedirect, SaveContextProvider, useNotify, useRefresh } from "react-admin";
import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import GlobalLoading from "../../utils/components/GlobalLoading";
import { GetRequest } from "../../utils/request/GetRequest";
import IconCancel from "@material-ui/icons/Cancel";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import { PostRequest } from "../../utils/request/PostRequest";
import { useRecordContext } from "ra-core";

const AddContractToBundleButton = ( { withRecord, setShowDialog, setLoading } ) => {
    const bundle = useRecordContext();
    const notify = useNotify();
    const refresh = useRefresh();

  const postData = {
    'bundleId': bundle._id,
    'contractId': withRecord._id,
  }

  // const [create, { loading, loaded, error, data }] = useCreate('bundles', postData)


  const onClick = () => {
    setLoading( true )
      PostRequest( `${process.env.REACT_APP_API_ENTRYPOINT}/bundles/add_contract`, postData )
          .then( ( response ) => {
              notify( 'Auftrag hinzugefügt', { type: 'success' } );
              refresh();
              setShowDialog( false );
            setLoading( false )
          })  }

  return <Button label="Auftrag hinzufügen" onClick={onClick} icon={<IconCancel/>}/>
}

const DialogCreateBundle = ( { children, record } ) => {
  const bundle = useRecordContext()
  const [showDialog, setShowDialog] = useState( false );
  const [loading, setLoading] = useState( false );
  const [members, setMembers] = useState( [] );

  const notify = useNotify();

  const bundleContracts = bundle.contractBundles.map( ( contractBundle ) => {
        return contractBundle.contract._id
  });

  const handleOpenClick = async() => {
    setShowDialog( true )
    setLoading( true )
    const result = await GetRequest( `/contracts/${record._id}/offersInRange`, false )
      .then( ( response ) => {
        setLoading( false )
        return response
      } )
      .catch( ( error ) => {
        setLoading( false )
        notify( `Error: ${error.message}`, { type: "warning" } );
      } )

    if( result['hydra:totalItems'] > 0 ) {
        const members = result['hydra:member']

      setMembers(
        members.filter( ( member ) => {
            return !bundleContracts.includes( member._id )
        }
      ))
    }
  }

  const handleCloseClick = () => {
    setShowDialog( false );
  };

  const Distance = ( { value } ) => {
    if( value === 0 ) {
      return <span>0 km</span>
    }

    if( value < 1000 ) {
      return new Intl.NumberFormat( 'de-DE', {
        style: "unit",
        unit: "meter",
      } ).format( value )
    }

    return ( value / 1000 ).toFixed( 2 )+" km"
  }

  const ContractTable = ( { items }) => (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>Auftrag</TableCell>
          <TableCell>Entfernung</TableCell>
          <TableCell>Abholzeit</TableCell>
          <TableCell>Von</TableCell>
          <TableCell>Nach</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map( ( member, index ) => {
          return <TableRow key={index}>
            <TableCell>
              <AddContractToBundleButton record={record} withRecord={member} setShowDialog={setShowDialog} setLoading={setLoading}/>

            </TableCell>
            <TableCell>{member.auftragsnummer}</TableCell>
            <TableCell><Distance value={member.distanceToDestination}/></TableCell>
            <TableCell>{member.pickupTime}</TableCell>
            <TableCell>
              {member.pickupLocation?.strasse}<br />
              {member.pickupLocation?.ort}
            </TableCell>
            <TableCell>
              {member.deliveryLocation?.strasse}<br />
              {member.deliveryLocation?.ort}
            </TableCell>
          </TableRow>
        })}
      </TableBody>
    </Table>
  )

  const bringdatum = new Date(record.bringdatum).toLocaleDateString( "de-DE", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  } );

  const mutationMode = 'pessimistic'

  return <FormWithRedirect
    resource="contracts"
    render={( { handleSubmitWithRedirect, pristine, saving } ) => {

      return <SaveContextProvider>
          <div onClick={handleOpenClick}>{children}</div>
          <Dialog fullWidth open={showDialog} onClose={handleCloseClick} maxWidth={"md"}>
            <DialogTitle>Anschluss an {record.auftragsnummer} hinzufügen</DialogTitle>

            <DialogContent>
              {loading && <GlobalLoading primaryText="" secondaryText="Anschlussaufträge werden geladen"/>}

              <DialogContentText>
                Für den {bringdatum} wurden folgende Anschlussaufträge ohne Fahrer gefunden:
              </DialogContentText>

                <ContractTable items={members.sort( function( a, b ) {
                  return a[ 'distanceToDestination' ] >= b[ 'distanceToDestination' ] ? 1 : -1
                } )}/>

            </DialogContent>

            <DialogActions>
              <Button
                label="ra.action.cancel"
                onClick={handleCloseClick}
              >
                <IconCancel/>
              </Button>
            </DialogActions>
          </Dialog>
        </SaveContextProvider>
    }}/>
}

export const AddContractButton = (props) => (
  <Button
    children={<><AddToPhotosIcon />&nbsp;Auftrag hinzufügen</>}
    {...props}
    />
)

export const AddContractToBundleButtonWithDialog = ( props ) => {
    const { record, ...rest } = props;
    return (
      <>
        <DialogCreateBundle record={record}>
            <AddContractButton {...rest} />
        </DialogCreateBundle>
      </>
    )
}
