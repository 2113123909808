import { FieldGuesser } from "@api-platform/admin";
import React from "react";
import { BooleanField, TextField } from "react-admin";
import HotList from "../utils/components/HotList";
import DateField from "../utils/components/DateField";

const NotificationList = ( props) => (
  <HotList sortOrder="desc" {...props}>
    <TextField source={"createdAtFormatted"} label={"Am"} />
    <TextField source={"title"} label={"Titel"}/>
    <TextField source={"body"} label={"Nachricht"} />
  </HotList>
);

export default NotificationList;
