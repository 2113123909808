import * as React from "react";
import EntityToolbar from "../../utils/components/Toolbar";
import { ResetProtocolButton } from "./Buttons";

export const ProtocolToolbar = ( props ) => (
  <EntityToolbar {...props}>
    <ResetProtocolButton {...props}/>
  </EntityToolbar>

);
