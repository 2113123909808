import { InputContainer, ParagraphBox, ParagraphHeadline, Spacer } from "../../../utils/components/FormElements";
import { SelectInput, BooleanInput, FormDataConsumer } from "react-admin";
import StyledInput from "../../../utils/components/StyledInput";
import { BillingAddressReferenceInput } from "../../../billingAddress/components/BillingAddressReferenceInput";
import * as React from "react";
import { CustomerReferenceInput } from "../../../customers/components/CustomerReferenceInput";
import { getAddressData } from "../../../utils/request";
import { useForm } from "react-final-form";
import { getFilter } from "../../../contracts/ContractEdit";

export default ({ record }) => {
    const { change, batch } = useForm();
    const handleAddressChange = async (customerId) => {
        if (!customerId) return;

        const newAddress = await getAddressData(customerId);

        let anrede = '';
        if (newAddress.anrede == 'Herr' || newAddress.anrede == 'Frau') {
            anrede = newAddress.anrede;
        }

        // Change fields value to new address
        batch(() => {
            change("recipientCompany", newAddress.firma);
            change("recipientUstid", newAddress.ustid);
            change("recipientSalutation", anrede);
            change("recipientFirstName", newAddress.vorname);
            change("recipientLastName", newAddress.nachname);
            change("recipientStreet", newAddress.strasse);
            change("recipientZipcode", newAddress.plz);
            change("recipientCity", newAddress.ort);
            change("recipientCountry", newAddress.land);
            change("recipientMail", newAddress.rechnungsEmail);
            change("recipientPhone", newAddress.telefon);
            change("recipientSteuerId", newAddress.steuerId);
            change("recipientSteuerNo", newAddress.steuernummer);
        });

  };

    return <>
        <ParagraphBox>
            <InputContainer left>
                <ParagraphHeadline>Stammdaten</ParagraphHeadline>

                <ParagraphBox>
                    <StyledInput left>
                        <CustomerReferenceInput source={"customer"} label={"Bestandskunde"} />
                    </StyledInput>

                    <StyledInput right>
                        <FormDataConsumer>
                            {({ formData }) => (

                                <BillingAddressReferenceInput
                                  source={"altAddress"}
                                  onChange={handleAddressChange}
                                  label={"Alternative Rechnungsadresse"}
                                  filter={getFilter(formData.customer, 'adressbesitzer')}
                                />
                           )}
                        </FormDataConsumer>
                    </StyledInput>
                </ParagraphBox>

                <ParagraphBox>
                    <InputContainer left>
                        <StyledInput source={"recipientCompany"} label={"Firma"} />
                    </InputContainer>

                    <InputContainer right>
                        <StyledInput source={"recipientUstid"} label={"Ustid"} />
                    </InputContainer>
                </ParagraphBox>

                <Spacer />

                <ParagraphBox>
                    <InputContainer left flex={1} style={{ paddingRight: "0.25rem" }}>
                        <SelectInput
                            source={"recipientSalutation"}
                            choices={[
                                { id: 'Herr', name: 'Herr' },
                                { id: 'Frau', name: 'Frau' },
                            ]}
                            label={"Anrede"}
                            allowEmpty={true}
                            fullWidth
                            helperText={false}
                        />
                    </InputContainer>
                    <StyledInput source={"recipientFirstName"} label={"Vorname"} center />
                    <StyledInput source={"recipientLastName"} label={"Nachname"} right />
                </ParagraphBox>

                <ParagraphBox>
                    <StyledInput source={"recipientStreet"} label={"Straße & Hausnummer"} autoComplete="street-address" flex={3} left />
                    <StyledInput source={"recipientZipcode"} label={"PLZ"} flex={1} center />
                    <StyledInput source={"recipientCity"} label={"Ort"} flex={2} center />
                    <StyledInput source={"recipientCountry"} label={"Land"} flex={3} right />
                </ParagraphBox>

                <ParagraphBox>
                    <StyledInput source={"recipientMail"} label={"E-Mail"} left />
                    <StyledInput source={"recipientPhone"} label={"Telefon"} right />
                </ParagraphBox>

                <ParagraphBox>
                    <StyledInput source={"recipientSteuerId"} label={"Steuer ID"} left />
                    <StyledInput source={"recipientSteuerNo"} label={"Steuernummer"} center />
                    <StyledInput right display="flex" alignItems="center">
                        <BooleanInput source={"skipTax"} label="Keine Mehrwertsteuer ausweisen" helperText={false} />
                    </StyledInput>
                </ParagraphBox>
            </InputContainer>
        </ParagraphBox>

    </>
}
