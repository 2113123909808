import React, { useState } from "react";
import { useTheme } from '@material-ui/styles';

import { FormDataConsumer, IconButtonWithTooltip, SelectInput, TextInput, useNotify, useUpdate } from "react-admin";
import { Box, Button, FormControl, FormControlLabel, IconButton, List, ListItem, MenuItem, Select, Switch, TablePagination, Toolbar } from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import CloseIcon from "@material-ui/icons/RemoveCircleOutline";
import MoveToIcon from '@material-ui/icons/ImportExport';
import AddIcon from "@material-ui/icons/AddCircleOutline";
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import MoveToPositionFormDialog from "./MoveToPositionFormDialog";
import CurrencyInput from "../CurrencyInput";
import { InputContainer, ParagraphBox } from "../../FormElements";
import PropTypes from 'prop-types';
import { FieldTitle } from 'ra-core';
import { makeStyles } from "@material-ui/core/styles";

const TablePaginationActions = ( props ) => {

  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = ( event ) => {
    onPageChange( event, 0 );
  };

  const handleBackButtonClick = ( event ) => {
    onPageChange( event, page - 1 );
  };

  const handleNextButtonClick = ( event ) => {
    onPageChange( event, page + 1 );
  };

  const handleLastPageButtonClick = ( event ) => {
    onPageChange( event, Math.max( 0, Math.ceil( count / rowsPerPage ) - 1 ) );
  };

  const handlePageSelect = ( event ) => {
    onPageChange( event, event.target.value );
  }

  const pageSelectOptions = [];
  for ( let i = 0; i < Math.ceil( count / rowsPerPage ); i++ ) {
    pageSelectOptions.push( i );
  }

  return (
    <Box sx={ { flexShrink: 0, ml: 2.5 } }>
      <FormControl>
        <Select value={ page } onChange={ handlePageSelect } label="Seite">
          { pageSelectOptions.map( ( item, index ) => {
            return ( <MenuItem key={ index } value={ item }>{ item + 1 }</MenuItem> )
          } ) }
        </Select>
      </FormControl>

      <IconButton
        onClick={ handleFirstPageButtonClick }
        disabled={ page === 0 }
        aria-label="first page"
      >
        { theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon /> }
      </IconButton>

      <IconButton
        onClick={ handleBackButtonClick }
        disabled={ page === 0 }
        aria-label="previous page"
      >
        { theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft /> }
      </IconButton>

      <IconButton
        onClick={ handleNextButtonClick }
        disabled={ page >= Math.ceil( count / rowsPerPage ) - 1 }
        aria-label="next page"
      >
        { theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight /> }
      </IconButton>

      <IconButton
        onClick={ handleLastPageButtonClick }
        disabled={ page >= Math.ceil( count / rowsPerPage ) - 1 }
        aria-label="last page"
      >
        { theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon /> }
      </IconButton>

    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const BillingPositionsFormIterator = ( props ) => {
  const { page, setPage, perPage, setPerPage, fieldName } = props;
  const [ isMoveToPosDialogOpen, setIsMoveToPosDialogOpen ] = useState( false );
  const [ moveToPosFrom, setMoveToPosFrom ] = useState( 0 );
  const [ update ] = useUpdate()
  const [ showGross, setShowGross ] = useState( false );
  const notify = useNotify();
  const updatePos = ( from, to ) => {
    let move = from > to ? -1 : 0;
    update(
      'invoice_entries',
      props.fields?.value[ from ][ '@id' ], {
      position: props.fields?.value[ to ][ 'position' ] + move
    },
      props.fields?.value[ from ], {
      onSuccess: () => {
        notify( `Eintrag erfolgreich verschoben`, { type: 'success' } );
      },
      onFailure: ( error ) => {
        notify( error.message || `Fehler beim verschieben`, { type: 'warning', multiLine: true }
        )
      }
    }
    );
  }

  const total = props.fields?.value?.length ?? 0;

  const addButtonClickHandler = () => {
    props.fields.push( {
      // "@id": "/api/invoice_entries/223",
      "@type": "InvoiceEntry",
      position: total + 1,
      title: "",
      description: "",
      amount: "0.00",
      tax: 19,
      bruttoAmount: "0.00",
      bruttoEqualsNetto: true
    } );

    const maxPage = Math.ceil( ( total + 1 ) / perPage ) - 1;
    if ( page < maxPage ) {
      setPage( maxPage );
    }
  }

  const moveToPosButtonClickHandler = ( index ) => {
    setIsMoveToPosDialogOpen( true );
    setMoveToPosFrom( index );
  }

  const handleChangePage = ( event, newPage ) => {
    setPage( newPage );
  };

  const handleChangeRowsPerPage = ( event ) => {
    setPerPage( parseInt( event.target.value, 10 ) );
    setPage( 0 );
  };

  const classes = useStyles();



  return (
    <>
      <FormControlLabel control={<Switch onClick={() => setShowGross( !showGross )}/>} label={<FieldTitle label="Bruttowerte einblenden"/>}/>

      <List>
        { props.fields.map( ( item, index ) => {
          return index < ( page + 1 ) * perPage && index >= page * perPage ? (
            <ListItem key={ index } className={ classes.listItem }>
              <ListItemControl index={ index } total={ total } moveToPos={ updatePos } moveFunc={ props.fields.move } { ...props } />

              <ParagraphBox style={ { width: '100%' } }>
                <InputContainer left flex={ 7 } mr={ "0.5rem" }>
                  <TextInput helperText={ false } className={ classes.textInput } label={ "Titel" } source={ `${ item }.title` } fullWidth multiline />
                  <TextInput helperText={ false } className={ classes.textInput } label={ "Beschreibung" } source={ `${ item }.description` } fullWidth multiline />
                </InputContainer>

                <InputContainer right>
                  <SelectInput
                    label={ "MwSt." }
                    source={ `${ item }.tax` }
                    initialValue={ 19 }
                    choices={ [
                      { id: 0, name: 'Keine MwSt.' },
                      { id: 7, name: '7%' },
                      { id: 19, name: '19%' }
                    ] }
                    fullWidth
                    helperText={false}
                  />

                  <FormDataConsumer>
                    { ( { formData } ) => (
                      showGross ? <CurrencyInput source={ `${ item }.bruttoAmount` } label="Bruttobetrag" required disabled /> :
                        <CurrencyInput source={ `${ item }.amount` } label="Betrag" required />
                    ) }
                  </FormDataConsumer>


                </InputContainer>
              </ParagraphBox>

              <Box display={ 'flex' } flexDirection={ "column" } justifyContent={ "center" } ml={ "0.5rem" }>
                <Button
                  color={ 'primary' }
                  title="Position verschieben"
                  style={ { minWidth: '32px' } }
                  onClick={ () => moveToPosButtonClickHandler( index ) }>
                  <MoveToIcon />
                </Button>

                <Button
                  color={ 'primary' }
                  title="Position entfernen"
                  style={ { minWidth: '32px' } }
                  onClick={ () => props.fields.remove( index ) }>
                  <CloseIcon />
                </Button>
              </Box>
            </ListItem>
          ) : null
        } ) }

        <Toolbar style={ { justifyContent: 'space-between' } }>
          <Button
            color={ 'primary' }
            label="ra.action.add"
            onClick={ addButtonClickHandler }
          >
            <AddIcon /> Neu
          </Button>

          <TablePagination
            rowsPerPageOptions={ [ 10, 25, 50, 100 ] }
            component="div"
            count={ total }
            page={ page }
            rowsPerPage={ perPage }
            onPageChange={ handleChangePage }
            onRowsPerPageChange={ handleChangeRowsPerPage }
            labelRowsPerPage={ 'Zeilen pro Seite' }
            ActionsComponent={ TablePaginationActions }
          />
        </Toolbar>

        <MoveToPositionFormDialog
          isOpen={ isMoveToPosDialogOpen }
          setIsOpen={ setIsMoveToPosDialogOpen }
          moveFunc={ props.fields.move }
          saveFunc={ updatePos }
          from={ moveToPosFrom }
          maxValue={ total }
          items={ props.fields }
        />
      </List>
    </>
  )
}

const ListItemControl = ( props ) => {
  const { index, total, moveToPos, moveFunc } = props;
  const classes = useStyles();
  return <Box className={ classes.listItemControl }>
    <IconButtonWithTooltip
      label="ra.action.move_up"
      size="small" onClick={ () => {
        moveToPos( index, index - 1 )
        moveFunc( index, index - 1 );
      } }
      disabled={ index <= 0 }>
      <ArrowUpwardIcon />
    </IconButtonWithTooltip>

    <Box className={ classes.currentPos }>{ index + 1 }</Box>

    <IconButtonWithTooltip label="ra.action.move_down" size="small" onClick={ () => {
      moveToPos( index, index + 1 )
      moveFunc( index, index + 1 );
    } } disabled={ total == null || index >= total - 1 }>
      <ArrowDownwardIcon />
    </IconButtonWithTooltip>
  </Box>
}

const useStyles = makeStyles( ( theme ) => ( {
  listItem: {
    padding: '0 5px 0 5px',
    display: 'flex',
    backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.default,
    // borderBottom: "2px solid rgba(128,128,128, 0.75)",
    '&:nth-child(odd)': {
      // boxShadow: "rgb(0,0,0,0.26) 0px 2px 20px 0px",
      backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.odd,
    },
    '&:hover': {
      backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.hover,
    },
  },

  listItemControl: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center"
  },

  currentPos: {
    display: "flex",
    alignItems: "center",
  },
  textInput: {
    margin: "8px 3px 4px 3px",
    float: 'left',
  }
} ) )

BillingPositionsFormIterator.defaultProps = {
  fieldName: 'artikeldatenArray'
}


export default BillingPositionsFormIterator;
