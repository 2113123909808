import React from "react";

import { BooleanField, DeleteWithConfirmButton, TextField, useRedirect } from "react-admin";
import { Box, Button, Chip, Tooltip } from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/RemoveCircleOutline";
import AddIcon from "@material-ui/icons/AddCircleOutline";

import { Spacer } from "../../utils/components/FormElements";
import { makeStyles } from "@material-ui/core/styles";
import * as ButtonStyles from "../../utils/components/styles/ButtonStyles";
import { EditButton } from "../../utils/components/Buttons";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import { useRecordContext } from "ra-core";

const UserFormIterator = ( props ) => {
  const redirect = useRedirect();

  const addButtonClickHandler = () => {
    redirect( 'create', '/users', {}, {}, { record: { customer: props.record[ '@id' ] } } )
  }

  const classes = useStyles();

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nutzername</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Abteilung / Filiale</TableCell>
              <TableCell>Aktiv</TableCell>
              <TableCell>Gruppen</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.fields.map( ( item, index ) => {
              if( typeof props?.fields?.value[ index ] !== "object" ) {
                return null;
              }

              return <TableRow>
                <TableCell><TextField helperText={false} className={classes.textInput} label={"Nutzername"} source={`${item}.username`} fullWidth/></TableCell>
                <TableCell><TextField helperText={false} className={classes.textInput} label={"Mail-Adresse"} source={`${item}.email`} fullWidth/></TableCell>
                <TableCell><TextField source={`${item}.departmentName`} label={"Filiale / Abteilung"} link={false} fullWidth/></TableCell>
                <TableCell><BooleanField source={`${item}.active`} label={"Aktiviert"}/></TableCell>
                <TableCell>
                  {props?.fields?.value[ index ]?.userGroups?.map( ( group, index ) => {
                    return <Tooltip title={group.description ?? group.name} key={group.slug} arrow interactive>
                      <Chip label={group.name}/>
                    </Tooltip>
                  } )}
                </TableCell>
                <TableCell>
                  <EditButton
                    title={"Bearbeiten"}
                    label={""}
                    entity={props?.fields?.value[ index ]}
                    target={"_blank"}
                    style={ButtonStyles.noMargin}
                    children={<EditIcon/>}
                    resource={'users'}
                    {...props}
                  />

                  <DeleteWithConfirmButton
                    color={'primary'}
                    title="Position entfernen"
                    style={{ minWidth: '32px' }}
                    record={{ id: props?.fields?.value[ index ][ '@id' ] }}
                    basePath={'/users'}
                    resource={'users'}
                    redirect={false}
                    disabled={props.fields.value[ index ].isOwner}
                    confirmTitle={`Lösche Nutzer ${props.fields.value[ index ].username}`}

                  >
                    <CloseIcon/>
                  </DeleteWithConfirmButton>
                </TableCell>
              </TableRow>
            } )}
          </TableBody>
        </Table>

      </TableContainer>
      <Spacer/>
      <Box>

        <Button
          color={'primary'}
          label="ra.action.add"
          onClick={addButtonClickHandler}
        >
          <AddIcon/> Neu
        </Button>
      </Box>
    </>
  )
}

const useStyles = makeStyles( ( theme ) => ( {
  listItem: {
    padding: '0 5px 0 5px',
    display: 'flex',
    backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.default,
    // borderBottom: "2px solid rgba(128,128,128, 0.75)",
    '&:nth-child(odd)': {
      // boxShadow: "rgb(0,0,0,0.26) 0px 2px 20px 0px",
      backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.odd,
    },
    '&:hover': {
      backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.hover,
    },
  },

  listItemControl: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center"
  },

  currentPos: {
    display: "flex",
    alignItems: "center",
  },
  textInput: {
    margin: "8px 3px 4px 3px",
    float: 'left',
  }
} ) )

export default UserFormIterator;
