import ServiceList from "./ServiceList";
import { EntityCreate, EntityEdit } from "./ServiceEdit";

const Services = {
  list: ServiceList,
  edit: EntityEdit,
  create: EntityCreate,
  name: "services"
};

export default Services;
