import AccessoriesList from "./AccessoriesList";
import { EntityCreate, EntityEdit } from "./AccessoriesEdit";

const Accessories = {
  list: AccessoriesList,
  edit: EntityEdit,
  create: EntityCreate,
  name: "accessories"
};

export default Accessories;
