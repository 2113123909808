import React, { useState } from "react";
import { useTheme } from '@material-ui/styles';

import { BooleanField, Button, Confirm, TextField, useNotify, useRedirect, useRefresh } from "react-admin";
import { Spacer } from "../../../utils/components/FormElements";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import { PostRequest } from "../../../utils/request/PostRequest";

const SnapshotsTabFormIterator = (props) => {
  const classes = useStyles();

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Datum</TableCell>
              <TableCell>Alter Status</TableCell>
              <TableCell>Neuer Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.fields.map( ( item, index ) => {
              const obj = {
                protocol: props.record.id,
                snapshot: props.record.availableSnapshots[index].id,
              }

              return <TableRow>
                <TableCell><TextField helperText={false} className={classes.textInput} label={"#"} source={`${item}.counter`} fullWidth /></TableCell>
                <TableCell><TextField helperText={false} className={classes.textInput} label={"Datum"} source={`${item}.createdAt`} fullWidth /></TableCell>
                <TableCell><TextField helperText={false} className={classes.textInput} label={"#"} source={`${item}.oldProcessState`} fullWidth /></TableCell>
                <TableCell><TextField helperText={false} className={classes.textInput} label={"#"} source={`${item}.newProcessState`} fullWidth /></TableCell>
                <TableCell>
                  <RestoreButton obj={obj} status={props.record.availableSnapshots[index].oldProcessState} />
                </TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>

      </TableContainer>
      <Spacer />
    </>
  )
}

const RestoreButton = (props) => {
  const refresh = useRefresh();
  const notify = useNotify();

  const [open, setOpen] = useState( false );
  const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

  const restore = async (values) => {
    await PostRequest(entrypoint + "/protocols/restore_snapshot", {
      protocol: values.protocol,
      snapshot: values.snapshot,
    }).then((response) => {
      refresh();
      notify("Erfolgreich wiederhergestellt", { type: "success" });
    }).catch((error) => {
      notify(`Error: ${error.message}`, { type: "warning" });
    })
  };

  return <>
    <Button
    onClick={() => setOpen(true)}
    label={`Auf Status ${props.status} zurücksetzen`}
    />
    <Confirm
      isOpen={open}
      title={"Wiederherstellungspunkt laden"}
      content={"Wollen Sie das Protokoll wirklich mit dem Wiederherstellungspunkt überschreiben?"}
      onConfirm={() =>  restore(props.obj)}
      onClose={() => setOpen(false)}
    />
  </>
}

const useStyles = makeStyles( ( theme ) => ( {
  listItem: {
    padding: '0 5px 0 5px',
    display: 'flex',
    backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.default,
    // borderBottom: "2px solid rgba(128,128,128, 0.75)",
    '&:nth-child(odd)': {
      // boxShadow: "rgb(0,0,0,0.26) 0px 2px 20px 0px",
      backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.odd,
    },
    '&:hover': {
      backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.hover,
    },
  },

  listItemControl: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center"
  },

  currentPos: {
    display: "flex",
    alignItems: "center",
  },
  textInput: {
    margin: "8px 3px 4px 3px",
    float: 'left',
  }
} ) )

SnapshotsTabFormIterator.defaultProps = {
  fieldName: 'availableSnapshots'
}


export default SnapshotsTabFormIterator;
