import BillingAddressList from "./BillingAddressList";
import { EntityCreate, EntityEdit } from "./BillingAddressEdit";

const BillingAddress = {
  list: BillingAddressList,
  edit: EntityEdit,
  create: EntityCreate,
  name: "billing_addresses"
};

export default BillingAddress;
