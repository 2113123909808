import React from "react";
import { BooleanField, FunctionField, TextField } from 'react-admin';
import HotList from "../utils/components/HotList";
import { bundleFilter, bundleFilterDefaultValues } from "./components/BundleFilter";
import EntityLink from "../utils/components/EntityLink";

const BundleList = props => (
  <HotList sort={'id'} sortOrder="DESC" filters={bundleFilter} filterDefaultValues={bundleFilterDefaultValues} {...props} >
    <TextField source={"bundle"} label={"ID"} sortBy="id"/>
    <FunctionField label={"Anzahl Aufträge"} render={(record) => {
      return <>{record.contractBundles?.length}</>
    }}/>
    <FunctionField label={"Aufträge"} render={(record) => {
      return <>{record.contractBundles?.map(function(item, count) {
        return <EntityLink record={item?.contract} label={`${item.contract.auftragsnummer}`} target={"_blank"}/>
      })}</>
    }}/>
    <BooleanField source={"active"} label={"Aktiv"}/>
    <BooleanField source={"openForApplications"} label={"In Angebotsliste anzeigen"}/>
    <BooleanField source={"disposed"} label={"Disponiert"}/>
    {/*<FunctionField label={"Disponiert"} render={record => {*/}
    {/*  if( record.fullyDisposed ) {*/}
    {/*    return "Vollständig";*/}
    {/*  } else if( record.partiallyDisposed ) {*/}
    {/*    return "Teilweise";*/}
    {/*  } else {*/}
    {/*    return "-";*/}
    {/*  }*/}
    {/*}} />*/}
  </HotList>
);


export default BundleList;
