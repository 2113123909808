import React from "react";
import { SearchInput, BooleanInput, DateInput, SelectArrayInput } from "react-admin";
import { CustomerReferenceArrayInput } from "../../customers/components/CustomerReferenceInput";
import { DriverReferenceArrayInput } from "../../drivers/components/DriverReferenceInput";
import { AddressReferenceArrayInput, translateStellplatzLabel } from "../../address/components/AddressReferenceInput";

const filter = [
  <SelectArrayInput label={"Status"} source={"processState"} choices={[
    { name: 'Nicht begonnen', value: 'UNPROCESSED' },
    { name: 'Begonnen', value: 'STARTED' },
    { name: 'Abgeholt (U2)', value: 'SUBMITTED' },
    { name: 'Ausgeliefert (U3)', value: 'RECEIVED' },
    { name: 'Abgeschlossen (U4)', value: 'CLOSED' }
  ]} optionValue={"value"} optionText={"name"} emptyText={"Alle anzeigen"} alwaysOn/>,

  <SearchInput
    placeholder="Auftrag, FIN, Fahrer"
    source="specialFilter"
    style={{ minWidth: "250px", marginTop: "0" }}
    alwaysOn
  />,

  <SearchInput
    placeholder="Kennzeichen"
    source="licensePlateFilter"
    style={{ width: "150px", marginTop: "0" }}
    alwaysOn
  />,

  <CustomerReferenceArrayInput label="Kunden" source="auftrag.auftraggeber" alwaysOn/>,
  <CustomerReferenceArrayInput label="Kunden ausgenommen" source="exceptCustomers" alwaysOn/>,

  <DriverReferenceArrayInput source={"logistics_and_subs"} filter={{ groups_and_subs: ["3", "5", "6", "7", "8"] }} alwaysOn/>,

  <DateInput
    label="Abholdatum von"
    source="auftrag.datum[after]"
    style={{ minWidth: "200px" }}
    alwaysOn
  />,
  <DateInput
    label="Abholdatum bis"
    source="auftrag.datum[before]"
    style={{ minWidth: "200px" }}
    alwaysOn
  />,

  <AddressReferenceArrayInput
    label="Stellplatz"
    source="address"
    filter={{ kategorie: "1" }}
    optionText={translateStellplatzLabel}
    alwaysOn/>,

  <BooleanInput label="Verschickt" options={{ size: "small" }} source="mailSend" parse={v => v ? 1 : false} format={v => v === 1} alwaysOn/>,
  <BooleanInput label="Ausgeblendet" options={{ size: "small" }} parse={v => v ? 0 : null} format={v => v === 0} source="aktiv" alwaysOn/>,
];

const filterDefaultValues = {
  "processState": ['CLOSED'],
  "mailSend": false,
  "aktiv": true
  // "abgeschlossen": "0"
};

export {
  filter,
  filterDefaultValues
}
