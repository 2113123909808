import React, { useState } from "react";
import { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { Box } from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import {
  Button,
  SaveButton,
  BooleanInput,
  useNotify,
  FormWithRedirect,
  TextField,
  useDataProvider,
  useRefresh,
  SaveContextProvider,
  ReferenceInput,
  SelectInput,
  AutocompleteInput, FormDataConsumer, Confirm,
} from "react-admin";
import IconCancel from "@material-ui/icons/Cancel";
import translateDriverLabel from "./TranslateDriverLabel";
import { DriverReferenceInput, DriverReferenceWithSuggestionInput } from "../../drivers/components/DriverReferenceInput";
import { useForm } from "react-final-form";
import { getAddressData } from "../../utils/request";

const styles = {
  outline: {
    border: "1px solid black",
    padding: "3px 5px",
    borderRadius: "5px",
    display: "inline-block",
    marginBottom: "2px",
  },
  error: {
    border: "1px solid black",
    padding: "3px 5px",
    borderRadius: "5px",
    display: "inline-block",
    marginBottom: "2px",
    backgroundColor: "#c82333",
    borderColor: "#bd2130",
    color: "white",
    hover: {
      color: "yellow",
    },
  },
};



function DialogChangeDriver ( { children, record } ) {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const [ showDialog, setShowDialog ] = useState( false );
  // const [update, { loading, error }] = useUpdate('contracts');

  const notify = useNotify();

  const handleOpenClick = () => {
    setShowDialog( true );
  };

  const handleCloseClick = () => {
    setShowDialog( false );
  };

  const [open, setOpen] = useState( false );

  const onSave = async ( values ) => {
    const benutzer = values.benutzer ?? null
    const fahrer = values.driver ?? null

    if( values.deactivateBundle ) {
      for( const bundle of record.bundles ) {
        await dataProvider.update( 'bundles', {
          id: bundle[ '@id' ],
          data: {
            active: false,
          }
        } ).then( ( response ) => {
          // success side effects go here
          notify( `Fahrtpaket ${bundle.bundle} erfolgreich aufgelöst`, { type: "success" } );
        } ).catch( ( error ) => {
          // failure side effects go here
          notify( `Fehler beim bearbeiten: ${error.message}`, { type: "warning" } );
        } )
      }
    }

    dataProvider
      .update( "contracts", {
        id: record.id,
        data: {
          benutzer: benutzer,
          driver: fahrer,
          driverChangedWithMail: values.driverChangedWithMail,
          driverChangedWithStornoMail: values.driverChangedWithStornoMail,
          setDriverForAdditionalRides: values.setDriverForAdditionalRides,
          sperrenFuerAngebotsliste: values.sperrenFuerAngebotsliste
        }
      } )
      .then( ( response ) => {
        // success side effects go here
        refresh();
        notify( "Fahrer erfolgreich gewechselt", { type: "success" } );
      } )
      .catch( ( error ) => {
        // failure side effects go here
        notify( `Error: ${ error.message }`, { type: "warning" } );
      } );
  };

  return (
    <SaveContextProvider>
      <div style={ { display: "inline" } } onClick={ handleOpenClick }>{ children }</div>
      <Dialog fullWidth open={ showDialog } onClose={ handleCloseClick }>
        <DialogTitle>Auftrag { record.auftragsnummer }</DialogTitle>
        <DialogContent>
          <DialogContentText>
            { record.driver && (
              <Box>
                <Typography variant="subtitle2">Aktueller Fahrer</Typography>
                { translateDriverLabel( record.driver ) }
                <br />
                { record.driver.strasse } { record.driver.hausnr }
                <br />
                { record.driver.plz } { record.driver.ort }
                <br />
                <br />
                { record.driver.email }
                <br />
                Telefon: { record.driver.telefon }
                <br />
                Telefon 2: { record.driver.telefon2 }
                <br />
                <br />
                Standort: { record.driver.verfuegbarstandort }
                <br />
                Stellplatz: { record.driver.stellplatz }
                <br />
                Zielregion: { record.driver.verfuegbarzielregion }
                <br />
                Region: { record.driver.fahrerregion }
              </Box>
            ) }
          </DialogContentText>
        </DialogContent>

        <Divider variant="middle" />

        <FormWithRedirect
          resource="contracts"
          save={ onSave }
          render={ ( { handleSubmitWithRedirect, pristine, saving } ) => {
            const { change } = useForm();
            const handleApplicationChange = async ( applicationId ) => {
              const currentApplication = record.openApplications.filter( ( application ) => {
                return application[ '@id' ] === applicationId
              } )

              change( "driver['@id']", currentApplication[ 0 ][ "driver" ][ '@id' ] );
            };

            return <>
              <DialogContent>
                <DialogContentText style={ { marginBottom: "0", marginTop: "1rem" } }>
                  <Typography variant="subtitle2">App-Benutzer</Typography>
                </DialogContentText>

                <DriverReferenceInput source={ "benutzer" } label={ "App Benutzer" }
                  initialValue={ record.benutzer }
                  record={ record }>
                  <AutocompleteInput
                    optionText={ ( record ) => translateDriverLabel( record ) }
                    source={ "benutzer.name" }
                    optionValue={ "@id" }
                  />
                </DriverReferenceInput>
              </DialogContent>
              <DialogContent>
                <DialogContentText
                  style={ { marginBottom: "0", marginTop: "1rem" } }
                >
                  <Typography variant="subtitle2">Neuer Fahrer</Typography>
                </DialogContentText>

                <DriverReferenceWithSuggestionInput
                  source={ "driver" }
                  initialValue={ record.driver }
                  record={ record }
                  suggestions={ record.appliedDrivers }
                  displaySetDriverForAdditionalRides={ record.anschlussAuftrag }
                  setDriverForAdditionalRides={ record.setDriverForAdditionalRides }
                  autofocus={true}
                />

                { record.openApplications.length > 0 && <ReferenceInput
                  source={ "openApplications" }
                  reference={ "applications" }
                  label={ "Beworbene Fahrer" }
                  fullWidth
                  onChange={ ( event ) => {
                    return event.target.value ? handleApplicationChange( event.target.value ) : null
                  } }
                  helperText={ false }
                  filter={ { contract: record.id, status: 'pending' } }
                >
                  <SelectInput optionText={ ( application ) => {
                    return application.driver.name
                  } } />
                </ReferenceInput> }

                { record.driver && (
                  <BooleanInput
                    label="Storno an alten versenden"
                    source="driverChangedWithStornoMail"
                    initialValue={ true }
                    fullWidth
                  />
                ) }
                <BooleanInput
                  label="Auftragsbestätigung an neuen Fahrer versenden"
                  source="driverChangedWithMail"
                  initialValue={ true }
                  fullWidth
                />
              <BooleanInput
                  label="Auftrag für Angebotsliste sperren"
                  source="sperrenFuerAngebotsliste"
                  initialValue={record.sperrenFuerAngebotsliste}
                  fullWidth
                />
                <FormDataConsumer>
                  { ( { formData, ...rest } ) => {
                    return record.isPartOfBundle && formData.driver && <BooleanInput source="deactivateBundle" label="Fahrtpaket auflösen" initialValue={true}/>
                  }}
                </FormDataConsumer>
              </DialogContent>
              <DialogActions>
                <FormDataConsumer>
                  { ( { formData, ...rest } ) => (
                    formData.driver ? <SaveButton
                      handleSubmitWithRedirect={ handleSubmitWithRedirect }
                      pristine={ pristine }
                      saving={ saving }
                    /> : <Box>
                        <Button
                            title={"Speichern"}
                            color="primary"
                            children={<>
                                <SaveIcon style={{marginRight: '0.5rem', fontSize: '18px'}} />
                                Speichern
                            </>}
                            variant="contained"
                            style={{padding: "6px 16px", fontSize: "0.875rem"}}
                            onClick={() => setOpen(true)}
                        />
                        <Confirm
                         isOpen={open}
                         title={`Fahrer entfernen`}
                         content="Möchten Sie wirklich den Fahrer entfernen und nicht durch einen neuen Fahrer ersetzen? Die Fahrt wird damit zu einer Fahrer Fehlt Fahrt."
                         onConfirm={() => onSave(formData)}
                         onClose={() => setOpen( false )}
                         />
                    </Box>
                  )}
                </FormDataConsumer>

                <Button
                  label="ra.action.cancel"
                  onClick={ handleCloseClick }
                // disabled={loading}
                >
                  <IconCancel />
                </Button>
              </DialogActions>

            </>
          } }
        />
      </Dialog>
    </SaveContextProvider>
  );
}

const DriverTooltipContent = ( { driver } ) => (
  <>
    <Typography variant="subtitle1" align="center">
      { translateDriverLabel( driver ) }
    </Typography>
    <Typography variant="body2" align="center" style={ { marginBottom: "10px" } }>
      { driver.strasse } { driver.hausnr }
      <br />
      { driver.plz } { driver.ort }
    </Typography>
    <Typography variant="body2" align="center" style={ { marginBottom: "10px" } }>
      { driver.telefon && ( <>Telefon: { driver.telefon }<br /></> ) }
      { driver.telefon2 && ( <>Telefon 2: { driver.telefon2 }</> ) }
    </Typography>
    { driver.verfuegbarstandort && (
      <Typography variant="body2" align="center">
        Standort: { driver.verfuegbarstandort }
      </Typography>
    ) }
    { driver.stellplatz && (
      <Typography variant="body2" align="center">
        Stellplatz: { driver.stellplatz }
      </Typography>
    ) }
    { driver.verfuegbarzielregion && (
      <Typography variant="body2" align="center">
        Zielregion: { driver.verfuegbarzielregion }
      </Typography>
    ) }
    { driver.fahrerregion && (
      <Typography variant="body2" align="center">
        Region: { driver.fahrerregion }
      </Typography>
    ) }
  </>
);

const OpenApplicationCounter = ( { record, myClasses } ) => {
  let pendingApplications = [];
  try {
    pendingApplications = record.openApplications.filter( ( application ) => application.status === 'pending' );
  } catch ( error ) {
    console.log( error, record.openApplications );
  }
  if ( pendingApplications.length === 0 ) {
    return <span className={ myClasses.error }>FF</span>;
  };
  const kuerzels = pendingApplications.map( ( application ) => application.driver.kuerzel ).reduce( ( acc, cur ) => {
    return acc.includes( cur ) ? acc : acc.concat( cur );
  }, [] );

  return (
    <Tooltip title={ kuerzels } arrow>
      <span className={ myClasses.error }>FF { pendingApplications.length > 0 ? '(' + pendingApplications.length + ')' : '' }</span>
    </Tooltip>
  );
}

const DriverFieldView = ( { record, classes } ) => {
  return (
    <Fragment>
      <DialogChangeDriver record={ record }>
        <div style={ { cursor: "pointer" } } className={ "driverKuerzel" }>

          { record.driver ? (
            <Tooltip
              placement="right"
              title={ <DriverTooltipContent driver={ record.driver } /> }
              arrow
            >
              <span className={ classes.outline }>
                <TextField
                  record={ record }
                  source={ "driver.kuerzel" }
                  label="Fahrer"
                />
              </span>
            </Tooltip>
          ) : (

            <OpenApplicationCounter record={ record } myClasses={ classes } />
          ) }
        </div>
      </DialogChangeDriver>
    </Fragment>
  );
};

const DriverFieldOnlyView = ( { record, classes } ) => {
  return (
    <Fragment>
      <div className={ "driverKuerzel" }>
        { record.driver ? (
          <Tooltip
            placement="right"
            title={ <DriverTooltipContent driver={ record.driver } /> }
            arrow
          >
            <span className={ classes.outline }>
              <TextField
                record={ record }
                source={ "driver.kuerzel" }
                label="Fahrer"
              />
            </span>
          </Tooltip>
        ) : (
          <span className={ classes.error }>FF</span>
        ) }
      </div>
    </Fragment>
  );
};


export const DriverField = withStyles( styles )( DriverFieldView );
export const DriverViewField = withStyles( styles )( DriverFieldOnlyView );
