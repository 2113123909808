import { FieldGuesser } from "@api-platform/admin";
import React from "react";
import { BooleanField } from "react-admin";
import HotList from "../utils/components/HotList";

const AccessoriesList = (props) => (
  <HotList {...props} sort="sortierung" allowCreate={true}>
    <FieldGuesser source={"name"}/>
    <FieldGuesser source={"sortierung"}/>
    <BooleanField source={"boolVoreingestellt"} label={"Voreingestellt"}/>
    <FieldGuesser source={"menge"}/>
  </HotList>
);

export default AccessoriesList;
