import { MailLogList } from "./MailLogList";

const Mail = {
  name: "mail_logs",
  options: {
    label: "Mail Log"
  },
  list: MailLogList,
}

export default Mail;
