import * as React from "react";
import { useState } from "react";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import { BooleanInput } from "../../utils/components";
import { FormWithRedirect, SaveButton, useDataProvider, useNotify } from "react-admin";
import GlobalLoading from "../../utils/components/GlobalLoading";
import { GetRequest } from "../../utils/request/GetRequest";
import UserSettings from "../../utils/components/UserSettings";
import { apiEndpoint, PostRequest } from "../../utils/request/PostRequest";

export const EntityEdit = ( props ) => {
  const [loading, setLoading] = useState( false )
  const dataProvider = useDataProvider();
  const notify = useNotify();

  if( localStorage.getItem( 'userId' ) === null ) {
    GetRequest( "/users/me", false ).then( ( response ) => {
      localStorage.setItem( 'userId', response.hsUserId )
    } )
  }


  const save = async ( values ) => {
    setLoading( true )
    const data = {
      enablePushNotifications: values.enablePushNotifications,
      openLinksInNewTab: values.openLinksInNewTab
    }

    await PostRequest(
      `${apiEndpoint}/api/users/${localStorage.getItem( 'userId' )}/updateUserSetting`,
      {
        key: 'enablePushNotifications',
        value: values.enablePushNotifications
      }
    ).then( () => {
      localStorage.setItem( "userSettings", JSON.stringify( data ) )
    })

    await PostRequest(
      `${apiEndpoint}/api/users/${localStorage.getItem( 'userId' )}/updateUserSetting`,
      {
        key: 'openLinksInNewTab',
        value: values.openLinksInNewTab
      }
    ).then( () => {
      localStorage.setItem( "userSettings", JSON.stringify( data ) )
    })

    setLoading( false )
  }

  const userSettings = UserSettings()

  return <FormWithRedirect
    resource={"users"}
    save={save}
    render={( { handleSubmitWithRedirect, pristine, saving } ) => {
      return <>
        {loading &&<GlobalLoading/>}
        <Card elevation={1}>
        <CardHeader title={"Benutzereinstellungen"} />

        <CardContent>
          <BooleanInput source="enablePushNotifications"
                        label="Benachrichtigungen aktivieren"
                        initialValue={userSettings.enablePushNotifications}
                        helperText={"Erlaubt dem Admin, Push-Benachrichtigungen über den Browser zu verschicken"}/>
          <BooleanInput source="openLinksInNewTab"
                        label="Links in neuem Tab öffnen"
                        initialValue={userSettings.openLinksInNewTab}
                        helperText={"Öffnet Verlinkungen innerhalb des Admins in einem neuen Tab"}/>
        </CardContent>

        <CardContent>
          <SaveButton
            handleSubmitWithRedirect={ handleSubmitWithRedirect }
            pristine={ pristine }
            saving={ saving } />
        </CardContent>
      </Card>
      </>
    }
  }/>
}
