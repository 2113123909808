import { useNotify, useRefresh } from "react-admin";
import React, { Fragment, useState } from "react";
import { apiEndpoint, PostRequest } from "../../utils/request/PostRequest";
import MailIcon from "@material-ui/icons/Mail";
import GlobalLoading from "../../utils/components/GlobalLoading";
import { Button } from "@material-ui/core";

export const RetryButton = ( props ) => {
  const { record, ...rest } = props;
  if( !record ) {
    return null;
  }

  const [loading, setLoading] = useState( false );
  const notify = useNotify();
  const refresh = useRefresh();

  const handleConfirm = async() => {
    setLoading( true )
    await PostRequest(
      `${apiEndpoint}${record.id}/retry`,
      {}
      ).then( ( data ) => {
        setLoading( false );
        refresh();
      } )
      .catch( ( error ) => {
        setLoading( false );
        notify(
          error.message || 'Fehler beim Verschicken des Protokolls',
          { type: 'warning', multiLine: true }
        )
      } )
  };

  return (
    <Fragment>
      {loading &&<GlobalLoading/>}
      <Button
        onClick={() => handleConfirm( )}
        variant={"text"}
        color={"primary"}
        size={"small"}
        style={{padding: 0}}
        {...rest}
      >Erneut versenden</Button>
    </Fragment>
  )
}
