import { CreateButton, ShowButton, ListButton, TopToolbar } from "react-admin";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import propTypes from "prop-types";

const DefaultEditActions = ( props ) => {
  const { allowNew, allowShow, basePath, data } = props;

  return (
    <TopToolbar>
      <ListButton label={"Zurück zur Liste"} icon={<ChevronLeft/>}/>
      {allowNew && <CreateButton label={"Neu"}/>}
      {allowShow && <ShowButton label={"Anzeigen"} {...{ basePath, record: data }} />}
      {props.children}
    </TopToolbar>
  );
};

DefaultEditActions.defaultProps = {
  allowNew: false,
  allowShow: false,
};

DefaultEditActions.propTypes = {
  // PropTypes.element.isRequired
  record: propTypes.object,
  allowNew: propTypes.bool,
  allowShow: propTypes.bool,
  basePath: propTypes.string,
};

export default DefaultEditActions;
