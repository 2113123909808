import PDFButton from "../../../utils/components/Buttons/PDFButton";

const BILLING_URL = process.env.REACT_APP_API_BILLING_PDF_URL
const BillingPDFButton = ( props ) => {
  const { record, ...rest } = props;
  if( !record ) {
    return null;
  }

  const link = BILLING_URL+'formnr='+record._id+'&Formulartyp='+record.formulartyp

  return (
    <PDFButton
      label={rest.title}
      url={link}
    />
  );
};

export default BillingPDFButton;
