import { useState } from "react";
import { TextInput, IconButtonWithTooltip, BooleanInput } from "react-admin";
import { Toolbar, Button, TablePagination, Checkbox } from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import CloseIcon from "@material-ui/icons/RemoveCircleOutline";
import MoveToIcon from '@material-ui/icons/ImportExport';
import AddIcon from "@material-ui/icons/AddCircleOutline";
import MoveToPositionFormDialog from "./../../utils/components/InputComponents/ArrayInput/MoveToPositionFormDialog";

const ArrayItemTextInput = ( props ) => {
  const { width, ...rest } = props;
  return <TextInput style={{ margin: "8px 3px 4px 3px", float: 'left', minWidth: width }} helperText={false} {...rest}/>
}

const BillingPositionsFormIterator = (props) => {
  const { page, setPage, perPage, setPerPage } = props;
  const [isMoveToPosDialogOpen, setIsMoveToPosDialogOpen] = useState(false);
  const [moveToPosFrom, setMoveToPosFrom] = useState(0);

  const total = props.fields.value.length;

  const addButtonClickHandler = () => {
    props.fields.push({
                        id: '',
                        artikelnummer: '0',
                        beschreibung: '',
                        menge: '1',
                        misc1: '',
                        wert: '',
                        misc2: '',
                        wert2: '',
                        beschreibung2: '',
                        beschreibung3: '',
                        mwst: '19',
                        misc3: '0',
                        misc4: ''
                      });

    const maxPage = Math.ceil((total + 1) / perPage) - 1;
    if (page < maxPage) {
      setPage(maxPage);
    }
  }

  const moveToPosButtonClickHandler = (index) => {
    setIsMoveToPosDialogOpen(true);
    setMoveToPosFrom(index);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  return (
    <ul>
      {props.fields.map((item, index) => {
        return index < (page + 1) * perPage && index >= page * perPage ? (
          <li key={index} style={{ display: 'flex' }}>
            <div>
              <IconButtonWithTooltip
                label="ra.action.move_up"
                size="small"
                onClick={() => props.fields.move(index, index - 1)}
                disabled={index <= 0}
              >
                <ArrowUpwardIcon />
              </IconButtonWithTooltip>

              <div>{index + 1}</div>

              <IconButtonWithTooltip
                label="ra.action.move_down"
                size="small"
                onClick={() => props.fields.move(index, index + 1)}
                disabled={total == null || index >= total - 1}
              >
                <ArrowDownwardIcon />
              </IconButtonWithTooltip>
            </div>
            <ArrayItemTextInput label={"Position"} source={`artikeldatenArray[${index}].beschreibung`} width={"40%"} multiline />
            <ArrayItemTextInput label={"Beschreibung"} source={`artikeldatenArray[${index}].beschreibung2`} width={"30%"} multiline />
            <ArrayItemTextInput label={"Wert"} source={`artikeldatenArray[${index}].wert`} width={"5%"} />
            <ArrayItemTextInput label={"Mwst"} source={`artikeldatenArray[${index}].mwst`} width={"5%"} initialValue={19} />
            <BooleanInput
              label="Mwst berechnen"
              source={`artikeldatenArray[${index}].misc3`}
              style={{ margin: "8px 3px 4px 3px", width: "5%" }}
              parse={v => v ? '1' : '0'} format={v => v === '1'}              />

            <Button
              color={'primary'}
              title="Position verschieben"
              onClick={() => moveToPosButtonClickHandler(index)}>
              <MoveToIcon />
            </Button>
            <Button
              color={'primary'}
              title="Position entfernen"
              onClick={() => props.fields.remove(index)}>
              <CloseIcon />
            </Button>
          </li>
        ) : null
      })}
      <Toolbar style={{ justifyContent: 'space-between'}}>
        <Button
          color={'primary'}
          label="ra.action.add"
          onClick={addButtonClickHandler}
        >
          <AddIcon /> Neu
        </Button>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={total}
          page={page}
          rowsPerPage={perPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={'Zeilen pro Seite'}
        />
      </Toolbar>
      <MoveToPositionFormDialog
        isOpen={isMoveToPosDialogOpen}
        setIsOpen={setIsMoveToPosDialogOpen}
        moveFunc={props.fields.move}
        from={moveToPosFrom}
        maxValue={total}
        {...props}
      />
    </ul>
  )
}

export default BillingPositionsFormIterator;
