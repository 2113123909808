import React from "react";
import HotList from "../utils/components/HotList";
import { TextField } from "react-admin";

const AppEventList = ( props) => {
  return <HotList {...props} allowCreate={true} hasEdit={true} title={"App Events"}>
    <TextField source={"name"} label={"Name"} />
    <TextField source={"event"} label={"Event"} />
  </HotList>
}

export default AppEventList;
