import { Tooltip } from '@material-ui/core';
import { FunctionField, } from 'react-admin';

import CalendarTodayIcon from '@material-ui/icons/CalendarToday';


export const ContractDatesInfo = ( record ) => {

  const getDayString = ( date ) =>
    new Date( date ).toLocaleDateString( "de-DE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    } );

  const getCalendarString = ( record ) => {
    let calendarString = '';
    calendarString += getDayString( record.datum );
    calendarString += ' - ';
    calendarString += getDayString( record.bringdatum );

    if ( ( record.abcfahrt || record.abafahrt ) && record.anschlussAuftrag ) {
      calendarString += ' - ';
      calendarString += getDayString( record.anschlussAuftrag.bringdatum );
    }

    return calendarString;
  };
  const isSameMonth = ( date1, date2 ) => {
    const date1Month = new Date( date1 ).getMonth();
    const date2Month = new Date( date2 ).getMonth();
    return date1Month === date2Month;
  };

  const isSameDay = ( date1, date2 ) => {
    const date1Day = new Date( date1 ).getDate();
    const date2Day = new Date( date2 ).getDate();
    return date1Day === date2Day;
  };

  const getCalendarColor = ( record ) => {
    const date1 = record.datum;
    const date2 = record.bringdatum;
    if ( !isSameMonth( date1, date2 ) ) {
      return 'red';
    }
    if ( !isSameDay( date1, date2 ) ) {
      return 'orange';
    }
    if ( ( record.abcfahrt || record.abafahrt ) && record.anschlussAuftrag ) {
      return getCalendarColor( record.anschlussAuftrag );
    }
    return 'green';
  };

  return <>
    <FunctionField
      label="Auftragstyp"
      render={ ( record ) => (
        <Tooltip title={ getCalendarString( record ) }>
          <CalendarTodayIcon htmlColor={ getCalendarColor( record ) } />
        </Tooltip>
      ) } />

  </>
}
