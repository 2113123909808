import { ParagraphHeadline, Spacer } from "../../../utils/components/FormElements";
import { Box, Card } from "@material-ui/core";
import { CustomerReferenceInput } from "../../../customers/components/CustomerReferenceInput";
import { BillingAddressReferenceInput } from "../../../billingAddress/components/BillingAddressReferenceInput";
import TextInput from "../../../utils/components/TextInput";
import { BooleanInput, maxLength, SelectInput, usePermissions } from "react-admin";
import { AddressReferenceInput } from "../../../address/components/AddressReferenceInput";
import * as React from "react";
import { ResetNeuMerkerButton } from "../../../contracts/components/ListButtons/ResetNeuMerkerButton";

export default ( props ) => {
  const {permissions} = usePermissions()

  return <Card className={props.classes.card}>
    <ParagraphHeadline>Stammdaten</ParagraphHeadline>
    <Box display="flex">
      <Box flex={1} mr="0.5em">
        <CustomerReferenceInput source={"contractClientAddress"} />
      </Box>

      <Box flex={1}>
        <BillingAddressReferenceInput source={"billingClientAddress"}/>
      </Box>
    </Box>

    <Box display="flex">
      <Box flex={1} mr="0.5em">
        <TextInput
          source={"leasingVertragsnummer"}
          label={"Leasingvertrags-Nr."}
          fullWidth
        />
      </Box>
      <Box flex={1}>
        <SelectInput
          source={"leasingProtokoll"}
          label={"Leasingprotokoll"}
          choices={[
            { id: "ALD", name: "ALD Automotive" },
            { id: "ALPHABET", name: "Alphabet Leasing" },
            { id: "ATHLON", name: "Athlon Leasing" },
            { id: "SIXT", name: "Sixt Leasing" },
            { id: "MC", name: "Mobility Concept" },
            { id: "ARVAL", name: "Arval" },
            { id: "BHG", name: "bhg Übergabeprotokoll" },
            { id: "BFMUEBEL", name: "Bfm + UEBEL" },
          ]}
          helperText={false}
          fullWidth
        />
      </Box>
    </Box>

    <Box display="flex">
      <Box flex={2} mr={"0.5em"}>
        <AddressReferenceInput source={"leistungsadresse"} label={"Leistungsempf\xe4nger"}/>
      </Box>
      <Box flex={1}>
        <TextInput
          source={"kostenstelle"}
          label={"Kostenstelle"}
          fullWidth
        />
      </Box>
    </Box>

    <Box display={"flex"}>
      {permissions && (permissions.includes('vermaco_admin') || permissions.includes('vermaco_buchhaltung') || permissions.includes('vermaco_dispo') ) && <>
        <Box flex={1} mr={"0.5rem"}>
          <TextInput
            multiline
            fullWidth
            source={"rechnungsnotiz"}
            label={"Rechnungsnotiz"}
          />
        </Box>
        <Box flex={1} mr={"0.5rem"}>
          <TextInput
            multiline
            fullWidth
            source={"gutschriftnotiz"}
            label={"Gutschriftnotiz"}
          />
        </Box>
      </>}
      <Box flex={1}>
        <TextInput
          multiline
          fullWidth
          source={"infoBackend"}
          label={"Interne Notizen"}
        />
      </Box>
    </Box>
    <Spacer />

    <TextInput
      source={"infoDriver"}
      label={"Info Fahrer"}
      fullWidth
    />

    <Box display="flex">
      <Box flex={1} mr="0.5em">
        <BooleanInput
          source="sperrenFuerAngebotsliste"
          label={"Für Angebotsliste sperren"}
          helperText={false}
        />
      </Box>
      <Box flex={1} ml="0.5em">
        <ResetNeuMerkerButton record={props.record} isCreate={true} helperText={false} />
      </Box>
    </Box>


  </Card>
}
