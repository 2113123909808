import { AutocompleteInput } from "react-admin";


export const ComponentDropdown = props => {
        return <AutocompleteInput
          choices={[
                  { id: 'Frontscheibe', name: 'Frontscheibe' },
                  { id: 'Motorhaube', name: 'Motorhaube' },
                  { id: 'Frontschürze', name: 'Frontschürze' },
                  { id: 'Nummernschild V', name: 'Nummernschild V' },
                  { id: 'Kühlergrill', name: 'Kühlergrill' },
                  { id: 'Scheinwerfer VR', name: 'Scheinwerfer VR' },
                  { id: 'Nebelscheinwerfer VR', name: 'Nebelscheinwerfer VR' },
                  { id: 'Scheinwerfer VL', name: 'Scheinwerfer VL' },
                  { id: 'Nebelscheinwerfer VL', name: 'Nebelscheinwerfer VL' },
                  { id: 'Felge VL', name: 'Felge VL' },
                  { id: 'Reifen VL', name: 'Reifen VL' },
                  { id: 'Kotflügel VL', name: 'Kotflügel VL' },
                  { id: 'Außenspiegel L', name: 'Außenspiegel L' },
                  { id: 'Dachholm VL A-Säule', name: 'Dachholm VL A-Säule' },
                  { id: 'Seitenscheibe VL', name: 'Seitenscheibe VL' },
                  { id: 'Tür VL', name: 'Tür VL' },
                  { id: 'B-Säule L', name: 'B-Säule L' },
                  { id: 'Seitenscheibe HL', name: 'Seitenscheibe HL' },
                  { id: 'Tür HL', name: 'Tür HL' },
                  { id: '2. Seitenscheibe HL', name: '2. Seitenscheibe HL' },
                  { id: 'Dachholm HL C-Säule L', name: 'Dachholm HL C-Säule L' },
                  { id: 'Seitenteil HL', name: 'Seitenteil HL' },
                  { id: 'Tankdeckel L', name: 'Tankdeckel L' },
                  { id: 'Felge HL', name: 'Felge HL' },
                  { id: 'Reifen HL', name: 'Reifen HL' },
                  { id: 'Seitenschweller L', name: 'Seitenschweller L' },
                  { id: 'Heckscheibe', name: 'Heckscheibe' },
                  { id: 'Heckklappe', name: 'Heckklappe' },
                  { id: 'Heckdeckel oben', name: 'Heckdeckel oben' },
                  { id: 'Heckdeckel unten', name: 'Heckdeckel unten' },
                  { id: 'Rücklicht L', name: 'Rücklicht L' },
                  { id: 'Rücklicht R', name: 'Rücklicht R' },
                  { id: 'Heckschürze', name: 'Heckschürze' },
                  { id: 'Nummernschild H', name: 'Nummernschild H' },
                  { id: 'Rückstrahler L', name: 'Rückstrahler L' },
                  { id: 'Rückstrahler R', name: 'Rückstrahler R' },
                  { id: 'Auspuff Blende', name: 'Auspuff Blende' },
                  { id: 'Dachholm HR C-Säule R', name: 'Dachholm HR C-Säule R' },
                  { id: '2. Seitenscheibe HR', name: '2. Seitenscheibe HR' },
                  { id: 'Tankdeckel R', name: 'Tankdeckel R' },
                  { id: 'Seitenteil HR', name: 'Seitenteil HR' },
                  { id: 'Felge HR', name: 'Felge HR' },
                  { id: 'Reifen HR', name: 'Reifen HR' },
                  { id: 'Seitenscheibe HR', name: 'Seitenscheibe HR' },
                  { id: 'Tür HR', name: 'Tür HR' },
                  { id: 'B-Säule R', name: 'B-Säule R' },
                  { id: 'Seitenscheibe VR', name: 'Seitenscheibe VR' },
                  { id: 'Tür VR', name: 'Tür VR' },
                  { id: 'Außenspiegel R', name: 'Außenspiegel R' },
                  { id: 'Dachholm R ( A-Säule)', name: 'Dachholm R ( A-Säule)' },
                  { id: 'Kotflügel VR', name: 'Kotflügel VR' },
                  { id: 'Felge VR', name: 'Felge VR' },
                  { id: 'Reifen VR', name: 'Reifen VR' },
                  { id: 'Seitenschweller R', name: 'Seitenschweller R' },
                  { id: 'Dach', name: 'Dach' },
                  { id: 'Schiebe-/Panoramadach', name: 'Schiebe-/Panoramadach' },
                  { id: 'Dachspoiler', name: 'Dachspoiler' },
                  { id: 'Dachhimmel', name: 'Dachhimmel' },
                  { id: 'Tür VL IV', name: 'Tür VL IV' },
                  { id: 'Einstieg VL', name: 'Einstieg VL' },
                  { id: 'Fußraum VL', name: 'Fußraum VL' },
                  { id: 'Armaturenbrett', name: 'Armaturenbrett' },
                  { id: 'Instrumententafel', name: 'Instrumententafel' },
                  { id: 'Lenkrad', name: 'Lenkrad' },
                  { id: 'Sitz VL', name: 'Sitz VL' },
                  { id: 'B-Säule R innen', name: 'B-Säule R innen' },
                  { id: 'Tür HL IV', name: 'Tür HL IV' },
                  { id: 'Einstieg HL', name: 'Einstieg HL' },
                  { id: 'Rücksitzbank', name: 'Rücksitzbank' },
                  { id: 'Fußraum HL', name: 'Fußraum HL' },
                  { id: 'Kofferraum Boden', name: 'Kofferraum Boden' },
                  { id: 'Ladekante', name: 'Ladekante' },
                  { id: 'Heckklappe -IV', name: 'Heckklappe -IV' },
                  { id: 'Kofferraum Seite L', name: 'Kofferraum Seite L' },
                  { id: 'Kofferraum Seite R', name: 'Kofferraum Seite R' },
                  { id: 'Tür HR IV', name: 'Tür HR IV' },
                  { id: 'Fußraum HR', name: 'Fußraum HR' },
                  { id: 'B-Säule L innen', name: 'B-Säule L innen' },
                  { id: 'Tür VR IV', name: 'Tür VR IV' },
                  { id: 'Sitz VR', name: 'Sitz VR' },
                  { id: 'Fußraum VR', name: 'Fußraum VR' },
                  { id: 'Motordämmmatte', name: 'Motordämmmatte' },
                  { id: 'Einstieg HR', name: 'Einstieg HR' },
                  { id: 'Mittelkonsole', name: 'Mittelkonsole' },
                  { id: 'Display', name: 'Display' },
                  { id: 'Hecktür Links', name: 'Hecktür Links' },
                  { id: 'Hecktür Rechts', name: 'Hecktür Rechts' },
                  { id: 'Schiebetür Rechts', name: 'Schiebetür Rechts' },
                  { id: 'Schiebetür Links', name: 'Schiebetür Links' },
                  { id: 'Einstieg VR', name: 'Einstieg VR' },
                  { id: 'Einstieg Vorne Rechts', name: 'Einstieg Vorne Rechts' },
                  { id: 'Einstieg Vorne Rechts', name: 'Einstieg Vorne Rechts' },
                  { id: 'Einstieg HR', name: 'Einstieg HR' },
                  { id: 'Innenraum Vorne', name: 'Innenraum Vorne' },
                  { id: 'Nummernschild H', name: 'Nummernschild H' },
          ]}
          {...props}>

        </AutocompleteInput>
}
