import React from "react";
import ReferenceArrayInput from "../../utils/components/ReferenceArrayInput";
import SelectArrayInput from "../../utils/components/SelectArrayInput";
import ReferenceInput from "../../utils/components/ReferenceInput";
import { SelectInput } from "react-admin";



export const ServiceReferenceInput = props => {

  const filter = { "boolchar_cleared": false, ...props.filter }
  return <ReferenceInput
    reference="services"
    label={"Zusatzleistungen"}
    sort={{ field: "sortierungsnummer", order: "ASC" }}
    filterToQuery={searchText => searchText ? { _name: searchText } : null}
    filter={filter}
    fullWidth
    {...props}>
    {props.children ?? <SelectInput />}
  </ReferenceInput>
}

export const ServiceReferenceArrayInput = props => (
  <ReferenceArrayInput
    reference="services"
    source="zusatzleistungenArray"
    label={"Zusatzleistungen"}
    sort={{ field: "sortierungsnummer", order: "ASC" }}
    filterToQuery={searchText => searchText ? { _name: searchText } : null}
    filter={{ "boolchar_cleared": false }}
    fullWidth
    {...props}>
    {props.children ?? ( <SelectArrayInput
      optionValue="_id"
      optionText={( service ) => {
        return service ? service.name : null
      }}
      style={{ minWidth: "200px" }}
      helperText={false}
    /> )}
  </ReferenceArrayInput>
)
