import DefaultPagination from "../DefaultPagination";
import DefaultListActions from "../DefaultListActions";
import { List } from "react-admin";
import { withStyles } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

const styles = {
  rowCell: {
    verticalAlign: "top",
  },
};

class HotGroupedList extends React.Component {
  render() {
    const { classes, itemsPerPage, sort, sortOrder, allowCreate, listActions, syncWithLocation, ...rest } =
      this.props;

    return (
      <List
        pagination={<DefaultPagination />}
        actions={
          listActions ?? <DefaultListActions
            hsfilterDefaultValues={this.props.filterDefaultValues}
            hsShowFilter={this.props.filters?.length > 0}
            allowCreate={allowCreate}
          />
        }
        syncWithLocation={syncWithLocation}
        sort={{ field: sort, order: sortOrder }}
        {...rest}
      >
        <>{this.props.children}</>
      </List>
    );
  }
}

HotGroupedList.propTypes = {
  classes: PropTypes.object.isRequired,
  context: PropTypes.object,

  perPage: PropTypes.number,
  sort: PropTypes.string,
  sortOrder: PropTypes.string,
  hasShow: PropTypes.bool,
  hasEdit: PropTypes.bool,
  allowCreate: PropTypes.bool,
  filters: PropTypes.any,
  filterDefaultValues: PropTypes.any,
  children: PropTypes.any,
};

HotGroupedList.defaultProps = {
  perPage: parseInt( process.env.REACT_APP_LIST_ITEMS ),
  sort: "id",
  sortOrder: "ASC",
};

export default withStyles(styles)(HotGroupedList);
