import { Button } from "react-admin";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import PropTypes from 'prop-types';

const PDFButton = ( props ) => {
  let { url, title, label, onClick, icon, ...rest } = props;

  // if( !url ) {
  //   return null;
  // }

  return (
    <Button
      title={title ?? label}
      label={label}
      onClick={() => onClick( url )}
      children={icon}
      {...rest}
    />
  );
};


const exportPdf = ( url ) => {
  window.open( url, "_blank" );
};

PDFButton.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  styles: PropTypes.object
}

PDFButton.defaultProps = {
  onClick: exportPdf,
  icon: <PictureAsPdfIcon/>
}

export default PDFButton;

