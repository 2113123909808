import ComplaintList from "./ComplaintList";
import { EntityCreate, EntityEdit } from "./ComplaintEdit";

const Complaints = {
  list: ComplaintList,
  edit: EntityEdit,
  create: EntityCreate,
  name: "protocol_complaint_types"
};

export default Complaints;
