export default ( props ) => {
  return (
    <iframe
      width="100%"
      frameBorder="0"
      src={props.src}
      style={{
        height: '84vh',
        maxHeight: '100%'
      }}
      {...props}
    ></iframe>
  );
};
