import {
  useNotify,
  useRefresh,
  useDataProvider
} from 'react-admin';
import { Button } from "@material-ui/core";

const CloseButton = ( { record } ) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  if( !record ) {
    return null;
  }

  const isClosed = record.processState === "CLOSED";

  const data = {
    ...record,
    processState: isClosed ? 'RECEIVED' : 'CLOSED',
    allUploadsDone: !isClosed ? true : record.allUploadsDone
  }

  const toggleState = () => dataProvider
    .update( 'protocols', { id: record.id, data: { ...data } } )
    .then( response => {
      refresh();
      notify( `${isClosed ? 'Auftrag wieder geöffnet' : 'Auftrag geschlossen'}`, 'success' );
    } )
    .catch( error => {
      notify( `Fehler: ${error.message}`, { type: 'warning' } );
    } );

  return <Button size="small" variant={"text"} color={"primary"} onClick={toggleState}>
    {isClosed ? 'Auftrag wieder öffnen' : 'Auftrag schließen'}
  </Button>
}

export default CloseButton;
