import { Button, Confirm, useDataProvider, useNotify, useRefresh } from "react-admin";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import React, { useState } from "react";

export const RemoveDriverButton = ( { record, ...rest } ) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
    const [open, setOpen] = useState(false);

  if( !record ) {
    return null;
  }

  const remove = () =>
    dataProvider
      .update("contracts", {
        id: record.id,
        data: {
          ...record,
          driver: null,
          driverChangedWithStornoMail: true,
        },
      })
      .then((response) => {
        // success side effects go here
        refresh();
        notify("Fahrer entfernt", "success");
        setOpen(false)
      })
      .catch((error) => {
        // failure side effects go here
        notify(`Fehler beim entfernen des Fahrers: ${error.message}`, {
          type: "warning",
        });
      });

  return (
      <>
          <Button
              title="Fahrer entfernen"
              onClick={() => setOpen(true)}
              children={<RemoveCircleIcon/>}
              {...rest}
          />
          <Confirm
              isOpen={open}
              title={`Fahrer entfernen`}
              content="Möchten Sie wirklich den Fahrer entfernen und nicht durch einen neuen Fahrer ersetzen? Die Fahrt wird damit zu einer Fahrer Fehlt Fahrt."
              onConfirm={() => remove()}
              onClose={() => setOpen(false)}
          />
      </>
  );
};
