import React, { Fragment, useState } from "react";
import { Layout, useNotify } from "react-admin";
import AppBar from "./AppBar";
import Menu from "./Menu";
import Alert from "@material-ui/lab/Alert";
import { IconButton } from "@material-ui/core";
import { CloseIcon } from "react-modal-image/lib/icons";
import { AlertTitle } from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";
import { onMessage } from "firebase/messaging";
import { messaging } from "../firebaseMessaging";

const CustomLayout = ( props ) => {
  const [info, setInfo] = useState( null )

  if( localStorage.getItem( 'outdated' ) === "1" && info === null ) {
    setInfo( true )
  }

  const notify = useNotify()
  onMessage( messaging, ( payload ) => {
    notify(
      `${payload.notification.title}\n${payload.notification.body}`, { type: "info", autoHideDuration: 10000, multiLine: true }
    );
  } );


  return <Layout {...props} appBar={AppBar} menu={Menu}>
    <Fragment>
      <Snackbar open={info} style={{ zIndex: 1000000 }}>
        <Alert severity="info" elevation={6} variant="filled" action={
          <IconButton
            style={{ alignSelf: "flex-start" }}
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setInfo( false );
            }}
          >
            <CloseIcon fontSize="inherit"/>
          </IconButton>
        }
               sx={{ mb: 3 }}>
          <AlertTitle>Neue Version verfügbar!</AlertTitle>
          Bitte laden Sie die Seite neu um die Oberfläche zu aktualisieren
        </Alert>
      </Snackbar>
    </Fragment>
    {props.children}

  </Layout>
}

export default CustomLayout;
