import ReferenceArrayInput from "../../utils/components/ReferenceArrayInput";
import { AutocompleteArrayInput } from "react-admin";

export const UserGroupReferenceArrayInput = ( props ) => {

  return <ReferenceArrayInput
    label={"Gruppen"}
    reference={"user_groups"}
    fullWidth
    resettable
    format={( value ) => value?.map(
      item =>  item !== null &&
               typeof item === "object" &&
               item.hasOwnProperty( "@id" )
               ? item[ "@id" ]
               : item
    ) ?? []}
    filterToQuery={( searchText ) => ( { _name: searchText } )}
    perPage={200}
    helperText={false}
    {...props}
  >
    <AutocompleteArrayInput
      optionText={"name"}
      source={"name"}
    />
  </ReferenceArrayInput>
}
