import { Create, Edit, FormWithRedirect } from "react-admin";
import { BooleanInput, TextInput } from "../utils/components";
import * as React from "react";
import DefaultEditActions from "../utils/DefaultEditActions";
import EntityToolbar from "../utils/components/Toolbar";
import { Box, withStyles } from "@material-ui/core";
import { FormTabContainerBox, InputContainer, ParagraphBox, ParagraphHeadline, Spacer } from "../utils/components/FormElements";
import RichTextInput from "ra-input-rich-text";


const EntityForm = (props) => (
  <FormWithRedirect {...props}
    render={(formProps) => {
      const toolbarOptions = [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        [{ 'align': [] }],
        ['blockquote', 'code-block'],
        ['link', 'image', 'video'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent


        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        // [{ 'font': [] }],

        ['clean']                                         // remove formatting button
      ];

      return <form>
        <FormTabContainerBox>
          <ParagraphHeadline>Allgemein</ParagraphHeadline>
          <ParagraphBox alignItems={"center"}>
            <InputContainer left flex={1}>
              <TextInput source={"title"} label={"Titel"} style={{minWidth: "350px"}}/>
            </InputContainer>
            <InputContainer right flex={2}>
              <ParagraphBox>
                <BooleanInput source={"showInFahrerportal"} label={"Fahrerportal"} />
                <BooleanInput source={"showInKundenportal"} label={"Kundenportal"} />
                <BooleanInput source={"showInApp"} label={"App"} />
                <BooleanInput source={"showForEmployees"} label={"Für Mitarbeiter"} />
              </ParagraphBox>
            </InputContainer>
          </ParagraphBox>

          <Spacer />

          <ParagraphHeadline>Inhalt</ParagraphHeadline>
          <ParagraphBox>
            <RichTextInput source={"content"} label={""} toolbar={toolbarOptions} />
          </ParagraphBox>

          <Spacer space="5rem"/>

        </FormTabContainerBox>

        <EntityToolbar isCreate={props.isCreate} createButtonLabel="Beitrag anlegen" {...formProps} />
      </form>
  }} />
);

const EntityTitle = ({ record }) => {
  return <span>News-Beitrag {record.id}</span>;
};

export const EntityEdit = withStyles({ card: { overflow: 'initial' }})(props => (
  <Edit
    actions={<DefaultEditActions {...props} />}
    title={<EntityTitle />}
    {...props}
  >
    <EntityForm isCreate={false} {...props} />
  </Edit>
));

export const EntityCreate = withStyles({ card: { overflow: 'initial' }})(props => (
  <Create
    actions={<DefaultEditActions {...props} />}
    title={"Neuen Beitrag anlegen"}
    {...props}
  >
    <EntityForm isCreate={true} {...props} />
  </Create>
));
