import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import { ArrayInput, Button, FormDataConsumer, FormWithRedirect, SaveContextProvider, useDataProvider, useNotify } from "react-admin";
import React, { useState } from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import GlobalLoading from "../../../utils/components/GlobalLoading";
import { GetRequest } from "../../../utils/request/GetRequest";
import IconCancel from "@material-ui/icons/Cancel";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import { ParagraphBox, ParagraphHeadline, Spacer } from "../../../utils/components/FormElements";
import { BooleanInput } from "../../../utils/components";
import SaveIcon from "@material-ui/icons/Save";
import BundleModalFormIterator from "../../../bundles/components/BundleModalFormIterator";


const CreateBundleWithContractButton = ( { record, withRecord, setDialogOpen } ) => {
  const dataProvider = useDataProvider()
  const [ bundle, setBundle ] = useState( null )
  const [ showEditDialog, setShowEditDialog ] = useState(  )
  const notify = useNotify()
  const postData = {
    'contracts': [
      { '@id': record.id },
      { '@id': withRecord['@id'] },
    ],
  }

  const onClick = () => {
    dataProvider.create( 'bundles', {
      data: {...postData}
    } )
      .then( ( response ) => {
        setBundle( response.data )
        setShowEditDialog( true )
        notify( 'Fahrtpaket erstellt', { type: 'success' } )
      } ).catch( ( error ) => {
        notify( 'Error: Fahrtpaket nicht erstellt: '+error.message, { type: 'warning' } )
      })
  }

  return <>
    <Button label="Paket erstellen" onClick={onClick} icon={<IconCancel/>}/>
    <DialogEditBundle showEditDialog={showEditDialog} setShowEditDialog={setShowEditDialog} bundle={bundle} setBundle={setBundle} setDialogOpen={setDialogOpen} />
  </>
}

const DialogEditBundle = ({bundle, showEditDialog, setShowEditDialog, setDialogOpen, setBundle, setSelectedContracts, removeSelectedContracts = false}) => {
  const notify = useNotify();
  const dataProvider = useDataProvider()
  const [showLoadingIndicator, setShowLoadingIndicator] = useState( false )
  const save = (formData) => {
    setShowLoadingIndicator( true )
    dataProvider.update('bundles', {
      id: bundle[ '@id' ],
      data: {
        active: formData.active,
        openForApplications: formData.openForApplications,
      }
    }).then( (response) => {
      notify('Fahrtpaket gespeichert', {type: 'success'})
      setShowEditDialog(false)
      setDialogOpen( false )
      setShowLoadingIndicator( false )

      if(removeSelectedContracts) {
        setSelectedContracts( new Set() )
      }
    })
    .catch( (error) => {
      notify( 'Error: Fahrtpaket nicht gespeichert: '+error.message, { type: 'warning' } )
      setShowLoadingIndicator( false )
    })
  }

  const onDialogClose = () => {
    if(removeSelectedContracts) {
      setSelectedContracts( new Set() )
    }
    setShowEditDialog( false )
  }

  return <Dialog fullWidth open={showEditDialog} onClose={onDialogClose} maxWidth={"md"}>
    {showLoadingIndicator && <GlobalLoading/>}
    <DialogTitle>Fahrtpaket {bundle?.bundle}</DialogTitle>
    <DialogContent>
      <Box p="1em" style={{ margin: "0 0 40px 0" }}>
        <ParagraphHeadline>Allgemein</ParagraphHeadline>
        <ParagraphBox display={"flex"} alignItems={"center"}>
          <BooleanInput source="active" record={bundle} initialValue={true} label={"Aktiv"}/>
          <BooleanInput source="openForApplications" record={bundle} initialValue={true} label={"In Angebotsliste anzeigen"}/>
        </ParagraphBox>

        <Spacer/>

        <ParagraphHeadline>Aufträge</ParagraphHeadline>
        <ArrayInput source="contractBundles" label={""} initialValue={bundle?.contractBundles} record={bundle}>
          <BundleModalFormIterator bundle={bundle} setBundle={setBundle}/>
        </ArrayInput>
      </Box>
    </DialogContent>
    <DialogActions>
      <FormDataConsumer>
        {( { formData } ) => (
          <Button title={"Speichern"}
                  color="primary"
                  children={<><SaveIcon style={{ marginRight: '0.5rem', fontSize: '18px' }}/> Speichern</>}
                  variant="contained"
                  style={{ padding: "6px 16px", fontSize: "0.875rem" }}
                  onClick={() => save( formData )}/>
        )}
      </FormDataConsumer>
    </DialogActions>
  </Dialog>
}

const DialogCreateBundle = ( { children, record } ) => {
  const [showDialog, setShowDialog] = useState( false );
  const [loading, setLoading] = useState( false );
  const [members, setMembers] = useState( [] );

  const notify = useNotify();

  const handleOpenClick = async() => {
    setShowDialog( true )
    setLoading( true )
    GetRequest( `/contracts/${record._id}/offersInRange`, false )
      .then( ( response ) => {
        if( response[ 'hydra:totalItems' ] > 0 ) {
          const members = response[ 'hydra:member' ]
          setMembers( members.filter( ( member ) => {
            return record.id !== member.id
          } ) )
        }
        setLoading( false )
      } )
      .catch( ( error ) => {
        setLoading( false )
        notify( `Error: ${error.message}`, { type: "warning" } );
      } )
  }

  const Distance = ( { value } ) => {
    if( value === 0 ) {
      return <span>0 km</span>
    }

    if( value < 1000 ) {
      return new Intl.NumberFormat( 'de-DE', {
        style: "unit",
        unit: "meter",
      } ).format( value )
    }

    return ( value / 1000 ).toFixed( 2 )+" km"
  }

  const ContractTable = ( { items }) => (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>Auftrag</TableCell>
          <TableCell>Entfernung</TableCell>
          <TableCell>Abholzeit</TableCell>
          <TableCell>Von</TableCell>
          <TableCell>Nach</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map( ( member, index ) => {
          return <TableRow key={index}>
            <TableCell>
              <CreateBundleWithContractButton record={record} withRecord={member} setDialogOpen={setShowDialog}/>
            </TableCell>
            <TableCell>{member.auftragsnummer}</TableCell>
            <TableCell><Distance value={member.distanceToDestination}/></TableCell>
            <TableCell>{member.pickupTime}</TableCell>
            <TableCell>
              {member.pickupLocation?.strasse}<br />
              {member.pickupLocation?.ort}
            </TableCell>
            <TableCell>
              {member.deliveryLocation?.strasse}<br />
              {member.deliveryLocation?.ort}
            </TableCell>
          </TableRow>
        })}
      </TableBody>
    </Table>
  )

  const bringdatum = new Date(record.bringdatum).toLocaleDateString( "de-DE", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  } );

  return <FormWithRedirect
    resource="contracts"
    render={( { handleSubmitWithRedirect, pristine, saving } ) => {

      return <SaveContextProvider>
          <div onClick={handleOpenClick}>{children}</div>
        <Dialog fullWidth open={showDialog} onClose={() => setShowDialog( false )} maxWidth={"md"}>
            <DialogTitle>Fahrtpaket für Auftrag {record.auftragsnummer} erstellen</DialogTitle>

            <DialogContent>
              {loading && <GlobalLoading primaryText="" secondaryText="Anschlussaufträge werden geladen"/>}

              <DialogContentText>
                Für den {bringdatum} wurden folgende Anschlussaufträge ohne Fahrer gefunden:
              </DialogContentText>

                <ContractTable items={members.sort( function( a, b ) {
                  return a[ 'distanceToDestination' ] >= b[ 'distanceToDestination' ] ? 1 : -1
                } )}/>

            </DialogContent>

            <DialogActions>
              <Button label="ra.action.cancel" onClick={() => setShowDialog( false )}>
                <IconCancel/>
              </Button>
            </DialogActions>
          </Dialog>
        </SaveContextProvider>
    }}/>
}

export const CreateBundleButton = (props) => (
  <Button
    children={<AddToPhotosIcon />}
    title={"Fahrtpaket erstellen"}
    {...props}
    />
)

export const CreateBundleButtonWithDialog = ( props ) => {
    const { record, ...rest } = props;
    return (
      <>
        <DialogCreateBundle record={record}>
            <CreateBundleButton {...rest} />
        </DialogCreateBundle>
      </>
    )
}

export const CreateBulkBundleButton = ( { contracts, setSelectedContracts } ) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [ showEditDialog, setShowEditDialog ] = useState( false )
  const [ bundle, setBundle ] = useState( null )
  const [ dialogOpen, setDialogOpen ] = useState( false )

  const createBundle = () => {
    const postData = {}
    let contractIds = []
    for( const contract of contracts ) {
      contractIds.push( { '@id': `/api/contracts/${contract}` } )
    }

    postData['contracts'] = contractIds
    dataProvider.create( 'bundles', {
      data: {...postData}
    } ).then( ( response ) => {
      setBundle( response.data )
      // setSelectedContracts( new Set() )
      setShowEditDialog( true )
      contractIds.forEach(contractId => {
        dataProvider.getOne('contracts', { id: contractId['@id'] })
      });
      notify( 'Fahrtpaket erstellt', { type: 'success' } )
    } ).catch( ( error ) => {
      notify( 'Fehler beim erstellen des Pakets: '+error.message, { type: 'warning' } )
    })
  }

  return <>
    <Button
      children={<>Fahrtpaket für {contracts.size} Aufträge erstellen</>}
      variant="contained"
      onClick={createBundle}
      style={{ marginTop: "5px" }}
    />
    <FormWithRedirect
      resource="contracts"
      render={( { handleSubmitWithRedirect, pristine, saving } ) => {
        return <DialogEditBundle showEditDialog={showEditDialog} setShowEditDialog={setShowEditDialog} bundle={bundle} setBundle={setBundle} setDialogOpen={setDialogOpen} setSelectedContracts={setSelectedContracts} removeSelectedContracts={true} />
      }}
      />
  </>
}
