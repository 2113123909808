import React from 'react';
import { SimpleFormIterator } from 'react-admin';
import { withStyles } from "@material-ui/core";
import { ReOrderButtons } from "./Buttons/ReOrderButtons";

const styles = {
  root: {
    // backgroundColor: '#FAFAFA',
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, 25%)",
    // border: "1px solid grey"
  },

  line: {
    // backgroundColor: '#FAC31E',
    // border: "1px solid red",
    // margin: "5px",
    // padding: "5px",

    // display: "flex",
    // flexDirection: "row",
    // flexWrap: "wrap",
    // alignItems: "baseline"
    display: "grid",
    gridTemplateColumns: "auto",
    gridTemplateRows: "auto",
    alignItems: "center",
    padding: "1rem"

  },

  indexContainer: {
    // order: 1

    // backgroundColor: '#C91F1F',
    // border: "1px solid grey",
    // margin: "5px",
    // padding: "5px"

    gridColumnStart: 1,
    gridColumnEnd: 2,
    gridRowStart: 1,
    gridRowEnd: 2,
    padding: 0

  },

  index: {},

  form: {
    // order: 2

    // backgroundColor: '#1F63DA',
    // border: "1px solid grey",
    // margin: "5px",
    // padding: "5px"

    gridColumn: "1 / 3",
    gridRowStart: 2,
    gridRowEnd: 3
  },

  leftIcon: {},

  action: {
    // order: 1

    // backgroundColor: '#8A179A',
    // border: "1px solid grey",
    // margin: "5px",
    // padding: "5px"
    textAlign: 'right',
    gridColumnStart: 2,
    gridColumnEnd: 3,
    gridRowStart: 1,
    gridRowEnd: 2,
    padding: 0
  }
};

SimpleFormIterator.defaultProps = {
  reOrderButtons: <ReOrderButtons/>
}

export default withStyles( styles )( SimpleFormIterator );
// export default CustomFormIterator;

// export { CustomIterator };
