import { Button, useNotify, useRefresh } from "react-admin";
import axios from "axios";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";

export const GetButton = ( { record, style = {} } ) => {
  const notify = useNotify();
  const refresh = useRefresh();
  if( !record ) {
    return null;
  }

  const CONTRACT_ENDPOINT = `${process.env.REACT_APP_API_ENTRYPOINT}/contracts/${record._id}/update_user`;
  const handleClick = async() => {
    try {
      const res = await axios.patch(
        CONTRACT_ENDPOINT,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/merge-patch+json",
          },
        }
      );
      refresh();
      notify(`Erfolgreich abgeholt.`, "success");
    } catch (err) {
      notify(`Error ${err.message}`, "warning");
    }
  };

  return (
    <Button
      title="Auftrag in App holen"
      onClick={handleClick}
      children={<AssignmentReturnedIcon/>}
      style={style}
    />
  );
};
