import { Box, Card, Typography } from "@material-ui/core";
import { ParagraphBox, ParagraphHeadline } from "../../../utils/components/FormElements";
import { transformIRI } from "../../../utils/TransformIri";
import { useForm } from "react-final-form";
import React, { useEffect, useState } from "react";
import { getAddressData } from "../../../utils/request";
import { AddressReferenceInput, translateAddressLabel } from "../../../address/components/AddressReferenceInput";
import AutocompleteInput from "../../../utils/components/AutocompleteInput";
import TextInput from "../../../utils/components/TextInput";
import { validateRequired } from "../../../utils/validators/DefaultValidators";
import { BooleanInput, FormDataConsumer } from "react-admin";
import DatePicker from "../../../utils/components/DatePicker";
import TimeRangeInputAlternative from "../../../utils/components/TimeRangeInputAlternative";
import AddressInputBlock from "../../../contracts/components/AddressInputBlock";

export default ( props ) => {
  const { onFocus, filter } = props;
  const { change, batch, getState } = useForm();
  const [isBestandsadresse, setIsBestandsadresse] = useState( false );
  const [bestandsadresse, setBestandsadresse] = useState( null );

  useEffect( () => {
    if( isBestandsadresse ) {
      change( "deliveryLocationIsSet", true );
    } else {
      change( "deliveryLocationIsSet", false );
    }
  } );

  const handleAddressChange = async( customerId ) => {
    const newAddress = await getAddressData( customerId );

    // Change fields value to new address
    batch( () => {
      change( "deliveryLocation.firma", newAddress.firma );
      change( "deliveryLocation.strasse", newAddress.strasse );
      change( "deliveryLocation.ort", newAddress.ort );
      change( "deliveryLocation.ansprechparter1", newAddress.ansprechparter1 );
      change( "deliveryLocation.anspr1email", newAddress.anspr1email );
      change( "deliveryLocation.anspr1telefon", newAddress.anspr1telefon );
      change( "deliveryTimeFrom", newAddress.startzeit );
      change( "deliveryTimeTo", newAddress.endzeit );
    } );

    setBestandsadresse( newAddress );
    setIsBestandsadresse( true );
    change( "deliveryLocation.saveLocation", false )
  };

  const handleTextInputChange = ( prefix ) => {
    const fields = ['firma', 'strasse', 'ort', 'ansprechparter1', 'anspr1telefon', 'anspr1email']
    const state = getState()

    if( bestandsadresse ) {
      setIsBestandsadresse( true )
      for( let field of fields ) {
        // const value = formData[prefix][field];
        const value = state.values[ prefix ][ field ] ?? ''
        if( bestandsadresse && bestandsadresse[ field ] !== value ) {
          setIsBestandsadresse( false )
          break
        }
      }
    } else {
      setIsBestandsadresse( false )
    }
  };


  return (
    <Card className={props.classes.card}>
      <ParagraphHeadline>Zustelladresse</ParagraphHeadline>
      <AddressInputBlock type="delivery" source={"deliveryLocation"} record={{ deliveryLocation: {} }} displayHeader={false}/>

      <ParagraphBox>
        <FormDataConsumer>
          {( { formData } ) => (
            <BooleanInput
              source={`deliveryLocation.save`}
              label={"Adresse für weitere Aufträge speichern"}
              disabled={!!formData?.deliveryLocation?.source}
              helperText={false}/>
          )}
        </FormDataConsumer>
      </ParagraphBox>

      <Box display="flex">
        <Box flex={1}>
          <div class="MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth">
            <div style={{"z-index":"2"}}  class="MuiInputBase-root MuiFilledInput-root MuiFilledInput-underline MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-marginDense MuiFilledInput-marginDense">
              <label class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense MuiInputLabel-filled MuiFormLabel-filled">Zustelldatum</label>
              <DatePicker fieldName="deliveryDate" />
            </div>
          </div>
        </Box>
        <Box flex={1} ml="0.5rem">
          <TimeRangeInputAlternative record={{
            deliveryTimeFrom: '',
            deliveryTimeTo: '',
          }} sourceFrom={"deliveryTimeFrom"} sourceTo={"deliveryTimeTo"} />
        </Box>
      </Box>

      <TextInput
        multiline
        fullWidth
        source={"bringinfos"}
        label={"Infos"}
      />
    </Card>
  );
}
