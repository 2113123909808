import React, { useState } from "react";

import { FormDataConsumer, useGetList, useNotify, useRefresh } from "react-admin";
import { Box, Button, TableCell as DefaultTableCell } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/RemoveCircleOutline";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { Spacer } from "../../utils/components/FormElements";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import GlobalLoading from "../../utils/components/GlobalLoading";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { AddressReferenceInput } from "../../address/components/AddressReferenceInput";
import Grid from "@material-ui/core/Grid";
import { useForm } from "react-final-form";
import { BillingAddressReferenceInput } from "../../billingAddress/components/BillingAddressReferenceInput";
import EntityLink from "../../utils/components/EntityLink";
import { useRecordContext } from "ra-core";
import { apiEndpoint, PatchRequest } from "../../utils/request/PatchRequest";

const TableCell = (props) => {
  const classes = useStyles();
  return <DefaultTableCell {...props} className={classes.listItem} />

}
export const AddressFormIterator = (props) => {
  const record = useRecordContext()
  const refresh = useRefresh()
  const notify = useNotify();
  const classes = useStyles();
  const {change} = useForm();
  const [loadingChanges, setLoadingChanges] = useState(false)

  // Fetch all addresses at once
  const { data, ids, loading, error } = useGetList(
    'addresses',
    {perPage: 1000, page: 1},
    { field: 'firma', order: 'ASC' },
    { customers: record.id }
  );

  const addAddress = (address) => {
    setLoadingChanges(true)
    PatchRequest(`${apiEndpoint}${record.id}/addAddress`, {
      address: address
    } ).then( () => {
      notify('Adresse hinzugefügt', { type: 'info' });
      setLoadingChanges(false)
      refresh()
    }).catch( (err) => {
      notify(`Fehler: ${err.message}`, { type: 'warning' });
      setLoadingChanges(false)
    })
  }
  const deleteAddress = (address) => {
    setLoadingChanges(true)
    PatchRequest(`${apiEndpoint}${record.id}/deleteAddress`, {
      address: address
    } ).then( () => {
      notify('Adresse entfernt', { type: 'info' });
      setLoadingChanges(false)
      refresh()
    }).catch( (err) => {
      notify(`Fehler: ${err.message}`, { type: 'warning' });
      setLoadingChanges(false)
    })
  }

  if (loading || loadingChanges ) return <GlobalLoading primaryText="custom.message.loading" secondaryText=""/>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <Box>
        <Grid spacing={2} container alignItems="center">
          <Grid item xs={6} lg={3}>
            <AddressReferenceInput
              source={"selectedAddress"}
              reference={"addresses"} label={"Adresse"}
              perPage={100}
              resettable={true}
              allowEmpty={false}
              helperText={false}
              filter={{notIn: ids?.map((item) => item )}}
              fullWidth />
          </Grid>
          <Grid item>
            <FormDataConsumer>
              {({ formData, ...rest }) => (
                <Button
                  color={'primary'}
                  disabled={formData.selectedAddress === undefined || formData.selectedAddress === ''}
                  onClick={() => {
                    addAddress(formData.selectedAddress);
                    change('selectedAddress', "")
                  }}
                  label="ra.action.add">
                  <AddIcon/> Hinzufügen
                </Button>
              )}
            </FormDataConsumer>
          </Grid>
        </Grid>
      </Box>

      <Spacer space="1rem"/>
      {ids?.length > 0 && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Firma</TableCell>
                <TableCell>Suchname</TableCell>
                <TableCell>Ort</TableCell>
                <TableCell>Straße</TableCell>
                <TableCell>Tel</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Stellplatz</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ids.map((id, index) => {
                const address = data[id];
                return <TableRow className={classes.listRow}>
                  <TableCell textAlign={"left"}>
                    <EntityLink record={address} label={address.originId} style={{justifyContent: "flex-start"}}/>
                  </TableCell>
                  <TableCell>{address.firma}</TableCell>
                  <TableCell>{address.suchname}</TableCell>
                  <TableCell>{address.ort}</TableCell>
                  <TableCell>{address.strasse}</TableCell>
                  <TableCell>{address.tel}</TableCell>
                  <TableCell>{address.email}</TableCell>
                  <TableCell>{address.stellplatz ? <CheckIcon /> : <ClearIcon /> }</TableCell>
                  <TableCell>
                    <Button
                      color={'primary'}
                      title="Position entfernen"
                      style={ { minWidth: '32px' } }
                      onClick={ () => {
                        deleteAddress(address['@id']);
                      }}>
                      <CloseIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              })}
            </TableBody>
          </Table>

        </TableContainer>
      )}
    </>
  )
}

export const BillingAddressFormIterator = (props) => {
  const classes = useStyles();
  const {change} = useForm();

  const addressList = props.fields?.value?.map( ( item, index ) => {
    return item.id ?? item
  })

  const addresses = [ ...new Set( addressList )]

  // Fetch all addresses at once
  const { data, ids, loading, error } = useGetList(
    'billing_addresses',
    {perPage: 1000, page: 1},
    { field: 'firma', order: 'ASC' },
    { id: addresses }
  );

  if (loading) return <GlobalLoading primaryText="custom.message.loading" secondaryText=""/>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <Box>
        <Grid spacing={2} container alignItems="center">
          <Grid item xs={6} lg={3}>
            <BillingAddressReferenceInput
              source={"selectedBillingAddress"}
              reference={"billing_addresses"}
              label={"Rechnungsadresse"}
              perPage={100}
              resettable={true}
              allowEmpty={false}
              helperText={false}
              filter={{notIn: props?.fields?.value?.map((item) => item )}}
              fullWidth />
          </Grid>
          <Grid item>
            <FormDataConsumer>
              {({ formData, ...rest }) => (
                <Button
                  color={'primary'}
                  disabled={formData.selectedBillingAddress === undefined || formData.selectedBillingAddress === ''}
                  onClick={() => {
                    props.fields.push(formData.selectedBillingAddress);
                    change('selectedBillingAddress', "")
                  }}
                  label="ra.action.add">
                  <AddIcon/> Hinzufügen
                </Button>
              )}
            </FormDataConsumer>
          </Grid>
        </Grid>
      </Box>

      <Spacer space="1rem"/>

      {props.fields.value?.length > 0 && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Firma</TableCell>
                <TableCell>Ort</TableCell>
                <TableCell>Straße</TableCell>
                <TableCell>Email</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ids.map((id, index) => {
                const address = data[id];
                return <TableRow className={classes.listRow}>
                  <TableCell>{address.originId}</TableCell>
                  <TableCell>{address.firma}</TableCell>
                  <TableCell>{address.plz} {address.ort}</TableCell>
                  <TableCell>{address.strasse} {address.hausnr}</TableCell>
                  <TableCell>{address.email}</TableCell>
                  <TableCell>
                    <Button
                      color={'primary'}
                      title="Position entfernen"
                      style={ { minWidth: '32px' } }
                      onClick={ () => {
                        const removeIndex = props.fields.value.findIndex(field => {
                          if( typeof field === 'string' ) {
                            return field === address[ '@id' ]
                          }
                          if( field.hasOwnProperty('@id') ) {
                            return field['@id'] === address[ '@id' ]
                          }
                        });

                        if( removeIndex >= 0 ) {
                          props.fields.remove(removeIndex);
                        }
                      }}>
                      <CloseIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              })}
            </TableBody>
          </Table>

        </TableContainer>
      )}
    </>
  )
}

const useStyles = makeStyles( ( theme ) => ( {
  listRow: {
    padding: '0 5px 0 5px',
    backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.default,
    // borderBottom: "2px solid rgba(128,128,128, 0.75)",
    '&:nth-child(odd)': {
      // boxShadow: "rgb(0,0,0,0.26) 0px 2px 20px 0px",
      backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.odd,
    },
    '&:hover': {
      backgroundColor: theme.overrides.invoiceTable.row.backgroundColor.hover,
    },
  },
  listItem: {
    padding: '0 10px'
  },

  textInput: {
    margin: "8px 3px 4px 3px",
    float: 'left',
  }
} ) )
