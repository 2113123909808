import * as React from "react";
import { Create, Edit, SimpleForm, NumberInput, TextInput } from "react-admin";
import { InputGuesser } from "@api-platform/admin";
import DefaultEditActions from "../utils/DefaultEditActions";
import CustomBooleanInput from "../utils/components/CustomBooleanInput";

const EntityForm = (props) => (
  <SimpleForm {...props}>
    <TextInput source={"name"}/>
    <NumberInput source={"sortierung"} defaultValue={0}/>
    <CustomBooleanInput source={"voreingestellt"}/>
    <NumberInput source={"menge"} defaultValue={0}/>
  </SimpleForm>
);

const EntityTitle = ({ record }) => {
  return <span>Zubehör {record ? `"${record.name}"` : ""}</span>;
};

const EntityEdit = (props) => (
  <Edit
    actions={<DefaultEditActions {...props} />}
    title={<EntityTitle />}
    {...props}
  >
    <EntityForm />
  </Edit>
);

const EntityCreate = (props) => (
  <Create
    actions={<DefaultEditActions {...props} />}
    {...props}
    title={"Neuen Eintrag anlegen"}
  >
    <EntityForm {...props} style={{ width: "100%" }} />
  </Create>
);

export { EntityEdit, EntityCreate };
