import { BooleanField, TextField, FunctionField, EditButton } from "react-admin";

import React from "react";
import Link from "../utils/components/Link";
import { List, ListItem } from "@material-ui/core";
import {
  driverFilter,
  driverFiltersDefaultValues,
} from "./components/DriverFilter";
import HotList from "../utils/components/HotList";
import UserSettings from "../utils/components/UserSettings";
import DateField from "../utils/components/DateField";

const MoreInfos = ( { id, record, resource } ) => {
  let subs = record.Subfahrer;
  if ( record.subfahrer ){
    subs = record.subfahrer;
  }
  subs = subs.filter(subfahrer => subfahrer.active )
  return (
    <div style={{backgroundColor: 'rgba(0,0,0,0.05)', padding: "1rem"}}>
      <h2 style={{margin: 0}}>Subfahrer von { record.name }</h2>
      <List>
        {subs.map( subfahrer => (
          <ListItem key={ subfahrer.id } style={{padding: "0.25rem"}}>{ subfahrer.name } | { subfahrer.kuerzel } <Link to={`/drivers/${subfahrer['@id'].replaceAll('/', '%2F')}`} >Details</Link></ListItem>
        ) ) }
      </List>
    </div>
  );
};

const DriverList = (props) => {
  const t = UserSettings()?.openLinksInNewTab ? '_blank' : '_self';

  return <HotList
    expand={ <MoreInfos /> }
    isRowExpandable={row => row.Subfahrer?.filter((sub) => sub.active).length > 0}
    bulkActionButtons={false}
    filterDefaultValues={driverFiltersDefaultValues}
    filters={driverFilter}
    allowCreate={true}
    {...props}
    hasEdit={false}
  >
    <TextField source={"userId"} label={"ID"} sortBy="id"/>
    <TextField source={"name"} label={"Fahrername"} sortBy={"fahrer"}/>
    <FunctionField
                label="Kürzel"
                sortBy="kuerzel"
                render={record => {
                    if (record.fahrervon)
                        return `${record.kuerzel} | ${record.fahrervon.kuerzel}`;
                    else return `${record.kuerzel}`;
                }}
            />
    <TextField source={"vorname"} label={"Vorname"}/>
    <TextField source={"nachname"} label={"Nachname"}/>
    <TextField source={"telefon"} label={"Telefon"}/>
    <TextField source={"klassen"} label={"Führerschein"} sortable={false}/>
    <TextField source={"concatAddressData"} label={"Wohnort"} sortBy={"plz"}/>
    <TextField source={"fahrerExt.currentVersion"} label={"Version"} />
    <BooleanField source={ "active" } label={ "Aktiv" } sortBy="aktiv" />
    <BooleanField source={"appAccess"} label={"App Zugriff"} sortBy="fahrerExt.appAccess"/>
    <FunctionField
                label="OS"
                render={record => {
                    if (record.osVersion?.startsWith('Android'))
                        return `Android`;
                    else if ( record.osVersion?.startsWith( 'iOS' ) )
                      return `iOS`;
                }}
            />
    <EditButton target={t} />

  </HotList>
}

export default DriverList;
