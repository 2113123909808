import UserList from "./UserList";
import { EntityCreate, EntityEdit } from "./UserEdit";

const User = {
  name: "users",
  options: {
    label: "Nutzer-Konten"
  },
  list: UserList,
  edit: EntityEdit,
  create: EntityCreate
}

export default User;
