import { Button, useNotify, useRefresh, useDataProvider } from "react-admin";

import CancelIcon from "@material-ui/icons/Cancel";
import ConfirmIcon from "@material-ui/icons/CheckCircleOutline";

export const GenericContractButton = ( { record, icon = <CancelIcon />, text, showText = false, data = {}, style = {} } ) => {
  // const [open, setOpen] = useState( false );
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  if( !record ) {
    return null;
  }

  const resource = "contracts";

  const handleClick = () =>
    dataProvider
      .update( resource, {
        id: record.id,
        data: {
          ...record,
          ...data
        },
      } )
      .then( ( response ) => {
        // success side effects go here
        refresh();
        notify( "Auftrag erfolgreich bearbeitet", { type: "success" } );
      } )
      .catch( ( error ) => {
        // failure side effects go here
        notify( `Fehler beim bearbeiten: ${error.message}`, { type: "warning" } );
      } );

  // const handleClick = () => setOpen( true );
  // const handleDialogClose = () => setOpen( false );
  // const handleConfirm = () => {
  //   cancel();
  //   setOpen( false );
  // };

  return (
    <>
      <Button
        title={text}
        onClick={handleClick}
        children={
          <>
            {icon}
            {showText && text}
          </>
        }
        style={style}
      />
      {/*<Confirm*/}
      {/*  isOpen={open}*/}
      {/*  title={`Auftrag ${record.auftragsnummer} ${record.isStorniert ? "freigeben" : "stornieren"}`}*/}
      {/*  content="Sind Sie sicher?"*/}
      {/*  onConfirm={handleConfirm}*/}
      {/*  onClose={handleDialogClose}*/}
      {/*/>*/}
    </>
  );
};
