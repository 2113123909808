import { Box, Card } from "@material-ui/core";
import { InputContainer, ParagraphBox, ParagraphHeadline } from "../../../utils/components/FormElements";
import React from "react";
import { BooleanInput, FormDataConsumer } from "react-admin";
import TextInput from "../../../utils/components/TextInput";
import DatePicker from "../../../utils/components/DatePicker";
import TimeRangeInputAlternative from "../../../utils/components/TimeRangeInputAlternative";
import AddressInputBlock from "../../../contracts/components/AddressInputBlock";

export default ( props ) => {
  return <>
    <Card className={props.classes.card}>
      <ParagraphHeadline>Abholadresse</ParagraphHeadline>
      <AddressInputBlock type="pickup" source={"pickupLocation"} record={{ pickupLocation: {} }} displayHeader={false}/>


      <ParagraphBox>
        <FormDataConsumer>
          {( { formData } ) => (
            <BooleanInput
              source={`pickupLocation.save`}
              label={"Adresse für weitere Aufträge speichern"}
              disabled={!!formData?.pickupLocation?.source}
              helperText={false}/>
          )}
        </FormDataConsumer>
      </ParagraphBox>

      <Box display={"flex"}>
        <InputContainer left>
          <div className={`MuiFormControl-root MuiTextField-root MuiFormControl-marginDense MuiFormControl-fullWidth`} style={{ marginRight: "2px" }}>
            <div style={{ zIndex: "2" }} className={"MuiInputBase-root MuiFilledInput-root MuiFilledInput-underline MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-marginDense MuiFilledInput-marginDense"}>
              <label className={"MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense MuiInputLabel-filled MuiFormLabel-filled"}>
                Abholdatum
              </label>

              <DatePicker fieldName={`pickupDate`}/>
            </div>
          </div>
        </InputContainer>
        <InputContainer right>
          <TimeRangeInputAlternative record={{
            pickupTimeFrom: '',
            pickupTimeTo: '',
          }} sourceFrom={`pickupTimeFrom`} sourceTo={`pickupTimeTo`}/>
        </InputContainer>
      </Box>

      <TextInput
        multiline
        fullWidth
        source={'abholinfos'}
        label={`AbholInfos`}
      />
    </Card>
  </>
}
