import * as React from "react";
import { Create, Edit, SimpleForm, BooleanInput, SelectInput } from "react-admin";
import DefaultEditActions from "../utils/DefaultEditActions";
import { TextInput } from "../utils/components";
import { Box } from "@material-ui/core";


const EntityForm = (props) => (
  <SimpleForm {...props}>
    <Box>
      <TextInput source={"name"} label={"Beschreibung"} fullWidth />
    </Box>

    <Box>
      <TextInput source={"slug"} label={"Techn. Name"} fullWidth disabled={!props.isCreate}/>
    </Box>

    <Box>
      {/*<SelectInput source="conditionField"*/}
      {/*             label="Bedingung"*/}
      {/*             resettable={true}*/}
      {/*             choices={[*/}
      {/*  { id: "VerzoegerteAbgabe", name: "KFZ Vollelektrisch" },*/}
      {/*  { id: "Abafahrt", name: "ABA Fahrt" },*/}
      {/*]} fullWidth />*/}
      <TextInput source={"conditionField"} label={"Bedingung"} fullWidth />
    </Box>
  </SimpleForm>
);

const EntityTitle = ({ record }) => {
  return <span>{record.name}</span>;
};

export const EntityEdit = (props) => (
  <Edit
    actions={<DefaultEditActions {...props} />}
    title={<EntityTitle />}
    {...props}
  >
    <EntityForm
      isCreate={false} />
  </Edit>
);

export const EntityCreate = (props) => (
  <Create
    actions={<DefaultEditActions {...props} />}
    title={"Neuen Eintrag anlegen"}
    {...props}
  >
    <EntityForm
      isCreate={true}
      {...props}
    />
  </Create>
);
