import { FormTab, ArrayInput } from "react-admin";
import * as React from "react";
import MailsTabFormIterator from "./MailsTabFormIterator";


export default ( props ) => {

  return <FormTab label={"Mails"} {...props}>
    <ArrayInput source={"mailLogEntries"} label={""} fullWidth>
      <MailsTabFormIterator fieldName={"availableSnapshots"}/>
    </ArrayInput>
  </FormTab>
}
