import React from "react";
import { TextField } from "react-admin";
import HotList from "../utils/components/HotList";

export const PriceTypeList = ( props ) => (
  <HotList {...props}
           sort={"createdAt"}
           sortOrder={"DESC"}
           allowCreate={true}>
    <TextField source={"name"} label={"Beschreibung"}/>
    <TextField source={"slug"} label={"Techn. Name"}/>
  </HotList>
);

