import { ContractCreate, ContractEdit } from "./ContractEdit";
import { ContractList } from "./ContractList";

const Contracts = {
  name: "contracts",
  options: {
    label: "Aufträge",
  },

  list: ContractList,
  create: ContractCreate,
  edit: ContractEdit,
};

export default Contracts;
