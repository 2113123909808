import { useNotify } from "react-admin";
import { generateInvoicePDF, generatePDFLink } from "../../utils/request/PostRequest";
import { useRecordContext } from "ra-core";
import PDFButton from "../../utils/components/Buttons/PDFButton";
import * as React from "react";
import { useState } from "react";
import GlobalLoading from "../../utils/components/GlobalLoading";
import PictureAsPdfIconOutlined from "@material-ui/icons/PictureAsPdfOutlined";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { IconSpinner } from "../../utils/components/icons/IconSpinner";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

export const InvoiceDocumentButtonGroup = ( { record, showLabels = true } ) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  if( !record ) {
    return null;
  }

  const documentName = record.type === "Rechnung" ? "Rechnung" : "Gutschrift";

  const handleInvoiceClick = async( event, id ) => {
    setLoading(true);

    try {
      const response = await generateInvoicePDF( id );
      window.open(response.url, '_blank' )
    } catch (error) {
      notify( error.message, { type: 'error' } );
      setLoading(false);
    }

    setLoading(false);
  }

  const handleClick = async( type, overwrite = false ) => {
    setLoading(true );
    const apiURL = `${process.env.REACT_APP_API}`;
    const requestURL = `${apiURL}/invoice/${record._id}/additional_docs`
    const data = {
      template: record.expensesTemplate ?? 'default',
      type: type,
      overwrite: overwrite
    }

    await generatePDFLink( requestURL, data ).then( response => {
      if( !response.url ) {
        notify( response.message, { type: 'success' } );
      } else {
        window.open( response.url, "_blank" );
      }
      setLoading(false );
    } ).catch( error => {
      notify( error.response.data ?? error.message, { type: 'error' } );
      setLoading(false );
    })
  }

  return <>
    {loading &&<GlobalLoading/>}

    {record.type === "Rechnung" && record.showExpensesSummary && <PDFButton
      label={showLabels ? "Aufstellung" : "Aufst"}
      title={"Aufstellung zu "+ documentName + ' ' + record.orderNumber}
      // icon={<AttachFileIcon/>}
      onClick={() => handleClick('expensesSummary' )}
      style={{ minWidth: 'auto' }}
    />}

    {record.type === "Rechnung" && record.hasExpenses && <>
      <PDFButton
      label={showLabels ? "Auslagen" : "Ausl"}
      title={"Auslagen zu "+ documentName + ' ' + record.orderNumber}
      onClick={() => handleClick('expenses' )}
      icon={<PictureAsPdfIcon/>}
      disabled={record.documentInProgress}
      style={{ minWidth: 'auto' }}
      />
      <PDFButton
      label={showLabels ? "Auslagen" : "Ausl"}
      title={"Auslagen zu "+ documentName + ' ' + record.orderNumber + ' neu generieren'}
      icon={record.documentInProgress ? <IconSpinner /> : <RotateLeftIcon/>}

      onClick={() => handleClick('expenses', true )}
      style={{ minWidth: 'auto' }}
      />
    </>}





    <PDFButton
      label={showLabels ? "Auftragsliste" : "Auftr"}
      title={"Auftragsliste zu "+ documentName + ' ' + record.orderNumber}
      icon={<AttachFileIcon/>}
      onClick={() => handleClick('contracts' )}
      style={{ minWidth: 'auto' }}
    />

    <PDFButton title={documentName + ' ' + record.orderNumber}
               url={`${process.env.REACT_APP_API}/invoice/${record._id}`}
               onClick={( event ) => handleInvoiceClick( event, record._id )}
               label={showLabels ? documentName : 'Doc'}

               style={{ minWidth: 'auto' }}/>

    {process.env.REACT_APP_ENV === "dev" &&
     <PDFButton url={`${process.env.REACT_APP_API}/invoice/${record._id}?debug=1`}
                label={showLabels ? "Vorschau" : ""}
                title={documentName + ' ' + record.orderNumber}
                style={{ minWidth: 'auto' }}
                icon={<PictureAsPdfIconOutlined/>}
     />}
  </>
}
