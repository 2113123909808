import { Button, useNotify, useRefresh, useDataProvider } from "react-admin";
import React, { useState } from 'react';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import ListIcon from "@material-ui/icons/List";
import { red } from "@material-ui/core/colors";
import GlobalLoading from "../../../utils/components/GlobalLoading";

export const DisableProtocolMailButton = ( { record, style = {} } ) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  if( !record || record.driver ) {
    return null;
  }
  const showButtonText = false;
  const buttonText = record.mailLocked ? "Mailversand freigeben" : "Mailversand sperren";
  let buttonIcon = <PlaylistAddCheckIcon />;
  if( record.mailLocked ) {
    buttonIcon = <ListIcon style={{color: red[500]}} />
  }

  const setLockMail = () => {
    setLoading(true)
    dataProvider
      .update(
        "protocols", {
        id: record.id,
        data: {
          ...record,
          mailLocked: !record.mailLocked
        },
      } )
      .then( ( response ) => {
        // success side effects go here
        notify( "Erfolgreich bearbeitet", { type: "success" } );
        setLoading(false)
      } )
      .catch( ( error ) => {
        // failure side effects go here
        notify( `Fehler beim bearbeiten: ${error.message}`, { type: "warning" } );
        setLoading(false)
      } );
  }


  return (
    <>
      {loading &&<GlobalLoading/>}
      <Button
        title={buttonText}
        onClick={setLockMail}
        children={
          <>
            {buttonIcon}
            {showButtonText && buttonText}
          </>
        }
        style={style}
      />
    </>
  );
};

export const BulkToggleDisplayInOfferList = ( { contracts } ) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const update = async ( state ) => {
    contracts.map( async contract => {
      await dataProvider.update("contracts", {
        id: `/api/contracts/${contract}`,
        data: {
          ...contract,
          sperrenFuerAngebotsliste: state
        },
      }).then((response) => {
        // success side effects go here
        notify("Auftrag erfolgreich bearbeitet", { type: "success" });
      }).catch((error) => {
        // failure side effects go here
        notify(`Fehler beim bearbeiten: ${error.message}`, { type: "warning" });
      })
    })
  }

  return <>
    <Button label={`Aufträge Für Angebotsliste sperren`} variant="contained" onClick={() => update(true)}/>&nbsp;
    <Button label={`Aufträge Für Angebotsliste freigeben`} variant="contained" onClick={() => update(false)}/>
  </>
}
