import { useState } from "react";
import { useDataProvider, useNotify, useRefresh, Confirm } from "react-admin"
import { Button } from "@material-ui/core";

const ResetSignatureButton = ( props ) => {
  const { type, record, state, resetState } = props

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const [open, setOpen] = useState( false );
  const [loading, setLoading] = useState( false );
  const [obj, setObj] = useState( {} )
  const [confirmTitle, setConfirmTitle] = useState( "Protokolldaten zurücksetzen" )
  const [confirmContent, setConfirmContent] = useState( "Wollen Sie die Unterschrift wirklich löschen?" )

  const states = [
    { id: 'UNPROCESSED', name: 'Nicht begonnen', next: 'STARTED' },
    { id: 'STARTED', name: 'Begonnen', next: 'SUBMITTED' },
    { id: 'SUBMITTED', name: 'Abgeholt (U2)', next: 'RECEIVED' },
    { id: 'RECEIVED', name: 'Ausgeliefert (U3)', next: 'CLOSED' },
    { id: 'CLOSED', name: 'Abgeschlossen (U4)', next: null },
  ]

  const currentStatus = states.find( status => status.id === state )
  const nextStatus = states.find( status => status.id === resetState )

  const handleDialogClose = () => setOpen( false );

  const handleConfirm = () => {
    setLoading( true )
    dataProvider
      .update( 'protocols',
               { id: record.id, data: { ...record, ...obj }, previousData: record }
      ).then( ( response ) => {
      // success side effects go here
      refresh();
      setLoading( false )
      notify( "Erfolgreich bearbeitet", "success" );
    } ).catch( ( error ) => {
      // failure side effects go here
      setLoading( false )
      notify( `Fehler beim bearbeiten: ${error.message}`, { type: "warning" } );
    } );
  }

  const buttonStyles = {
    root: {
      color: "red",
      padding: '0.25rem 0.5rem',
      margin: '0.25rem 0.5rem',

    }
  }

  return <>
    <Button
      variant={"text"}
      color={"primary"}
      onClick={() => {
        let obj = {}
        obj[ type ] = null
        obj[ 'processState' ] = state
        setConfirmTitle( "Protokolldaten zurücksetzen" )
        setConfirmContent( "Wollen Sie die Unterschrift wirklich löschen?" )
        setObj( obj )
        setOpen( true )
      }}
    >Unterschrift löschen</Button>
    <Button
      variant={"text"}
      color={"primary"}
      onClick={() => {
        let obj = {}
        setConfirmTitle( "Status setzen" )

        obj[ 'processState' ] = nextStatus.id
        setConfirmContent( "Wollen Sie wirklich das Protokoll auf den Status "+nextStatus.name+" setzen?" )
        setObj( obj )
        setOpen( true )
    }}>Status auf {nextStatus.name} setzen</Button>

    <Confirm
      isOpen={open}
      loading={loading}
      title={confirmTitle}
      content={confirmContent}
      onConfirm={handleConfirm}
      onClose={handleDialogClose}
    />
  </>;
}

const ResetBlockButton = ( props ) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const [open, setOpen] = useState( false );
  const [loading, setLoading] = useState( false );

  const handleClick = () => setOpen( true );
  const handleDialogClose = () => setOpen( false );
  const handleConfirm = () => {
    const { record } = props
    setLoading( true );

    dataProvider
      .update( 'protocols',
               { id: record.id, data: { ...record, resetBlock: props.block }, previousData: record }
      ).then( ( response ) => {
      refresh();
      setLoading( false );
      setOpen( false );
      notify( "Protokoll erfolgreich zurückgesetzt", "success" );
    } ).catch( ( error ) => {
      setLoading( false );
      setOpen( false );
      let text = error.message
      if( typeof error.response?.data[ 'hydra:description' ] !== "undefined" ) {
        text += `\n${error.response?.data[ 'hydra:description' ]}`
      }
      notify( text, { type: "warning", multiLine: true } );
    } );

  }

  const buttonStyle = {
    backgroundColor: '#C82333',
    color: "white",
    padding: '0.25rem 1rem'
  }

  const confirm_content = props.confirmContent ?? "Möchten Sie das Protokoll und die entsprechenden Daten wirklich zurücksetzen?"

  return <>
    <Button
      style={buttonStyle}
      onClick={handleClick}>
      Protokolldaten zurücksetzen
    </Button>
    <Confirm
      isOpen={open}
      loading={loading}
      title="Protokolldaten zurücksetzen"
      content={confirm_content}
      onConfirm={handleConfirm}
      onClose={handleDialogClose}
    />
  </>
}

const ResetProtocolButton = ( props ) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const { record } = props

  const [open, setOpen] = useState( false );
  const [loading, setLoading] = useState( false );
  const [anschluss, setAnschluss] = useState( false );

  const handleClick = () => setOpen( true );
  const handleDialogClose = () => setOpen( false );
  const handleConfirm = () => {
    setLoading( true );

    const r = anschluss ? record.anschlussProtokoll : record

    dataProvider
      .update( 'protocols',
               { id: r[ '@id' ], data: { ...r, reset: true }, previousData: r }
      ).then( ( response ) => {
      refresh();
      setLoading( false );
      setOpen( false );
      notify( "Protokoll erfolgreich zurückgesetzt", "success" );
    } ).catch( ( error ) => {
      setLoading( false );
      setOpen( false );
      let text = error.message
      if( typeof error.response?.data[ 'hydra:description' ] !== "undefined" ) {
        text += `\n${error.response?.data[ 'hydra:description' ]}`
      }
      notify( text, { type: "warning", multiLine: true } );
    } );

  }

  const buttonStyle = {
    backgroundColor: '#C82333',
    margin: '0 10px',
    color: "white",
    padding: '0.25rem 1rem'
  }

  return <>
    <Button
      style={buttonStyle}
      onClick={() => {
        setAnschluss( false )
        handleClick()
      }}>Protokolldaten zurücksetzen</Button>
    {record.anschlussProtokoll && <Button
      style={buttonStyle}
      onClick={() => {
        setAnschluss( true )
        handleClick()
      }}>Anschluss zurücksetzen</Button>}
    <Confirm
      isOpen={open}
      loading={loading}
      title="Protokolldaten zurücksetzen"
      content="Möchten Sie das Protokoll und die entsprechenden Daten wirklich zurücksetzen?"
      onConfirm={handleConfirm}
      onClose={handleDialogClose}
    />
  </>
}

const ResetLockButton = ( { type, record } ) => {
  if( !record || !type ) {
    return null
  }

  let obj = {}
  obj[ type ] = false;

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const reset = () =>

    dataProvider
      .update( 'protocols',
               { id: record.id, data: { ...record, ...obj }, previousData: record }
      ).then( ( response ) => {
      // success side effects go here
      refresh();
      notify( "Sperre erfolgreich aufgehoben", { type: "success" } );
    } ).catch( ( error ) => {
      // failure side effects go here
      notify( `Fehler beim entsperren: ${error.message}`, { type: "warning" } );
    } );

  return <Button onClick={reset}>Sperre aufheben</Button>;
}

const ResetEmptyRunButton = ( { record } ) => {
  if( !record ) {
    return null
  }
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const reset = () => {
    const _clone = Object.assign( {}, record );

    _clone.emptyRunDone = false;
    _clone.emptyRunReason = null;
    _clone.emptyRunApprovedBy = null;
    _clone.emptyRunOtherReason = null;

    dataProvider
        .update( 'protocols',
                 {
                   id: record.id,
                   data: {
                     ..._clone,
                   },
                   previousData: record
                 }

        ).then( ( response ) => {
        // success side effects go here
        refresh();
        notify( "Leerfahrt zurück gesetzt", { type: "success" } );
      } ).catch( ( error ) => {
        // failure side effects go here
        notify( `Fehler beim zurück setzen: ${error.message}`, { type: "warning" } );
      } );
  }

  return <Button variant="text" color="primary" onClick={reset}>
    Leerfahrt zurück setzen
  </Button>;
}

export { ResetSignatureButton, ResetProtocolButton, ResetLockButton, ResetBlockButton, ResetEmptyRunButton };
