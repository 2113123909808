import merge from "lodash/merge";

export const noMargin = {
  margin: 0,
  minWidth: 'auto',
}

export const defaultButtonStyle = {
  minWidth: "auto",
  display: "flex"
}

export const arrowButtonStyle = merge(
  {},
  defaultButtonStyle,
  noMargin, {
    padding: '2px 5px',
    // padding: '0',
})

export const arrowButtonIconStyle = {
  fontSize: '1.25rem'
}
