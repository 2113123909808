import React from "react";
import {FormDataConsumer} from "react-admin";
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { Box } from "@material-ui/core";
import { ca } from "date-fns/locale";

export default ( props ) => {

    const { source, tax, calcGross, ...rest } = props;

  return <FormDataConsumer>
      {( { formData } ) => {
        let value = eval( `formData.${source}` )

        if( calcGross == "true" && tax != undefined ) {
            let taxValue = eval( `formData.${tax}` )
            if( typeof taxValue === 'string' || taxValue instanceof String ) {
                taxValue = parseInt( taxValue );
            }

            value = value * ( 1 + taxValue / 100 );
        }

        return <CurrencyTextField
          // minimumValue={0}
          value={value}
          currencySymbol={"€"}
          variant={"filled"}
          decimalCharacter={","}
          digitGroupSeparator={"."}
          outputFormat={"string"}
          label={"Rechnungsbetrag"}
          margin={"dense"}
          name={source}
          onChange={( event, value ) => {
            eval( `formData.${source}='${value}'` )
          }}
          fullWidth
          {...rest} />
      }}
    </FormDataConsumer>
}
