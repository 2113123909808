import { Grid as DefaultGrid } from "@material-ui/core";
import { AddressReferenceInput } from "../../address/components/AddressReferenceInput";
import { TextInput as DefaultTextInput } from "../../utils/components";
import { makeStyles } from "@material-ui/core/styles";
import { ArrayInput, useTranslate } from "react-admin";
import ContactFormIterator from "../../utils/components/InputComponents/ArrayInput/ContactFormIterator";
import * as React from "react";
import Typography from "@material-ui/core/Typography";
import CountrySelectInput from "../../utils/components/CountrySelectInput";
import { useForm, useFormState } from "react-final-form";
import { useEffect, useState } from "react";
import { getAddressData } from "../../utils/request";


const useStyles = makeStyles( {
                                MuiFormControlMarginDense: {
                                  margin: '2px 0',
                                  padding: '0',
                                },
                                GridItem: {
                                  paddingRight: '0.25rem',
                                  '&:last-item': {
                                    paddingRight: '0'
                                  }
                                }
                              } );

const Grid = ( props ) => {
  const classes = useStyles();
  return <DefaultGrid {...props} className={classes.GritItem} />
}

const TextInput = ( props ) => {
  const classes = useStyles();
  return <DefaultTextInput
    helperText={false}
    autoComplete="off"
    fullWidth
    className={classes.MuiFormControlMarginDense}
    {...props}
  />
}


const ProtocolAddressInputBlock = ( { source, type = 'pickup', record } ) => {
  const classes = useStyles();

  return <Grid container spacing={1}>
    <Grid item xs={12}>
      <AddressReferenceInput
        source={`${source}.source`}
        label={"Bekannte Adresse"}
        onChange={ ( address ) => {
          return handleAddressChange( address );
        } }
      />
    </Grid>

    <Grid item xs={12} lg={6}>
      <TextInput source={`${source}.name`} label={"Name"} record={record} />
    </Grid>

    <Grid item xs={12} lg={6}>
      <TextInput source={`${source}.firma`} label={"Firma"} record={record}/>
    </Grid>

    <Grid item xs={12} lg={9}>
      <TextInput source={`${source}.street`} label={"Straße"} record={record}/>
    </Grid>

    <Grid item xs={12} lg={3}>
      <TextInput source={`${source}.houseNum`} label={"Hausnummer"} record={record}/>
    </Grid>

    <Grid item xs={12} lg={2}>
      <TextInput source={`${source}.zip`} label={"PLZ"} record={record}/>
    </Grid>

    <Grid item xs={12} lg={6}>
      <TextInput source={`${source}.city`} label={"Ort"} record={record}/>
    </Grid>

    <Grid item xs={12} lg={4}>
      <CountrySelectInput source={`${source}.country`} className={classes.MuiFormControlMarginDense} record={record}/>
    </Grid>

    {/*<Grid item xs={12}>*/}
    {/*  <Typography variant="subtitle1">*/}
    {/*    Ansprechpartner*/}
    {/*  </Typography>*/}

    {/*  <ArrayInput source={`${source}.contacts`} label={""} className={classes.MuiFormControlMarginDense}>*/}
    {/*    <ContactFormIterator inputClassName={classes.MuiFormControlMarginDense}/>*/}
    {/*  </ArrayInput>*/}
    {/*</Grid>*/}
  </Grid>
}

export default ProtocolAddressInputBlock;
