import Paper from '@material-ui/core/Paper';
import CustomIFrame from '../../utils/components/Iframe';


const STATISTICS_URL = process.env.REACT_APP_API_STATISTICS
const STATISTICS_TOKEN = process.env.REACT_APP_API_STATISTICS_TOKEN

export default ( props ) => {
    const { children, value, index, page, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`statistics-tabpanel-${index}`}
            {...other}
        >
            {value === index && (
                <Paper style={{ marginTop: '1rem' }}>
                    <CustomIFrame
                        src={`${STATISTICS_URL}/?token=${STATISTICS_TOKEN}`}
                    />
                </Paper>
            )}
        </div>
    );
};
