import * as React from "react";
import { Create, Edit, SimpleForm, BooleanInput } from "react-admin";
import DefaultEditActions from "../utils/DefaultEditActions";
import { DateInput } from "../utils/components/DateInput";
import { Box } from "@material-ui/core";
import {
  validateRequired
} from "../utils/validators/DefaultValidators";


const EntityForm = (props) => (
  <SimpleForm validate={(values) => {
    const errors = {};
    if (!values.datum) {
      errors.datum = ["Das Datum muss angegeben werden"];
    }
    return errors;
  } } {...props}>
    <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-start"}>
      <Box mr="0.5em">
        <DateInput
          source={"datum"}
          label={"Datum"}
          disabled={!props.isCreate}
          helperText={false}
          validate={validateRequired} />
      </Box>
      <Box ml={"0.5em"} display={"flex"}>
        <BooleanInput
          source={"active"}
          label={"Aktiv"}
          helperText={false}
          style={{alignSelf: "center"}}
          defaultValue={true} />
      </Box>
    </Box>
  </SimpleForm>
);

const EntityTitle = ({ record }) => {
  if( !record.datum) {
    return <span>Unb. Datum</span>;
  }

  const date = new Date(record.datum).toLocaleDateString( "de-DE", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  })

  return <span>Sperre für {date}</span>;
};

export const EntityEdit = (props) => (
  <Edit
    actions={<DefaultEditActions {...props} />}
    title={<EntityTitle />}
    {...props}
  >
    <EntityForm
      isCreate={false} />
  </Edit>
);

export const EntityCreate = (props) => (
  <Create
    actions={<DefaultEditActions {...props} />}
    title={"Neuen Eintrag anlegen"}
    {...props}
  >
    <EntityForm
      isCreate={true}
      {...props}
    />
  </Create>
);
