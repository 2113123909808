import { Button, useCreate, useNotify, useRedirect } from "react-admin";
import * as React from "react";
import { useRecordContext } from "ra-core";

export const CancellationInvoiceButton = ( { record }) => {
  if( !record || record.type !== 'Rechnung' || record.invoice ) {
    return null;
  }
  const redirect = useRedirect()
  const notify = useNotify()

  const _clone = Object.assign( {}, record );
  _clone.invoice = record['@id'];
  _clone.orderNumber = '';
  delete _clone[ '@id' ];
  delete _clone[ 'id' ];
  delete _clone[ '_id' ];
  delete _clone[ 'invoiceEntries' ];

  const [create, { loading, loaded, error, data }] = useCreate( 'invoices', _clone, {
    onSuccess: ( data ) => {
      notify(`${record.type} wurde erstellt`, { type: 'success' } );
      redirect( 'edit', '/invoices', data.data['@id'] )
    },
    onFailure: ( error ) => {
      notify(
        error.message || `Fehler beim Verschicken der ${record.type} `,
        { type: 'warning', multiLine: true }
      )
    }} );

  return <Button
    label={"Stornorechnung erstellen"}
    onClick={ async() => await create() }/>;
};
