import React, {
  isValidElement,
  Children,
  cloneElement,
  useCallback,
} from 'react';
import { sanitizeListRestProps } from 'ra-core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import classnames from 'classnames';

import { DatagridHeaderCell, DatagridBody, DatagridLoading } from 'react-admin';

const useStyles = makeStyles(theme => ({
  table: {
    tableLayout: 'auto',
  },
  thead: {},
  tbody: {},
  headerRow: {},
  headerCell: {},
  checkbox: {},
  row: {},
  clickableRow: {
    cursor: 'pointer',
  },
  rowEven: {},
  rowOdd: {},
  rowCell: {},
  expandHeader: {
    padding: 0,
    width: theme.spacing(6),
  },
  expandIconCell: {
    width: theme.spacing(6),
  },
  expandIcon: {
    padding: theme.spacing(1),
    transform: 'rotate(-90deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expanded: {
    transform: 'rotate(0deg)',
  },
}));

const ContractBillingDatagrid = ({ classes: classesOverride, ...props }) => {
  const classes = useStyles({ classes: classesOverride });
  const {
    basePath,
    optimized = false,
    body = <DatagridBody />,
    children,
    className,
    currentSort,
    data,
    expand,
    hasBulkActions,
    hover,
    ids,
    loading,
    loaded,
    onSelect,
    onToggleItem,
    resource,
    rowClick,
    rowStyle,
    selectedIds,
    setSort,
    size = 'small',
    total,
    version,
    ...rest
  } = props;

  let totalSum = 0;

  const formatMoney = (value) => new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR'
  }).format(value);

  const formatSumCell = (value) => {
    return (<strong>{value}</strong>);
  }

  const calcSum = (ids, source) => ids.map(id => {
    const floatValue = parseFloat(data[id]?.[source]);
    return isNaN(floatValue) ? 0 : floatValue;
  }).reduce((acc, curr) => acc + curr, 0);

  const calcSumAndTotal = (ids, source) => {
    const sum = calcSum(ids, source);
    totalSum += sum;

    return sum;
  }

  const getSumCell = (index) => {
    switch (index) {
      case 7:
        return formatSumCell('Summen:');
      case 10:
        const priceSum = ids.map(id => {
          const floatVal = parseFloat( data[ id ]?.auftragswert );
          return isNaN(floatVal) ? 0 : floatVal;
        }).reduce((acc, curr) => acc + curr, 0);

        totalSum += priceSum;

        return formatSumCell(formatMoney(priceSum));
      case 8:
        return formatSumCell(formatMoney(calcSumAndTotal(ids, 'differenceBetweenSollAndGooglePriceGroups')));
      case 11:
        return formatSumCell( formatMoney( calcSumAndTotal( ids, 'costTaxiOrVwPauschaleTotal' ) ) );
      case 12:
        return formatSumCell( formatMoney( calcSumAndTotal( ids, 'billingListCostWashTotal' ) ) );
      case 13:
        return formatSumCell( formatMoney( calcSumAndTotal( ids, 'wartezeitPreisTotal' ) ) );
      case 14:
        return formatSumCell( formatMoney( calcSumAndTotal( ids, 'costFuelNetTotal' ) ) );
      case 15:
        return formatSumCell( formatMoney( calcSumAndTotal( ids, 'costWashBillNetTotal' ) ) );
      case 16:
        return formatSumCell( formatMoney( calcSumAndTotal( ids, 'costTrainNetTotal' ) ) );
      case 17:
        return formatSumCell( formatMoney( calcSumAndTotal( ids, 'costOilNetTotal' ) ) );
      case 18:
        return formatSumCell( formatMoney( calcSumAndTotal( ids, 'costOtherNetBillingTotal' ) ) );
      default:
        return;
    }
  }

  const getTotalCell = (index) => {
    switch (index) {
      case 7:
        return formatSumCell('Gesamtsumme:');
      case 10:
        return formatSumCell(formatMoney(totalSum));
      default:
        return;
    }
  }

  const updateSort = useCallback(
    event => {
      event.stopPropagation();
      setSort(event.currentTarget.dataset.sort);
    },
    [setSort]
  );

  const handleSelectAll = useCallback(
    event => {
      if (event.target.checked) {
        onSelect(
          ids.concat(selectedIds.filter(id => !ids.includes(id)))
        );
      } else {
        onSelect([]);
      }
    },
    [ids, onSelect, selectedIds]
  );

  if (loaded === false) {
    return (
      <DatagridLoading
        classes={classes}
        className={className}
        expand={expand}
        hasBulkActions={hasBulkActions}
        nbChildren={React.Children.count(children)}
        size={size}
      />
    );
  }

  if (loaded && (ids.length === 0 || total === 0)) {
    return null;
  }

  return (
    <Table
      className={classnames(classes.table, className)}
      size={size}
      {...sanitizeListRestProps(rest)}
    >
      <TableHead className={classes.thead}>
        <TableRow
          className={classnames(classes.row, classes.headerRow)}
        >
          {expand && (
            <TableCell
              padding="none"
              className={classes.expandHeader}
            />
          )}
          {hasBulkActions && (
            <TableCell padding="checkbox">
              <Checkbox
                className="select-all"
                color="primary"
                checked={
                  selectedIds.length > 0 &&
                  ids.length > 0 &&
                  ids.every(id => selectedIds.includes(id))
                }
                onChange={handleSelectAll}
              />
            </TableCell>
          )}
          {Children.map(children, (field, index) =>
            isValidElement(field) ? (
              <DatagridHeaderCell
                className={classes.headerCell}
                currentSort={currentSort}
                field={field}
                isSorting={
                  currentSort.field ===
                  (field.props.sortBy || field.props.source)
                }
                key={field.props.source || index}
                resource={resource}
                updateSort={updateSort}
              />
            ) : null
          )}
        </TableRow>
      </TableHead>
      {cloneElement(
        body,
        {
          basePath,
          className: classes.tbody,
          classes,
          expand,
          rowClick,
          data,
          hasBulkActions,
          hover,
          ids,
          onToggleItem,
          resource,
          rowStyle,
          selectedIds,
          version,
        },
        children
      )}
      <tfoot>
        <TableRow>
          {Children.map(children, (field, index) => {
              return index !== 1 && index !== 2 && (
                  <TableCell colSpan={index===0 ? 3 : 1}>{getSumCell(index)}</TableCell>
              )
          })}
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
            {Children.map(children, (field, index) => {
              return index !== 1 && index !== 2 && (
                  <TableCell colSpan={index===0 ? 3 : 1}>{getTotalCell(index)}</TableCell>
                )
            })}
          <TableCell></TableCell>

        </TableRow>
      </tfoot>
    </Table>
  );
}

ContractBillingDatagrid.defaultProps = {
  data: {},
  hasBulkActions: false,
  ids: [],
  selectedIds: [],
};

export default ContractBillingDatagrid;
