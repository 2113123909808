import { BooleanInput, SearchInput, SelectInput, useListContext } from 'react-admin';
import moment from 'moment';
import "moment/locale/de";
import { CustomerReferenceArrayInput, CustomerReferenceInput } from "../../../customers/components/CustomerReferenceInput";
import { AddressReferenceArrayInputWithSpecialFilter } from "../../../address/components/AddressReferenceInput";
import React from "react";
import { DateInputWithLocalStorage } from "../../../utils/components/DateInput";
import { BillingAddressReferenceInput } from "../../../billingAddress/components/BillingAddressReferenceInput";

moment.locale( "de" );

const billingFilters = [
  <SearchInput
    placeholder="Auftrag, KZ, FIN, Fahrer"
    source="specialFilter"
    style={{ minWidth: "250px", marginTop: "0" }}
    alwaysOn
  />,

  // <CustomerReferenceInput label="Kunden" source="auftraggeber" filter={{hasContractsToBill: 1}} alwaysOn/>,
  <CustomerReferenceInput label="Kunde" source="auftraggeber" alwaysOn/>,
  <CustomerReferenceArrayInput label="Kunden ausgenommen" source="exceptCustomers" alwaysOn/>,

  <AddressReferenceArrayInputWithSpecialFilter
   label="Leistungsadresse"
   source="leistungsadresse"
   alwaysOn/>,
  <BillingAddressReferenceInput
   label="Alternative Rechnungsadresse"
   source="alternativerechnungsadresse"
   alwaysOn/>,
  <DateInputWithLocalStorage
    label="Leistungsdatum ab"
    source="custom__bringdatum[after]"
    origin="billingFilters"
    style={{ minWidth: "200px" }}
    alwaysOn
  />,
  <DateInputWithLocalStorage
    label="Leistungsdatum bis"
    source="custom__bringdatum[before]"
    origin="billingFilters"
    style={{ minWidth: "200px" }}
    alwaysOn
  />,

  <BooleanInput label="Nur Abgerechnete anzeigen"
                title={"Bereits abgerechnete Aufträge anzeigen"}
                options={{ size: "medium" }}
                source="berrechnet"
                style={{ paddingBottom: '10px' }}
                parse={v => v ? "1" : ["0", ""]}
                format={v => v === "1"}
                alwaysOn/>,

  <BooleanInput label="Für Rechnung gesperrt"
                title={"Bereits abgerechnete Aufträge anzeigen"}
                options={{ size: "medium" }}
                source="boolchar_sperrenfuerrechnung"
                style={{ paddingBottom: '10px' }}
                alwaysOn/>,

  <SelectInput label="Auftragstypen"
               source="custom__contract_type"
               choices={[
                 { name: 'Normale Aufträge', value: 'normal' },
                 { name: 'Stationsfahrt', value: 'vwstationsfahrt' },
                 { name: 'E-Fahrzeug', value: 'verzoegerteabgabe' },
               ]}
               optionValue={"value"} emptyText={"Alle anzeigen"} emptyValue={"all"} alwaysOn/>,

  <SelectInput label="Auftragstyp"
               source="contractType"
               choices={[
                 { name: 'Abholung', id: 'PICKUP' },
                 { name: 'Zustellung', id: 'DELIVERY' },
               ]}
               emptyText={"Alle anzeigen"}
               alwaysOn />,
]

let billingFiltersDefaultValues = {
  berrechnet: ['0', ''],
  boolchar_sperrenfuerrechnung: false,
};

export { billingFilters, billingFiltersDefaultValues };
