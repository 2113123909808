export const imageTypes = [
  { id: "submitter_vorne_links", name: "Abholung Vorne links" },
  { id: "submitter_felge_vorne_links", name: "Abholung Felge Vorne links" },
  { id: "submitter_vorne_rechts", name: "Abholung Vorne rechts" },
  { id: "submitter_felge_vorne_rechts", name: "Abholung Felge Vorne rechts" },
  { id: "submitter_hinten_rechts", name: "Abholung Hinten rechts" },
  { id: "submitter_felge_hinten_rechts", name: "Abholung Felge Hinten rechts" },
  { id: "submitter_hinten_links", name: "Abholung Hinten links" },
  { id: "submitter_felge_hinten_links", name: "Abholung Felge Hinten links" },
  { id: "submitter_dach", name: "Abholung Dach" },
  { id: "submitter_sicherheits_set", name: "Abholung Sicherheits-Set" },
  { id: "submitter_tire_fit", name: "Abholung Tire-Fit/Reserverad" },
  { id: "submitter_insulating_mat", name: "Abholung Motorraum-Dämmmatte" },
  { id: "submitter_innenraum_vorne_links", name: "Abholung Innenraum Vorne links" },
  { id: "submitter_innenraum_hinten", name: "Abholung Innenraum Hinten" },
  { id: "submitter_innenraum_vorne_rechts", name: "Abholung Innenraum Vorne rechts" },
  { id: "submitter_additional_trunk", name: "Abholung Kofferraumabdeckung" },
  { id: "submitter_instrumententafel", name: "Abholung Instrumententafel" },
  { id: "submitter_kfz_schein", name: "Abholung KFZ-Schein" },
  { id: "submitter_kfz_schein_temp", name: "Abholung Rotes Fahrzeugscheinheft" },
  { id: "submitter_schluessel", name: "Abholung Schlüssel+Bordbuch" },
  { id: "submitter_serviceheft", name: "Abholung Serviceheft" },
  { id: "submitter_wheelSet", name: "Abholung Radsatz" },
  { id: "submitter_fuelCard", name: "Abholung Tankkarte" },
  { id: "submitter_chargingCable", name: "Abholung Ladekabel" },
  { id: "submitter_serviceBooklet", name: "Abholung Servicebuch" },
  { id: "receiver_vorne_links", name: "Zustellung Vorne links" },
  { id: "receiver_felge_vorne_links", name: "Zustellung Felge Vorne links" },
  { id: "receiver_vorne_rechts", name: "Zustellung Vorne rechts" },
  { id: "receiver_felge_vorne_rechts", name: "Zustellung Felge Vorne rechts" },
  { id: "receiver_hinten_rechts", name: "Zustellung Hinten rechts" },
  { id: "receiver_felge_hinten_rechts", name: "Zustellung Felge Hinten rechts" },
  { id: "receiver_hinten_links", name: "Zustellung Hinten links" },
  { id: "receiver_felge_hinten_links", name: "Zustellung Felge Hinten links" },
  { id: "receiver_dach", name: "Zustellung Dach" },
  { id: "receiver_sicherheits_set", name: "Zustellung Sicherheits-Set" },
  { id: "receiver_tire_fit", name: "Zustellung Tire-Fit/Reserverad" },
  { id: "receiver_insulating_mat", name: "Zustellung Motorraum-Dämmmatte" },
  { id: "receiver_innenraum_vorne_links", name: "Zustellung Innenraum Vorne links" },
  { id: "receiver_innenraum_hinten", name: "Zustellung Innenraum Hinten" },
  { id: "receiver_innenraum_vorne_rechts", name: "Zustellung Innenraum Vorne rechts" },
  { id: "receiver_instrumententafel", name: "Zustellung Instrumententafel" },
  { id: "receiver_kfz_schein", name: "Zustellung KFZ-Schein" },
  { id: "receiver_kfz_schein_temp", name: "Zustellung Rotes Fahrzeugscheinheft" },
  { id: "receiver_schluessel", name: "Zustellung Schlüssel+Bordbuch" },
  { id: "receiver_serviceheft", name: "Zustellung Serviceheft" },
  { id: "receiver_wheelSet", name: "Zustellung Radsatz" },
  { id: "receiver_additional_trunk", name: "Zustellung Kofferraumabdeckung" },
  { id: "receiver_fuelCard", name: "Zustellung Tankkarte" },
  { id: "receiver_chargingCable", name: "Zustellung Ladekabel" },
  { id: "receiver_serviceBooklet", name: "Zustellung Servicebuch" },
  { id: "fuelCard", name: "Tankkarte" },
  { id: "radsatz", name: "Radsatz" },
  { id: "wheelSet", name: "Radsatz" },
  { id: "chargingCable", name: "Ladekabel" },
  { id: "serviceBooklet", name: "Servicebuch" },
];
