import { linkToRecord } from 'react-admin';
import Link from '../../utils/components/Link';
import IconLink from "@material-ui/icons/InsertLink";
import PropTypes from "prop-types";

const EntityLink = ( props ) => {
  const entity = props.record

  if( !entity || ( !entity[ "@type" ] && !props.context ) ) {
    return null;
  }

  const { title, icon, style } = props
  const context = props.context ?? entity[ "@type" ]

  let type = '';
  if( context === "Contract" ) {
    type = 'contracts';
  } else if( context === "Protocol" ) {
    type = 'protocols';
  } else if( context === "Address" ) {
    type = 'addresses';
  } else if( context === "ProtocolImage" ) {
    type = 'protocol_images';
  }

  if( !type ) {
    console.warn( "Cant find type for entity", entity );
    return null;
  }

  if( !entity[ '@id' ] && !entity.id ) {
    return;
  }

  const id = entity[ '@id' ] ?? '/api/contracts/'+entity.id;

  const link = linkToRecord( `/${type}`, id, 'edit' );
  const text = props.children ?? props.label;

  return <Link
    to={link}
    title={title}
    style={style ?? {}}
    icon={icon}
    {...props}>
    {text}
  </Link>;
};

EntityLink.propTypes = {
  entity: PropTypes.object,
  title: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.any,
}

EntityLink.defaultProps = {
  title: '',
  label: '',
  icon: <IconLink/>,
}

export default EntityLink;
