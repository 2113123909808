import UserGroupsList from "./UserGroupsList";
import { EntityCreate, EntityEdit } from "./UserGroupsEdit";

export default {
  name: "user_groups",
  options: {
    label: "Rollen"
  },
  list: UserGroupsList,
  // show: EntityShow,
  edit: EntityEdit,
  create: EntityCreate,
}
