import * as React from "react";
import { ArrayInput, Create, Edit, FormWithRedirect, SaveButton } from "react-admin";


import DefaultEditActions from "../utils/DefaultEditActions";
import BundleFormIterator from "./components/BundleFormIterator";
import { useState } from "react";
import useLocalStorage from "../utils/useLocalStorage";
import { ParagraphBox, ParagraphHeadline, Spacer } from "../utils/components/FormElements";
import { Box } from "@material-ui/core";
import { BooleanInput } from "../utils/components";
import Toolbar from "../utils/components/Toolbar";

const EntityForm = props => {
  const [posPage, setPosPage] = useState(0);
  const [posPerPage, setPosPerPage] = useLocalStorage('billing.positions.perPage', 10);

  React.useEffect(() => {
    setPosPage(0);
    return () => {
      setPosPage(0);
    }
  }, []);


  return <FormWithRedirect
    {...props}
    render={ formProps =>
    <form {...formProps} style={{ width: '100%' }}>
      <Box p="1em" style={{margin: "0 0 40px 0"}}>
        <ParagraphHeadline>Allgemein</ParagraphHeadline>
        <ParagraphBox display={"flex"} alignItems={"center"}>
          <BooleanInput source="active" label={"Aktiv"} />
          <BooleanInput source="openForApplications" label={"In Angebotsliste anzeigen"} />
        </ParagraphBox>

        <Spacer />

        <ParagraphHeadline>Aufträge</ParagraphHeadline>
        <ArrayInput source="contractBundles" label={""} {...props}>
          <BundleFormIterator
            perPage={posPerPage}
            setPerPage={setPosPerPage}
            page={posPage}
            setPage={setPosPage}
            {...props} />
        </ArrayInput>
      </Box>

      <Toolbar {...formProps} />
    </form>
  }>
  </FormWithRedirect>
}

const EntityTitle = ( { record } ) => {
  return <span>Fahrtpaket {record ? `"${record.bundle}"` : ''}</span>;
}

const EntityEdit = props => (
  <Edit actions={<DefaultEditActions {...props} />} title={<EntityTitle/>} {...props}>
    <EntityForm  {...props} />
  </Edit>
);

const EntityCreate = props => (
  <Create actions={<DefaultEditActions {...props} />} {...props} title={"Neuen Eintrag anlegen"}>
    <EntityForm style={{ width: '100%' }} {...props} />
  </Create>
);


export { EntityEdit, EntityCreate };
