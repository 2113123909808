import { ArrayInput, Create, CreateButton, Edit, FormWithRedirect, ListButton, TopToolbar, BooleanInput } from "react-admin";
import { FormTabContainerBox, ParagraphBox, ParagraphHeadline, Spacer } from "../utils/components/FormElements";
import DefaultEditActions from "../utils/DefaultEditActions";
import Toolbar from "../utils/components/Toolbar";
import { ResetArticlesButton } from "../utils/components/Buttons";
import * as React from "react";
import BillingBlock from "./components/form/BillingBlock";
import RecipientBlock from "./components/form/RecipientBlock";
import BillingPositionsFormIterator from "../utils/components/InputComponents/ArrayInput/BillingPositionsFormIterator";
import useLocalStorage from "../utils/useLocalStorage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { InvoiceDocumentButtonGroup } from "./components/InvoiceDocumentButtonGroup";
import { useState } from "react";
import { CancellationInvoiceButton } from "./components/CancellationInvoiceButton";


const validateBeforeSubmit = ( values ) => {
  const errors = {};
  if ( values.type === 'Gutschrift' && !values.recipientUstid && !values.recipientSteuerNo ) {
    errors.recipientUstid = ['UstId oder Steuernummer muss angegeben werden'];
    errors.recipientSteuerNo = ['UstId oder Steuernummer muss angegeben werden'];
  }

  return errors;
}

const EntityForm = ( props ) => {
  const [posPage, setPosPage] = useState(0);
  const [posPerPage, setPosPerPage] = useLocalStorage('billing.positions.perPage', 100);

  React.useEffect(() => {
    setPosPage(0);
    return () => {
      setPosPage(0);
    }
  }, []);

  const { isCreate, ...rest } = props;

  return (
    <FormWithRedirect
      warnWhenUnsavedChanges
      validate={validateBeforeSubmit}
      {...rest}
      render={( formProps ) => {

        return (
          <form>
            <FormTabContainerBox>
              <BillingBlock record={formProps.record} isCreate={isCreate} />

              <Spacer/>

              <RecipientBlock record={formProps.record} />

              <Spacer/>

              <ParagraphHeadline>Positionen</ParagraphHeadline>

              <ParagraphBox>
                <ArrayInput source="invoiceEntries" label={""}>
                  <BillingPositionsFormIterator
                    perPage={posPerPage}
                    setPerPage={setPosPerPage}
                    page={posPage}
                    setPage={setPosPage}
                    fieldName={'invoice_entries'} />
                </ArrayInput>
              </ParagraphBox>

            </FormTabContainerBox>

            {isCreate ?
             <Toolbar
               showSaveAndCloseButton={true}
               showSaveButton={false}
               showResetButton={false}
               saveAndCloseButtonLabel={"Erstellen"}
               {...formProps} /> :
             <Toolbar
               showSaveAndCloseButton={true}
               showResetButton={true}
               {...formProps}>
               <ResetArticlesButton {...rest} />
             </Toolbar>
            }

          </form>
        )
      }}
    />
  )
}

const EntityTitle = ( { record } ) => {
  let title = `${record.type} ${record ? record.orderNumber : ""}`
  if( record.invoice ) {
    title = `Rechnung ${record.orderNumber} - Stornorechnung zu Rechnung ${record.invoice.orderNumber}`
  }
  return <span>{title}</span>;
};


const InvoiceEditActions = ( props ) => {
  return (
    <>
      <TopToolbar>
        <ListButton label="Zurück zur Liste" icon={<ChevronLeft/>}/>
        <CancellationInvoiceButton record={props?.data}  />
        <InvoiceDocumentButtonGroup record={props?.data} />
        <CreateButton label={"neu"}/>
      </TopToolbar>
    </>
  );
};

const EntityEdit = ( props ) => {
  return <Edit
    actions={<InvoiceEditActions {...props} />}
    title={<EntityTitle/>}
    // title={"Neuen Eintrag anlegen"}

    {...props}
  >
    <EntityForm isCreate={false}/>
  </Edit>
};

const EntityCreate = ( props ) => (
  <Create
    actions={<DefaultEditActions allowNew={true} {...props} />}
    title={"Neuen Eintrag anlegen"}
    {...props}
  >
    <EntityForm isCreate={true}/>
  </Create>
);

export { EntityEdit, EntityCreate };
