import { AutocompleteArrayInput, AutocompleteInput, ReferenceInput } from "react-admin";
import ReferenceArrayInput from "../../utils/components/ReferenceArrayInput";

export const UserReferenceInput = ( props ) => {

  return <ReferenceInput
    label={"Nutzer"}
    reference={"users"}
    fullWidth
    resettable
    format={( v ) =>
      v !== null &&
      typeof v === "object" &&
      v.hasOwnProperty( "@id" )
      ? v[ "@id" ]
      : v
    }
    filterToQuery={( searchText ) => ( { _name: searchText } )}
    perPage={200}
    helperText={false}
    {...props}
  >
    <AutocompleteInput
      optionText={"kategorie"}
      source={"kategorie"}
    />
  </ReferenceInput>
}

export const UserReferenceArrayInput = ( props ) => {

  return <ReferenceArrayInput
    label={"Nutzer"}
    reference={"users"}
    fullWidth
    resettable
    format={( v ) =>
      v !== null &&
      typeof v === "object" &&
      v.hasOwnProperty( "@id" )
      ? v[ "@id" ]
      : v
    }
    filterToQuery={( searchText ) => ( { _name: searchText } )}
    perPage={200}
    helperText={false}
    {...props}
  >
    <AutocompleteArrayInput
      optionText={"username"}
      source={"username"}
    />
  </ReferenceArrayInput>
}
