import { Button, List, ListContextProvider, ResourceContextProvider, useListContext, useListController } from 'react-admin';
import groupByDay from "../components/Timeline/groupByDay";
import DefaultPagination from "../../utils/DefaultPagination";
import React, { useState } from "react";
import { contractsFilters, contractsFiltersDefaultValues } from "../components/ContractsFilter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import { MultipleDriverField } from "../components/MultipleDriverField";
import { CreateBulkBundleButton } from "../components/ListButtons/CreateBundleButton";
import { BulkToggleDisplayInOfferList } from "../components/ListButtons/ToggleDisplayInOfferListButton";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import { getDayString, useStyles } from "../ContractList";
import Table from "@material-ui/core/Table";
import DispositionTableItem from "./DispositionTableItem";

const DispositionListView = () => {
  const [selectedContracts, setSelectedContracts] = useState( new Set() );
  const { ids, data } = useListContext();
  const classes = useStyles();

  if( !ids ) {
    return null;
  }

  const records = ids.map( ( id ) => data[ id ] );
  const { days, eventsByDay } = groupByDay( records );
  if( days.length === 0 ) {
    return <></>;
  }

  const listItems = days.map( ( day ) => {
    const contractTableDayGroup = [<TableRow key={day.toString()}>
                                     <TableCell component="th" colSpan={20} className={classes.dayRow}>
                                       <Typography
                                         className={classes.day}
                                         variant="subtitle2"
                                         gutterBottom
                                       >
                                         {getDayString( day )} ({eventsByDay[ day ].length} Fahrt/en)
                                       </Typography>
                                     </TableCell>
                                   </TableRow>]
    let itemsByDay = []
    const contractTableItems = eventsByDay[ day ].map( ( record ) => {
      let contract = record
      if( record.anschlussvon && data[ record.anschlussvon ] ) {
        contract = data[ record.anschlussvon ]
      }
      if( itemsByDay.includes( contract['@id'] ) ) {
        return null
      }
      itemsByDay.push( contract['@id'] )
      return  <DispositionTableItem record={contract} key={contract['@id']} selectedContracts={selectedContracts} setSelectedContracts={setSelectedContracts}/>
    } )

    return contractTableDayGroup.concat( contractTableItems )
  } )


  return (
    <Box>
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <Box className={classes.responsiveBox}>
          {selectedContracts.size > 0 && (
            <>
              <MultipleDriverField contracts={selectedContracts} setSelectedContracts={setSelectedContracts} />&nbsp;
              <CreateBulkBundleButton contracts={selectedContracts} setSelectedContracts={setSelectedContracts} />&nbsp;
              <BulkToggleDisplayInOfferList contracts={selectedContracts}  setSelectedContracts={setSelectedContracts}/>&nbsp;
              <Button onClick={() => setSelectedContracts(new Set())} label="Auswahl aufheben" variant="outlined" style={{ marginTop: "5px" }}/>
            </>
          )}
        </Box>

        <DefaultPagination/>
      </Box>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeadCell} colSpan={3}>
                Nr
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                Fahrer / Kunde
              </TableCell>
              <TableCell className={classes.tableHeadCell} colSpan={3}>
                Tour
              </TableCell>
              <TableCell className={classes.tableHeadCell}>Retoure</TableCell>
              <TableCell className={classes.tableHeadCell} colSpan={20}>
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listItems}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export const DispositionList = ( props ) => {
  const perPage = parseInt( process.env.REACT_APP_LIST_ITEMS )

  const listControllerProps = {
    basePath: '/disposition',
    hasCreate: false,
    resource: 'contracts',
    perPage: perPage,
    exporter: false,
    syncWithLocation: true,
    disableSyncWithLocation: false,
    sort: {
      field: 'datum',
      order: 'ASC'
    },
  };


  const controllerProps = useListController(listControllerProps);

  return (
    <ResourceContextProvider value="contracts">
      <ListContextProvider value={controllerProps}>
        <List
          filter={{ anschlussvon: '' }}
          filters={contractsFilters}
          filterDefaultValues={contractsFiltersDefaultValues}
          allowCreate={false}
          basePath={'/disposition'}
          syncWithLocation={true}
          disableSyncWithLocation={false}
          sort={{ field: 'datum', order: 'ASC' }}
          {...controllerProps}
        >
          <DispositionListView {...props} key={"dispositionListView"}/>
        </List>
      </ListContextProvider>
    </ResourceContextProvider>
  );
};
