import { Button, FormWithRedirect, useDataProvider, useNotify, useRefresh } from "react-admin";
import axios from "axios";
import CheckIcon from "@material-ui/icons/Check";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import GlobalLoading from "../../../utils/components/GlobalLoading";
import React, { useState } from "react";
import { Box } from "@material-ui/core";

export const ConfirmButton = (props) => {
  const { record, sendConfirmationEmail, title, style, buttonText } = props;
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();

  const notify = useNotify();
  if (!record) {
    return null;
  }

  const confirm = async () => {
    setLoading(true);
    dataProvider.update(
      'contracts', {
        id: record['@id'],
        data: {
          auftragbestaetigt: '1',
          sendConfirmationEmail: sendConfirmationEmail
        }
      } ).then( ( response ) => {
      // success side effects go here
      notify( `Auftrag bestätigt`, { type: "success" } );
      record.unbestaetigt = false;
      setLoading(false);
    } ).catch( ( error ) => {
      // failure side effects go here
      notify( `Fehler beim bearbeiten: ${error.message}`, { type: "warning" } );
      setLoading(false);
    } )
  };

  return record.auftragbestaetigt !== '1' ? (
    <>
      {loading &&<GlobalLoading/>}
      <Button
        style={style || { minWidth: "none" }}
        title={title || "Auftrag bestaetigen"}
        onClick={confirm}
        children={
          sendConfirmationEmail === true ? <CheckCircleIcon /> : <CheckIcon />
        }
        label={buttonText}
      />
    </>
  ) : null;
}

export const DeclineButton = (props) => {
  const { record, title, style } = props;
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();

  const notify = useNotify();
  const refresh = useRefresh();
  if (!record) {
    return null;
  }

  const decline = async () => {
    setLoading(true);
    dataProvider
      .update( "contracts", {
        id: record.id,
        data: {
          auftragbestaetigt: "0",
        },
      })
      .then( ( response ) => {
        // success side effects go here
        notify( "Erfolgreich bearbeitet", { type: "success" } );
        setLoading( false );
      } )
      .catch( ( error ) => {
        // failure side effects go here
        notify( `Error: ${ error.message }`, { type: "warning" } );
        setLoading( false );
      } );

  };

  return record.auftragbestaetigt === '1' ? (
    <>
      {loading &&<GlobalLoading/>}
      <Button
        style={{ minWidth: "none" }}
        title={"Auftragsbestätigung zurückziehen"}
        onClick={decline}
        label={"Auftragsbestätigung zurückziehen"}
      />
    </>
  ) : null;
};

export const BulkConfirmButton = ( { contracts, setSelectedContracts }) => {
  const dataProvider = useDataProvider()
  const [loading, setLoading] = useState( false )
  const notify = useNotify()

  const update = async(sendMail) => {
    setLoading( true )
    let contractIds = []

    for( const contract of contracts ) {
      contractIds.push( `/api/contracts/${contract}` )
    }

    for(const contract of contractIds ) {
      await dataProvider.update(
        'contracts', {
          id: contract,
          data: {
            auftragbestaetigt: '1',
            sendConfirmationEmail: sendMail
          }
        } ).then( ( response ) => {
        // success side effects go here
        notify( `Auftrag bestätigt`, { type: "success" } );
      } ).catch( ( error ) => {
        // failure side effects go here
        notify( `Fehler beim bearbeiten: ${error.message}`, { type: "warning" } );
      } )
    }

    setSelectedContracts( new Set() )

    setLoading( false )
  }

  return <>
    {loading &&<GlobalLoading/>}
    <Button
      children={<>Aufträge bestätigen</>}
      variant="contained"
      onClick={() => update(true)}
      style={{ marginTop: "5px" }}
    />&nbsp;<Button
      children={<>Aufträge still bestätigen</>}
      variant="contained"
      onClick={() => update(false)}
      style={{ marginTop: "5px" }}
    />
  </>
}

export const ConfirmButtonGroup = ( { record }) => {
  return record.auftragbestaetigt === '1' ? <DeclineButton record={record} /> : <>
    <ConfirmButton record={record} sendConfirmationEmail={true} title={"Auftrag bestätigen und E-Mail senden"} buttonText={"Auftrag bestätigen"}/>
    <ConfirmButton record={record} title={"Auftrag still bestätigen"} buttonText={"Auftrag still bestätigen"}/>
  </>
}
