import { FunctionField } from 'react-admin';
import { useRecordContext } from 'ra-core';
import get from 'lodash/get';
import CurrencyFormatter from "./styles/CurrencyFormatter";

const PriceTextField = (props) => {
  const { source, ...rest } = props;
  const record = useRecordContext(props);
  const value = get(record, source);

  return (
    <FunctionField
      render={() => {
        return CurrencyFormatter( value );
      }}
      {...rest}
    />
  )
};

export default PriceTextField;
